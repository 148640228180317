import { Box } from "@mui/material";
import colors from '../utils/index.module.scss';

interface Props {
    checkboxStyle: {
        dim: string;
        borderRadius: string;
        outerDim: string;
        outerRadius: string;
    } | undefined
    focused: boolean
}

export function CheckboxIcon(props: Props) {
    const { checkboxStyle } = props
    const { focused } = props

    return (
        <div
            style={{
                width: checkboxStyle?.outerDim,
                height: checkboxStyle?.outerDim,
                backgroundColor: focused ? colors.primary50 : '',
                borderRadius: checkboxStyle?.outerRadius,
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center'
            }}>
            <Box
                sx={{
                    zIndex: 2,
                    height: checkboxStyle?.dim,
                    width: checkboxStyle?.dim,
                    borderRadius: checkboxStyle?.borderRadius,
                    border: '1px solid ' + (focused ? colors.primary500 : colors.neutral100),
                    backgroundColor: colors.white,
                    "&:hover": {
                        borderColor: colors.primary500
                    },
                }}
            />
        </div>
    )
}