import axios from "axios";
import { keycloak } from "../keycloak";
import { CompanyCountDTO, DashboardActivitiesResponse, FiltersCounterDTO } from "./dto";
import { DashboardService } from "./service";

export class DashboardServiceImpl implements DashboardService {
    public activityDashboard(companyIds: number[], year: number): Promise<DashboardActivitiesResponse[]> {
        let url = '/api/companies/dashboard?'
        companyIds.forEach(id => url = url.concat('companyIds=' + id + '&'))
        url = url.concat('year=' + year)
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public getAllCount(request: FiltersCounterDTO): Promise<CompanyCountDTO> {
        let url = '/api/companies/dashboardCount?' + "status=" + request.status
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}
