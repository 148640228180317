import { MenuItem } from "@mui/material";
import { format, isValid } from "date-fns";
import { ReactNode, useEffect, useState } from "react";
import { ButtonComponent } from "../../button";
import { Colors, IconStyle, Size, Variant } from "../../button/dto";
import { FileInputComponent } from "../../fileInput";
import { FormGroup } from "../../formGroup";
import { HasDocumentIcon } from "../../icons/hasDocument";
import { ModalComponent } from "../../modal";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { SelectComponent } from "../../select";
import { SpinnerComponent } from "../../spinner";
import { TextInput } from "../../textInput";
import colors from '../../utils/index.module.scss';
import { EmployeeTypology, F24Typology, FinancialStatementsTypology, FiscalDocumentCreationDTO, FiscalDocumentStatus, FiscalDocumentType, OtherTypology } from "./dtoDocument";
import { createFiscalDocuments, setOpenFiscalDocumentAreaModal } from "./sliceDocument";

interface Props {
    type: FiscalDocumentType
}

export function FiscalDocumentAreaModal(props: Props) {
    const { type } = props

    const dispatch = useAppDispatch()

    const companyState = useAppSelector(state => state.company)
    const fiscalDocumentState = useAppSelector(state => state.fiscalDocument)

    const [file, setFile] = useState<File | null>(null)
    const [name, setName] = useState<string>('')
    const [typology, setTypology] = useState<FinancialStatementsTypology | F24Typology | EmployeeTypology | OtherTypology>('' as FinancialStatementsTypology | '' as F24Typology | '' as EmployeeTypology | '' as OtherTypology)
    const [expirationDate, setExpirationDate] = useState<Date>(new Date())
    const [referenceDate, setReferenceDate] = useState<Date>(new Date())
    const [month, setMonth] = useState<string>('')
    const [year, setYear] = useState<string>('')
    const [description, setDescription] = useState<string>('')

    let formData = new FormData();

    useEffect(() => {
        if (fiscalDocumentState.openFiscalDocumentAreaModal) {
            setFile(null)
            setName('')
            setMonth('')
            setYear('')
            setDescription('')
            setTypology('' as FinancialStatementsTypology | '' as F24Typology | '' as EmployeeTypology | '' as OtherTypology)
            setExpirationDate(new Date())
        }
    }, [fiscalDocumentState.openFiscalDocumentAreaModal])

    useEffect(() => {
        if (expirationDate === undefined) {
            setExpirationDate(new Date())
        }
    }, [expirationDate])

    let years: ReactNode[] = []
    const startYear = 2022

    for (let i = startYear; i <= (new Date().getFullYear() + 1); i++) {
        years.push(<MenuItem value={i} key={i}>{i}</MenuItem>)
    }

    return (
        <ModalComponent
            open={fiscalDocumentState.openFiscalDocumentAreaModal}
            handleClose={() => dispatch(setOpenFiscalDocumentAreaModal(false))}
        >
            <div
                style={{
                    padding: '20px',
                    backgroundColor: colors.white,
                    boxShadow: colors.boxShadowXL,
                    borderRadius: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '100%'
                }}
            >
                {
                    companyState.findCompanyStatus === 'loading' ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
                            <SpinnerComponent size={"small"} />
                        </div>
                    ) : (
                        <>
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="text text--lg typography--semibold typography--black">Aggiungi file</span>
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-between" style={{ gap: '16px', width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: 8, width: '100%', alignItems: 'center' }}>
                                    {file !== null && <HasDocumentIcon />}
                                    <FileInputComponent id={"fiscal-documents-modal"}
                                        onChange={e => {
                                            if (e !== null && e !== undefined) {
                                                setFile(e[0])
                                                setName(e[0].name)
                                            }
                                        }}
                                    />
                                </div>
                                <FormGroup
                                    label={"Nome file"}
                                    required
                                    inputs={[
                                        <TextInput key={"fiscal-documents-modal-name"}
                                            onChange={e => {
                                                setName(e.target.value)
                                            }}
                                            id={"type"}
                                            value={name}
                                            type={"text"}
                                            placeholder={"es. Fatture Acquisti Marzo 2023"}
                                        />]}
                                    style={"column"}
                                />
                                <FormGroup
                                    label={"Tipologia"}
                                    required
                                    inputs={[
                                        <SelectComponent
                                            id={""}
                                            onChange={e => {
                                                setTypology(e.target.value as FinancialStatementsTypology || e.target.value as F24Typology || e.target.value as EmployeeTypology)
                                            }}
                                            key={"fiscal-documents-modal-typology"}
                                            value={typology}
                                            menuItems={
                                                companyState.taxAreaTabValue === 0 ?
                                                    [
                                                        <MenuItem value='' key={'0'}>Seleziona la tipologia...</MenuItem>,
                                                        <MenuItem value={FinancialStatementsTypology.unique} key={FinancialStatementsTypology.unique}>Unico</MenuItem>,
                                                        <MenuItem value={FinancialStatementsTypology.iva} key={FinancialStatementsTypology.iva}>IVA</MenuItem>,
                                                        <MenuItem value={FinancialStatementsTypology.document770} key={FinancialStatementsTypology.document770}>770</MenuItem>,
                                                        <MenuItem value={FinancialStatementsTypology.balanceDocument} key={FinancialStatementsTypology.balanceDocument}>Bilancio</MenuItem>,
                                                    ]
                                                    : companyState.taxAreaTabValue === 1 ? [
                                                        <MenuItem value='' key={'0'}>Seleziona la tipologia...</MenuItem>,
                                                        <MenuItem key={F24Typology.contributions} value={F24Typology.contributions}>Contributi</MenuItem>,
                                                        <MenuItem key={F24Typology.directTax} value={F24Typology.directTax}>Imposte dirette</MenuItem>,
                                                        <MenuItem key={F24Typology.iva} value={F24Typology.iva}>IVA</MenuItem>,
                                                        <MenuItem key={F24Typology.other} value={F24Typology.other}>Altro</MenuItem>
                                                    ]
                                                        : companyState.taxAreaTabValue === 2 ? [
                                                            <MenuItem value='' key={'0'}>Seleziona la tipologia...</MenuItem>,
                                                            <MenuItem key={EmployeeTypology.paySlip} value={EmployeeTypology.paySlip}>Busta Paga</MenuItem>,
                                                            <MenuItem key={EmployeeTypology.attendance} value={EmployeeTypology.attendance}>Presenze</MenuItem>,
                                                            <MenuItem key={EmployeeTypology.cu} value={EmployeeTypology.cu}>CU</MenuItem>,
                                                            <MenuItem key={EmployeeTypology.other} value={EmployeeTypology.other}>Altro</MenuItem>
                                                        ] :
                                                            [
                                                                <MenuItem value='' key={'0'}>Seleziona la tipologia...</MenuItem>,
                                                                <MenuItem value={OtherTypology.inspection} key={OtherTypology.inspection}>Visura</MenuItem>,
                                                                <MenuItem value={OtherTypology.durc} key={OtherTypology.durc}>Durc</MenuItem>,
                                                            ]}
                                        />]}
                                    style={"column"}
                                />
                                {
                                    companyState.taxAreaTabValue === 1 &&
                                    <FormGroup
                                        label="Scadenza"
                                        inputs={[
                                            <TextInput
                                                id={""}
                                                type={"date"}
                                                placeholder={""}
                                                value={format((expirationDate), 'yyyy-MM-dd')}
                                                onChange={e => {
                                                    if (isValid(new Date(e.target.value)))
                                                        setExpirationDate(new Date(e.target.value))
                                                    else {
                                                        setExpirationDate(new Date())
                                                    }
                                                }}
                                            />
                                        ]}
                                        style={"column"}
                                    />

                                }
                                {
                                    companyState.taxAreaTabValue === 0 &&
                                    <FormGroup
                                        label={''}
                                        inputs={[
                                            <SelectComponent
                                                id={""}
                                                required
                                                label="Anno di riferimento"
                                                onChange={e => {
                                                    setYear(e.target.value)
                                                }}
                                                key={"fiscal-document-financial-statements-year"}
                                                value={year}
                                                menuItems={[<MenuItem value='' key={'0'}>Seleziona anno</MenuItem>, years]}
                                            />
                                        ]}
                                        style={"column"}
                                    />
                                }
                                {
                                    companyState.taxAreaTabValue === 4 &&
                                    <FormGroup
                                        label="Anno di rilascio"
                                        required
                                        inputs={[
                                            <TextInput
                                                id={""}
                                                type={"date"}
                                                placeholder={""}
                                                value={format((referenceDate), 'yyyy-MM-dd')}
                                                onChange={e => {
                                                    if (isValid(new Date(e.target.value)))
                                                        setReferenceDate(new Date(e.target.value))
                                                    else {
                                                        setReferenceDate(new Date())
                                                    }
                                                }}
                                            />
                                        ]}
                                        style={"column"}
                                    />
                                }
                                {
                                    companyState.taxAreaTabValue === 2 &&
                                    <FormGroup
                                        label={''}
                                        inputs={[
                                            <div style={{ display: 'flex', gap: 12 }}>
                                                <SelectComponent
                                                    id={""}
                                                    required
                                                    label="Anno di riferimento"
                                                    onChange={e => {
                                                        setYear(e.target.value)
                                                    }}
                                                    key={"fiscal-documents-modal-year"}
                                                    value={year}
                                                    menuItems={[<MenuItem value='' key={'0'}>Seleziona anno</MenuItem>, years]}
                                                />
                                                <SelectComponent
                                                    id={""}
                                                    required
                                                    label="Mese di riferimento"
                                                    onChange={e => {
                                                        setMonth(e.target.value)
                                                    }}
                                                    key={"fiscal-documents-modal-month"}
                                                    value={month}
                                                    menuItems={
                                                        [
                                                            <MenuItem value='' key={'select-a-month'}>Seleziona mese</MenuItem>,
                                                            <MenuItem value='0' key={'0'}>Gennaio</MenuItem>,
                                                            <MenuItem value='1' key={'1'}>Febbraio</MenuItem>,
                                                            <MenuItem value='2' key={'2'}>Marzo</MenuItem>,
                                                            <MenuItem value='3' key={'3'}>Aprile</MenuItem>,
                                                            <MenuItem value='4' key={'4'}>Maggio</MenuItem>,
                                                            <MenuItem value='5' key={'5'}>Giugno</MenuItem>,
                                                            <MenuItem value='6' key={'6'}>Luglio</MenuItem>,
                                                            <MenuItem value='7' key={'7'}>Agosto</MenuItem>,
                                                            <MenuItem value='8' key={'8'}>Settembre</MenuItem>,
                                                            <MenuItem value='9' key={'9'}>Ottobre</MenuItem>,
                                                            <MenuItem value='10' key={'10'}>Novembre</MenuItem>,
                                                            <MenuItem value='11' key={'11'}>Dicembre</MenuItem>,
                                                        ]
                                                    }
                                                />
                                            </div>
                                        ]}
                                        style={"column"}
                                    />
                                }
                                <FormGroup
                                    label={"Note"}
                                    inputs={[<TextInput key={"fiscal-documents-modal-notes"}
                                        onChange={e => {
                                            setDescription(e.target.value)
                                        }}
                                        id={"type"}
                                        type={"text"}
                                        placeholder={"Aggiungi qui una descrizione"}
                                        multiline
                                    />]}
                                    style={"column"} />
                            </div>
                            <div style={{ margin: 0, padding: 0, gap: '12px' }} className="row">
                                <div className="col" style={{ margin: 0, padding: 0 }}><ButtonComponent label={"Annulla"} onClick={() => dispatch(setOpenFiscalDocumentAreaModal(false))} color={Colors.NEUTRAL} variant={Variant.OUTLINE} size={Size.FIT} iconStyle={IconStyle.OFF} /></div>
                                <div className="col" style={{ margin: 0, padding: 0 }}><ButtonComponent label={"Carica file"}
                                    onClick={() => {
                                        formData.delete('file')
                                        formData.delete('fiscalDocument')
                                        if (file !== null) {
                                            formData.append('file', file);
                                        }
                                        const fiscalDocumentCreationDTO: FiscalDocumentCreationDTO = {
                                            expirationDate: expirationDate,
                                            status: FiscalDocumentStatus.toRead,
                                            note: description,
                                            fileName: name,
                                            typology: typology,
                                            referenceDate: referenceDate,
                                            referenceMonth: Number(month),
                                            referenceYear: Number(year),
                                            companyId: companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse.id.toString() : '',
                                            type: type
                                        }
                                        dispatch(setOpenFiscalDocumentAreaModal(false))
                                        formData.append('fiscalDocument', JSON.stringify(fiscalDocumentCreationDTO));
                                        dispatch(createFiscalDocuments(formData))
                                    }}
                                    color={Colors.PRIMARY} variant={Variant.SOLID} size={Size.FIT} iconStyle={IconStyle.OFF}
                                    disabled={
                                        companyState.taxAreaTabValue === 0
                                            ? (file === null
                                                || typology === '' as FinancialStatementsTypology
                                                || year === '')
                                            : companyState.taxAreaTabValue === 1
                                                ? (file === null
                                                    || typology === '' as F24Typology)
                                                : companyState.taxAreaTabValue === 1
                                                    ? (file === null
                                                        || typology === '' as EmployeeTypology
                                                        || year === ''
                                                        || month === '') :
                                                    (
                                                        file === null
                                                        || typology === '' as OtherTypology
                                                    )
                                    }
                                />
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </ModalComponent >
    )
}