import { Box } from "@mui/material";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import { Breadcrumb } from "../breadcrumb";
import { HeaderComponent } from "../header";
import { HeadingComponent } from "../heading";
import { HeadingSize, HeadingVerticalAlign } from "../heading/dto";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SidebarComponent } from "../sidebar";
import { MenuItems } from "../sidebar/dto";
import { setOpen } from "../sidebar/slice";
import { SpinnerComponent } from "../spinner";
import colors from '../utils/index.module.scss';

interface Props {
    menuItem?: MenuItems
    children: ReactNode
    headingLabel: string
    headingSubLabel?: string
    headingButtons: ReactElement[]
    breadcrumbItems: string[]
    headingSize: HeadingSize
    headingVerticalAlign?: HeadingVerticalAlign
    showSpinner?: boolean
}

export function LayoutComponent(props: Props) {
    const { menuItem } = props
    const { children } = props
    const { headingLabel } = props
    const { headingSubLabel } = props
    const { headingButtons } = props
    const { breadcrumbItems } = props
    const { headingSize } = props
    const { headingVerticalAlign } = props
    const { showSpinner } = props

    const open = useAppSelector(state => state.sidebar.open)

    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    const [flexDirection, setFlexDirection] = useState<'row' | 'column-reverse'>('row');

    const dispatch = useAppDispatch()

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);
        if (screenSize.width < 1080) {
            dispatch(setOpen(false))
            setFlexDirection('column-reverse')
        } else {
            setFlexDirection('row')
        }
        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    return (
        <Box
            style={{
                backgroundColor: colors.primary50,
                minHeight: "100vh",
            }}
            sx={{ display: 'flex' }}
        >
            <SidebarComponent menuItem={menuItem} />
            <Box component="main"
                sx={{
                    flexGrow: 1,
                    position: 'absolute',
                    left: open ? '280px' : '72px',
                    width: 'calc(100% -' + (open ? ' 280px' : ' 72px') + ') !important',
                    transition: '.2s all ease-in-out',
                }}
            >
                <div className="container-fluid" style={{ height: '100%', backgroundColor: colors.primary50 }}>
                    <div className="row" style={{ minHeight: '68px', padding: '24px 24px 12px', backgroundColor: colors.primary50 }}>
                        <div className="col-12 m-0 p-0">
                            <div className="row m-0 p-0 justify-content-between align-items-center gap-3" style={{ flexDirection: flexDirection }}>
                                <div className="col-12 col-lg-auto m-0 p-0">
                                    <Breadcrumb items={breadcrumbItems} />
                                </div>
                                <div className="col-12 col-lg-auto m-0 p-0">
                                    <HeaderComponent />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row"
                        style={{
                            padding: '20px 24px 24px',
                            backgroundColor: colors.white,
                            borderRadius: '24px 0px 0px 0px',
                            boxShadow: 'rgb(34 36 47 / 5%) 0px 1px 10px',
                            marginLeft: 0,
                            minHeight: 'calc(100vh - 68px)'
                        }}
                    >
                        <div className="col-12 d-flex flex-column w-100" style={{ gap: 16 }}>
                            <HeadingComponent label={headingLabel} verticalAlign={headingVerticalAlign} buttons={headingButtons} size={headingSize} subLabel={headingSubLabel} />
                            {
                                showSpinner === true ? (
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
                                        <SpinnerComponent size={"small"} />
                                    </div>
                                ) : (
                                    children
                                )
                            }
                        </div>
                    </div>
                </div>
            </Box>
        </Box>

    )
}