export interface ChatFilters {
    firstUser: string
    secondUser: string
    topic: number
}

export interface ChatDTO {
    id: number,
    firstUser: string,
    secondUser: string,
    isBroadcast: boolean,
    carbonCopy: string[],
    lastMessageDate: Date,
    topic: number,
    notSeenMessages: number,
    active: boolean
}

export interface UserInfoDTO {
    name: string,
    surname: string,
    role: string,
    mail: string,
    avatarObjectId: string | null,
    userId: string,
    businessName?: string
}

export interface MessageDTO {
    id: number,
    timestamp: Date,
    message: string,
    sender: string
}

export interface MessageResponse {
    total: number
    page: number
    perPage: number
    prev: boolean
    next: boolean
    totalPage: number
    messages: MessageDTO[]
}

export const chatRoleMap = new Map<string | undefined, string>([
    ['admins', 'Amministratore'],
    ['admin-accountants', 'Account'],
    ['accountants', 'Contabile'],
    ['financial-advisors', 'Commercialista'],
    ['operators', 'Collaboratore'],
    ['companies', 'Azienda']
])