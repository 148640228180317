import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { findAvatar } from "../auth/slice";
import { AvatarComponent } from "../avatar";
import { AvatarSize, AvatarType } from "../avatar/dto";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { StyledMenu } from "../contextMenu";
import { MenuItemComponent } from "../contextMenu/menuItem";
import { LogoutIcon } from "../icons/logout";
import { SettingsIcon } from "../icons/settings";
import { keycloak } from "../keycloak";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SpinnerComponent } from "../spinner";
import colors from '../utils/index.module.scss';

export function HeaderComponent() {
    const [anchorElHeader, setAnchorElHeader] = useState<null | HTMLElement>(null);

    const authState = useAppSelector(state => state.auth)

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (authState.findMeResponse !== undefined && authState.findMeResponse.avatarObjectId !== null && authState.findMeResponse.avatarObjectId !== undefined)
            dispatch(findAvatar(authState.findMeResponse.avatarObjectId))
    }, [authState.findMeResponse])

    if (authState.findAvatarStatus === 'loading') {
        return <SpinnerComponent size={"small"} />
    }

    return (
        <div>
            <div className="d-flex flex-row align-items-center" style={{ gap: 4 }} onClick={(event: React.MouseEvent<HTMLElement>) => { setAnchorElHeader(event.currentTarget) }}>
                <div>
                    <ButtonComponent
                        onClick={undefined}
                        color={Colors.NEUTRAL}
                        variant={Variant.LINK}
                        size={Size.SM}
                        iconStyle={IconStyle.OFF}
                        label={keycloak.hasRealmRole('admin') ? "Amministratore" : (authState.findMeResponse?.name + ' ' + authState.findMeResponse?.surname)}
                    />
                </div>
                <div className="d-flex align-items-center">
                    <AvatarComponent src={authState.findAvatarResponse} type={AvatarType.SINGLE} size={AvatarSize.MD} />
                </div>
            </div>
            <StyledMenu anchorEl={anchorElHeader} open={Boolean(anchorElHeader)} onClose={() => setAnchorElHeader(null)}
                anchorOrigin={{
                    vertical: keycloak.hasRealmRole('admin') ? 100 : 140,
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}>
                {
                    !keycloak.hasRealmRole('admin') &&
                    <MenuItemComponent
                        onClick={() => {
                            navigate('/profile')
                        }}>
                        <SettingsIcon colorBase={colors.neutral600} />
                        Profilo
                    </MenuItemComponent>
                }
                <MenuItemComponent
                    onClick={() => keycloak.logout({ redirectUri: window.location.origin })}>
                    <LogoutIcon colorBase={colors.neutral600} />
                    Logout
                </MenuItemComponent>
            </StyledMenu>
        </div>
    )
}