import { TableBody } from "@mui/material";
import { differenceInDays, format } from "date-fns";
import { ReactNode, useEffect } from "react";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { EmptyList } from "../emptyList/emptyList";
import { ErrorPopup } from "../errorPopup";
import { HeadingComponent } from "../heading";
import { HeadingSize } from "../heading/dto";
import { keycloak } from "../keycloak";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SpinnerComponent } from "../spinner";
import { SuccessPopup } from "../successPopup";
import { TableComponent } from "../table";
import { TableCellComponent } from "../table/tableCell";
import { TableHeadComponent } from "../table/tableHead";
import { TableRowComponent } from "../table/tableRow";
import { CompanyMenuBankAccountComponent } from "./menuBankAccount";
import { findAcubeConnectRequest, findByIdAcubeGetAccount, setAcubeGetTransactionsStatus, setAcubeReconnectRequestStatus, setFindAcubeConnectRequestStatus } from "./slice";

export function BankAccountComponent() {
    const companyState = useAppSelector(state => state.company)
    const dispatch = useAppDispatch()

    let acubeAccounts: ReactNode[] = []
    useEffect(() => {
        if (companyState.findCompanyStatus === 'successfully')
            dispatch(findByIdAcubeGetAccount({ id: companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse?.id.toString() : '', uuid: companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse?.acubeAccounts : [] }))
    }, [companyState.findCompanyStatus])

    if (companyState.findByIdAcubeGetAccountStatus === 'loading') {
        return (<div style={{ width: '100%', height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><SpinnerComponent size='small' /></div>)

    }

    if (companyState.findByIdAcubeGetAccountStatus === 'successfully' && companyState.findByIdAcubeGetAccountResponse !== undefined &&
        companyState.findByIdAcubeGetAccountResponse !== null &&
        companyState.findByIdAcubeGetAccountResponse.length > 0) {
        companyState.findByIdAcubeGetAccountResponse.forEach((bank, index) => {
            const expirationDate = new Date(bank.consentExpiresAt)
            const difference = differenceInDays(expirationDate, new Date())
            acubeAccounts.push(
                <TableRowComponent key={bank.uuid + '-' + bank.name}>
                    <TableCellComponent label={bank.name} cellType={'row-medium'} alignLabel={"left"} />
                    <TableCellComponent label={bank.iban === null ? '-' : bank.iban} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent content={
                        <span
                            className={"text text--md text-overflow typography--regular " + (difference <= 20 ? 'typography-destructive--400' : 'typography-neutral--600')} >
                            {format(new Date(bank.consentExpiresAt), 'dd/MM/yyyy')}
                        </span>}
                        cellType={"row-regular"}
                        alignLabel={"left"}
                    />
                    <TableCellComponent content={<CompanyMenuBankAccountComponent uuid={bank.uuid} name={bank.name} fiscalId={bank.fiscalId} consentExpiresAt={bank.consentExpiresAt} />} cellType={"row-regular"} alignLabel={"right"} />
                </TableRowComponent>
            )
        })
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '24px' }}>
            <ErrorPopup
                active={companyState.acubeGetTransactionsStatus === 'failed'}
                close={() => dispatch(setAcubeGetTransactionsStatus('idle'))}
                message="Si è verificato un errore durante la richiesta delle transazioni"
            />
            <ErrorPopup
                active={companyState.findAcubeConnectRequestStatus === 'failed'}
                close={() => dispatch(setFindAcubeConnectRequestStatus('idle'))}
                message="Si è verificato un errore nella richiesta"
            />
            <ErrorPopup
                active={companyState.acubeReconnectRequestStatus === 'failed'}
                close={() => dispatch(setAcubeReconnectRequestStatus('idle'))}
                message="Si è verificato un errore nella richiesta"
            />
            <SuccessPopup
                active={companyState.acubeReconnectRequestStatus === 'successfully'}
                close={() => dispatch(setAcubeReconnectRequestStatus('idle'))}
                message="Richiesta mandata con successo"
            />
            <SuccessPopup
                active={companyState.findAcubeConnectRequestStatus === 'successfully'}
                close={() => dispatch(setFindAcubeConnectRequestStatus('idle'))}
                message="Richiesta mandata con successo"
            />
            {/* if(companyState.findAcubeConnectRequestStatus === 'successfully' && keycloak.hasRealmRole('financialAdvisor') POPUP */}
            <HeadingComponent
                label={"Conto corrente"}
                size={HeadingSize.MD}
                buttons={keycloak.hasRealmRole("financial-advisor") ? [
                    <ButtonComponent
                        label={"Richiedi accesso"}
                        onClick={() => dispatch(findAcubeConnectRequest(companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse?.id.toString() : ''))}
                        color={Colors.PRIMARY}
                        variant={Variant.OUTLINE}
                        size={Size.MD}
                        iconStyle={IconStyle.OFF}
                    />
                ] : []}
            />
            <TableComponent>
                <TableHeadComponent>
                    <TableCellComponent label={"nome"} cellType={"head"} alignLabel={"left"} size="small" />
                    <TableCellComponent label={"iban"} cellType={"head"} alignLabel={"left"} />
                    <TableCellComponent label={"scadenza"} cellType={"head"} alignLabel={"left"} size="small" />
                    <TableCellComponent cellType={"head"} alignLabel={"right"} size="context-menu" />
                </TableHeadComponent>
                <TableBody>
                    {acubeAccounts}
                </TableBody>
            </TableComponent>
            {
                companyState.findByIdAcubeGetAccountResponse !== undefined &&
                companyState.findByIdAcubeGetAccountResponse.length === 0 &&
                <div style={{ width: '100%', height: '50vh' }}>
                    <EmptyList />
                </div>
            }
        </div>
    )
}