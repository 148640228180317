import { FindAllWithholdingsResponse, WithholdingCreationDTO, WithholdingsDTO, WithholdingsFilters } from "./dto";
import { WithholdingsServiceImpl } from "./serviceImpl";

export interface WithholdingsService {
    findAllWithholdings(filters: WithholdingsFilters): Promise<FindAllWithholdingsResponse>
    deleteWithholding(id: number): Promise<void>
    createWithholding(request: FormData): Promise<void>
    findWithholding(id: number): Promise<WithholdingsDTO>
}
export function NewWithholdingsService(): WithholdingsService {
    return new WithholdingsServiceImpl();
}
