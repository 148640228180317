import { CloseIcon } from '../icons/close';
import colors from '../utils/index.module.scss';
import './style.css';

interface Props {
  active: boolean;
  close: () => void;
  message?: string;
}

export function ErrorPopup(props: Props) {
  const { active } = props;
  const { close } = props;
  const { message } = props;

  if (!active) {
    return null;
  }

  setTimeout(() => close(), 4000);

  return (
    <div className={'popup-error d-flex align-items-center justify-content-between p-3'}>
      <div>
        <p className={'text text--lg typography--medium typography--white p-0 m-0'}>{message}</p>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => close()}><CloseIcon colorBase={colors.white} /></div>
    </div>
  );
}
