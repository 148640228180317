import { Checkbox, FormControlLabel } from "@mui/material";
import { useState } from "react";
import { CheckedCheckboxIcon } from "./checkedIcon";
import { CheckboxSize, CheckboxStyle } from "./dto";
import { CheckboxIcon } from "./icon";
import { IndeterminateCheckboxIcon } from "./indeterminateIcon";
import { CheckboxSizeMap, CheckboxStyleMap } from "./style";

interface Props {
    label?: string,
    supportingText?: string,
    checked?: boolean,
    indeterminate?: boolean,
    size: CheckboxSize,
    style: CheckboxStyle,
    disabled?: boolean,
    onChange?: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void)
}

export function CheckboxComponent(props: Props) {
    const { label } = props
    const { supportingText } = props
    const { size } = props
    const { style } = props
    const { checked } = props
    const { indeterminate } = props
    const { disabled } = props
    const { onChange } = props

    const [focused, setFocused] = useState<boolean>(false);

    const sizeStyle = CheckboxSizeMap.get(size)
    const checkboxStyle = CheckboxStyleMap.get(JSON.stringify({ size, style }))

    return (
        <FormControlLabel
            sx={{
                margin: 0, height: checkboxStyle?.outerDim,
                '& .MuiCheckbox-root': {
                    padding: 0
                },
            }}
            control={
                <Checkbox
                    classes={{
                        root: 'custom-checkbox',
                        checked: 'selected-custom-checkbox'
                    }}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    indeterminate={indeterminate}
                    checked={checked}
                    disabled={disabled}

                    icon={<CheckboxIcon checkboxStyle={checkboxStyle} focused={focused} />}
                    checkedIcon={<CheckedCheckboxIcon checkboxStyle={checkboxStyle} focused={focused} size={CheckboxSize.SM} />}
                    indeterminateIcon={<IndeterminateCheckboxIcon checkboxStyle={checkboxStyle} focused={focused} size={CheckboxSize.SM} />}
                    onChange={(e, checked) => onChange && onChange(e, checked)}
                />
            }
            label={
                <p style={{ margin: 0, opacity: disabled ? 0.5 : 1 }} className={sizeStyle?.placeholder}>
                    {label}
                    <br />
                    <span style={{ margin: 0 }} className={sizeStyle?.supportingText}>
                        {supportingText}
                    </span>
                </p>
            }
        />
    )
}