import { Tab } from "@mui/material";
import { ReactNode, useState } from "react";
import { ButtonComponent } from "../../button";
import { Colors, IconStyle, Size, Variant } from "../../button/dto";
import { PeriodicityEnum } from "../../companyQuotationWizard/dto";
import { HorizontalTabPrimaryComponent } from "../../horizontalTabPrimary";
import { TabPanelComponent } from "../../horizontalTabPrimary/tabPanel";
import { ModalComponent } from "../../modal";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { SpinnerComponent } from "../../spinner";
import { ControlsPanel } from "../controls/controlsPanel";
import { ActivityKind } from "./../dto";
import { completeGroup, setCompleteGroupModal, setInitialTabAccountingValue, setOpenOrdinaryAccountingModal } from "./../slice";
import { WithholdingsPanel } from "./../withholdings/withholdingsPanel";
import { OrdinaryAccountingRow } from './ordinaryAccountingRow';
import { RecordingsPanel } from "./recordingsPanel";

export function OrdinaryAccountingModal() {
    const companyState = useAppSelector(state => state.company)

    const company = companyState.ordinaryAccountingUser
    const activityGroups = companyState.ordinaryAccountingActivityGroups.filter(group => group.activityKind === ActivityKind.Records)
    const initialTab = companyState.ordinaryAccountingInitialTab
    const period = companyState.ordinaryAccountingPeriod
    const year = companyState.ordinaryAccountingYear

    const dispatch = useAppDispatch()

    const [count, setCount] = useState<number>(0)
    const [todoCount, setTodoCount] = useState<number>(0)


    const handleChangeAccountingTab = (event: React.SyntheticEvent, newValue: number) => {
        dispatch(setInitialTabAccountingValue(newValue));
    };

    if (company === undefined) {
        return (
            <></>
        )
    }

    let todoListCheck: ReactNode[] = []
    if (company.todos !== null)
        company.todos.filter(todo => todo.split('::')[1].toString() === initialTab.toString()).forEach(todo => {
            todoListCheck.push(
                <OrdinaryAccountingRow
                    hasDocument={false}
                    key={todo}
                    label={todo.split('::')[0]}
                    count={0}
                    setCount={(e) => setCount(e)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (event.target.checked) {
                            setTodoCount(todoCount + 1)
                        }
                        else {
                            setTodoCount(todoCount - 1)
                        }
                    }}
                />
            )
        })


    const monthlyMap = new Map<number, string>([
        [0, 'Gennaio'],
        [1, 'Febbraio'],
        [2, 'Marzo'],
        [3, 'Aprile'],
        [4, 'Maggio'],
        [5, 'Giugno'],
        [6, 'Luglio'],
        [7, 'Agosto'],
        [8, 'Settembre'],
        [9, 'Ottobre'],
        [10, 'Novembre'],
        [11, 'Dicembre'],
    ])

    const quarterlyMap = new Map<number, string>([
        [0, 'I trimestre'],
        [1, 'II trimestre'],
        [2, 'III trimestre'],
        [3, 'IV trimestre'],
    ])

    return (
        <div>
            <ModalComponent
                open={companyState.openOrdinaryAccountingModal}
                handleClose={() => {
                    dispatch(setOpenOrdinaryAccountingModal(false))
                    setTodoCount(0)
                }}
                width="medium"
            >
                {
                    (
                        companyState.findActivityGroupsStatus === 'loading' ||
                        companyState.setActivityCompleteStatus === 'loading' ||
                        companyState.setActivityOpenStatus === 'loading' ||
                        companyState.completeGroupStatus === 'loading' ||
                        companyState.findCompanyCategoryStatus === 'loading' ||
                        companyState.findActivityByGroupStatus === 'loading' ||
                        companyState.findActivityTypeByIdStatus === 'loading' ||
                        companyState.findCompanyStatus === 'loading'
                    ) ? (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '20px',
                                gap: '16px',
                                width: '100%',
                                height: '30vh'
                            }}
                        >
                            <SpinnerComponent size="small" />
                        </div>
                    ) : (
                        <div>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                padding: '20px 20px 0 20px',
                                gap: '16px',
                                width: '100%',
                            }}
                            >
                                <span className="heading heading--xs typography--semibold typography-neutral--800">
                                    {((company.periodicity === PeriodicityEnum.Monthly ? monthlyMap.get(period) : quarterlyMap.get(period)) + ' ' + year)}
                                </span>
                                <HorizontalTabPrimaryComponent
                                    value={companyState.initialTabAccountingActivities}
                                    negativeMargin={20}
                                    handleChange={handleChangeAccountingTab}>
                                    <Tab label="Registrazioni" value={0} />
                                    <Tab label="Ritenute" value={1}
                                    // disabled={keycloak.hasRealmRole('financial-advisor') || keycloak.hasRealmRole('operator')}
                                    />
                                    <Tab label="Controlli" value={2} />
                                </HorizontalTabPrimaryComponent>
                                <TabPanelComponent value={companyState.initialTabAccountingActivities} index={0} >
                                    <div className="w-100">
                                        <RecordingsPanel />
                                    </div>
                                </TabPanelComponent>
                                <TabPanelComponent value={companyState.initialTabAccountingActivities} index={1} >
                                    <div className="w-100">
                                        <WithholdingsPanel />
                                    </div>
                                </TabPanelComponent>
                                <TabPanelComponent value={companyState.initialTabAccountingActivities} index={2} >
                                    <div className="w-100">
                                        <ControlsPanel />
                                    </div>
                                </TabPanelComponent>
                            </div>
                        </div>
                    )
                }
            </ModalComponent>
            <ModalComponent
                open={companyState.completeGroupModal}
                handleClose={() => dispatch(setCompleteGroupModal(false))}
            >
                <div
                    style={{
                        padding: '16px 24px 24px 24px',
                    }}
                >
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        width: '100%',
                        marginBottom: '24px'
                    }}
                    >
                        <p className="heading heading--xs typography--semibold typography-neutral--800 m-0 p-0">
                            {((company.periodicity === PeriodicityEnum.Monthly ? monthlyMap.get(period) : quarterlyMap.get(period)) + ' ' + year)}
                        </p>
                        <p className="text text--lg typography--regular typography-neutral--400 m-0 p-0">
                            {'Operazioni ' + activityGroups.find(group => group.activityCategory.id === initialTab)?.activityCategory.name}
                        </p>
                    </div>
                    <div style={{
                        display: 'flex', gap: 8, flexDirection: 'column',
                        maxHeight: '45vh',
                        overflow: 'auto'
                    }}>
                        {todoListCheck}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: '12px',
                            width: '100%',
                            height: '80px',
                        }}
                    >
                        <div style={{ margin: 0, padding: 0, gap: '12px', width: '100%' }} className="row">
                            <div className="col" style={{ margin: 0, padding: 0 }}>
                                <ButtonComponent
                                    key={'modal-button-3'}
                                    label={"Annulla"}
                                    onClick={() => {
                                        dispatch(setCompleteGroupModal(false))
                                        setTodoCount(0)
                                    }}
                                    color={Colors.NEUTRAL}
                                    variant={Variant.OUTLINE}
                                    size={Size.FIT}
                                    iconStyle={IconStyle.OFF}
                                />
                            </div>
                            <div className="col" style={{ margin: 0, padding: 0 }}>
                                <ButtonComponent
                                    key={'modal-button-4'}
                                    disabled={todoCount !== todoListCheck.length}
                                    label={"Chiudi " + activityGroups.find(group => group.activityCategory.id === initialTab)?.activityCategory.name}
                                    onClick={() => {
                                        dispatch(completeGroup(activityGroups.find(group => group.activityCategory.id === initialTab)?.id.toString() || ''));
                                        dispatch(setCompleteGroupModal(false));
                                        dispatch(setOpenOrdinaryAccountingModal(false))
                                    }}
                                    color={Colors.PRIMARY}
                                    variant={Variant.SOLID}
                                    size={Size.FIT}
                                    iconStyle={IconStyle.OFF}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalComponent >
        </div >
    )
}