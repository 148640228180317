import { FindAllOperatorFiltersDTO, FindAllOperators, OperatorResponseDTO } from "./dto";
import { OperatorServiceImpl } from "./serviceImpl";

export interface OperatorService {
    findAllOperators(filters: FindAllOperatorFiltersDTO): Promise<FindAllOperators>
    createOperator(request: FormData): Promise<void>
    findOperator(id: string): Promise<OperatorResponseDTO>
    editOperator(id: string, data: FormData): Promise<void>
    deleteOperator(id: string): Promise<void>;
}

export function NewOperatorService(): OperatorService {
    return new OperatorServiceImpl();
}
