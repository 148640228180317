export enum ProgressStepSize {
    SM = 'SM',
    MD = 'MD',
    LG = 'LG'
}

export enum ProgressStepColor {
    NEUTRAL = 'NEUTRAL',
    PRIMARY = 'PRIMARY',
    SUCCESS = 'SUCCESS'
}