import { useEffect } from "react";
import { HeadingComponent } from "../../heading";
import { HeadingSize } from "../../heading/dto";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { SpinnerComponent } from "../../spinner";
import { SwitchComponent } from "../../switch";
import { TextInput } from "../../textInput";
import { setInstrumentalVehicles, setInstrumentalVehiclesLicensePlate } from "../slice";

interface Props {
    disabled: boolean
}

export function InstrumentalVehicles(props: Props) {
    const { disabled } = props
    const companyState = useAppSelector(state => state.company)
    const dispatch = useAppDispatch()

    const instrumentalVehicles = companyState.findCompanyResponse?.instrumentalVehicles
    const instrumentalVehiclesLicensePlate = companyState.findCompanyResponse?.instrumentalVehiclesLicensePlate
    const instrumentalVehiclesRequest = companyState.companyRegistryDTO.instrumentalVehicles
    const instrumentalVehiclesLicensePlateRequest = companyState.companyRegistryDTO.instrumentalVehiclesLicensePlate

    useEffect(() => {
        if (companyState.findCompanyStatus === 'successfully' && companyState.findCompanyResponse !== undefined) {
            if (instrumentalVehicles === undefined || instrumentalVehicles === null) {
                dispatch(setInstrumentalVehicles(false))
            } else {
                dispatch(setInstrumentalVehicles(instrumentalVehicles))
                dispatch(setInstrumentalVehiclesLicensePlate(instrumentalVehiclesLicensePlate))
            }
        }
    }, [instrumentalVehicles, companyState.findCompanyStatus, companyState.findCompanyResponse])

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <HeadingComponent size={HeadingSize.SM} label={"Automezzi Strumentali"} buttons={[]}
            />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 24
                }}
            >
                <TextInput
                    id={"reverse-change-input"}
                    type={"text"}
                    disabled={!instrumentalVehiclesRequest || disabled}
                    placeholder={"Inserisci targa (Usa la “,” per separarle)"}
                    value={instrumentalVehiclesLicensePlateRequest === null ? '' : instrumentalVehiclesLicensePlateRequest}
                    onChange={(e) => {
                        dispatch(setInstrumentalVehiclesLicensePlate(e.target.value))
                    }}
                />
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8 }}>
                    <SwitchComponent
                        disabled={disabled}
                        checked={instrumentalVehiclesRequest}
                        onChange={(e) => {
                            if (e.target.checked) {
                                dispatch(setInstrumentalVehicles(true))
                            } else {
                                dispatch(setInstrumentalVehicles(false))
                                dispatch(setInstrumentalVehiclesLicensePlate(''))
                            }
                        }}
                    />
                    <span className="text text--md typography--medium typography-neutral--500">Attiva</span>
                </div>
            </div>
        </div>
    )
}