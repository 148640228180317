import { ReactNode, useEffect } from "react";
import { ButtonComponent } from "../../button";
import { Colors, IconStyle, Size, Variant } from "../../button/dto";
import { HeadingComponent } from "../../heading";
import { HeadingSize } from "../../heading/dto";
import { PlusIcon } from "../../icons/plus";
import { TrashIcon } from "../../icons/trash";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { TextInput } from "../../textInput";
import colors from '../../utils/index.module.scss';
import { setAdmins, setAdminsNumber } from "../slice";

interface Props {
    disabled: boolean
}

export function AdminNames(props: Props) {
    const { disabled } = props
    const companyState = useAppSelector(state => state.company)
    const dispatch = useAppDispatch()

    const admins = companyState.findCompanyResponse?.admins
    const adminsRequest = companyState.companyRegistryDTO.admins

    useEffect(() => {
        if (companyState.findCompanyStatus === 'successfully' && companyState.findCompanyResponse !== undefined) {
            if (admins === undefined || admins === null || admins.length === 0) {
                dispatch(setAdmins(['']))
                dispatch(setAdminsNumber(0))
            } else {
                dispatch(setAdmins(admins))
                dispatch(setAdminsNumber(admins.length))
            }
        }
    }, [admins, companyState.findCompanyStatus, companyState.findCompanyResponse])

    let adminsList: ReactNode[] = []
    if (adminsRequest !== undefined && adminsRequest !== null && adminsRequest.length > 0 && companyState.findCompanyStatus === 'successfully' && companyState.findCompanyResponse !== undefined) {
        adminsRequest.forEach((admin, index) => {
            adminsList.push(
                <div
                    key={'admin-' + index}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 24
                    }}
                >
                    <TextInput
                        id={"admin-input-" + index}
                        type={"text"}
                        disabled={disabled}
                        placeholder={"Nome amministratore"}
                        value={adminsRequest[index]}
                        onChange={(e) => {
                            const newAdmin = [...adminsRequest]
                            newAdmin[index] = e.target.value
                            dispatch(setAdmins(newAdmin))
                        }}
                    />
                    <ButtonComponent
                        label={""}
                        disabled={disabled}
                        onClick={() => {
                            let newAdmins = [...adminsRequest]
                            newAdmins = newAdmins.slice(0, index).concat(newAdmins.slice(index + 1));
                            dispatch(setAdmins(newAdmins.length === 0 ? [""] : newAdmins))
                            dispatch(setAdminsNumber(newAdmins.length))
                        }}
                        color={Colors.PRIMARY}
                        variant={Variant.LINK}
                        size={Size.SM}
                        iconStyle={IconStyle.ONLY}
                        icon={<TrashIcon colorBase={colors.primary500} />}
                    />
                </div>
            )
        })
    }

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <HeadingComponent size={HeadingSize.SM} label={"Amministratori"} buttons={[
                <ButtonComponent
                    disabled={adminsRequest.length >= 10 || disabled}
                    label={"Aggiungi Amministratore"}
                    icon={<PlusIcon colorBase={""} />}
                    onClick={() => {
                        const newAdmin = [...adminsRequest]
                        newAdmin.push('')
                        dispatch(setAdmins(newAdmin))
                        dispatch(setAdminsNumber(newAdmin.length))
                    }}
                    color={Colors.PRIMARY}
                    variant={Variant.OUTLINE}
                    size={Size.SM}
                    iconStyle={IconStyle.LEFT}
                />
            ]}
            />
            {adminsList}
        </div>
    )
}