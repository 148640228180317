import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { CheckboxComponent } from "../checkbox";
import { CheckboxSize, CheckboxStyle } from "../checkbox/dto";
import { ModalComponent } from "../modal";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SpinnerComponent } from "../spinner";
import colors from '../utils/index.module.scss';
import { AtecoResponseDTO } from "./dto";
import { findAllAtecos, saveAtecos, setOpenAtecoModal } from "./slice";

export function AtecoModal() {
    const companyState = useAppSelector(state => state.company)
    const dispatch = useAppDispatch()
    const [selectedActivities, setSelectedActivities] = useState<number[]>([])
    const [page, setPage] = useState<number>(0)
    const [atecos, setAtecos] = useState<AtecoResponseDTO[]>([])

    useEffect(() => {
        if (
            companyState.findCompanyStatus === 'successfully' &&
            companyState.findCompanyResponse !== undefined &&
            companyState.findCompanyResponse.atecoCodeIds !== undefined &&
            companyState.openAtecoModal
        ) {
            setSelectedActivities([...companyState.findCompanyResponse.atecoCodeIds])
        }
    }, [companyState.findCompanyStatus, companyState.openAtecoModal])

    useEffect(() => {
        if (
            companyState.findAllAtecosStatus === 'successfully' &&
            companyState.findAllAtecosResponse !== undefined &&
            companyState.findAllAtecosResponse.data !== undefined &&
            companyState.findAllAtecosResponse.data.length > 0
        ) {
            let atecosList = [...atecos, ...companyState.findAllAtecosResponse.data]
            companyState.findAllAtecosResponse.data.forEach((ateco, index) => {
                atecosList.concat(
                    ateco
                )
            })
            setAtecos(atecosList)
        }
    }, [companyState.findAllAtecosStatus])

    if (companyState.findAllAtecosStatus === 'failed' || companyState.findAllAtecosResponse === undefined) {
        return (<div>Si è verificato un errore durante il caricamento dei dati</div>)
    }

    return (
        <ModalComponent
            open={companyState.openAtecoModal}
            handleClose={() => dispatch(setOpenAtecoModal(false))}
        >
            <div
                style={{
                    padding: '20px',
                    backgroundColor: colors.white,
                    boxShadow: colors.boxShadowXL,
                    borderRadius: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '24px',
                    width: '100%'
                }}
            >
                {
                    companyState.saveAtecosStatus === 'loading'
                        ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
                                <SpinnerComponent size={"small"} />
                            </div>
                        ) : (
                            <>
                                <div className="d-flex align-items-center justify-content-between">
                                    <span className="text text--lg typography--semibold typography--black">Codici Ateco</span>
                                </div>
                                <div className="d-flex flex-column align-items-start justify-content-between" style={{ gap: '16px', width: '100%' }}>
                                    <div
                                        id="scrollableDivAtecos"
                                        className="d-flex flex-column"
                                        style={{ gap: 4, width: '100%', overflow: 'auto', maxHeight: '300px' }}
                                    >
                                        <InfiniteScroll
                                            scrollableTarget="scrollableDivAtecos"
                                            dataLength={atecos.length}
                                            next={() => {
                                                setTimeout(() => {
                                                    setPage(page + 1)
                                                    dispatch(findAllAtecos({ page: page + 1, itemsPerPage: 10 }))
                                                }, 500)
                                            }}
                                            hasMore={companyState.findAllAtecosResponse.next}
                                            loader={
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        padding: 10,
                                                        margin: 0,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                    <SpinnerComponent size={"small"} />
                                                </div>
                                            }
                                        >
                                            {atecos.map(ateco => (
                                                <div
                                                    key={ateco.code}
                                                    style={{
                                                        display: 'flex',
                                                        padding: '8px 12px',
                                                        flexDirection: 'row',
                                                        alignItems: 'flex-start',
                                                        justifyContent: 'space-between',
                                                        alignSelf: 'stretch',
                                                        borderRadius: '6px',
                                                        border: (selectedActivities.find(_ateco => _ateco === ateco.id) !== undefined ? ('1px solid ' + colors.primary200) : ('1px solid ' + colors.white)),
                                                        background: colors.white,
                                                        width: '100%',
                                                        gap: '8px'
                                                    }}>
                                                    <span className="text text--lg typography--medium typography-neutral--800">
                                                        {ateco.code + ' ' + ateco.description}
                                                    </span>
                                                    <CheckboxComponent size={CheckboxSize.SM} style={CheckboxStyle.SQUARE}
                                                        checked={selectedActivities.find(_ateco => _ateco === ateco.id) !== undefined}
                                                        onChange={(event, checked) => {
                                                            if (event.target.checked) {
                                                                setSelectedActivities([...selectedActivities.concat(ateco.id)])
                                                            }
                                                            else {
                                                                setSelectedActivities([...selectedActivities.filter(activity => activity.toString() !== ateco.id.toString())])
                                                            }
                                                        }} />
                                                </div>
                                            ))}
                                        </InfiniteScroll>
                                    </div>
                                </div>
                                <div style={{ margin: 0, padding: 0, gap: '12px', width: '100%' }} className="row">
                                    <div className="col" style={{ margin: 0, padding: 0 }}><ButtonComponent label={"Annulla"} onClick={() => dispatch(setOpenAtecoModal(false))} color={Colors.NEUTRAL} variant={Variant.OUTLINE} size={Size.FIT} iconStyle={IconStyle.OFF} /></div>
                                    <div className="col" style={{ margin: 0, padding: 0 }}><ButtonComponent label={"Salva"} onClick={() => { dispatch(saveAtecos({ data: selectedActivities, id: companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse?.id.toString() : '' })); dispatch(setOpenAtecoModal(false)) }} color={Colors.PRIMARY} variant={Variant.SOLID} size={Size.FIT} iconStyle={IconStyle.OFF} /></div>
                                </div>
                            </>)}
            </div >
        </ModalComponent >
    )
}