import { ReactNode, useEffect } from "react";
import { ButtonComponent } from "../../button";
import { Colors, IconStyle, Size, Variant } from "../../button/dto";
import { HeadingComponent } from "../../heading";
import { HeadingSize } from "../../heading/dto";
import { PlusIcon } from "../../icons/plus";
import { TrashIcon } from "../../icons/trash";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { SpinnerComponent } from "../../spinner";
import { SwitchComponent } from "../../switch";
import { TextInput } from "../../textInput";
import colors from '../../utils/index.module.scss';
import { setPurchaseRegisters, setPurchaseRegistersNumber } from "../slice";

interface Props {
    disabled: boolean
}

export function PurchaseRegister(props: Props) {
    const { disabled } = props
    const companyState = useAppSelector(state => state.company)
    const dispatch = useAppDispatch()

    const purchaseRegister = companyState.findCompanyResponse?.purchasesRegister
    const purchaseRegisterRequest = companyState.companyRegistryDTO.purchasesRegister

    useEffect(() => {
        if (companyState.findCompanyStatus === 'successfully' && companyState.findCompanyResponse !== undefined) {
            if (purchaseRegister === undefined || purchaseRegister === null || purchaseRegister.length === 0) {
                dispatch(setPurchaseRegisters(['']))
                dispatch(setPurchaseRegistersNumber(0))
            } else {
                dispatch(setPurchaseRegisters(purchaseRegister))
                dispatch(setPurchaseRegistersNumber(purchaseRegister.length))
            }
        }
    }, [purchaseRegister, companyState.findCompanyResponse, companyState.findCompanyResponse])

    let registers: ReactNode[] = []
    if (purchaseRegisterRequest !== undefined && purchaseRegisterRequest !== null && purchaseRegisterRequest.length > 0) {
        purchaseRegisterRequest.forEach((purchase, index) => {
            registers.push(
                <div
                    key={'purchase-register-' + index}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 24
                    }}
                >
                    <TextInput
                        disabled={disabled}
                        id={"purchase-register-input-" + index}
                        type={"text"}
                        placeholder={"Inserire nome registro"}
                        value={purchaseRegisterRequest[index].split('::')[0]}
                        onChange={(e) => {
                            const newRegister = [...purchaseRegisterRequest]
                            newRegister[index] = e.target.value + (purchaseRegisterRequest[index].split('::')[1] !== undefined ? "::estero" : '')
                            dispatch(setPurchaseRegisters(newRegister))
                        }}
                    />
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8 }}>
                        <SwitchComponent
                            disabled={disabled}
                            checked={purchaseRegisterRequest[index].split('::').length === 2}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    const newRegister = [...purchaseRegisterRequest]
                                    newRegister[index] = purchaseRegisterRequest[index].concat('::estero')
                                    dispatch(setPurchaseRegisters(newRegister))
                                } else {
                                    const newRegister = [...purchaseRegisterRequest]
                                    newRegister[index] = purchaseRegisterRequest[index].split('::')[0]
                                    dispatch(setPurchaseRegisters(newRegister))
                                }
                            }}
                        />
                        <span className="text text--md typography--medium typography-neutral--500">Estero</span>
                    </div>
                    <ButtonComponent
                        label={""}
                        disabled={disabled}
                        onClick={() => {
                            let newPurchases = [...purchaseRegisterRequest]
                            newPurchases = newPurchases.slice(0, index).concat(newPurchases.slice(index + 1));
                            dispatch(setPurchaseRegisters(newPurchases.length === 0 ? [""] : newPurchases))
                            dispatch(setPurchaseRegistersNumber(newPurchases.length))
                        }}
                        color={Colors.PRIMARY}
                        variant={Variant.LINK}
                        size={Size.SM}
                        iconStyle={IconStyle.ONLY}
                        icon={<TrashIcon colorBase={colors.primary500} />}
                    />
                </div>
            )
        })
    }

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <HeadingComponent size={HeadingSize.SM} label={"Registro Acquisti"} buttons={[
                <ButtonComponent
                    disabled={purchaseRegisterRequest.length >= 5 || disabled}
                    label={"Aggiungi registro"}
                    icon={<PlusIcon colorBase={""} />}
                    onClick={() => {
                        const newPurchases = [...purchaseRegisterRequest]
                        newPurchases.push('')
                        dispatch(setPurchaseRegisters(newPurchases))
                        dispatch(setPurchaseRegistersNumber(newPurchases.length))
                    }}
                    color={Colors.PRIMARY}
                    variant={Variant.OUTLINE}
                    size={Size.SM}
                    iconStyle={IconStyle.LEFT}
                />
            ]}
            />
            {registers}
        </div>
    )
}