import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmOrDenyModal } from "../confirmOrDenyModal.tsx";
import { ContextMenuComponent } from "../contextMenu";
import { MenuItemComponent } from "../contextMenu/menuItem";
import { TrashIcon } from "../icons/trash";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import colors from '../utils/index.module.scss';
import { deleteActivityType, setActivityTypeSettedId, setOpenDeleteActivityTypeModal } from "./slice";

interface Props {
    id: string
}

export function ActivityMenuComponent(props: Props) {
    const { id } = props
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const activityState = useAppSelector(state => state.activity)

    const dispatch = useAppDispatch()

    return (
        <ContextMenuComponent anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
            <MenuItemComponent
                onClick={() => {
                    dispatch(setActivityTypeSettedId(id))

                    dispatch(setOpenDeleteActivityTypeModal(true))
                }}>
                <TrashIcon colorBase={colors.neutral600} />
                Cancella
            </MenuItemComponent>
            <ConfirmOrDenyModal
                open={activityState.openDeleteActivityTypeModal}
                handleClose={() => dispatch(setOpenDeleteActivityTypeModal(false))}
                title={"Elimina attività"}
                description={"Sei sicuro di voler eliminare questa attività?"}
                labelDeny={"Annulla"}
                labelConfirm={"Elimina"}
                actionConfirm={() => {
                    dispatch(deleteActivityType(id))
                    dispatch(setOpenDeleteActivityTypeModal(false))
                }}
            />
        </ContextMenuComponent>
    )
}