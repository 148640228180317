import { MenuItem } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { findAllActivityCategory } from "../activity/slice";
import { AvatarComponent } from "../avatar";
import { AvatarSize, AvatarType } from "../avatar/dto";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { AccountingSchemeEnum, PeriodicityEnum, SpecialVatSchemeEnum, numberToForeignMap, numberToJournalMap } from "../companyQuotationWizard/dto";
import { foreignLabelMap, journalLabelMap } from "../companyQuotationWizard/summary";
import { ConfirmOrDenyModal } from "../confirmOrDenyModal.tsx";
import { ErrorPopup } from "../errorPopup";
import { FileInputComponent } from "../fileInput";
import { FormGroup } from "../formGroup";
import { HeadingComponent } from "../heading";
import { HeadingSize } from "../heading/dto";
import { CloseIcon } from "../icons/close";
import { keycloak } from "../keycloak";
import { PillComponent } from "../pills";
import { PillsColor, PillsEmphasis, PillsOutline, PillsSize } from "../pills/dto";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SelectComponent } from "../select";
import { SpinnerComponent } from "../spinner";
import { SuccessPopup } from "../successPopup";
import { SwitchComponent } from "../switch";
import { TextInput } from "../textInput";
import { toBase64 } from "../utils";
import { AtecoModal } from "./atecoModal";
import { BankModal } from "./bankModal";
import { CompanyStatusEnum } from "./dto";
import { editBankAccounts, findAllAtecos, resetErrorRegistryEmail, resetErrorUpdateRegistryEmail, resetErrorUpdateRegistryVat, setCompanyEditPremium, setCompanyUpdateRegistryBusinessName, setCompanyUpdateRegistryEmail, setCompanyUpdateRegistryEmployeesNumber, setCompanyUpdateRegistryFiscalCode, setCompanyUpdateRegistryName, setCompanyUpdateRegistryNote, setCompanyUpdateRegistryPhoneNumber, setCompanyUpdateRegistryStatus, setCompanyUpdateRegistrySurname, setCompanyUpdateRegistryVatNumber, setEditBankStatus, setFindCompanyAvatarStatus, setOpenAtecoModal, setOpenBankModal, setOpenDeleteBankModal, setRegistryEmail, setRegistryEmailStatus, setSaveAtecosStatus, setUpdateCompanyEmployeesNumber, setUpdateCompanyFiscalCode, setUpdateCompanyName, setUpdateCompanyNote, setUpdateCompanyPhoneNumber, setUpdateCompanyStatus, setUpdateCompanySurname, setUpdateCompanyVatNumber, setUpdateRegistryTabValue } from "./slice";

interface Props {
    setFile: React.Dispatch<React.SetStateAction<File | null>>
}

const atecoLabels = (atecos: number): string => {
    if (atecos <= 2) {
        return ('Inseriscine fino a 2')
    } else if (atecos > 2 && atecos <= 4) {
        return ('Inseriscine da 3 a 4')
    } else {
        return ('Inseriscine più di 4')
    }
}

const banksLabel = (bank: number): string => {
    if (bank <= 2) {
        return ('Inseriscine fino a 2')
    } else if (bank > 2 && bank <= 5) {
        return ('Inseriscine da 3 a 5')
    } else {
        return ('Inseriscine più di 5')
    }
}
export function CompanyProfileComponent(props: Props) {
    const { setFile } = props

    const companyState = useAppSelector(state => state.company)
    const activityState = useAppSelector(state => state.activity)
    const objectState = useAppSelector(state => state.object)

    const [avatarToBase64, setAvatarToBase64] = useState<string>('')
    const [bankIndexToDelete, setBankIndexToDelete] = useState<number>(-1)
    const [showEmail, setShowEmail] = useState<boolean>(companyState.findCompanyResponse?.email !== null)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (
            companyState.findCompanyStatus === 'successfully' &&
            companyState.settingsTabValue === 0
        ) {
            dispatch(findAllActivityCategory())
            dispatch(findAllAtecos({ page: 0, itemsPerPage: 10 }))
        }
    }, [companyState.findCompanyStatus, companyState.settingsTabValue])

    useEffect(() => {
        if (
            companyState.findCompanyAvatarStatus === 'successfully' &&
            companyState.settingsTabValue === 0
        ) {
            dispatch(setFindCompanyAvatarStatus('idle'))
            if (companyState.companyAvatar !== undefined && companyState.companyAvatar !== null) {
                setAvatarToBase64(companyState.companyAvatar)
            }
        }
    }, [companyState.findCompanyAvatarStatus])

    useEffect(() => {
        if (companyState.companyUpdateDTO.phoneNumber === '')
            dispatch(setUpdateCompanyPhoneNumber(undefined))
        if (companyState.companyUpdateDTO.name === '')
            dispatch(setUpdateCompanyName(undefined))
        if (companyState.companyUpdateDTO.surname === '')
            dispatch(setUpdateCompanySurname(undefined))
        if (companyState.companyUpdateDTO.employeesNumber?.toString() === '')
            dispatch(setUpdateCompanyEmployeesNumber(undefined))
        if (companyState.companyUpdateDTO.fiscalCode === '')
            dispatch(setUpdateCompanyFiscalCode(undefined))
        if (companyState.companyUpdateDTO.note === '')
            dispatch(setUpdateCompanyNote(undefined))
    }, [companyState.companyUpdateDTO])

    useEffect(() => {
        if (companyState.companyUpdateRegistryRequest.businessName === '')
            dispatch(setCompanyUpdateRegistryBusinessName(undefined))
        if (companyState.companyUpdateRegistryRequest.email === '')
            dispatch(setRegistryEmail(undefined))
        if (companyState.companyUpdateRegistryRequest.employeesNumber?.toString() === '')
            dispatch(setCompanyUpdateRegistryEmployeesNumber(undefined))
        if (companyState.companyUpdateRegistryRequest.fiscalCode === '')
            dispatch(setCompanyUpdateRegistryFiscalCode(undefined))
        if (companyState.companyUpdateRegistryRequest.name === '')
            dispatch(setCompanyUpdateRegistryName(undefined))
        if (companyState.companyUpdateRegistryRequest.note === '')
            dispatch(setCompanyUpdateRegistryNote(undefined))
        if (companyState.companyUpdateRegistryRequest.phoneNumber === '')
            dispatch(setCompanyUpdateRegistryPhoneNumber(undefined))
        if (companyState.companyUpdateRegistryRequest.surname === '')
            dispatch(setCompanyUpdateRegistrySurname(undefined))
        if (companyState.companyUpdateRegistryRequest.vatNumber === '')
            dispatch(setCompanyUpdateRegistryVatNumber(undefined))
    }, [companyState.companyUpdateRegistryRequest])

    if (
        companyState.findCompanyStatus === 'loading' ||
        companyState.saveRolesStatus === 'loading' ||
        companyState.editBankStatus === 'loading' ||
        companyState.editCompanyActivityTypesStatus === 'loading' ||
        companyState.editTodosStatus === 'loading' ||
        companyState.setActivityOpenStatus === 'loading' ||
        companyState.setActivityCompleteStatus === 'loading' ||
        objectState.saveDocumentStatus === 'loading' ||
        companyState.deleteDocumentStatus === 'loading' ||
        activityState.findAllActivityCategoryStatus === 'loading' ||
        companyState.findCompanyAvatarStatus === 'loading' ||
        companyState.findLoansStatus === 'loading' ||
        companyState.saveAtecosStatus === 'loading' ||
        companyState.findFundingsStatus === 'loading' ||
        companyState.companyUpdateStatus === 'loading' ||
        companyState.companyUpdateRegistryStatus === 'loading' ||
        companyState.findLeasingsStatus === 'loading' ||
        companyState.findAtecoByIdStatus === 'loading'
    ) {
        return (<div style={{ width: '100%', height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><SpinnerComponent size='small' /></div>)
    }

    if (
        companyState.findCompanyStatus === 'failed' ||
        activityState.findAllActivityCategoryStatus === 'failed'
    ) {
        return (<div>Si è verificato un errore durante il caricamento dei dati</div>)
    }

    let company = companyState.findCompanyResponse
    let activityCategories: ReactNode[] = []
    let bankAccounts: ReactNode[] = []
    let atecoList: ReactNode[] = []

    if (
        companyState.findAtecoByIdStatus === 'successfully' &&
        companyState.findAtecoByIdResponse.length > 0
    ) {
        companyState.findAtecoByIdResponse.forEach(ateco => {
            atecoList.push(
                <PillComponent key={ateco.id} label={ateco.code} size={PillsSize.MD} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.TRUE} />
            )
        })
    }

    if (companyState.findCompanyStatus === 'successfully' && company !== undefined && companyState.settingsTabValue === 0) {
        if (company.bankingAccounts !== null)
            company.bankingAccounts.forEach((bank, index) => {
                bankAccounts.push(
                    <div key={bank} className="neutral-container--padding-small flex-row justify-content-between align-items-center" >
                        <span className="typography-neutral--400 text text--lg typography--semibold">
                            {bank.split('::')[0] + ' '}
                            <span className="typography--medium">
                                {'→ ' + bank.split('::')[1]}
                            </span>
                        </span>
                        {
                            (keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('financial-advisor')) &&
                            <ButtonComponent
                                label={""}
                                disabled={!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor')}
                                icon={<CloseIcon colorBase={""} />}
                                onClick={() => {
                                    setBankIndexToDelete(index)
                                    dispatch(setOpenDeleteBankModal(true))
                                }}
                                color={Colors.NEUTRAL}
                                variant={Variant.LINK}
                                size={Size.SM}
                                iconStyle={IconStyle.ONLY}
                            />
                        }
                    </div>
                )
            })
    }

    if (
        activityState.findAllActivityCategoryResponse !== undefined &&
        activityState.findAllActivityCategoryResponse.data !== undefined &&
        activityState.findAllActivityCategoryResponse.data.length > 0 &&
        companyState.settingsTabValue === 0
    ) {
        activityState.findAllActivityCategoryResponse.data.forEach(activity => {
            activityCategories.push(
                <SwitchComponent
                    disabled
                    label={activity.name}
                    id={'company-settings-activity-category-' + activity.id.toString()}
                    key={'company-settings-activity-category-' + activity.id}
                    checked={company?.activityCategoryIds?.includes(activity.id)}
                />
            )
        })
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        dispatch(setUpdateRegistryTabValue(newValue))
    };

    return (
        <div className="row m-0 p-0" style={{ gap: 24 }}>
            <ErrorPopup
                active={companyState.companyUpdateRegistryStatus === 'failed'}
                close={() => dispatch(setCompanyUpdateRegistryStatus('idle'))}
                message="Si è verificato un errore durante la modifica dell'azienda"
            />
            <SuccessPopup
                active={companyState.companyUpdateRegistryStatus === 'successfully'}
                close={() => dispatch(setCompanyUpdateRegistryStatus('idle'))}
                message="Azienda aggiornata"
            />
            <ErrorPopup
                active={companyState.companyUpdateStatus === 'failed'}
                close={() => dispatch(setUpdateCompanyStatus('idle'))}
                message="Si è verificato un errore durante la modifica dell'azienda"
            />
            <SuccessPopup
                active={companyState.companyUpdateStatus === 'successfully'}
                close={() => dispatch(setUpdateCompanyStatus('idle'))}
                message="Azienda aggiornata"
            />
            <ErrorPopup
                active={companyState.saveAtecosStatus === 'failed'}
                close={() => dispatch(setSaveAtecosStatus('idle'))}
                message="Si è verificato un errore durante la modifica della lista dei codici ateco"
            />
            <SuccessPopup
                active={companyState.saveAtecosStatus === 'successfully'}
                close={() => dispatch(setSaveAtecosStatus('idle'))}
                message="Lista codici ateco aggiornata"
            />
            <ErrorPopup
                active={companyState.editBankStatus === 'failed'}
                close={() => dispatch(setEditBankStatus('idle'))}
                message="Si è verificato un errore durante la modifica della lista delle banche"
            />
            <SuccessPopup
                active={companyState.editBankStatus === 'successfully'}
                close={() => dispatch(setEditBankStatus('idle'))}
                message="Lista banche aggiornata"
            />
            <ErrorPopup
                active={companyState.companyRegistryEmailStatus === 'failed'}
                close={() => dispatch(setRegistryEmailStatus('idle'))}
                message="Si è verificato un errore durante l'attivazione del profilo azienda"
            />
            <SuccessPopup
                active={companyState.companyRegistryEmailStatus === 'successfully'}
                close={() => dispatch(setRegistryEmailStatus('idle'))}
                message="Profilo azienda attivato"
            />
            <div className="col-12 col-lg m-0 p-0">
                <div className="row m-0 p-0" style={{ gap: 16, height: '100%', alignItems: 'flex-start', flexDirection: 'column' }}>
                    <div className="col-12 m-0 p-0">
                        <div className="neutral-container--padding-large">
                            <HeadingComponent label={"Anagrafica azienda"} buttons={[]} size={HeadingSize.SM} />
                            <div className="w-100 d-flex gap-2">
                                <AvatarComponent
                                    src={avatarToBase64}
                                    type={AvatarType.SINGLE}
                                    size={AvatarSize.MD}
                                />
                                <FileInputComponent
                                    disabled={!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('admin-accountant') && !keycloak.hasRealmRole('accountant')}
                                    id={"company-profile-update-registry-avatar"}
                                    onChange={e => {
                                        setFile(e[0]);
                                        toBase64(e[0])
                                            .then(data => {
                                                setAvatarToBase64('data:' + e[0].type + ';base64,' + data)
                                            })
                                    }}
                                />
                            </div>
                            <FormGroup label={"Ragione sociale"} required inputs={[
                                <TextInput
                                    id={"company-profile-update-registry-business-name"}
                                    type={"text"}
                                    disabled={company?.state === CompanyStatusEnum.Active || (!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('admin-accountant') && !keycloak.hasRealmRole('accountant') && !keycloak.hasRealmRole('financial-advisor'))}
                                    placeholder={"Ragione sociale"}
                                    defaultValue={company?.state === CompanyStatusEnum.Active ? (company?.businessName !== null ? company?.businessName : '') : companyState.companyUpdateRegistryRequest.businessName}
                                    onChange={e => {
                                        dispatch(setCompanyUpdateRegistryBusinessName(e.target.value))
                                    }}
                                />]}
                                style={"column"}
                            />
                            <div className="row m-0 p-0" style={{ gap: 16, width: '100%' }}>
                                <div className="col-12 col-lg m-0 p-0">
                                    <FormGroup
                                        label={"Nome"}
                                        inputs={[
                                            <TextInput
                                                id={"company-profile-update-registry-name"}
                                                type={"text"}
                                                placeholder={"Nome"}
                                                disabled={!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('admin-accountant') && !keycloak.hasRealmRole('accountant')}
                                                defaultValue={company?.state === CompanyStatusEnum.Active ? (company?.name !== null ? company?.name : '') : companyState.companyUpdateRegistryRequest.name}
                                                onChange={e => {
                                                    if (company?.state === CompanyStatusEnum.Active) {
                                                        dispatch(setUpdateCompanyName(e.target.value))
                                                    } else
                                                        dispatch(setCompanyUpdateRegistryName(e.target.value))
                                                }}
                                            />]}
                                        style={"column"} />
                                </div>
                                <div className="col-12 col-lg m-0 p-0">
                                    <FormGroup
                                        label={"Cognome"}
                                        inputs={[
                                            <TextInput
                                                id={"company-profile-update-registry-surname"}
                                                type={"text"}
                                                placeholder={"Cognome"}
                                                disabled={!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('admin-accountant') && !keycloak.hasRealmRole('accountant')}
                                                defaultValue={company?.state === CompanyStatusEnum.Active ? (company?.surname !== null ? company?.surname : '') : companyState.companyUpdateRegistryRequest.surname}
                                                onChange={e => {
                                                    if (company?.state === CompanyStatusEnum.Active) {
                                                        dispatch(setUpdateCompanySurname(e.target.value))
                                                    } else
                                                        dispatch(setCompanyUpdateRegistrySurname(e.target.value))
                                                }}
                                            />]} style={"column"}
                                    />
                                </div>
                            </div>
                            <div className="row m-0 p-0" style={{ gap: 16, width: '100%' }}>
                                <div className="col-12 col-lg m-0 p-0">
                                    <FormGroup
                                        required
                                        label={"Partita IVA"}
                                        inputs={[
                                            <TextInput
                                                id={"company-profile-update-registry-vat-number"}
                                                type={"text"}
                                                disabled={company?.state === CompanyStatusEnum.Active || (!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('admin-accountant') && !keycloak.hasRealmRole('accountant'))}
                                                placeholder={"Partita IVA"}
                                                onFocus={e => dispatch(resetErrorUpdateRegistryVat())}
                                                infoText={companyState.errors.updateRegistry.vat !== undefined ? 'Questa partita IVA è già in uso' : 'Inserire da 11 a 16 caratteri'}
                                                error={companyState.errors.updateRegistry.vat !== undefined}
                                                defaultValue={company?.state === CompanyStatusEnum.Active ? (company?.vatNumber !== null ? company?.vatNumber : '') : companyState.companyUpdateRegistryRequest.vatNumber}
                                                onChange={e => {
                                                    if (company?.state === CompanyStatusEnum.Active) {
                                                        dispatch(setUpdateCompanyVatNumber(e.target.value))
                                                    } else
                                                        dispatch(setCompanyUpdateRegistryVatNumber(e.target.value))
                                                }}
                                            />]}
                                        style={"column"}
                                    />
                                </div>
                                <div className="col-12 col-lg m-0 p-0">
                                    <FormGroup
                                        label={"Codice Fiscale"}
                                        required
                                        inputs={[
                                            <TextInput
                                                id={"company-profile-update-registry-fiscal-code"}
                                                type={"text"}
                                                required
                                                infoText="Inserire da 11 a 16 caratteri"
                                                placeholder={"Codice fiscale"}
                                                disabled={!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('admin-accountant') && !keycloak.hasRealmRole('accountant')}
                                                defaultValue={company?.state === CompanyStatusEnum.Active ? (company?.fiscalCode !== null ? company?.fiscalCode : '') : companyState.companyUpdateRegistryRequest.fiscalCode}
                                                onChange={e => {
                                                    if (company?.state === CompanyStatusEnum.Active) {
                                                        dispatch(setUpdateCompanyFiscalCode(e.target.value))
                                                    } else
                                                        dispatch(setCompanyUpdateRegistryFiscalCode(e.target.value))
                                                }}
                                            />]}
                                        style={"column"}
                                    />
                                </div>
                            </div>
                            <div className="row m-0 p-0" style={{ gap: 16, width: '100%' }}>
                                <div className="col-12 col-lg m-0 p-0">
                                    <FormGroup
                                        label={"Numero dipendenti"}
                                        inputs={[
                                            <TextInput
                                                id={"company-profile-update-registry-employees-number"}
                                                type={"number"}
                                                disabled={!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('admin-accountant') && !keycloak.hasRealmRole('accountant')}
                                                placeholder={"Numero dipendenti"}
                                                defaultValue={company?.employeesNumber !== null ? company?.employeesNumber : ''}
                                                onChange={e => {
                                                    if (company?.state === CompanyStatusEnum.Active) {
                                                        dispatch(setUpdateCompanyEmployeesNumber(e.target.value))
                                                    } else
                                                        dispatch(setCompanyUpdateRegistryEmployeesNumber(e.target.value))
                                                }}
                                            />]}
                                        style={"column"}
                                    />
                                </div>
                                <div className="col-12 col-lg m-0 p-0">
                                    <FormGroup
                                        label={"Telefono"}
                                        inputs={[
                                            <TextInput
                                                id={"company-profile-update-registry-phone-number"}
                                                type={"text"}
                                                placeholder={"Telefono"}
                                                disabled={!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('admin-accountant') && !keycloak.hasRealmRole('accountant')}
                                                infoText="Inserire da 9 a 10 caratteri"
                                                defaultValue={company?.state === CompanyStatusEnum.Active ? (company?.phoneNumber !== null ? company?.phoneNumber : '') : companyState.companyUpdateRegistryRequest.phoneNumber}
                                                onChange={e => {
                                                    if (company?.state === CompanyStatusEnum.Active) {
                                                        dispatch(setUpdateCompanyPhoneNumber(e.target.value === '' ? null : e.target.value))
                                                    } else
                                                        dispatch(setCompanyUpdateRegistryPhoneNumber(e.target.value === '' ? null : e.target.value))
                                                }}
                                            />]}
                                        style={"column"}
                                    />
                                </div>
                                {
                                    company?.state === CompanyStatusEnum.RegistryPending &&
                                    <FormGroup
                                        label={"Email"}
                                        inputs={[
                                            <TextInput
                                                id={"company-profile-update-registry-email"}
                                                type={"text"}
                                                disabled={!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('admin-accountant') && !keycloak.hasRealmRole('accountant')}
                                                placeholder={"Email"}
                                                onFocus={e => {
                                                    dispatch(resetErrorUpdateRegistryEmail())
                                                    dispatch(resetErrorRegistryEmail())
                                                }}
                                                infoText={
                                                    (companyState.errors.updateRegistry.email !== undefined || companyState.errors.registryEmail.email !== undefined) ? 'Questo indirizzo email è già in uso' : undefined}
                                                error={companyState.errors.updateRegistry.email !== undefined || companyState.errors.registryEmail.email !== undefined}
                                                defaultValue={companyState.companyUpdateRegistryRequest.email}
                                                onChange={e => {
                                                    dispatch(setCompanyUpdateRegistryEmail(e.target.value === '' ? null : e.target.value))
                                                }}
                                            />
                                        ]}
                                        style={"column"}
                                    />
                                }
                                <FormGroup
                                    label={"Note"}
                                    inputs={[
                                        <TextInput
                                            id={"company-profile-update-registry-notes"}
                                            type={"number"}
                                            placeholder={"Un po' d’informazioni sull'azienda e sui servizi che offre."}
                                            multiline
                                            disabled={!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('admin-accountant') && !keycloak.hasRealmRole('accountant')}
                                            infoText="275 caratteri rimasti"
                                            defaultValue={company?.note !== null ? company?.note : ''}
                                            onChange={e => {
                                                if (company?.state === CompanyStatusEnum.Active) {
                                                    dispatch(setUpdateCompanyNote(e.target.value))
                                                } else
                                                    dispatch(setCompanyUpdateRegistryNote(e.target.value))
                                            }}
                                        />
                                    ]}
                                    style={"column"}
                                />
                            </div>

                        </div>
                    </div>
                    {
                        company?.state === CompanyStatusEnum.Active &&
                        <div className="col-12 m-0 p-0">
                            <div className="neutral-container--padding-large" style={{ minHeight: '90px', alignItems: 'center', justifyContent: 'center' }}>
                                <div className="row m-0 p-0 w-100 gap-4 align-items-center">
                                    <div className="col-12 col-lg-auto p-0 m-0">
                                        <SwitchComponent
                                            label="Attiva profilo azienda"
                                            disabled={company?.email !== null}
                                            checked={showEmail}
                                            onChange={() => setShowEmail(!showEmail)}
                                        />
                                    </div>
                                    {
                                        showEmail &&
                                        <div className="col-12 col-lg p-0 m-0">
                                            <TextInput
                                                id={"company-profile-update-registry-email"}
                                                type={"text"}
                                                disabled={company?.email !== null || (!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('admin-accountant') && !keycloak.hasRealmRole('accountant'))}
                                                placeholder={"Email"}
                                                onFocus={e => {
                                                    dispatch(resetErrorUpdateRegistryEmail())
                                                    dispatch(resetErrorRegistryEmail())
                                                }}
                                                infoText={
                                                    (companyState.errors.updateRegistry.email !== undefined || companyState.errors.registryEmail.email !== undefined) ? 'Questo indirizzo email è già in uso' : undefined}
                                                error={companyState.errors.updateRegistry.email !== undefined || companyState.errors.registryEmail.email !== undefined}
                                                defaultValue={company?.state === CompanyStatusEnum.Active ? (company?.email !== null ? company?.email : '') : companyState.companyRegistryEmailRequest.email}
                                                onChange={e => {
                                                    if (company?.state === CompanyStatusEnum.Active)
                                                        dispatch(setRegistryEmail(e.target.value === '' ? null : e.target.value))
                                                    else
                                                        dispatch(setCompanyUpdateRegistryEmail(e.target.value))
                                                }}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="col-12 col-lg m-0 p-0">
                <div className="row m-0 p-0" style={{ gap: 16, height: '100%', alignItems: 'flex-start', flexDirection: 'column' }}>
                    <div className="col-12 m-0 p-0">
                        <div className="neutral-container--padding-large">
                            <HeadingComponent label={"Impostazioni"} size={HeadingSize.SM} buttons={[]} />
                            <div className="row m-0 p-0" style={{ gap: 16, width: '100%' }}>
                                <div className="col-12 col-lg m-0 p-0">
                                    <SelectComponent disabled id={"company-profile-regime"} value={company?.accountingScheme !== undefined ? company?.accountingScheme : ''} menuItems={[<MenuItem key='accounting-scheme-0' value=''>Regime contabile</MenuItem>, <MenuItem key={'accounting-scheme-' + company?.accountingScheme} value={company?.accountingScheme}>{company?.accountingScheme === AccountingSchemeEnum.Ordinary ? 'Ordinario' : 'Semplificato'}</MenuItem>]} label="Regime contabile" />
                                </div>
                                <div className="col-12 col-lg m-0 p-0">
                                    <SelectComponent disabled id={"company-profile-periodicity"} value={company?.periodicity} menuItems={[<MenuItem key='periodicity-0' value=''>Seleziona la tipologia...</MenuItem>, <MenuItem key={'periodicity-' + company?.periodicity} value={company?.periodicity}>{company?.periodicity === PeriodicityEnum.Monthly ? 'Mensile' : 'Trimestrale'}</MenuItem>]} label="Periodicità operazioni" />
                                </div>
                            </div>
                            <div className="row m-0 p-0" style={{ gap: 16, width: '100%' }}>
                                {
                                    company?.journal !== null &&
                                    <div className="col-12 col-lg m-0 p-0">
                                        <SelectComponent disabled id={"company-profile-journal"} value={company?.journal !== null ? company?.journal.toFixed(0) : ''} menuItems={[<MenuItem key='journal-0' value=''>Seleziona la tipologia...</MenuItem>, <MenuItem key={'journal-' + company?.journal} value={company?.journal !== null ? company?.journal.toFixed(0) : ''}>{company?.journal !== undefined ? journalLabelMap.get(numberToJournalMap(company.journal)) : ''}</MenuItem>]} label="Movimenti libro giornale" />
                                    </div>
                                }
                                <div className="col-12 col-lg m-0 p-0">
                                    <SelectComponent disabled id={"company-profile-foreign"} value={company?.annualForeignInvoices.toString()} menuItems={[<MenuItem key='foreign-0' value=''>Seleziona la tipologia...</MenuItem>, <MenuItem key={'foreign-' + company?.annualForeignInvoices} value={company?.annualForeignInvoices.toString()}>{company?.annualForeignInvoices !== undefined ? foreignLabelMap.get(numberToForeignMap(company?.annualForeignInvoices)) : ''}</MenuItem>]} label="Fatture estere annue" />
                                </div>
                            </div>
                            <div className="row m-0 p-0" style={{ gap: 16, width: '100%' }}>
                                <div className="col-12 col-lg m-0 p-0">
                                    <FormGroup label={"Regimi IVA speciali"} inputs={[
                                        <SwitchComponent checked={company?.specialVatSchemes} disabled />,
                                    ]} style={"column"} />
                                </div>
                                {
                                    company?.specialVatSchemes &&
                                    <div className="col-12 col-lg m-0 p-0">
                                        <FormGroup label={""} inputs={[
                                            <SelectComponent
                                                disabled
                                                id={"select-special-vat-scheme"}
                                                value={company?.specialVatScheme === null ? '' : company.specialVatScheme}
                                                menuItems={[
                                                    <MenuItem key={'SpecialVatSchemeEnum.SCHEME_1'} value={''}>Nessun regime IVA speciale</MenuItem>,
                                                    <MenuItem key={SpecialVatSchemeEnum.SCHEME_1} value={SpecialVatSchemeEnum.SCHEME_1}>Agricoltura e pesca</MenuItem>,
                                                    <MenuItem key={SpecialVatSchemeEnum.SCHEME_2} value={SpecialVatSchemeEnum.SCHEME_2}>Vendita sali e tabacchi</MenuItem>,
                                                    <MenuItem key={SpecialVatSchemeEnum.SCHEME_3} value={SpecialVatSchemeEnum.SCHEME_3}>Commercio dei fiammiferi</MenuItem>,
                                                    <MenuItem key={SpecialVatSchemeEnum.SCHEME_4} value={SpecialVatSchemeEnum.SCHEME_4}>Editoria</MenuItem>,
                                                    <MenuItem key={SpecialVatSchemeEnum.SCHEME_5} value={SpecialVatSchemeEnum.SCHEME_5}>Gestione di servizi di telefonia pubblica</MenuItem>,
                                                    <MenuItem key={SpecialVatSchemeEnum.SCHEME_6} value={SpecialVatSchemeEnum.SCHEME_6}>Rivendita di documenti di trasporto pubblico e di sosta</MenuItem>,
                                                    <MenuItem key={SpecialVatSchemeEnum.SCHEME_7} value={SpecialVatSchemeEnum.SCHEME_7}>Intrattenimenti, giochi e altre attività di cui al D.P.R. n. 640/1972</MenuItem>,
                                                    <MenuItem key={SpecialVatSchemeEnum.SCHEME_8} value={SpecialVatSchemeEnum.SCHEME_8}>Agenzie di viaggi e turismo</MenuItem>,
                                                    <MenuItem key={SpecialVatSchemeEnum.SCHEME_9} value={SpecialVatSchemeEnum.SCHEME_9}>Agriturismo</MenuItem>,
                                                    <MenuItem key={SpecialVatSchemeEnum.SCHEME_10} value={SpecialVatSchemeEnum.SCHEME_10}>Vendite a domicilio</MenuItem>,
                                                    <MenuItem key={SpecialVatSchemeEnum.SCHEME_11} value={SpecialVatSchemeEnum.SCHEME_11}>Rivendita di beni usati, di oggetti d'arte, d'antiquariato o da collezione</MenuItem>,
                                                    <MenuItem key={SpecialVatSchemeEnum.SCHEME_12} value={SpecialVatSchemeEnum.SCHEME_12}>Agenzie di vendite all'asta di oggetti d'arte, antiquariato o da collezione</MenuItem>,
                                                ]}
                                            />
                                        ]} style={"column"} />
                                    </div>
                                }
                            </div>
                            <div className="row m-0 p-0" style={{ gap: 16, width: '100%' }}>
                                {
                                    company?.accountingScheme === AccountingSchemeEnum.Ordinary &&
                                    <div className="col-12 col-lg m-0 p-0">
                                        <FormGroup label={"Gestione servizi"} inputs={[
                                            <div className="d-flex flex-row gap-4">
                                                {activityCategories}
                                            </div>
                                        ]} style={"column"} />
                                    </div>
                                }
                                <div className="col-12 col-lg m-0 p-0">
                                    <FormGroup label={"Area fiscale"} inputs={[
                                        <SwitchComponent disabled={company?.state !== CompanyStatusEnum.Active || !keycloak.hasRealmRole('admin') || company.email === null} checked={companyState.companyEditPremium.premium} onChange={(_, checked) => dispatch(setCompanyEditPremium(checked))} />,
                                    ]} style={"column"} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        company?.state === CompanyStatusEnum.Active &&
                        <div className="col-12 m-0 p-0" style={{}}>
                            <div className="neutral-container--padding-large">
                                <HeadingComponent size={HeadingSize.SM} label={"Sezionali registri"} subLabel={atecoLabels(company.atecoCodesNumber)}
                                    buttons={
                                        keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('financial-advisor') || keycloak.hasRealmRole('admin-accountant') || keycloak.hasRealmRole('accountant') ?
                                            [<ButtonComponent disabled={company?.state !== CompanyStatusEnum.Active} label={"Aggiungi codice"} onClick={() => dispatch(setOpenAtecoModal(true))} color={Colors.PRIMARY} variant={Variant.OUTLINE} size={Size.SM} iconStyle={IconStyle.OFF} />] : []} />
                                {
                                    company.atecoCodeIds.length > 0 &&
                                    <div className="neutral-container--padding-small flex-row" >
                                        {atecoList}
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    {
                        company?.state === CompanyStatusEnum.Active && company.accountingScheme === AccountingSchemeEnum.Ordinary &&
                        <div className="col-12 col-lg m-0 p-0">
                            <div className="neutral-container--padding-large" style={{ height: '100%', flexGrow: 2 }}>
                                <HeadingComponent size={HeadingSize.SM} label={"Numero estratti conto"} subLabel={banksLabel(company.banksNumber)} buttons={keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('financial-advisor') || keycloak.hasRealmRole('admin-accountant') || keycloak.hasRealmRole('accountant') ? [<ButtonComponent disabled={company?.state !== CompanyStatusEnum.Active} label={"Aggiungi banca"} onClick={() => dispatch(setOpenBankModal(true))} color={Colors.PRIMARY} variant={Variant.OUTLINE} size={Size.SM} iconStyle={IconStyle.OFF} />] : []} />
                                <div style={{ overflow: 'auto', gap: '16px', display: 'flex', flexDirection: 'column', width: '100%', paddingRight: '16px', height: 'auto' }}>
                                    {bankAccounts}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <AtecoModal />
            <BankModal />
            <ConfirmOrDenyModal
                open={companyState.openDeleteBankModal}
                handleClose={() => dispatch(setOpenDeleteBankModal(false))}
                title={"Elimina banca"}
                description={"Sei sicuro di voler eliminare la banca?"}
                labelDeny={"Annulla"}
                labelConfirm={"Elimina"}
                actionConfirm={() => {
                    if (company !== undefined) {
                        let newBankList = [...company.bankingAccounts]
                        dispatch(editBankAccounts({ companyId: company.id.toString(), data: { bankingAccounts: newBankList.filter(bank => newBankList.indexOf(bank) !== bankIndexToDelete) } }))
                        dispatch(setOpenDeleteBankModal(false))
                    }
                }}
            />
        </div>
    )
}