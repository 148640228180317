import { ReactNode, useEffect } from "react";
import { ButtonComponent } from "../../button";
import { Colors, IconStyle, Size, Variant } from "../../button/dto";
import { FileInputComponent } from "../../fileInput";
import { HeadingComponent } from "../../heading";
import { HeadingSize } from "../../heading/dto";
import { DownloadIcon } from "../../icons/download";
import { HasDocumentIcon } from "../../icons/hasDocument";
import { PlusIcon } from "../../icons/plus";
import { TrashIcon } from "../../icons/trash";
import { NewObjectService } from "../../objects/service";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { SpinnerComponent } from "../../spinner";
import colors from '../../utils/index.module.scss';
import { findFundings, setFindFundingsStatus, setFundings, setFundingsNumber } from "../slice";

interface Props {
    disabled: boolean
}

export function Fundings(props: Props) {
    const { disabled } = props
    const companyState = useAppSelector(state => state.company)
    const dispatch = useAppDispatch()

    const objectService = NewObjectService()

    const fundings = companyState.findCompanyResponse?.fundingObjectIds
    const fundingsRequest = companyState.fundings

    useEffect(() => {
        if (companyState.findCompanyStatus === 'successfully' && companyState.findCompanyResponse !== undefined) {
            dispatch(setFindFundingsStatus('idle'))
            if (fundings !== undefined && fundings !== null && fundings.length > 0) {
                dispatch(findFundings(fundings.map(id => id.toString())))
            }
            if (fundingsRequest === null || fundingsRequest.length === 0) {
                dispatch(setFundings([null]))
                dispatch(setFundingsNumber(0))
            }
        }
    }, [companyState.findCompanyStatus, companyState.findCompanyResponse])

    useEffect(() => {
        if (companyState.findFundingsStatus === 'successfully') {
            dispatch(setFindFundingsStatus('idle'))
            if (companyState.findFundingsResponse !== undefined && companyState.findFundingsResponse.length > 0) {
                const objectStorage = NewObjectService()
                let newFunding: File[] = []
                companyState.findFundingsResponse.forEach((file, index) => {
                    newFunding.push(objectStorage.base64ToFile(file))
                })
                dispatch(setFundings(newFunding))
                dispatch(setFundingsNumber(newFunding.filter(funding => funding !== null).length))
            }
            else {
                dispatch(setFundings([null]))
                dispatch(setFundingsNumber(0))
            }
        }
    }, [companyState.findFundingsStatus])

    async function handleClick(funding: File, index: number) {
        const buffer = await funding.arrayBuffer()
        objectService.downloadPdf('Finanziamento-' + (index + 1), buffer, funding.type)
    }

    if (companyState.findCompanyStatus === 'loading' || companyState.findFundingsStatus === 'loading') {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <SpinnerComponent size="small" />
            </div>
        )
    }

    let fundingsList: ReactNode[] = []
    if (fundingsRequest !== undefined && fundingsRequest !== null && fundingsRequest.length > 0) {
        fundingsRequest.forEach((funding, index) => {
            if (funding === null) {
                fundingsList.push(
                    <div
                        key={'funding-' + index}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: 8,
                            borderRadius: '8px'
                        }}
                    >
                        <FileInputComponent id={"funding-" + index} disabled={disabled} onChange={e => {
                            if (e !== null && e !== undefined) {
                                const newFunding = [...fundingsRequest]
                                newFunding[index] = e[0]
                                dispatch(setFundings(newFunding))
                                dispatch(setFundingsNumber(newFunding.filter(funding => funding !== null).length))
                            }
                        }} />
                        <ButtonComponent
                            label={""}
                            disabled={disabled}
                            onClick={() => {
                                let newFundings = [...fundingsRequest]
                                newFundings = newFundings.slice(0, index).concat(newFundings.slice(index + 1));
                                dispatch(setFundings(newFundings.length !== 0 ? newFundings : [null]))
                                dispatch(setFundingsNumber(newFundings.filter(funding => funding !== null).length))
                            }}
                            color={Colors.PRIMARY}
                            variant={Variant.LINK}
                            size={Size.SM}
                            iconStyle={IconStyle.ONLY}
                            icon={<TrashIcon colorBase={colors.primary500} />}
                        />
                    </div>
                )
            } else {
                const size = (funding.size / (1024 * 1024)).toFixed(2)
                fundingsList.push(
                    <div
                        key={'funding-' + index}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: 8,
                            border: '1px solid ' + colors.neutral80,
                            borderRadius: '8px',
                            padding: '10px 12px 10px 12px'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: 8
                            }}
                        >
                            <HasDocumentIcon />
                            <span className="text text--md typography--medium thypography-neutral--800">{fundingsRequest[index]?.name === 'unknown' ? 'Finanziamento-' + (index + 1) : fundingsRequest[index]?.name}</span>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: 8
                            }}
                        >
                            <span className="text text--md typography--regular thypography-neutral--400">{size + ' MB'}</span>
                            <ButtonComponent
                                label={""}
                                disabled={disabled}
                                onClick={() => {
                                    let newFundings = [...fundingsRequest]
                                    newFundings = newFundings.slice(0, index).concat(newFundings.slice(index + 1));
                                    dispatch(setFundings(newFundings.length !== 0 ? newFundings : [null]))
                                    dispatch(setFundingsNumber(newFundings.filter(funding => funding !== null).length))
                                }}
                                color={Colors.PRIMARY}
                                variant={Variant.LINK}
                                size={Size.SM}
                                iconStyle={IconStyle.ONLY}
                                icon={<TrashIcon colorBase={colors.primary500} />}
                            />
                            <ButtonComponent
                                label={""}
                                disabled={disabled}
                                onClick={() => handleClick(funding, index)}
                                color={Colors.PRIMARY}
                                variant={Variant.LINK}
                                size={Size.SM}
                                iconStyle={IconStyle.ONLY}
                                icon={<DownloadIcon colorBase={colors.primary500} />}
                            />
                        </div>
                    </div>
                )
            }
        })
    }

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <HeadingComponent size={HeadingSize.SM} label={"Finanziamenti"} buttons={[
                <ButtonComponent
                    disabled={fundingsRequest.length >= 5 || disabled}
                    label={"Aggiungi Finanziamento"}
                    icon={<PlusIcon colorBase={""} />}
                    onClick={() => {
                        const newFunding = [...fundingsRequest]
                        newFunding.push(null)
                        dispatch(setFundings([...newFunding]))
                        dispatch(setFundingsNumber(newFunding.filter(funding => funding !== null).length))
                    }}
                    color={Colors.PRIMARY}
                    variant={Variant.OUTLINE}
                    size={Size.SM}
                    iconStyle={IconStyle.LEFT}
                />
            ]}
            />
            {fundingsList}
        </div>
    )
}