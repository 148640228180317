
import { keycloak } from "../../keycloak";
import { AdminNames } from "./admin";
import { Partners } from "./partners";

export function PartnersAndDirectors() {

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 24, width: '100%' }}>
            <div className="row m-0 p-0" style={{ gap: 24, width: '100%' }}>
                <div className="col-12 col-xl p-0 m-0">
                    <AdminNames disabled={!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('admin-accountant') && !keycloak.hasRealmRole('accountant') && !keycloak.hasRealmRole('operator')} />
                </div>
                <div className="col-12 col-xl p-0 m-0">
                    <Partners disabled={!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('admin-accountant') && !keycloak.hasRealmRole('accountant') && !keycloak.hasRealmRole('operator')} />
                </div>
            </div>
        </div>
    )
}