import { ReactElement, ReactNode, cloneElement, useEffect, useState } from "react";
import colors from '../utils/index.module.scss';
import './style.scss';


interface Props {
    open: boolean
    icon?: ReactElement
    endIcon?: ReactNode
    label?: string
    active?: boolean
    onClick?: () => void
}

export function SidebarRow(props: Props) {
    const { open } = props
    const { icon } = props
    const { endIcon } = props
    const { label } = props
    const { active } = props
    const { onClick } = props

    const [hover, setHover] = useState<boolean>(false)
    const [color, setColor] = useState<string>(colors.neutral500)

    useEffect(() => {
        if (active) {
            setColor(colors.primary500)
        } else {
            setColor(colors.neutral500)
        }
        if (hover) {
            setColor(colors.neutral600)
        }
    }, [hover, active])

    return (
        <div
            style={{ cursor: 'pointer' }}
            onClick={() => onClick && onClick()}
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            className={active ? 'sidebar-row--active sidebar-row' : "sidebar-row"}
        >
            <div style={{ display: "flex", alignItems: 'center' }}>
                {icon && cloneElement(icon, { colorBase: color })}
            </div>
            {
                open && (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: 'calc(100% - 30px)', alignItems: 'space-between' }}>
                        <div style={{ verticalAlign: 'middle', height: '20px', width: 'calc(100% - 30px)' }} className="button button--sm typography--semibold text-overflow">
                            {label}
                        </div>
                        <div style={{ display: "flex", alignItems: 'center' }}>
                            {endIcon}
                        </div>
                    </div>
                )
            }
        </div>
    )
}