import Keycloak from "keycloak-js";

const urlMap = new Map<string, string>([
    ['https://iltuocontabile.madfarm.it', 'https://servicecontabile-auth.madfarm.it'],
    ['https://app.iltuocontabile.it', 'https://auth.servicecontabile.it'],
    ['http://localhost:3000', 'http://localhost:8081'],
])

export const keycloak = new Keycloak({
    realm: "iltuocontabile",
    url: urlMap.get(window.location.origin),
    clientId: "frontend",
})
