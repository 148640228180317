import { ReactNode, useEffect } from "react";
import { ButtonComponent } from "../../button";
import { Colors, IconStyle, Size, Variant } from "../../button/dto";
import { FileInputComponent } from "../../fileInput";
import { HeadingComponent } from "../../heading";
import { HeadingSize } from "../../heading/dto";
import { DownloadIcon } from "../../icons/download";
import { HasDocumentIcon } from "../../icons/hasDocument";
import { PlusIcon } from "../../icons/plus";
import { TrashIcon } from "../../icons/trash";
import { NewObjectService } from "../../objects/service";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { SpinnerComponent } from "../../spinner";
import colors from '../../utils/index.module.scss';
import { findLeasings, setFindLeasingsStatus, setLeasings, setLeasingsNumber } from "../slice";

interface Props {
    disabled: boolean
}

export function Leasings(props: Props) {
    const { disabled } = props
    const companyState = useAppSelector(state => state.company)
    const dispatch = useAppDispatch()

    const objectService = NewObjectService()

    const leasings = companyState.findCompanyResponse?.leasingObjectIds
    const leasingsRequest = companyState.leasings

    useEffect(() => {
        if (companyState.findCompanyStatus === 'successfully' && companyState.findCompanyResponse !== undefined) {
            dispatch(setFindLeasingsStatus('idle'))
            if (leasings !== undefined && leasings !== null && leasings.length > 0) {
                dispatch(findLeasings(leasings.map(id => id.toString())))
            }
            if (leasingsRequest === null || leasingsRequest.length === 0) {
                dispatch(setLeasings([null]))
                dispatch(setLeasingsNumber(0))
            }
        }
    }, [companyState.findCompanyStatus, companyState.findCompanyResponse])

    useEffect(() => {
        if (companyState.findLeasingsStatus === 'successfully') {
            dispatch(setFindLeasingsStatus('idle'))
            if (companyState.findLeasingsResponse !== undefined && companyState.findLeasingsResponse.length > 0) {
                const objectStorage = NewObjectService()
                let newLeasings: File[] = []
                companyState.findLeasingsResponse.forEach((file, index) => {
                    newLeasings.push(objectStorage.base64ToFile(file))
                })
                dispatch(setLeasings(newLeasings))
                dispatch(setLeasingsNumber(newLeasings.filter(leasings => leasings !== null).length))
            }
            else {
                dispatch(setLeasings([null]))
                dispatch(setLeasingsNumber(0))
            }
        }
    }, [companyState.findLeasingsStatus])

    async function handleClick(leasing: File, index: number) {
        const buffer = await leasing.arrayBuffer()
        objectService.downloadPdf('Leasing-' + (index + 1), buffer, leasing.type)
    }

    if (companyState.findCompanyStatus === 'loading' || companyState.findLeasingsStatus === 'loading') {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <SpinnerComponent size="small" />
            </div>
        )
    }

    let leasigsList: ReactNode[] = []
    if (leasingsRequest !== undefined && leasingsRequest !== null && leasingsRequest.length > 0) {
        leasingsRequest.forEach((leasing, index) => {
            if (leasing === null) {
                leasigsList.push(
                    <div
                        key={'leasings-' + index}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: 8,
                            borderRadius: '8px'
                        }}
                    >
                        <FileInputComponent id={"leasings-" + index} disabled={disabled} onChange={e => {
                            if (e !== null && e !== undefined) {
                                const newLeasing = [...leasingsRequest]
                                newLeasing[index] = e[0]
                                dispatch(setLeasings(newLeasing))
                                dispatch(setLeasingsNumber(newLeasing.filter(leasing => leasing !== null).length))
                            }
                        }} />
                        <ButtonComponent
                            label={""}
                            disabled={disabled}
                            onClick={() => {
                                let newLeasing = [...leasingsRequest]
                                newLeasing = newLeasing.slice(0, index).concat(newLeasing.slice(index + 1));
                                dispatch(setLeasings(newLeasing.length !== 0 ? newLeasing : [null]))
                                dispatch(setLeasingsNumber(newLeasing.filter(leasing => leasing !== null).length))
                            }}
                            color={Colors.PRIMARY}
                            variant={Variant.LINK}
                            size={Size.SM}
                            iconStyle={IconStyle.ONLY}
                            icon={<TrashIcon colorBase={colors.primary500} />}
                        />
                    </div>
                )
            } else {
                const size = (leasing.size / (1024 * 1024)).toFixed(2)
                leasigsList.push(
                    <div
                        key={'leasing-' + index}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: 8,
                            border: '1px solid ' + colors.neutral80,
                            borderRadius: '8px',
                            padding: '10px 12px 10px 12px'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: 8
                            }}
                        >
                            <HasDocumentIcon />
                            <span className="text text--md typography--medium thypography-neutral--800">{leasingsRequest[index]?.name === 'unknown' ? 'Leasing-' + (index + 1) : leasingsRequest[index]?.name}</span>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: 8
                            }}
                        >
                            <span className="text text--md typography--regular thypography-neutral--400">{size + ' MB'}</span>
                            <ButtonComponent
                                label={""}
                                disabled={disabled}
                                onClick={() => {
                                    let newLeasing = [...leasingsRequest]
                                    newLeasing = newLeasing.slice(0, index).concat(newLeasing.slice(index + 1));
                                    dispatch(setLeasings(newLeasing.length !== 0 ? newLeasing : [null]))
                                    dispatch(setLeasingsNumber(newLeasing.filter(leasing => leasing !== null).length))
                                }}
                                color={Colors.PRIMARY}
                                variant={Variant.LINK}
                                size={Size.SM}
                                iconStyle={IconStyle.ONLY}
                                icon={<TrashIcon colorBase={colors.primary500} />}
                            />
                            <ButtonComponent
                                label={""}
                                disabled={disabled}
                                onClick={() => handleClick(leasing, index)}
                                color={Colors.PRIMARY}
                                variant={Variant.LINK}
                                size={Size.SM}
                                iconStyle={IconStyle.ONLY}
                                icon={<DownloadIcon colorBase={colors.primary500} />}
                            />
                        </div>
                    </div>
                )
            }
        })
    }

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <HeadingComponent size={HeadingSize.SM} label={"Leasing"} buttons={[
                <ButtonComponent
                    disabled={leasingsRequest.length >= 5 || disabled}
                    label={"Aggiungi Leasing"}
                    icon={<PlusIcon colorBase={""} />}
                    onClick={() => {
                        const newLeasing = [...leasingsRequest]
                        newLeasing.push(null)
                        dispatch(setLeasings([...newLeasing]))
                        dispatch(setLeasingsNumber(newLeasing.filter(leasing => leasing !== null).length))
                    }}
                    color={Colors.PRIMARY}
                    variant={Variant.OUTLINE}
                    size={Size.SM}
                    iconStyle={IconStyle.LEFT}
                />
            ]}
            />
            {leasigsList}
        </div>
    )
}