import { capitalize } from "@mui/material";
import { compareDesc, format, isValid, lastDayOfMonth } from "date-fns";
import it from "date-fns/locale/it";
import { ReactNode, useEffect } from "react";
import { ButtonComponent } from "../../button";
import { Colors, IconStyle, Size, Variant } from "../../button/dto";
import { EmptyList } from "../../emptyList/emptyList";
import { ErrorPopup } from "../../errorPopup";
import { EventsCalendarComponent } from "../../eventsCalendar";
import { HeadingComponent } from "../../heading";
import { HeadingSize } from "../../heading/dto";
import { PillComponent } from "../../pills";
import { PillsColor, PillsEmphasis, PillsOutline, PillsSize } from "../../pills/dto";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { SpinnerComponent } from "../../spinner";
import { SuccessPopup } from "../../successPopup";
import colors from '../../utils/index.module.scss';
import { EventDTO, EventStatusEnum, EventTypeEnum } from "./dtoEvents";
import { MenuEventComponent } from "./menuEvent";
import { NewEventModal } from "./newEventModal";
import { findEvents, setDeleteEventStatus, setEditEventStatus, setFromDateFilter, setModalEventType, setMonth, setNewEventDate, setNewEventState, setOpenNewEventsModal, setToDateFilter, setYear } from "./sliceEvents";

export function CalendarTab() {
    const dispatch = useAppDispatch()

    const eventsState = useAppSelector(state => state.events)

    let listedEvents: ReactNode[] = []
    let eventsByDaysList: { eventsByDay: { events: EventDTO[], key: Date }[] } = { eventsByDay: [] }

    useEffect(() => {
        dispatch(findEvents(eventsState.findEventsFilters))
    }, [])

    useEffect(() => {
        dispatch(findEvents(eventsState.findEventsFilters))
    }, [eventsState.findEventsFilters.fromDate])

    useEffect(() => {
        if (eventsState.deleteEventStatus === 'successfully')
            dispatch(findEvents(eventsState.findEventsFilters))
    }, [eventsState.deleteEventStatus])

    useEffect(() => {
        if (eventsState.editEventStatus === 'successfully')
            dispatch(findEvents(eventsState.findEventsFilters))
    }, [eventsState.editEventStatus])

    useEffect(() => {
        if (eventsState.updateEventStatusStatus === 'successfully')
            dispatch(findEvents(eventsState.findEventsFilters))
    }, [eventsState.updateEventStatusStatus])

    if (
        eventsState.newEventState === "loading" ||
        eventsState.deleteEventStatus === "loading" ||
        eventsState.editEventStatus === "loading" ||
        eventsState.updateEventStatusStatus === "loading"
    ) {
        return (<div style={{ width: '100%', height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><SpinnerComponent size='small' /></div>)
    }

    if (
        eventsState.findEventsStatus === 'successfully' &&
        eventsState.findEventsResponse !== undefined &&
        eventsState.findEventsResponse.data !== undefined
    ) {
        eventsState.findEventsResponse.data.forEach(event => {
            let eventByDay = eventsByDaysList.eventsByDay.find(listedEvent => format(new Date(event.date), 'yyyy-MM-ddd') === format(new Date(listedEvent.key), 'yyyy-MM-ddd'))
            if (eventByDay === undefined) {
                eventsByDaysList.eventsByDay.push({
                    key: event.date,
                    events: [event]
                })
            } else {
                eventByDay.events.push(event)
            }
        })
        eventsByDaysList.eventsByDay.forEach(eventByDay => {
            listedEvents.push(
                <div
                    key={eventByDay.key.toString()}
                    style={{
                        display: "flex",
                        flexDirection: 'row',
                        alignItems: "flex-start",
                        padding: "12px 4px",
                        borderRadius: "12px",
                        border: "1px solid " + colors.neutral80,
                        width: '100%'
                    }}
                >
                    <div
                        style={{
                            width: "56px",
                            padding: "4px 0px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "2px",
                        }}
                    >
                        <span
                            style={{
                                color: (format(new Date(eventByDay.key), 'EEEE') === 'Sunday' ? colors.destructive500 : colors.neutral400),
                                textAlign: "center",
                                fontFamily: "Figtree",
                                fontSize: "12px",
                                fontWeight: "600",
                                lineHeight: "16px",
                            }}
                        >
                            {format(new Date(eventByDay.key), 'eee', { locale: it }).toUpperCase()}
                        </span>
                        <span
                            style={{
                                color: (format(new Date(eventByDay.key), 'EEEE') === 'Sunday' ? colors.destructive500 : colors.neutral800),
                                textAlign: "center",
                                fontFamily: "Figtree",
                                fontSize: "20px",
                                fontWeight: "500",
                                lineHeight: "24px",
                            }}
                        >
                            {format(new Date(eventByDay.key), 'dd')}
                        </span>
                    </div>
                    <div style={{
                        height: '100%',
                        width: '1px',
                        backgroundColor: colors.neutral80
                    }} />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "8px",
                            width: '100%'
                        }}
                    >
                        {eventByDay.events.map((event, index) =>
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        width: '100%'
                                    }}>
                                    <div
                                        key={event.id}
                                        style={{
                                            display: "flex",
                                            padding: "4px 16px",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            flexGrow: 1,
                                            width: '100%',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                                gap: '16px'
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontFamily: "Figtree",
                                                    fontSize: "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    lineHeight: "20px",
                                                    color: colors.neutral800
                                                }}
                                            >
                                                {event.name}
                                            </span>
                                            {
                                                event.type === EventTypeEnum.F24 &&
                                                (
                                                    event.status === EventStatusEnum.Resolved ?
                                                        <PillComponent key={event.id} label={"Risolto"} size={PillsSize.XS} color={PillsColor.SUCCESS} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} /> : (
                                                            compareDesc(new Date(event.date), new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) !== -1 ?
                                                                <PillComponent key={event.id} label={"Scaduto"} size={PillsSize.XS} color={PillsColor.DESTRUCTIVE} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} /> :
                                                                <PillComponent key={event.id} label={"In attesa"} size={PillsSize.XS} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />

                                                        )

                                                )
                                            }
                                        </div>
                                        <span
                                            className="text text--md typography--regular typography-neutral--500"
                                        >{event.note !== null && event.note.length < 140 ? event.note : event.note?.substring(0, 140).concat("...")}</span>
                                    </div>
                                    <MenuEventComponent event={event} />
                                </div>
                                {
                                    eventByDay.events.length - 1 !== index &&
                                    <div style={{
                                        height: '1px',
                                        width: '100%',
                                        alignSelf: 'center',
                                        backgroundColor: colors.neutral80
                                    }} />
                                }
                            </>
                        )}
                    </div>
                </div>
            )
        })
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: "column",
            gap: '24px',
            width: '100%'
        }}>
            <SuccessPopup
                active={eventsState.newEventState === 'successfully'}
                close={() => dispatch(setNewEventState('idle'))}
                message="Evento aggiunto"
            />
            <ErrorPopup
                active={eventsState.newEventState === 'failed'}
                close={() => dispatch(setNewEventState('idle'))}
                message="Si è verificato un errore durante la creazione dell'evento"
            />
            <SuccessPopup
                active={eventsState.editEventStatus === 'successfully'}
                close={() => dispatch(setEditEventStatus('idle'))}
                message="Evento modificato"
            />
            <ErrorPopup
                active={eventsState.editEventStatus === 'failed'}
                close={() => dispatch(setEditEventStatus('idle'))}
                message="Si è verificato un errore durante la modifica dell'evento"
            />
            <SuccessPopup
                active={eventsState.deleteEventStatus === 'successfully'}
                close={() => dispatch(setDeleteEventStatus('idle'))}
                message="Evento eliminato"
            />
            <ErrorPopup
                active={eventsState.deleteEventStatus === 'failed'}
                close={() => dispatch(setDeleteEventStatus('idle'))}
                message="Si è verificato un errore durante l'eliminazione dell'evento"
            />
            <HeadingComponent
                label={capitalize(format(new Date(eventsState.year, eventsState.month, 1), 'MMMM', { locale: it })) + ' ' + eventsState.year}
                buttons={[
                    <ButtonComponent
                        label={"Aggiungi scadenza"}
                        onClick={() => {
                            dispatch(setOpenNewEventsModal(true))
                            dispatch(setModalEventType('save'))
                        }}
                        color={Colors.PRIMARY}
                        variant={Variant.SOLID}
                        size={Size.MD}
                        iconStyle={IconStyle.OFF}
                    />
                ]}
                size={HeadingSize.MD}
            />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '24px'
                }}>
                {
                    eventsState.findEventsStatus === 'loading' ?
                        (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexGrow: 1
                                }}
                            >
                                <SpinnerComponent size="small" />
                            </div>
                        ) : (
                            eventsState.findEventsResponse?.total === 0 ?
                                (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexGrow: 1
                                        }}
                                    >
                                        <EmptyList />
                                    </div>
                                ) :
                                (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: 'flex-start',
                                            justifyContent: 'flex-start',
                                            flexGrow: 1,
                                            flexDirection: 'column',
                                            gap: "8px",
                                            maxHeight: '60vh',
                                            overflow: 'auto',
                                            paddingRight: '10px'
                                        }}
                                    >
                                        {listedEvents}
                                    </div>
                                )
                        )
                }
                <EventsCalendarComponent
                    onMonthChange={(date) => {
                        dispatch(setMonth(date.getMonth()))
                        dispatch(setYear(date.getFullYear()))
                        dispatch(setFromDateFilter(format(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd')))
                        dispatch(setToDateFilter(format(lastDayOfMonth(new Date(date.getFullYear(), date.getMonth(), 1)), 'yyyy-MM-dd')))
                    }}
                    onChange={(date) => {
                        if (isValid(new Date(date)))
                            dispatch(setNewEventDate(format(new Date(date), 'yyyy-MM-dd')))
                        else {
                            dispatch(setNewEventDate(format(new Date(), 'yyyy-MM-dd')))
                        }
                        dispatch(setModalEventType('save'))
                        dispatch(setOpenNewEventsModal(true))
                    }}
                />
            </div>
            <NewEventModal />
        </div>
    )
}