import colors from '../utils/index.module.scss'
import { ProgressBarColor, ProgressBarColorMap, ProgressBarSize, ProgressBarSizeMap } from './dto'

interface Props {
    value: number
    size?: ProgressBarSize
    color: ProgressBarColor
}

export function ProgressBarComponent(props: Props) {
    const { value } = props
    const { size } = props
    const { color } = props

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{
                height: '4px',
                backgroundColor: colors.neutral80,
                width: ProgressBarSizeMap.get(size),
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                borderRadius: '16px'
            }}>
                <div
                    style={{
                        height: '4px',
                        backgroundColor: ProgressBarColorMap.get(color),
                        width: value + '%',
                        borderRadius: '16px',
                        transition: 'width ease-in-out 1s'
                    }}
                />
            </div >
            <span style={{ marginLeft: '12px' }} className='text text--sm typography--medium typography-neutral--400'>
                {value.toFixed(0)}%
            </span>
        </div>
    )
}