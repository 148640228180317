import { TableBody } from "@mui/material";
import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AccountantResponseDTO } from "../accountant/dto";
import { findAllAccountantWithoutPagination, findAllAdminAccountant } from "../accountant/slice";
import { findAllActivityCategory } from "../activity/slice";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { CheckboxComponent } from "../checkbox";
import { CheckboxSize, CheckboxStyle } from "../checkbox/dto";
import { AccountingSchemeEnum, PeriodicityEnum } from "../companyQuotationWizard/dto";
import { FinancialAdvisorResponseDTO } from "../financialAdvisor/dto";
import { findAllFinancialAdvisorWithoutPagination } from "../financialAdvisor/slice";
import { ActivityIcon } from "../icons/activity";
import { FilePlusIcon } from "../icons/filePlus";
import { MessageSquareIcon } from "../icons/messageSquare";
import { InfoTextPopup } from "../infoTextPopup";
import { keycloak } from "../keycloak";
import { PillComponent } from "../pills";
import { PillsColor, PillsEmphasis, PillsOutline, PillsSize } from "../pills/dto";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SpinnerComponent } from "../spinner";
import { StatusIndicatorComponent } from "../statusIndicator";
import { StatusIndicatorColor, StatusIndicatorIcon, StatusIndicatorSize } from "../statusIndicator/dto";
import { TableComponent } from "../table";
import { TableCellComponent } from "../table/tableCell";
import { TableHeadComponent } from "../table/tableHead";
import { TableRowComponent } from "../table/tableRow";
import { CompanyResponseDTO, CompanyStatusEnum, CompanyStatusMap } from "./dto";
import { CompanyMenuComponent } from "./menu";
import { findAllCompanies, resetCompanyRegistryDTO, setOperationsTabValue, setSettingsTabValue } from "./slice";

export function CompanyListComponent() {
    const companyState = useAppSelector(state => state.company)
    const activityState = useAppSelector(state => state.activity)
    const accountantState = useAppSelector(state => state.accountant)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const operatorState = useAppSelector(state => state.operator)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(findAllCompanies(companyState.companyFilters))
        dispatch(findAllActivityCategory())
        if (!keycloak.hasRealmRole('operator')) {
            dispatch(findAllAccountantWithoutPagination())
            dispatch(findAllAdminAccountant())
        }
        dispatch(findAllFinancialAdvisorWithoutPagination())
    }, [])

    useEffect(() => {
        if (companyState.deleteCompanyStatus === 'successfully') {
            dispatch(findAllCompanies(companyState.companyFilters))
            if (!keycloak.hasRealmRole('operator')) {
                dispatch(findAllAccountantWithoutPagination())
                dispatch(findAllAdminAccountant())
            }
            dispatch(findAllActivityCategory())
            dispatch(findAllFinancialAdvisorWithoutPagination())
        }
    }, [companyState.deleteCompanyStatus])

    if (
        companyState.findAllCompaniesStatus === 'loading' ||
        activityState.findAllActivityCategoryStatus === 'loading' ||
        accountantState.findAllAccountantsWithoutPaginationStatus === 'loading' ||
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'loading' ||
        accountantState.findAllAdminAccountantStatus === 'loading'

    ) {
        return (<SpinnerComponent fullScreen size={"small"} />)
    }

    if (
        companyState.findAllCompaniesStatus === 'failed' ||
        activityState.findAllActivityCategoryStatus === 'failed' ||
        accountantState.findAllAccountantsWithoutPaginationStatus === 'failed' ||
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'failed' ||
        accountantState.findAllAdminAccountantStatus === 'failed'
    ) {
        return (<div>Si è verificato un errore durante il caricamento dei dati</div>)
    }

    const activityCategory = (company?: CompanyResponseDTO) => {
        let activityHeader: ReactNode[] = []
        let activityRow: ReactNode[] = []

        if (
            activityState.findAllActivityCategoryStatus === 'successfully' &&
            activityState.findAllActivityCategoryResponse !== undefined &&
            activityState.findAllActivityCategoryResponse.data !== undefined &&
            activityState.findAllActivityCategoryResponse.data.length > 0
        ) {
            activityState.findAllActivityCategoryResponse.data.forEach(activity => {
                activityHeader.push(
                    <TableCellComponent key={activity.id} size='small' label={activity.name} cellType={"head"} alignLabel={"center"} />
                )
                if (company !== undefined)
                    activityRow.push(
                        <TableCellComponent key={'row-' + company.id + activity.id} content={<CheckboxComponent size={CheckboxSize.SM} disabled checked={company.activityCategoryIds?.includes(activity.id)} style={CheckboxStyle.SQUARE} />} cellType={"row-regular"} alignLabel={"center"} />
                    )
            })
        }
        return { header: activityHeader, row: activityRow }
    }

    let companiesRows: ReactNode[] = []

    if (
        companyState.findAllCompaniesStatus === 'successfully' &&
        companyState.findAllCompaniesResponse !== undefined &&
        companyState.findAllCompaniesResponse.data !== undefined &&
        companyState.findAllCompaniesResponse.data.length > 0

    ) {
        let accountant: AccountantResponseDTO | undefined
        let financialAdvisor: FinancialAdvisorResponseDTO | undefined
        let adminAccountant: AccountantResponseDTO | undefined
        companyState.findAllCompaniesResponse.data.forEach((company, index) => {
            if (
                accountantState.findAllAccountantsWithoutPaginationStatus === 'successfully' &&
                accountantState.findAllAccountantsWithoutPaginationResponse !== undefined &&
                accountantState.findAllAccountantsWithoutPaginationResponse.data !== undefined &&
                accountantState.findAllAccountantsWithoutPaginationResponse.data.length > 0 &&
                financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'successfully' &&
                financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse !== undefined &&
                financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data !== undefined &&
                financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data.length > 0
            ) {
                financialAdvisor = financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse?.data.find(financialAdvisor => financialAdvisor.id.toString() === company.financialAdvisorId.toString())
                if (accountantState.findAllAdminAccountantStatus === 'successfully' &&
                    accountantState.findAllAdminAccountantResponse !== undefined &&
                    accountantState.findAllAdminAccountantResponse.data !== undefined &&
                    accountantState.findAllAdminAccountantResponse.data.length > 0) {
                    adminAccountant = accountantState.findAllAdminAccountantResponse?.data.find(accountant => accountant.id.toString() === financialAdvisor?.accountantId.toString())
                }
                accountant = accountantState.findAllAccountantsWithoutPaginationResponse?.data.find(accountant => accountant.id.toString() === company.accountantId.toString())
            }
            let label = CompanyStatusMap.get(company.state)?.label
            let color = CompanyStatusMap.get(company.state)?.color
            let operators: string[] = []
            company.operatorIds.forEach(operator => {
                const selectedOperator = operatorState.findAllOperatorResponse?.data.find(op => op.id.toString() === operator.toString())
                if (selectedOperator !== undefined) {
                    operators.push(selectedOperator.name + ' ' + selectedOperator.surname)
                }
            })
            let operatorLabel: string = '-'
            if (operators.length === 1) {
                operatorLabel = operators[0]
            } else if (operators.length > 1) {
                operatorLabel = operators[0] + ', +' + (operators.length - 1)
            }
            const documentsUnseen = companyState.companiesUnseenDocumentsResponse.find(document => document.companyId === company.id)?.unseenRecived
            const messagesUnseen = companyState.companiesUnseenMessagesResponse.find(document => document.topic.toString() === company.id.toString())?.unseen
            companiesRows.push(
                <TableRowComponent key={'company-list-' + company.id}>
                    {
                        (keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('admin-accountant') || keycloak.hasRealmRole('accountant')) &&
                        <TableCellComponent label={financialAdvisor?.name + ' ' + financialAdvisor?.surname} cellType={"row-regular"} alignLabel={"left"} size="large" />
                    }
                    <TableCellComponent
                        size={'large'}
                        label={company.businessName !== null ? company.businessName : 'Azienda ' + (index + 1)}
                        content={
                            ((documentsUnseen !== undefined && documentsUnseen > 0) || (messagesUnseen !== undefined && messagesUnseen > 0)) ? (
                                <StatusIndicatorComponent icon={StatusIndicatorIcon.ONLY} color={StatusIndicatorColor.PRIMARY} size={StatusIndicatorSize.SM} pulsing />
                            ) : (
                                ''
                            )
                        }
                        clickable
                        onClick={() => {
                            dispatch(resetCompanyRegistryDTO())
                            if (company.state === CompanyStatusEnum.Active) {
                                navigate('/company/operation/' + company.id.toString() + '/activity')
                                dispatch(setOperationsTabValue(0))
                            } else {
                                navigate('/company/settings/' + company.id.toString() + '/profile')
                                dispatch(setSettingsTabValue(0))
                            }
                        }}
                        cellType={"row-bold"}
                        alignLabel={"left"}
                    />
                    {
                        keycloak.hasRealmRole('financial-advisor') && (
                            <TableCellComponent label={company.periodicity === PeriodicityEnum.Monthly ? 'Mensile' : 'Trimestrale'} cellType={"row-regular"} alignLabel={"left"} />
                        )
                    }
                    <TableCellComponent label={company.accountingScheme === AccountingSchemeEnum.Ordinary ? 'Ordinario' : 'Semplificato'} cellType={"row-regular"} alignLabel={"left"} />
                    {activityCategory(company).row}
                    {
                        !keycloak.hasRealmRole('accountant') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('operator') &&
                        <TableCellComponent label={accountant?.name + ' ' + accountant?.surname} cellType={"row-regular"} alignLabel={"center"} />
                    }
                    {
                        keycloak.hasRealmRole('admin') &&
                        <TableCellComponent label={adminAccountant?.name + ' ' + adminAccountant?.surname} cellType={"row-regular"} alignLabel={"center"} />
                    }
                    {
                        keycloak.hasRealmRole('financial-advisor') &&
                        (
                            <TableCellComponent label={operatorLabel} cellType={"row-bold"} alignLabel={"left"} />
                        )
                    }
                    <TableCellComponent content={<PillComponent label={label !== undefined ? label : ''} size={PillsSize.XS} color={color !== undefined ? color : PillsColor.NEUTRAL} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent label={'€ ' + company.price.toLocaleString('it-IT', { useGrouping: true })} cellType={"row-regular"} alignLabel={"right"} />
                    <TableCellComponent content={
                        <div className="d-flex justify-content-end align-items-center gap-2 w-100">
                            <InfoTextPopup
                                label={"Attività"}
                                position="bottom"
                                children={
                                    <ButtonComponent
                                        icon={<ActivityIcon colorBase={''} />}
                                        onClick={() => {
                                            navigate('/company/operation/' + company.id.toString() + '/activity')
                                            dispatch(setOperationsTabValue(0))
                                        }}
                                        color={Colors.NEUTRAL}
                                        label=""
                                        disabled={company.state !== CompanyStatusEnum.Active}
                                        variant={Variant.LINK}
                                        size={Size.SM}
                                        iconStyle={IconStyle.ONLY}
                                    />
                                }
                            />
                            <InfoTextPopup
                                label={"Documenti"}
                                position="bottom"
                                children={
                                    <ButtonComponent
                                        icon={<FilePlusIcon colorBase={''} />}
                                        onClick={() => {
                                            navigate('/company/operation/' + company.id.toString() + '/documents')
                                            dispatch(setOperationsTabValue(1))
                                        }}
                                        color={Colors.NEUTRAL}
                                        disabled={company.state !== CompanyStatusEnum.Active}
                                        label=""
                                        variant={Variant.LINK}
                                        size={Size.SM}
                                        iconStyle={IconStyle.ONLY}
                                    />
                                }
                            />
                            <InfoTextPopup
                                label={"Messaggi"}
                                position="bottom"
                                children={
                                    <ButtonComponent
                                        icon={<MessageSquareIcon colorBase={''} />}
                                        onClick={() => {
                                            navigate('/company/operation/' + company.id.toString() + '/messages')
                                            dispatch(setOperationsTabValue(2))
                                        }}
                                        color={Colors.NEUTRAL}
                                        label=""
                                        disabled={company.state !== CompanyStatusEnum.Active}
                                        variant={Variant.LINK}
                                        size={Size.SM}
                                        iconStyle={IconStyle.ONLY}
                                    />
                                }
                            />
                            <CompanyMenuComponent id={company.id.toString()} status={company.state} />
                        </div>
                    } cellType={"row-regular"} alignLabel={"right"} />
                </TableRowComponent>
            )
        })
    }

    return (
        <TableComponent>
            <TableHeadComponent>
                {
                    (keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('admin-accountant') || keycloak.hasRealmRole('accountant')) &&
                    <TableCellComponent size='large' label={"commercialista"} cellType={"head"} alignLabel={"left"} />
                }
                <TableCellComponent label={"ragione sociale"} cellType={"head"} alignLabel={"left"} size={"large"} />
                {
                    keycloak.hasRealmRole('financial-advisor') &&
                    (
                        <TableCellComponent label={"periodicità"} cellType={"head"} alignLabel={"left"} />
                    )
                }
                <TableCellComponent size='small' label={"regime"} cellType={"head"} alignLabel={"left"} />
                {activityCategory().header}
                {
                    !keycloak.hasRealmRole('accountant') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('operator') &&
                    <TableCellComponent size='medium' label={"contabile"} cellType={"head"} alignLabel={"center"} />
                }
                {
                    keycloak.hasRealmRole('admin') &&
                    <TableCellComponent size='medium' label={"account"} cellType={"head"} alignLabel={"center"} />
                }
                {
                    keycloak.hasRealmRole('financial-advisor') &&
                    (
                        <TableCellComponent label={"collaboratore"} cellType={"head"} alignLabel={"left"} />
                    )
                }
                <TableCellComponent size='small' label={"stato"} cellType={"head"} alignLabel={"left"} />
                <TableCellComponent size='small' label={"prezzo"} cellType={"head"} alignLabel={"right"} />
                <TableCellComponent cellType={"head"} alignLabel={"right"} size="context-menu" />
            </TableHeadComponent>
            <TableBody>
                {companiesRows}
            </TableBody>
        </TableComponent>
    )
}