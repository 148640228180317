import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { PromiseStatuses } from "../../utils"
import { EmployeeTypology, F24Typology, FinancialStatementsTypology, FindAllFiscalDocumentDTO, FiscalDocumentFilters, FiscalDocumentResponseDTO, FiscalDocumentType } from "./dtoDocument"
import { NewFiscalDocumentService } from "./serviceDocument"

interface FiscalDocumentState {
    openFiscalDocumentAreaModal: boolean
    findAllFiscalDocumentBalanceResponse?: FindAllFiscalDocumentDTO
    findAllFiscalDocumentOthersResponse?: FindAllFiscalDocumentDTO
    findAllFiscalDocumentF24Response?: FindAllFiscalDocumentDTO
    findAllFiscalDocumentEmployeeResponse?: FindAllFiscalDocumentDTO
    findAllFiscalDocumentBalanceStatus: PromiseStatuses
    findAllFiscalDocumentOthersStatus: PromiseStatuses
    findAllFiscalDocumentF24Status: PromiseStatuses
    findAllFiscalDocumentEmployeeStatus: PromiseStatuses
    fiscalDocumentBalanceFilters: FiscalDocumentFilters
    fiscalDocumentOthersFilters: FiscalDocumentFilters
    fiscalDocumentF24Filters: FiscalDocumentFilters
    fiscalDocumentEmployeeFilters: FiscalDocumentFilters
    createFiscalDocumentsStatus: PromiseStatuses
    deleteFiscalDocumentModal: boolean
    deleteFiscalDocumentStatus: PromiseStatuses
    findByIdFiscalDocumentStatus: PromiseStatuses
    findByIdFiscalDocumentResponse?: FiscalDocumentResponseDTO
}

const initialState: FiscalDocumentState = {
    openFiscalDocumentAreaModal: false,
    findAllFiscalDocumentBalanceStatus: 'idle',
    findAllFiscalDocumentF24Status: 'idle',
    findAllFiscalDocumentEmployeeStatus: 'idle',
    createFiscalDocumentsStatus: 'idle',
    fiscalDocumentBalanceFilters: {
        fileName: '',
        typology: '' as FinancialStatementsTypology,
        year: new Date().getFullYear(),
        page: 0,
        itemsPerPage: 15,
        status: '',
        type: FiscalDocumentType.balance,
    },
    fiscalDocumentF24Filters: {
        fileName: '',
        typology: '' as F24Typology,
        page: 0,
        itemsPerPage: 15,
        status: '',
        type: FiscalDocumentType.f24
    },
    fiscalDocumentEmployeeFilters: {
        fileName: '',
        typology: '' as EmployeeTypology,
        year: new Date().getFullYear(),
        page: 0,
        month: '',
        itemsPerPage: 15,
        status: '',
        type: FiscalDocumentType.employees
    },
    fiscalDocumentOthersFilters: {
        fileName: '',
        typology: '' as EmployeeTypology,
        page: 0,
        month: '',
        itemsPerPage: 15,
        status: '',
        type: FiscalDocumentType.other
    },
    deleteFiscalDocumentModal: false,
    deleteFiscalDocumentStatus: 'idle',
    findByIdFiscalDocumentStatus: 'idle',
    findAllFiscalDocumentOthersStatus: "idle",
}

export const findAllFiscalDocumentsBalance = createAsyncThunk(
    'taxArea/findAllFiscalDocumentsBalance',
    async (request: FiscalDocumentFilters, thunkApi): Promise<FindAllFiscalDocumentDTO> => {
        const fiscalDocumentService = NewFiscalDocumentService()

        return fiscalDocumentService.findAllFiscalDocuments(request)
    }
)

export const findAllFiscalDocumentsOthers = createAsyncThunk(
    'taxArea/findAllFiscalDocumentsOthers',
    async (request: FiscalDocumentFilters, thunkApi): Promise<FindAllFiscalDocumentDTO> => {
        const fiscalDocumentService = NewFiscalDocumentService()

        return fiscalDocumentService.findAllFiscalDocuments(request)
    }
)

export const findAllFiscalDocumentsF24 = createAsyncThunk(
    'taxArea/findAllFiscalDocumentsF24',
    async (request: FiscalDocumentFilters, thunkApi): Promise<FindAllFiscalDocumentDTO> => {
        const fiscalDocumentService = NewFiscalDocumentService()

        return fiscalDocumentService.findAllFiscalDocuments(request)
    }
)

export const findAllFiscalDocumentsEmployee = createAsyncThunk(
    'taxArea/findAllFiscalDocumentsEmployee',
    async (request: FiscalDocumentFilters, thunkApi): Promise<FindAllFiscalDocumentDTO> => {
        const fiscalDocumentService = NewFiscalDocumentService()

        return fiscalDocumentService.findAllFiscalDocuments(request)
    }
)

export const findByIdFiscalDocument = createAsyncThunk(
    'taxArea/findByIdFiscalDocument',
    async (id: string, thunkApi): Promise<FiscalDocumentResponseDTO> => {
        const fiscalDocumentService = NewFiscalDocumentService()

        return fiscalDocumentService.findByIdFiscalDocument(id)
    }
)

export const createFiscalDocuments = createAsyncThunk(
    'taxArea/createFiscalDocuments',
    async (request: FormData, thunkApi): Promise<void> => {
        const fiscalDocumentService = NewFiscalDocumentService()

        return fiscalDocumentService.createFiscalDocuments(request)
    }
)

export const deleteFiscalDocument = createAsyncThunk(
    'taxArea/deleteFiscalDocument',
    async (id: string, thunkApi): Promise<void> => {
        const fiscalDocumentService = NewFiscalDocumentService()

        return fiscalDocumentService.deleteFiscalDocuments(id)
    }
)

const fiscalDocumentSlice = createSlice({
    name: 'fiscalDocument/slice',
    initialState,
    reducers: {
        setOpenFiscalDocumentAreaModal: (state, action) => {
            state.openFiscalDocumentAreaModal = action.payload
        },
        setFiscalDocumentBalanceFilterPage: (state, action) => {
            state.fiscalDocumentBalanceFilters.page = action.payload
        },
        setFiscalDocumentOthersFilterPage: (state, action) => {
            state.fiscalDocumentOthersFilters.page = action.payload
        },
        setFiscalDocumentF24FilterPage: (state, action) => {
            state.fiscalDocumentF24Filters.page = action.payload
        },
        setFiscalDocumentEmployeeFilterPage: (state, action) => {
            state.fiscalDocumentEmployeeFilters.page = action.payload
        },
        setYearBalanceFilter: (state, action) => {
            state.fiscalDocumentBalanceFilters.year = action.payload
        },
        setYearOthersFilter: (state, action) => {
            state.fiscalDocumentOthersFilters.year = action.payload
        },
        setTypologyBalanceFilter: (state, action) => {
            state.fiscalDocumentBalanceFilters.typology = action.payload
        },
        setTypologyOthersFilter: (state, action) => {
            state.fiscalDocumentOthersFilters.typology = action.payload
        },
        setFileNameBalanceFilter: (state, action) => {
            state.fiscalDocumentBalanceFilters.fileName = action.payload
        },
        setFileNameOthersFilter: (state, action) => {
            state.fiscalDocumentOthersFilters.fileName = action.payload
        },
        setStatusBalanceFilter: (state, action) => {
            state.fiscalDocumentBalanceFilters.status = action.payload
        },
        setStatusOthersFilter: (state, action) => {
            state.fiscalDocumentOthersFilters.status = action.payload
        },
        setStatusF24Filter: (state, action) => {
            state.fiscalDocumentF24Filters.status = action.payload
        },
        setFileNameF24Filter: (state, action) => {
            state.fiscalDocumentF24Filters.fileName = action.payload
        },
        setTypologyF24Filter: (state, action) => {
            state.fiscalDocumentF24Filters.typology = action.payload
        },
        setYearEmployeeFilter: (state, action) => {
            state.fiscalDocumentEmployeeFilters.year = action.payload
        },
        setTypologyEmployeeFilter: (state, action) => {
            state.fiscalDocumentEmployeeFilters.typology = action.payload
        },
        setMonthEmployeeFilter: (state, action) => {
            state.fiscalDocumentEmployeeFilters.month = action.payload
        },
        setFileNameEmployeeFilter: (state, action) => {
            state.fiscalDocumentEmployeeFilters.fileName = action.payload
        },
        setStatusEmployeeFilter: (state, action) => {
            state.fiscalDocumentEmployeeFilters.status = action.payload
        },
        setDeleteFiscalDocumentModal: (state, action) => {
            state.deleteFiscalDocumentModal = action.payload
        },
        setDeleteFiscalDocumentStatus: (state, action) => {
            state.deleteFiscalDocumentStatus = action.payload
        },
        setSaveFiscalDocumentStatus: (state, action) => {
            state.createFiscalDocumentsStatus = action.payload
        },
        setFindByIdFiscalDocumentStatus: (state, action) => {
            state.findByIdFiscalDocumentStatus = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(findAllFiscalDocumentsBalance.pending, (state) => {
                state.findAllFiscalDocumentBalanceStatus = 'loading'
            })
            .addCase(findAllFiscalDocumentsBalance.fulfilled, (state, action) => {
                state.findAllFiscalDocumentBalanceStatus = 'successfully'
                state.findAllFiscalDocumentBalanceResponse = action.payload
            })
            .addCase(findAllFiscalDocumentsBalance.rejected, (state) => {
                state.findAllFiscalDocumentBalanceStatus = 'failed'
            })
            .addCase(findAllFiscalDocumentsF24.pending, (state) => {
                state.findAllFiscalDocumentF24Status = 'loading'
            })
            .addCase(findAllFiscalDocumentsF24.fulfilled, (state, action) => {
                state.findAllFiscalDocumentF24Status = 'successfully'
                state.findAllFiscalDocumentF24Response = action.payload
            })
            .addCase(findAllFiscalDocumentsF24.rejected, (state) => {
                state.findAllFiscalDocumentF24Status = 'failed'
            })
            .addCase(findAllFiscalDocumentsEmployee.pending, (state) => {
                state.findAllFiscalDocumentEmployeeStatus = 'loading'
            })
            .addCase(findAllFiscalDocumentsEmployee.fulfilled, (state, action) => {
                state.findAllFiscalDocumentEmployeeStatus = 'successfully'
                state.findAllFiscalDocumentEmployeeResponse = action.payload
            })
            .addCase(findAllFiscalDocumentsEmployee.rejected, (state) => {
                state.findAllFiscalDocumentEmployeeStatus = 'failed'
            })
            .addCase(findAllFiscalDocumentsOthers.pending, (state) => {
                state.findAllFiscalDocumentOthersStatus = 'loading'
            })
            .addCase(findAllFiscalDocumentsOthers.fulfilled, (state, action) => {
                state.findAllFiscalDocumentOthersStatus = 'successfully'
                state.findAllFiscalDocumentOthersResponse = action.payload
            })
            .addCase(findAllFiscalDocumentsOthers.rejected, (state) => {
                state.findAllFiscalDocumentOthersStatus = 'failed'
            })
            .addCase(findByIdFiscalDocument.pending, (state) => {
                state.findByIdFiscalDocumentStatus = 'loading'
            })
            .addCase(findByIdFiscalDocument.fulfilled, (state, action) => {
                state.findByIdFiscalDocumentStatus = 'successfully'
                state.findByIdFiscalDocumentResponse = action.payload
            })
            .addCase(findByIdFiscalDocument.rejected, (state) => {
                state.findByIdFiscalDocumentStatus = 'failed'
            })
            .addCase(createFiscalDocuments.pending, (state) => {
                state.createFiscalDocumentsStatus = 'loading'
            })
            .addCase(createFiscalDocuments.fulfilled, (state) => {
                state.createFiscalDocumentsStatus = 'successfully'
            })
            .addCase(createFiscalDocuments.rejected, (state) => {
                state.createFiscalDocumentsStatus = 'failed'
            })
            .addCase(deleteFiscalDocument.pending, (state) => {
                state.deleteFiscalDocumentStatus = 'loading'
            })
            .addCase(deleteFiscalDocument.fulfilled, (state) => {
                state.deleteFiscalDocumentStatus = 'successfully'
            })
            .addCase(deleteFiscalDocument.rejected, (state) => {
                state.deleteFiscalDocumentStatus = 'failed'
            })
    },
})

export const {
    setOpenFiscalDocumentAreaModal,
    setFiscalDocumentBalanceFilterPage,
    setFiscalDocumentEmployeeFilterPage,
    setFiscalDocumentF24FilterPage,
    setYearBalanceFilter,
    setTypologyBalanceFilter,
    setStatusBalanceFilter,
    setStatusF24Filter,
    setTypologyF24Filter,
    setYearEmployeeFilter,
    setTypologyEmployeeFilter,
    setMonthEmployeeFilter,
    setFileNameBalanceFilter,
    setFileNameEmployeeFilter,
    setFileNameF24Filter,
    setStatusEmployeeFilter,
    setDeleteFiscalDocumentModal,
    setDeleteFiscalDocumentStatus,
    setSaveFiscalDocumentStatus,
    setFindByIdFiscalDocumentStatus,
    setFileNameOthersFilter,
    setFiscalDocumentOthersFilterPage,
    setStatusOthersFilter,
    setTypologyOthersFilter,
    setYearOthersFilter
} = fiscalDocumentSlice.actions

export default fiscalDocumentSlice.reducer