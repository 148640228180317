import { ActivityType } from '../activity/dto'
import { ActivityGroupStatus, ActivityStatus, CompanyStatusEnum } from '../company/dto'
export interface ActvityGroupDashboard {
    id: number,
    dueDate: Date,
    period: number,
    year: number,
    state: ActivityGroupStatus,
    activities: ActivityDashboard[],
    activityCategory: {
        id: number,
        name: string
    }
    activityKind: ActivityKind
}

export interface ActivityDashboard {
    id: number,
    state: ActivityStatus,
    hasDocument: boolean
    activityType: ActivityType
    notes: string | null
}

export interface WithholdingsDashboard {
    id: number,
    note: string,
    companyId: number,
    period: number,
    year: number,
    read: boolean
    objectId: string
    fileName: string
}

export interface DashboardActivitiesResponse {
    id: number,
    activityGroups: ActvityGroupDashboard[],
    withholdings: WithholdingsDashboard[]
}

export enum ActivityKind {
    Records = "Records",
    Controls = "Controls"
}

export interface CompanyCountDTO{
    total: number,
    ordinary: number, 
    simplified: number, 
    financialAdvisors: number
}

export interface FiltersCounterDTO{
    status: CompanyStatusEnum
}