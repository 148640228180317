import { CompanyCreationDTO } from "./dto";
import { CompanyQuotationServiceImpl } from "./serviceImpl";

export interface CompanyQuotationService {
    calculatePrice(company: CompanyCreationDTO): number
    saveCompany(data: CompanyCreationDTO): Promise<void>
}

export function NewCompanyQuotationService(): CompanyQuotationService {
    return new CompanyQuotationServiceImpl();
}
