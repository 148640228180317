import { useEffect, useState } from "react"
import { IconsProps } from "./dto"

export function SmartphoneIcon(props: IconsProps) {
    const [color, setColor] = useState<string>(props.colorBase)

    useEffect(() => {
        setColor(props.colorBase)
    }, [props.colorBase])

    return (
        <svg onMouseOver={() => setColor(props.colorHover !== undefined ? props.colorHover : props.colorBase)}
            onMouseOut={() => setColor(props.colorBase)}
            width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0003 14.9998H10.0087M5.83366 1.6665H14.167C15.0875 1.6665 15.8337 2.4127 15.8337 3.33317V16.6665C15.8337 17.587 15.0875 18.3332 14.167 18.3332H5.83366C4.91318 18.3332 4.16699 17.587 4.16699 16.6665V3.33317C4.16699 2.4127 4.91318 1.6665 5.83366 1.6665Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}