import { HeadingComponent } from "../heading";
import { HeadingSize, HeadingVerticalAlign } from "../heading/dto";
import { keycloak } from "../keycloak";
import { useAppSelector } from "../redux/hooks";
import { SpinnerComponent } from "../spinner";

export function DashboardCountersComponent() {
    const dashboardState = useAppSelector(state => state.dashboard)

    if (dashboardState.getAllCountStatus === "loading") {
        return <div> <SpinnerComponent size="small" /> </div>
    }

    return (
        <div className="row m-0 p-0" style={{ gap: '16px' }}>
            <div className="col-12 col-lg m-0 p-0">
                <div className="primary-container">
                    <HeadingComponent verticalAlign={HeadingVerticalAlign.CENTER} label={"Aziende totali"} buttons={[]} size={HeadingSize.SM} />
                    <span className="heading heading--sm typography--semibold typography-primary--500">{dashboardState.getAllCountResponse?.total}</span>
                </div>
            </div>
            <div className="col-12 col-lg m-0 p-0">
                <div className="primary-container">
                    <HeadingComponent verticalAlign={HeadingVerticalAlign.CENTER} label={"In semplificata"} buttons={[]} size={HeadingSize.SM} />
                    <span className="heading heading--sm typography--semibold typography-primary--500">{dashboardState.getAllCountResponse?.simplified}</span>
                </div>
            </div>
            <div className="col-12 col-lg m-0 p-0">
                <div className="primary-container">
                    <HeadingComponent verticalAlign={HeadingVerticalAlign.CENTER} label={"In ordinaria"} buttons={[]} size={HeadingSize.SM} />
                    <span className="heading heading--sm typography--semibold typography-primary--500">{dashboardState.getAllCountResponse?.ordinary}</span>
                </div>
            </div>
            {
                !keycloak.hasRealmRole('accountant') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('operator') &&
                <div className="col-12 col-lg m-0 p-0">
                    <div className="primary-container">
                        <HeadingComponent verticalAlign={HeadingVerticalAlign.CENTER} label={"Commercialisti"} buttons={[]} size={HeadingSize.SM} />
                        <span className="heading heading--sm typography--semibold typography-primary--500">{dashboardState.getAllCountResponse?.financialAdvisors}</span>
                    </div>
                </div>
            }
        </div>
    )
}