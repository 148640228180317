import { MenuItem } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { AvatarComponent } from "../avatar";
import { AvatarSize, AvatarType } from "../avatar/dto";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { FileInputComponent } from "../fileInput";
import { FormGroup } from "../formGroup";
import { MailIcon } from "../icons/mail";
import { SmartphoneIcon } from "../icons/smartphone";
import { ModalComponent } from "../modal";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SelectComponent } from "../select";
import { SpinnerComponent } from "../spinner";
import { SwitchComponent } from "../switch";
import { TextInput } from "../textInput";
import { toBase64 } from "../utils";
import colors from '../utils/index.module.scss';
import { createAccountant, findAccountantsListAvatarsById, findAllAccountant, findAllAdminAccountant, resetCreateAccountantRequest, resetErrorCreateAccountantEmail, setCreateAccountantAdminAccountantId, setCreateAccountantDescription, setCreateAccountantEmail, setCreateAccountantIsAdmin, setCreateAccountantName, setCreateAccountantPhoneNumber, setCreateAccountantStatus, setCreateAccountantSurname, setFindAllAdminAccountantsAvatarStatus, setFindAllAdminAccountantsStatus, setOpenAccountantModal } from "./slice";

export function NewAccountantModal() {
    const accountantState = useAppSelector(state => state.accountant)

    let adminAccountantsMenuItems: ReactNode[] = []

    const dispatch = useAppDispatch()

    let formData = new FormData();

    const [file, setFile] = useState<File | null>(null)
    const [avatarToBase64, setAvatarToBase64] = useState<string>('')

    useEffect(() => {
        if (accountantState.openAccountantModal) {
            dispatch(findAllAdminAccountant())
            setFile(null)
            dispatch(resetErrorCreateAccountantEmail())
            setAvatarToBase64('')
        }
    }, [accountantState.openAccountantModal])

    useEffect(() => {
        if (accountantState.findAllAdminAccountantStatus === 'successfully' && accountantState.findAllAdminAccountantResponse !== undefined && accountantState.findAllAdminAccountantResponse.data !== undefined && accountantState.findAllAdminAccountantResponse.data.length > 0) {
            dispatch(findAccountantsListAvatarsById(accountantState.findAllAdminAccountantResponse.data.map(adminAccauntant => ({ objectId: adminAccauntant.avatarObjectId, userId: adminAccauntant.userId }))))
            dispatch(setFindAllAdminAccountantsStatus('idle'))
            dispatch(setFindAllAdminAccountantsAvatarStatus('idle'))
        }
    }, [accountantState.findAllAdminAccountantStatus])

    useEffect(() => {
        if (accountantState.createAccountantStatus === 'successfully') {
            dispatch(setCreateAccountantStatus('idle'))
            if (window.location.pathname.includes('company/operation')) {
                dispatch(findAllAccountant({ page: 0, itemsPerPage: 0, isAdmin: 'false', financialAdvisor: '', accountant: '' }))
            } else {
                dispatch(findAllAccountant(accountantState.findAllAccountantFilters))
            }
            dispatch(resetCreateAccountantRequest())
            dispatch(setOpenAccountantModal(false))
        }
    }, [accountantState.createAccountantStatus])

    if (accountantState.findAllAdminAccountantsAvatarStatus === 'successfully' && accountantState.openAccountantModal && accountantState.findAllAdminAccountantResponse !== undefined) {
        accountantState.findAllAdminAccountantResponse.data.forEach((adminAccountant, index) => {
            const avatar = accountantState.adminAccountantsListAvatarResponse.find(avatar => avatar.userId === adminAccountant.userId)?.objectId
            adminAccountantsMenuItems.push(
                <MenuItem key={'new-accountant-admin-accountant-' + index} value={adminAccountant.id}>
                    <AvatarComponent type={AvatarType.USER} size={AvatarSize.XS} name={adminAccountant.name + ' ' + adminAccountant.surname} src={avatar ?? ''} />
                </MenuItem>
            )
        }
        )
    }

    return (
        <ModalComponent
            open={accountantState.openAccountantModal}
            handleClose={() => {
                dispatch(setOpenAccountantModal(false));
                dispatch(resetCreateAccountantRequest())
            }}
        >
            <div
                style={{
                    padding: '20px',
                    backgroundColor: colors.white,
                    boxShadow: colors.boxShadowXL,
                    borderRadius: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '100%'
                }}
            >
                {
                    accountantState.findAllAdminAccountantStatus === 'loading' ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
                            <SpinnerComponent size={"small"} />
                        </div>
                    ) : (
                        <>
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="text text--lg typography--semibold typography--black">Aggiungi contabile</span>
                            </div>
                            <div className="d-flex flex-column align-items-start justify-content-between" style={{ gap: '16px', width: '100%' }}>
                                <div className="w-100 d-flex gap-2"><AvatarComponent src={avatarToBase64} type={AvatarType.SINGLE} size={AvatarSize.MD} /> <FileInputComponent id={"create-accountant-avatar"} onChange={e => { setFile(e[0]); toBase64(e[0]).then(data => setAvatarToBase64('data:' + e[0].type + ';base64,' + data)) }} /></div>
                                <TextInput id={"create-accountant-name"} required type={"text"} defaultValue={accountantState.createAccountantRequest.name} placeholder={"Nome"} onChange={(e) => dispatch(setCreateAccountantName(e.target.value))} />
                                <TextInput id={"create-accountant-surname"} required type={"text"} defaultValue={accountantState.createAccountantRequest.surname} placeholder={"Cognome"} onChange={(e) => dispatch(setCreateAccountantSurname(e.target.value))} />
                                <TextInput
                                    startIcon={<MailIcon colorBase="" />}
                                    required
                                    id={"create-accountant-email"}
                                    type={"text"}
                                    placeholder={"Email"}
                                    onFocus={e => dispatch(resetErrorCreateAccountantEmail())}
                                    infoText={accountantState.errors.createAccountant.email !== undefined ? 'Questo indirizzo email è già in uso' : undefined}
                                    error={accountantState.errors.createAccountant.email !== undefined}
                                    defaultValue={accountantState.createAccountantRequest.email}
                                    onChange={(e) => dispatch(setCreateAccountantEmail(e.target.value))}
                                />
                                <TextInput
                                    startIcon={<SmartphoneIcon colorBase="" />}
                                    infoText="Inserire da 9 a 10 caratteri"
                                    defaultValue={accountantState.createAccountantRequest.phoneNumber}
                                    id={"create-accountant-phone"} type={"text"} placeholder={"Telefono"}
                                    onChange={(e) => {
                                        dispatch(setCreateAccountantPhoneNumber(e.target.value === '' ? null : e.target.value))
                                    }} />
                                <SwitchComponent id="create-accountant-is-admin" label="Account" checked={accountantState.createAccountantRequest.isAdmin} onChange={e => dispatch(setCreateAccountantIsAdmin(!accountantState.createAccountantRequest.isAdmin))} />
                                {
                                    !accountantState.createAccountantRequest.isAdmin && (
                                        <SelectComponent required id={"create-accountant-admin-select"} defaultValue={accountantState.createAccountantRequest.adminAccountantId} value={accountantState.createAccountantRequest.adminAccountantId} menuItems={[<MenuItem key={'select-admin-accountant'} value=''>Seleziona il l'account...</MenuItem>, adminAccountantsMenuItems]} label="Account" onChange={(e) => dispatch(setCreateAccountantAdminAccountantId(e.target.value))} />
                                    )
                                }
                                <FormGroup label={"Note"} inputs={[<TextInput id={"create-accountant-note"} type={"text"} placeholder={"Aggiungi qui una descrizione"} multiline onChange={(e) => dispatch(setCreateAccountantDescription(e.target.value))} />]} style={"column"} />
                            </div>
                            <div style={{ margin: 0, padding: 0, gap: '12px' }} className="row">
                                <div className="col" style={{ margin: 0, padding: 0 }}><ButtonComponent label={"Annulla"} onClick={() => {
                                    dispatch(setOpenAccountantModal(false));
                                    dispatch(resetCreateAccountantRequest())
                                }} color={Colors.NEUTRAL} variant={Variant.OUTLINE} size={Size.FIT} iconStyle={IconStyle.OFF} /></div>
                                <div className="col" style={{ margin: 0, padding: 0 }}>
                                    <ButtonComponent
                                        label={"Avvia procedura"}
                                        onClick={() => {
                                            if (file !== null)
                                                formData.append('file', file)
                                            formData.append('accountantCreationDTO', JSON.stringify(accountantState.createAccountantRequest))
                                            dispatch(createAccountant(formData))
                                        }
                                        } color={Colors.PRIMARY} variant={Variant.SOLID} size={Size.FIT} iconStyle={IconStyle.OFF}
                                    />
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </ModalComponent >
    )
}