import { useState } from "react";
import { ContextMenuComponent } from "../contextMenu";
import { MenuItemComponent } from "../contextMenu/menuItem";
import { EditIcon } from "../icons/edit";
import { TrashIcon } from "../icons/trash";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import colors from '../utils/index.module.scss';
import { setCriticityOperation, setOpenCriticityModal, setOpenDeleteTodoModal, setTodoIndexToDelete, setTodoToEdit } from "./slice";

interface Props {
    index: number,
    todo: string
}

export function TodoMenu(props: Props) {
    const { index } = props
    const { todo } = props

    const companyState = useAppSelector(state => state.company)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const dispatch = useAppDispatch()

    return (
        <ContextMenuComponent anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
            <MenuItemComponent
                onClick={() => {
                    dispatch(setOpenCriticityModal(true));
                    dispatch(setCriticityOperation('edit'));
                    dispatch(setTodoToEdit({ todo: todo, index: index }))
                    setAnchorEl(null)
                }}>
                <EditIcon colorBase={colors.neutral600} />
                Modifica
            </MenuItemComponent>
            <MenuItemComponent
                onClick={() => { dispatch(setTodoIndexToDelete(index)); dispatch(setOpenDeleteTodoModal(true)); setAnchorEl(null); }}>
                <TrashIcon colorBase={colors.neutral600} />
                Cancella
            </MenuItemComponent>
        </ContextMenuComponent>
    )
}