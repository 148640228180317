import colors from "../utils/index.module.scss"

export enum IconStyle {
    OFF = 'OFF',
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
    ONLY = 'ONLY'
}

export enum Colors {
    PRIMARY = 'PRIMARY',
    NEUTRAL = 'NEUTRAL',
    DESTRUCTIVE = 'DESTRUCTIVE',
    SUCCESS = 'SUCCESS'
}

export enum Variant {
    SOLID = 'SOLID',
    OUTLINE = 'OUTLINE',
    GHOST = 'GHOST',
    LINK = 'LINK'
}

export enum Size {
    SM = 'SM',
    MD = 'MD',
    LG = 'LG',
    FIT = 'FIT'
}

export interface SchemeColor {
    base: string,
    dark: string,
    light: string,
    lighter: string,
    darker: string
}

export const ButtonColorsMap = new Map<Colors, SchemeColor>([
    [Colors.PRIMARY, {
        base: colors.primary500,
        dark: colors.primary600,
        light: colors.primary50,
        lighter: colors.white,
        darker: colors.primary700
    }],
    [Colors.NEUTRAL, {
        base: colors.neutral500,
        dark: colors.neutral600,
        light: colors.neutral80,
        lighter: colors.white,
        darker: colors.primary700
    }],
    [Colors.DESTRUCTIVE, {
        base: colors.destructive500,
        dark: colors.destructive500,
        light: colors.destructive50,
        lighter: colors.white,
        darker: colors.destructive700
    }],
    [Colors.SUCCESS, {
        base: colors.success500,
        dark: colors.success600,
        light: colors.white,
        lighter: colors.white,
        darker: colors.success700
    }]
])