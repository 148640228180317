import { useEffect, useState } from "react";
import { IconsProps } from "./dto";

export function TaskSquareIcon(props: IconsProps) {
    const [color, setColor] = useState<string>(props.colorBase)

    useEffect(() => {
        setColor(props.colorBase)
    }, [props.colorBase])

    return (
        <svg
            onMouseOver={() => setColor(props.colorHover !== undefined ? props.colorHover : props.colorBase)}
            onMouseOut={() => setColor(props.colorBase)}
            width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6834 8.01672H10.3083C9.96668 8.01672 9.68335 7.73339 9.68335 7.39172C9.68335 7.05006 9.96668 6.76672 10.3083 6.76672H14.6834C15.025 6.76672 15.3084 7.05006 15.3084 7.39172C15.3084 7.73339 15.0334 8.01672 14.6834 8.01672Z" fill={color} />
            <path d="M5.93334 8.65002C5.77501 8.65002 5.61667 8.59168 5.49167 8.46668L4.86667 7.84168C4.62501 7.60002 4.62501 7.20002 4.86667 6.95835C5.10834 6.71668 5.50834 6.71668 5.75001 6.95835L5.93334 7.14168L7.36667 5.70835C7.60834 5.46668 8.00834 5.46668 8.25001 5.70835C8.49167 5.95002 8.49167 6.35002 8.25001 6.59168L6.37501 8.46668C6.25834 8.58335 6.10001 8.65002 5.93334 8.65002Z" fill={color} />
            <path d="M14.6834 13.85H10.3083C9.96668 13.85 9.68335 13.5666 9.68335 13.225C9.68335 12.8833 9.96668 12.6 10.3083 12.6H14.6834C15.025 12.6 15.3084 12.8833 15.3084 13.225C15.3084 13.5666 15.0334 13.85 14.6834 13.85Z" fill={color} />
            <path d="M5.93334 14.4834C5.77501 14.4834 5.61667 14.4251 5.49167 14.3001L4.86667 13.6751C4.62501 13.4334 4.62501 13.0334 4.86667 12.7917C5.10834 12.5501 5.50834 12.5501 5.75001 12.7917L5.93334 12.9751L7.36667 11.5417C7.60834 11.3001 8.00834 11.3001 8.25001 11.5417C8.49167 11.7834 8.49167 12.1834 8.25001 12.4251L6.37501 14.3001C6.25834 14.4167 6.10001 14.4834 5.93334 14.4834Z" fill={color} />
            <path d="M12.5 18.9583H7.49996C2.97496 18.9583 1.04163 17.025 1.04163 12.5V7.49996C1.04163 2.97496 2.97496 1.04163 7.49996 1.04163H12.5C17.025 1.04163 18.9583 2.97496 18.9583 7.49996V12.5C18.9583 17.025 17.025 18.9583 12.5 18.9583ZM7.49996 2.29163C3.65829 2.29163 2.29163 3.65829 2.29163 7.49996V12.5C2.29163 16.3416 3.65829 17.7083 7.49996 17.7083H12.5C16.3416 17.7083 17.7083 16.3416 17.7083 12.5V7.49996C17.7083 3.65829 16.3416 2.29163 12.5 2.29163H7.49996Z" fill={color} />
        </svg>
    )
}