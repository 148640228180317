import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmOrDenyModal } from "../confirmOrDenyModal.tsx";
import { ContextMenuComponent } from "../contextMenu";
import { MenuItemComponent } from "../contextMenu/menuItem";
import { EditIcon } from "../icons/edit";
import { TrashIcon } from "../icons/trash";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import colors from '../utils/index.module.scss';
import { deleteFinancialAdvisor, setFinancialAdvisorIndexToDelete, setFinancialAdvisorSettedId, setOpenDeleteFinancialAdvisorModal } from "./slice";

interface Props {
    id: string
}

export function FinancialAdvisorMenuComponent(props: Props) {
    const { id } = props
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)

    return (
        <ContextMenuComponent anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
            <MenuItemComponent
                onClick={() => {
                    dispatch(setFinancialAdvisorSettedId(id))
                    navigate('/edit-financial-advisor/' + id)
                }}>
                <EditIcon colorBase={colors.neutral600} />
                Modifica
            </MenuItemComponent>
            <MenuItemComponent
                onClick={() => {
                    dispatch(setFinancialAdvisorIndexToDelete(id))
                    dispatch(setOpenDeleteFinancialAdvisorModal(true))
                }}>
                <TrashIcon colorBase={colors.neutral600} />
                Cancella
            </MenuItemComponent>
            <ConfirmOrDenyModal
                open={financialAdvisorState.openDeleteFinancialAdvisor}
                handleClose={() => dispatch(setOpenDeleteFinancialAdvisorModal(false))}
                title={"Elimina commercialista"}
                description={"Sei sicuro di voler eliminare questo commercialista?"}
                labelDeny={"Annulla"}
                labelConfirm={"Elimina"}
                actionConfirm={() => {
                    dispatch(deleteFinancialAdvisor(id))
                    dispatch(setOpenDeleteFinancialAdvisorModal(false))
                }}
            />
        </ContextMenuComponent>

    )
}