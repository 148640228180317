import { ReactNode, useEffect, useRef, useState } from "react";
import { ButtonComponent } from "../../button";
import { Colors, IconStyle, Size, Variant } from "../../button/dto";
import { ConfirmOrDenyModal } from "../../confirmOrDenyModal.tsx";
import { findActivitiesDashboard } from "../../dashboard/slice";
import { FileInputComponent } from "../../fileInput";
import { FormGroup } from "../../formGroup";
import { DownloadIcon } from "../../icons/download";
import { PlusIcon } from "../../icons/plus";
import { TrashIcon } from "../../icons/trash";
import { keycloak } from "../../keycloak";
import { NewObjectService } from "../../objects/service";
import { findFileById } from "../../objects/slice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { SpinnerComponent } from "../../spinner";
import { TextInput } from "../../textInput";
import colors from '../../utils/index.module.scss';
import { findAllCompanies, setFindDocumentStatus } from "../slice";
import { WithholdingCreationDTO, WithholdingsDTO } from "./dto";
import { createWithholdings, deleteWithholding, findAllWithholdings, findWithholdingById, setCreateWithholdingStatus, setDeleteWithholdingStatus, setFindByIdWithholdingStatus, setOpenConfirmWithholdingsDenyModal, setWithholdingsCreationRequest, setWithholdingsForAccounting } from "./slice";

export function WithholdingsPanel() {
    const withholdingsState = useAppSelector(state => state.withholdings)
    const companyState = useAppSelector(state => state.company)
    const dashboardState = useAppSelector(state => state.dashboard)

    const dispatch = useAppDispatch()

    const withholdingsResponse = withholdingsState.findAllWithholdingsResponse
    const withholdingsForAccounting = withholdingsState.withholdingsForAccounting
    const openModal = companyState.openOrdinaryAccountingModal

    const objectService = NewObjectService()

    const listRef = useRef(null)

    const [fileData, setFileData] = useState<{ files: File[], names: string[], formData: FormData[] }[][]>([]);
    const [id, setId] = useState<number>(0)

    useEffect(() => {
        if (openModal)
            dispatch(findAllWithholdings(withholdingsState.withholdingsFilter))
    }, [openModal])

    useEffect(() => {
        if (openModal && companyState.findAllCompaniesStatus === 'idle' && companyState.findAllCompaniesResponse === undefined) {
            dispatch(findAllCompanies(companyState.companyFilters))
        }
    }, [openModal, companyState.findAllCompaniesStatus])

    useEffect(() => {
        if (withholdingsState.findWithholdingByIdStatus === 'successfully') {
            const companiesId = companyState.findAllCompaniesResponse?.data.map(company => ({ companyId: company.id, year: new Date().getFullYear() }))
            if (Number(window.location.pathname.split('/')[3])) {
                dispatch(findActivitiesDashboard({ companyIds: [...companiesId?.map(company => company.companyId) || [], Number(window.location.pathname.split('/')[3] || 0)], year: companyState.ordinaryAccountingYearFilter }))
            } else {
                dispatch(findActivitiesDashboard({ companyIds: [...companiesId?.map(company => company.companyId) || []], year: companyState.ordinaryAccountingYearFilter }))
                dispatch(findAllWithholdings(withholdingsState.withholdingsFilter))
            }
            dispatch(setFindByIdWithholdingStatus('idle'))
        }
    }, [withholdingsState.findWithholdingByIdStatus])

    useEffect(() => {
        if (withholdingsState.createWithholdingStatus === 'successfully') {
            const companiesId = companyState.findAllCompaniesResponse?.data.map(company => ({ companyId: company.id, year: new Date().getFullYear() }))
            if (Number(window.location.pathname.split('/')[3])) {
                dispatch(findActivitiesDashboard({ companyIds: [...companiesId?.map(company => company.companyId) || [], Number(window.location.pathname.split('/')[3] || 0)], year: companyState.ordinaryAccountingYearFilter }))
            } else {
                dispatch(findActivitiesDashboard({ companyIds: [...companiesId?.map(company => company.companyId) || []], year: companyState.ordinaryAccountingYearFilter }))
                dispatch(findAllWithholdings(withholdingsState.withholdingsFilter))
                setFileData([])
            }
            dispatch(setCreateWithholdingStatus('idle'))
        }
    }, [withholdingsState.createWithholdingStatus])


    useEffect(() => {
        if (withholdingsState.deleteWithholdingStatus === 'successfully') {
            dispatch(setOpenConfirmWithholdingsDenyModal(false))
            const companiesId = companyState.findAllCompaniesResponse?.data.map(company => ({ companyId: company.id, year: new Date().getFullYear() }))
            if (Number(window.location.pathname.split('/')[3])) {
                dispatch(findActivitiesDashboard({ companyIds: [...companiesId?.map(company => company.companyId) || [], Number(window.location.pathname.split('/')[3] || 0)], year: companyState.ordinaryAccountingYearFilter }))
            } else {
                dispatch(findActivitiesDashboard({ companyIds: [...companiesId?.map(company => company.companyId) || []], year: companyState.ordinaryAccountingYearFilter }))
                dispatch(findAllWithholdings(withholdingsState.withholdingsFilter))
            }
            dispatch(setDeleteWithholdingStatus('idle'))
        }
    }, [withholdingsState.deleteWithholdingStatus])

    useEffect(() => {
        if (withholdingsState.deleteWithholdingStatus === "failed") {
            dispatch(setOpenConfirmWithholdingsDenyModal(false))
        }
    }, [withholdingsState.deleteWithholdingStatus])

    useEffect(() => {
        if (dashboardState.findActivitiesDashboardStatus === 'successfully') {
            const withholdingsForAccounting = dashboardState.findActivitiesDashboardResponse.find(group => (
                group.id === companyState.ordinaryAccountingUser?.id

            ))?.withholdings.filter(withholding => (
                withholding.period === companyState.ordinaryAccountingPeriod &&
                withholding.year === companyState.ordinaryAccountingYear
            ))
            dispatch(setWithholdingsCreationRequest([]))
            dispatch(setWithholdingsForAccounting(withholdingsForAccounting))
        }

    }, [dashboardState.findActivitiesDashboardStatus])

    useEffect(() => {
        if (withholdingsForAccounting !== undefined && withholdingsForAccounting.length === 0 && withholdingsState.withholdingsCreationRequest.length === 0) {
            dispatch(setWithholdingsCreationRequest([{
                companyId: companyState.ordinaryAccountingUser?.id || 0,
                period: companyState.ordinaryAccountingPeriod,
                year: companyState.ordinaryAccountingYear,
                file: undefined
            }]))
        }
    }, [withholdingsForAccounting])

    const withholdingBlock = (withholding: WithholdingCreationDTO | WithholdingsDTO, loaded: boolean, index: number): ReactNode => {

        const handleFileChange = (files: File[]) => {
            setFileData(file => {
                const newData = [...file];
                const newFilesData = files.map(file => ({
                    files: [file],
                    names: [file.name],
                    formData: [new FormData()]
                }));
                if (!newData[index]) {
                    newData[index] = [];
                }
                newData[index] = [...(newData[index] || []), ...newFilesData];
                return newData;
            });
        };

        const handleNameChange = (name: string, fileIndex: number, nameIndex: number) => {
            setFileData(file => {
                const newData = [...file];
                newData[index][fileIndex].names[nameIndex] = name;
                return newData;
            });
        };

        const handleRemoveFile = (fileIndex: number) => {
            setFileData(file => {
                const newData = [...file];
                newData[index].splice(fileIndex, 1);
                if (newData[index].length === 0) {
                    newData.splice(index, 1);
                }
                return newData;
            });
        };

        return (
            <div
                style={{ display: 'flex', flexDirection: 'column', gap: 10, marginBottom: 10 }}
                key={"withholdingd-" + ((loaded ? 'loaded-' : 'new-') + index)}
            >
                <FormGroup
                    inputs={[
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            <ButtonComponent
                                label={""}
                                disabled={keycloak.hasRealmRole('financial-advisor') || keycloak.hasRealmRole('operator')}
                                onClick={
                                    () => {
                                        if (loaded) {
                                            setId(withholding.id)
                                            dispatch(setOpenConfirmWithholdingsDenyModal(true))
                                        } else {
                                            if (withholdingsState.withholdingsCreationRequest.length === 1) {
                                                dispatch(setWithholdingsCreationRequest([{
                                                    companyId: companyState.ordinaryAccountingUser?.id || 0,
                                                    period: companyState.ordinaryAccountingPeriod,
                                                    year: companyState.ordinaryAccountingYear,
                                                    file: undefined
                                                }]))
                                                setFileData(prevState => {
                                                    const newData = [...prevState];
                                                    newData.splice(index, 1);
                                                    return newData;
                                                });
                                            } else {
                                                dispatch(setWithholdingsCreationRequest(withholdingsState.withholdingsCreationRequest.filter((_, _index) => _index !== index)))
                                                setFileData(prevState => {
                                                    const newData = [...prevState];
                                                    newData.splice(index, 1);
                                                    return newData;
                                                });
                                            }
                                        }
                                    }
                                }
                                color={Colors.PRIMARY}
                                variant={Variant.LINK}
                                size={Size.SM}
                                iconStyle={IconStyle.ONLY}
                                icon={<TrashIcon colorBase={""} />}
                            />
                            <ButtonComponent
                                label={""}
                                disabled={!loaded}
                                onClick={() => {
                                    dispatch(findFileById(withholding.objectId.toString())).then((e) => {
                                        //@ts-ignore
                                        objectService.downloadBase64WithExtension(withholding.fileName, e.payload !== null ? e.payload : '')
                                        dispatch(findWithholdingById(withholding.id))
                                            .then(() => {
                                                dispatch(setFindDocumentStatus('idle'))
                                            })
                                    });
                                }}
                                color={Colors.PRIMARY}
                                variant={Variant.LINK}
                                size={Size.SM}
                                iconStyle={IconStyle.ONLY}
                                icon={<DownloadIcon colorBase={""} />}
                            />
                        </div>
                    ]}
                    label={"Ritenuta"}
                    style={"row"}
                />
                {
                    !loaded && (
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 8, width: '100%' }}>
                            <FormGroup
                                label="Aggiungi file"
                                required
                                inputs={[
                                    <FileInputComponent
                                        disabled={loaded || keycloak.hasRealmRole('financial-advisor') || keycloak.hasRealmRole('operator')}
                                        id={"withholdings-file-" + ((loaded ? 'loaded-' : 'new-') + index)}
                                        key={"withholdings-file-" + ((loaded ? 'loaded-' : 'new-') + index)}
                                        multiple
                                        onChange={handleFileChange}
                                    />
                                ]}
                                style={"column"}
                            />
                            <div style={{ overflow: 'auto', width: '100%', maxHeight: '100%' }}>
                                <FormGroup
                                    label=""
                                    required
                                    inputs={(fileData[index] || []).map((fileItem, fileIndex) => (
                                        <div style={{ width: '100%', gap: '8px', display: 'flex', flexDirection: 'column' }} key={fileIndex}>
                                            <FormGroup
                                                label=""
                                                required
                                                inputs={[
                                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-end' }}>
                                                        <TextInput
                                                            key={"withholdings-input-file-name-" + fileIndex}
                                                            onChange={(e) => handleNameChange(e.target.value, fileIndex, 0)}
                                                            id={"type-" + fileIndex}
                                                            value={fileItem.names[0]}
                                                            type={"text"}
                                                            placeholder={"es. Fatture Acquisti Marzo 2023"}
                                                            disabled={keycloak.hasRealmRole('financial-advisor') || keycloak.hasRealmRole('operator')}
                                                        />
                                                        <ButtonComponent
                                                            label={""}
                                                            onClick={() => handleRemoveFile(fileIndex)}
                                                            color={Colors.PRIMARY}
                                                            variant={Variant.GHOST}
                                                            size={Size.SM}
                                                            iconStyle={IconStyle.ONLY}
                                                            icon={<TrashIcon colorBase={""} />}
                                                            disabled={keycloak.hasRealmRole('financial-advisor') || keycloak.hasRealmRole('operator')}
                                                        />
                                                    </div>
                                                ]}
                                                style={"column"}
                                            />
                                        </div>
                                    ))}
                                    style={"column"}
                                />
                            </div>
                        </div>
                    )
                }
                {
                    loaded && (
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 8, width: '100%' }}>
                            <FormGroup
                                label=""
                                inputs={[
                                    <div style={{ width: '100%', gap: '8px', display: 'flex', flexDirection: 'column' }}>
                                        <FormGroup
                                            label=""
                                            required
                                            inputs={[
                                                <TextInput
                                                    key={"withholdings-input-file-name"}
                                                    id={"withholdings-input-file-name"}
                                                    value={withholding.fileName}
                                                    type={"text"}
                                                    placeholder={"es. Fatture Acquisti Marzo 2023"}
                                                    disabled={loaded}
                                                />
                                            ]}
                                            style={"column"}
                                        />
                                    </div>
                                ]}
                                style={"column"}
                            />
                        </div>
                    )
                }
                <FormGroup
                    inputs={[
                        <TextInput
                            disabled={loaded || (keycloak.hasRealmRole('financial-advisor') || keycloak.hasRealmRole('operator'))}
                            id={"withholdingd-notes-" + ((loaded ? 'loaded-' : 'new-') + index)}
                            key={"withholdingd-notes-" + ((loaded ? 'loaded-' : 'new-') + index)}
                            type={"text"}
                            placeholder={"Inserisci note"}
                            value={withholding.note}
                            onChange={e => {
                                const newWithholdings = [...withholdingsState.withholdingsCreationRequest];
                                newWithholdings[index] = {
                                    ...withholdingsState.withholdingsCreationRequest[index],
                                    note: e.target.value
                                };
                                dispatch(setWithholdingsCreationRequest([...newWithholdings]))
                            }}
                        />
                    ]}
                    label={"Note"}
                    style={"column"}
                />
                <div style={{ height: '1px', width: '100%', backgroundColor: colors.neutral80, marginTop: 20, marginBottom: 20 }} />
                <ConfirmOrDenyModal
                    open={withholdingsState.openConfirmWithholdingsDenyModal}
                    handleClose={() =>
                        dispatch(setOpenConfirmWithholdingsDenyModal(false))
                    }
                    title={"Elimina ritenuta"}
                    description={"Sei sicuro di voler eliminare questa ritenuta?"}
                    labelDeny={"Annulla"}
                    labelConfirm={"Elimina"}
                    actionConfirm={() => {
                        dispatch(deleteWithholding(id))
                    }}
                />
            </div>
        )
    }

    const createWithholdingsList = withholdingsState.withholdingsCreationRequest.map((withholding, index) => (
        withholdingBlock(withholding, false, index)
    ))

    const loadedWithholdingsList = withholdingsResponse !== undefined && withholdingsResponse.data.length > 0 ?
        withholdingsResponse.data.map((withholding, index) => (
            withholdingBlock(withholding, true, index)
        )) : []

    return (
        <div style={{ marginBottom: '20px' }}>
            <div style={{ marginBottom: '20px', maxHeight: '40vh', overflow: 'auto' }} ref={listRef}>
                {
                    withholdingsState.findAllWithholdingsStatus === "loading" ? (
                        <div
                            style={{
                                width: '100%',
                                height: '50vh',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                            <SpinnerComponent size='small' />
                        </div>
                    ) :
                        <>
                            {loadedWithholdingsList}
                            {createWithholdingsList}
                        </>
                }
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                <div style={{ flex: '1 1 0' }}>
                    <ButtonComponent
                        label={"Aggiungi ritenuta"}
                        disabled={keycloak.hasRealmRole('financial-advisor') || keycloak.hasRealmRole('operator')}
                        onClick={() => {
                            dispatch(setWithholdingsCreationRequest([...withholdingsState.withholdingsCreationRequest, {
                                companyId: companyState.ordinaryAccountingUser?.id || 0,
                                period: companyState.ordinaryAccountingPeriod,
                                year: companyState.ordinaryAccountingYear,
                                file: undefined
                            }]))
                            setTimeout(() => {
                                //@ts-ignore
                                listRef.current?.lastElementChild?.lastElementChild?.scrollIntoView({ behavior: 'smooth' })
                            }, 30)
                        }}
                        color={Colors.PRIMARY}
                        variant={Variant.OUTLINE}
                        size={Size.FIT}
                        iconStyle={IconStyle.LEFT}
                        icon={<PlusIcon colorBase={""} />}
                    />
                </div>
                <div style={{ flex: '1 1 0' }}>
                    {
                        withholdingsState.createWithholdingStatus === 'loading' || dashboardState.findActivitiesDashboardStatus === 'loading' ?
                            <SpinnerComponent size="small" /> :
                            <ButtonComponent
                                label={"Salva"}
                                disabled={(
                                    keycloak.hasRealmRole('financial-advisor') || keycloak.hasRealmRole('operator')
                                ) || withholdingsState.withholdingsCreationRequest.length === 0
                                }
                                onClick={() => {
                                    fileData.forEach((file, fileIndex) => {
                                        file.forEach((_file) => {
                                            _file.formData.forEach((form, index) => {
                                                form.delete('file')
                                                form.delete('withholdingCreationDTO')
                                                if (_file.files.length > 0) {
                                                    if (_file.files[index] !== null) {
                                                        //@ts-ignore
                                                        form.append('file', _file.files[index])
                                                    }
                                                }
                                                form.append('withholdingCreationDTO', JSON.stringify({ ...withholdingsState.withholdingsCreationRequest[fileIndex], fileName: _file.names[index] }));
                                            })
                                            dispatch(createWithholdings(_file.formData))
                                        })
                                    })
                                }}
                                color={Colors.PRIMARY}
                                variant={Variant.SOLID}
                                size={Size.FIT}
                                iconStyle={IconStyle.OFF}
                            />
                    }
                </div>
            </div>
        </div>
    )
}