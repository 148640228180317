import { Router } from "@remix-run/router"
import { useEffect, useState } from "react"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { AccountantView } from "../accountant"
import { EditAccountantView } from "../accountant/editAccountant"
import { ActivityView } from "../activity"
import { CompanyView } from "../company"
import { OperationsView } from "../company/operation"
import { SettingsView } from "../company/settings"
import { CompanyToConfirmView } from "../companyToConfirm"
import { DashboardView } from "../dashboard"
import { FinancialAdvisorView } from "../financialAdvisor"
import { EditFinancialAdvisorView } from "../financialAdvisor/editFinancialAdvisor"
import { keycloak } from "../keycloak"
import { OperatorView } from "../operator"
import { EditOperatorView } from "../operator/editOperator"
import { ProfileView } from "../profile"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import { MenuItems } from "../sidebar/dto"
import { setMenuItems } from "../sidebar/slice"
import { SpinnerComponent } from "../spinner"

const accountantRouter = createBrowserRouter([
    {
        path: '/',
        element: (<DashboardView />)
    },
    {
        path: '/company',
        element: (<CompanyView />)
    },
    {
        path: '/company/settings/:id/:tab',
        element: (<SettingsView />)
    },
    {
        path: '/company/operation/:id/:tab',
        element: (<OperationsView />)
    },
    {
        path: '/profile',
        element: (<ProfileView />)
    },
])

const adminAccountantRouter = createBrowserRouter([
    {
        path: '/',
        element: (<DashboardView />)
    },
    {
        path: '/company',
        element: (<CompanyView />)
    },
    {
        path: '/company/settings/:id/:tab',
        element: (<SettingsView />)
    },
    {
        path: '/company/operation/:id/:tab',
        element: (<OperationsView />)
    },
    {
        path: '/profile',
        element: (<ProfileView />)
    },
    {
        path: '/activity',
        element: (<ActivityView />)
    },
])

const financialAdvisorRouter = createBrowserRouter([
    {
        path: '/',
        element: (<DashboardView />)
    },
    {
        path: '/company',
        element: (<CompanyView />)
    },
    {
        path: '/company/settings/:id/:tab',
        element: (<SettingsView />)
    },
    {
        path: '/company/operation/:id/:tab',
        element: (<OperationsView />)
    },
    {
        path: '/profile',
        element: (<ProfileView />)
    },
    {
        path: '/company-to-add',
        element: (<CompanyToConfirmView />)
    },
    {
        path: '/operator',
        element: (<OperatorView />)
    },
    {
        path: '/edit-operator/:id',
        element: (<EditOperatorView />)
    }
])

const operatorRouter = createBrowserRouter([
    {
        path: '/',
        element: (<DashboardView />)
    },
    {
        path: '/company',
        element: (<CompanyView />)
    },
    {
        path: '/company/settings/:id/:tab',
        element: (<SettingsView />)
    },
    {
        path: '/company/operation/:id/:tab',
        element: (<OperationsView />)
    },
    {
        path: '/profile',
        element: (<ProfileView />)
    }
])

const adminRouter = createBrowserRouter([
    {
        path: '/',
        element: (<DashboardView />)
    },
    {
        path: '/company',
        element: (<CompanyView />)
    },
    {
        path: '/company/settings/:id/:tab',
        element: (<SettingsView />)
    },
    {
        path: '/company/operation/:id/:tab',
        element: (<OperationsView />)
    },
    {
        path: '/accountant',
        element: (<AccountantView />)
    },
    {
        path: '/financial-advisor',
        element: (<FinancialAdvisorView />)
    },
    {
        path: '/activity',
        element: (<ActivityView />)
    },
    {
        path: '/profile',
        element: (<ProfileView />)
    },
    {
        path: '/edit-accountant/:id',
        element: (<EditAccountantView />)
    },
    {
        path: '/edit-financial-advisor/:id',
        element: (<EditFinancialAdvisorView />)
    },
    {
        path: '/company-to-add',
        element: (<CompanyToConfirmView />)
    },
    {
        path: '/operator',
        element: (<OperatorView />)
    },
    {
        path: '/edit-operator/:id',
        element: (<EditOperatorView />)
    }
])

export function KeycloakRouter() {
    const roles = useAppSelector(state => state.secured.userRoles)

    const dispatch = useAppDispatch()

    const [router, setRouter] = useState<Router | undefined>(undefined)

    useEffect(() => {
        if (roles !== undefined && roles.length > 0 && keycloak.token !== undefined) {
            if (keycloak.hasRealmRole('accountant')) {
                setRouter(accountantRouter)
                dispatch(setMenuItems([MenuItems.DASHBOARD, MenuItems.COMPANY]))
            } else if (keycloak.hasRealmRole('admin-accountant')) {
                setRouter(adminAccountantRouter)
                dispatch(setMenuItems([MenuItems.DASHBOARD, MenuItems.COMPANY, MenuItems.ACTIVITY]))
            } else if (keycloak.hasRealmRole('operator')) {
                setRouter(operatorRouter)
                dispatch(setMenuItems([MenuItems.DASHBOARD, MenuItems.COMPANY]))
            } else if (keycloak.hasRealmRole('admin')) {
                setRouter(adminRouter)
                dispatch(setMenuItems([MenuItems.DASHBOARD, MenuItems.COMPANY, MenuItems.COMPANY_TO_ADD, MenuItems.FINANCIAL_ADVISOR, MenuItems.OPERATOR, MenuItems.ACCOUNTANT, MenuItems.ACTIVITY]))
            } else if (keycloak.hasRealmRole('financial-advisor')) {
                setRouter(financialAdvisorRouter)
                dispatch(setMenuItems([MenuItems.DASHBOARD, MenuItems.COMPANY, MenuItems.OPERATOR, MenuItems.COMPANY_TO_ADD]))
            }
            else {
                console.log('err')
            }
        }
    }, [roles, keycloak.token])

    if (router === undefined) {
        return (<SpinnerComponent fullScreen size={'small'} />)
    }

    return (
        <RouterProvider router={router} />
    );
}