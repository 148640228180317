import { TableBody } from "@mui/material";
import { ReactElement, useEffect } from "react";
import { findAllCompanyWithoutPagination } from "../company/slice";
import { AccountingSchemeEnum } from "../companyQuotationWizard/dto";
import { findAllFinancialAdvisorWithoutPagination } from "../financialAdvisor/slice";
import { PillComponent } from "../pills";
import { PillsColor, PillsEmphasis, PillsOutline, PillsSize } from "../pills/dto";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SpinnerComponent } from "../spinner";
import { TableComponent } from "../table";
import { TableCellComponent } from "../table/tableCell";
import { TableHeadComponent } from "../table/tableHead";
import { TableRowComponent } from "../table/tableRow";
import { OperatorMenuComponent } from "./menu";
import { findAllOperators } from "./slice";

export function OperatorListComponent() {
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const operatorState = useAppSelector(state => state.operator)
    const companyState = useAppSelector(state => state.company)
    let operatorsList: ReactElement[] = []

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(findAllOperators(operatorState.findAllOperatorFilters))
        dispatch(findAllCompanyWithoutPagination())
        dispatch(findAllFinancialAdvisorWithoutPagination())
    }, [])

    useEffect(() => {
        if (operatorState.deleteOperatorStatus === 'successfully') {
            dispatch(findAllOperators(operatorState.findAllOperatorFilters))
            dispatch(findAllCompanyWithoutPagination())
            dispatch(findAllFinancialAdvisorWithoutPagination())
        }
    }, [operatorState.deleteOperatorStatus])

    if (companyState.findAllCompaniesWithoutPaginationStatus === 'loading' || financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'loading') {
        return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
            <SpinnerComponent size={"small"} />
        </div>)
    }

    if (companyState.findAllCompaniesWithoutPaginationStatus === 'failed' || financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'failed') {
        return (<div>Si è verificato un errore durante il caricamento dei dati</div>)
    }

    if (
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'successfully' &&
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse !== undefined &&
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data !== undefined &&
        companyState.findAllCompaniesWithoutPaginationStatus === 'successfully' &&
        companyState.findAllCompaniesWithoutPaginationResponse !== undefined &&
        companyState.findAllCompaniesWithoutPaginationResponse.data !== undefined &&
        operatorState.findAllOperatorStatus === 'successfully' &&
        operatorState.findAllOperatorResponse !== undefined &&
        operatorState.findAllOperatorResponse.data !== undefined &&
        operatorState.findAllOperatorResponse.data.length > 0
    ) {
        operatorState.findAllOperatorResponse.data.forEach(operator => {
            const financialAdvisor = financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse?.data.find(financialAdvisor => financialAdvisor.id.toString() === operator.financialAdvisorId.toString())
            const company = companyState.findAllCompaniesWithoutPaginationResponse?.data.filter(company => operator.companyIds?.includes(company.id))
            const ordinaryCompany = company?.filter(_company => _company.accountingScheme === AccountingSchemeEnum.Ordinary)
            const simplifiedCompany = company?.filter(_company => _company.accountingScheme === AccountingSchemeEnum.Simplified)
            operatorsList.push(
                <TableRowComponent key={'operator-row-user-' + operator.id}>
                    <TableCellComponent label={operator.name + ' ' + operator.surname} cellType={"row-medium"} alignLabel={"left"} />
                    <TableCellComponent content={<PillComponent label={String(company?.length)} size={PillsSize.XS} color={PillsColor.NEUTRAL} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"center"} />
                    <TableCellComponent content={<PillComponent label={String(simplifiedCompany?.length)} size={PillsSize.XS} color={PillsColor.NEUTRAL} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"center"} />
                    <TableCellComponent content={<PillComponent label={String(ordinaryCompany?.length)} size={PillsSize.XS} color={PillsColor.NEUTRAL} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"center"} />
                    <TableCellComponent label={financialAdvisor !== undefined ? (financialAdvisor?.name + ' ' + financialAdvisor?.surname) : ''} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent content={<OperatorMenuComponent id={operator.id.toString()} />} cellType={"row-regular"} alignLabel={"right"} />
                </TableRowComponent>
            )
        })
    }

    return (
        <TableComponent>
            <TableHeadComponent>
                <TableCellComponent label={"Collaboratore"} cellType={"head"} alignLabel={"left"} />
                <TableCellComponent size='small' label={"aziende"} cellType={"head"} alignLabel={"center"} />
                <TableCellComponent size='small' label={"in semplificata"} cellType={"head"} alignLabel={"center"} />
                <TableCellComponent size='small' label={"in ordinaria"} cellType={"head"} alignLabel={"center"} />
                <TableCellComponent size='large' label={"Commercialista"} cellType={"head"} alignLabel={"left"} />
                <TableCellComponent cellType={"head"} alignLabel={"right"} size="context-menu" />
            </TableHeadComponent>
            <TableBody>
                {operatorsList}
            </TableBody>
        </TableComponent>
    )
}