import { useState } from "react";
import { ConfirmOrDenyModal } from "../../confirmOrDenyModal.tsx";
import { ContextMenuComponent } from "../../contextMenu";
import { MenuItemComponent } from "../../contextMenu/menuItem";
import { TrashIcon } from "../../icons/trash";
import { NewObjectService } from "../../objects/service";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import colors from '../../utils/index.module.scss';
import { FiscalDocumentResponseDTO } from "./dtoDocument";
import { deleteFiscalDocument, setDeleteFiscalDocumentModal } from "./sliceDocument";

interface Props {
    fiscalDocument: FiscalDocumentResponseDTO
}

export function MenuFiscalDocument(props: Props) {
    const { fiscalDocument } = props

    const dispatch = useAppDispatch()

    const companyState = useAppSelector(state => state.company)
    const fiscalDocumentState = useAppSelector(state => state.fiscalDocument)
    const objectState = useAppSelector(state => state.object)

    const objectService = NewObjectService()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (fiscalDocument === undefined) {
        return <></>
    }

    return (
        <ContextMenuComponent anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
            <MenuItemComponent
                onClick={() => { dispatch(setDeleteFiscalDocumentModal(true)) }}>
                <TrashIcon colorBase={colors.neutral600} />
                Cancella
            </MenuItemComponent>

            <ConfirmOrDenyModal
                open={fiscalDocumentState.deleteFiscalDocumentModal}
                handleClose={() => { dispatch(setDeleteFiscalDocumentModal(false)); handleClose() }}
                title={"Elimina documento"}
                description={"Sei sicuro di voler eliminare questo documento?"}
                labelDeny={"Annulla"}
                labelConfirm={"Elimina"}
                actionConfirm={() => {
                    if (fiscalDocument !== undefined) {
                        dispatch(deleteFiscalDocument(fiscalDocument.id))
                        dispatch(setDeleteFiscalDocumentModal(false))
                        handleClose()
                    }
                }}
            />
        </ContextMenuComponent>
    )
}