import axios from "axios";
import { getMimeTypeFromBase64 } from "../company/menuDocuments";
import { keycloak } from "../keycloak";
import { ObjectService } from "./service";

export class ObjectServiceImpl implements ObjectService {
    public saveDocument(form: FormData): Promise<void> {
        let url: string = "/api/document"
        return axios({
            url: url,
            method: "POST",
            data: form,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findFileById(id: string): Promise<string> {
        let url: string = "/api/objects/file/" + id
        if (id) {
            return axios({
                url: url,
                method: "GET",
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    ContentType: 'application/json',
                    "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "Access-Control-Allow-Methods": "GET",
                }
            }).then((response) => response.data)
        }
        return Promise.resolve("")

    }

    public findFileByIdWithUserId(id: string, userid: string): Promise<{ userId: string, objectId: string }> {
        let url: string = "/api/objects/file/" + id
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then(response => {
            const data = response.data;
            const modifiedResponse = {
                userId: userid,
                objectId: data.objectId
            };
            return modifiedResponse;
        });
    }

    private mimeTypeMap = new Map<string, string>([
        [".png", 'image/png',],
        [".jpg", 'image/jpeg',],
        [".jpeg", 'image/jpeg',],
        [".pdf", 'application/pdf',],
        [".csv", 'text/csv',],
        [".docx", 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',],
        [".xml", 'application/xml',],
        [".xlsx", 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',],
        [".zip", 'application/zip',],
        [".odt", 'application/vnd.oasis.opendocument.text',],
        [".xls", 'application/vnd.ms-excel',],
        [".doc", 'application/msword',],
        [".txt", 'text/plain',],
        [".rtf", 'application/rtf',],
        ['.rar', 'application/vnd.rar'],
        [".ods", 'application/vnd.oasis.opendocument.spreadsheet']
    ])

    public downloadBase64WithExtension(name: string, bytes: string): void {
        const link = document.createElement('a');
        const newNameFile = this.removeDots(name)

        const type = bytes.split('data:')[1].split(';base64,')[0]
        const typeToMime = this.mimeTypeMap.get(type)
        const href = typeToMime === undefined ? bytes : ('data:' + typeToMime + ';base64,' + bytes.split(';base64,')[1])

        link.download = newNameFile;
        link.href = href
        link.click();
    }

    public downloadBase64WithoutExtension(name: string, bytes: string, type: string): void {
        const link = document.createElement('a');
        const newNameFile = this.removeDots(name)

        link.download = newNameFile;
        link.href = "data:" + type + ";base64," + bytes;
        link.click();
    }

    public removeDots(value?: string): string {
        if (!value) {
            return '';
        }

        let fileNameArray = value.split('.');

        if (fileNameArray[fileNameArray.length - 1] === '') {
            fileNameArray.pop();
        }

        return fileNameArray.join('-');
    }

    public removeSpaces(value?: string): string {
        if (!value) {
            return '';
        }

        let fileNameArray = value.split(' ');

        if (fileNameArray[fileNameArray.length - 1] === '') {
            fileNameArray.pop();
        }

        return fileNameArray.join('-');
    }

    public base64ToArrayBuffer(file: string): ArrayBufferLike {
        var binaryString = atob(file);
        var bytes = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }

    public downloadPdf(name: string, bytes: ArrayBuffer, type?: string): void {
        const link = document.createElement('a');
        let newNameFile = this.removeDots(name)

        link.download = newNameFile;

        link.href = URL.createObjectURL(new Blob([new Int8Array(bytes)], { type: this.mimeTypeMap.get(type!) }));

        link.click();
    }

    public base64ToFile(base64String: string): File {
        const type = getMimeTypeFromBase64(base64String)
        const base64WithoutPrefix = base64String.split('base64,')[1]

        const byteCharacters = atob(base64WithoutPrefix !== undefined ? base64WithoutPrefix : '');
        const byteArray = new Uint8Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteArray[i] = byteCharacters.charCodeAt(i);
        }


        return new File([new Blob([byteArray])], 'unknown', { type: type });
    }
}
