import { MenuItem } from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'
import { findAllActivityCategory } from '../../activity/slice'
import { ButtonComponent } from '../../button'
import { Colors, IconStyle, Size, Variant } from '../../button/dto'
import { PeriodicityEnum } from '../../companyQuotationWizard/dto'
import { ActivityKind, ActvityGroupDashboard, WithholdingsDashboard } from '../../dashboard/dto'
import { findActivitiesDashboard } from '../../dashboard/slice'
import { ErrorPopup } from '../../errorPopup'
import { HeadingComponent } from '../../heading'
import { HeadingSize } from '../../heading/dto'
import { keycloak } from '../../keycloak'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { SelectComponent } from '../../select'
import { SpinnerComponent } from '../../spinner'
import colors from '../../utils/index.module.scss'
import { setControlsInitialTab, setOrdinaryControlsActivityCategories, setOrdinaryControlsActivityGroups } from '../controls/slice'
import { ActivityGroupStatus, ActivityStatus } from '../dto'
import { getCompanyUnseenInfoMessages, setInitialTabAccountingValue, setOpenOrdinaryAccountingModal, setOrdinaryAccountingActivityCategories, setOrdinaryAccountingActivityGroups, setOrdinaryAccountingCompany, setOrdinaryAccountingInitialTab, setOrdinaryAccountingPeriod, setOrdinaryAccountingYear, setOrdinaryAccountingYearFilter } from '../slice'
import { setDeleteMessage, setDeleteWithholdingStatus, setWithholdingsForAccounting, setWithholdingsPeriod, setWittholdingsCompanyId, setWittholdingsYear } from '../withholdings/slice'
import { OrdinaryAccountingModal } from './ordinaryAccountingModal'

const activityGroupsColorsMap = (activityGroups?: ActvityGroupDashboard): { color: Colors, variant: Variant } => {
    if (activityGroups === undefined) {
        return { color: Colors.NEUTRAL, variant: Variant.OUTLINE }
    }
    let activityStatus = activityGroups.activities.map(activity => activity.state)
    if (activityGroups.state === ActivityGroupStatus.Expired) {
        return { color: Colors.DESTRUCTIVE, variant: Variant.SOLID }
    } else if (activityGroups.state === ActivityGroupStatus.Completed) {
        return { color: Colors.SUCCESS, variant: Variant.SOLID }
    }
    else if (activityGroups.state === ActivityGroupStatus.Pending && activityStatus.includes(ActivityStatus.Completed)) {
        return { color: Colors.PRIMARY, variant: Variant.SOLID }
    }

    return { color: Colors.NEUTRAL, variant: Variant.OUTLINE }
}

const withholdingsColorMap = (withholdings: WithholdingsDashboard[]): { color: Colors, variant: Variant, pulsing?: boolean } => {
    let withholdingsStatus: boolean[] = withholdings.map(status => status.read)

    if (withholdings.length === 0 || withholdings === undefined) {
        return { color: Colors.NEUTRAL, variant: Variant.OUTLINE }
    } else {
        if (withholdingsStatus.every(read => read === true)) {
            return { color: Colors.SUCCESS, variant: Variant.SOLID }
        } else {
            if (keycloak.hasRealmRole('financial-advisor') || keycloak.hasRealmRole('operator')) {
                return { color: Colors.PRIMARY, variant: Variant.SOLID, pulsing: true }
            } else {
                return { color: Colors.PRIMARY, variant: Variant.SOLID }
            }
        }
    }
}

const controlsColorMap = (controls?: ActvityGroupDashboard): { color: Colors, variant: Variant, pulsing?: boolean } => {
    if (controls === undefined) {
        return { color: Colors.NEUTRAL, variant: Variant.OUTLINE }
    }
    let activityStatus = controls.activities.map(activity => activity.state)
    if (controls.state === ActivityGroupStatus.Expired) {
        return { color: Colors.DESTRUCTIVE, variant: Variant.SOLID }
    } else if (controls.state === ActivityGroupStatus.Completed) {
        return { color: Colors.SUCCESS, variant: Variant.SOLID }
    } else if (controls.state === ActivityGroupStatus.Pending && activityStatus.includes(ActivityStatus.Completed)) {
        return { color: Colors.PRIMARY, variant: Variant.SOLID }
    }

    return { color: Colors.NEUTRAL, variant: Variant.OUTLINE }
}

export function OrdinaryAccounting() {
    const companyState = useAppSelector(state => state.company)
    const activityState = useAppSelector(state => state.activity)
    const dashboardState = useAppSelector(state => state.dashboard)
    const withholdingState = useAppSelector(state => state.withholdings)

    const [period, setPeriods] = useState<3 | 11>(11)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (
            companyState.findCompanyStatus === 'successfully' &&
            companyState.findCompanyResponse !== undefined
        ) {
            dispatch(findAllActivityCategory())
        }
    }, [companyState.findCompanyStatus])

    useEffect(() => {
        if (
            companyState.findCompanyStatus === 'successfully' &&
            companyState.operationsTabValue === 0 &&
            companyState.findCompanyResponse !== undefined &&
            companyState.findCompanyResponse.activityCategoryIds !== undefined &&
            companyState.findCompanyResponse.activityCategoryIds !== null
        ) {
            const companiesId = companyState.findAllCompaniesResponse?.data.map(company => ({ companyId: company.id, year: new Date().getFullYear() }))
            dispatch(findActivitiesDashboard({ companyIds: [...companiesId?.map(company => company.companyId) || [], Number(window.location.pathname.split('/')[3])], year: companyState.ordinaryAccountingYearFilter }))
            dispatch(getCompanyUnseenInfoMessages([...companiesId?.map(company => company.companyId.toString()) || [], window.location.pathname.split('/')[3]]))
            setPeriods(companyState.findCompanyResponse.periodicity === PeriodicityEnum.Monthly ? 11 : 3)
        }
    }, [companyState.ordinaryAccountingYearFilter, companyState.findCompanyStatus, companyState.operationsTabValue])

    if (
        companyState.findCompanyCategoryStatus === 'loading' ||
        dashboardState.findActivityByCompanyIdsStatus === 'loading' ||
        dashboardState.findActivityGroupByCompanyIdsStatus === 'loading' ||
        companyState.findCompanyStatus === 'loading' ||
        companyState.findCompanyResponse === undefined ||
        activityState.findAllActivityCategoryStatus === 'loading'
    ) {
        return (<div style={{ width: '100%', height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><SpinnerComponent size='small' /></div>)
    }

    let registrations: ReactNode[] = []
    let withholdings: ReactNode[] = []
    let controls: ReactNode[] = []
    const company = companyState.findCompanyResponse

    const monthlyMap = new Map<number, string>([
        [0, 'GEN'],
        [1, 'FEB'],
        [2, 'MAR'],
        [3, 'APR'],
        [4, 'MAG'],
        [5, 'GIU'],
        [6, 'LUG'],
        [7, 'AGO'],
        [8, 'SET'],
        [9, 'OTT'],
        [10, 'NOV'],
        [11, 'DIC'],
    ])

    const quarterlyMap = new Map<number, string>([
        [0, 'I trimestre'],
        [1, 'II trimestre'],
        [2, 'III trimestre'],
        [3, 'IV trimestre'],
    ])

    if (
        companyState.operationsTabValue === 0 &&
        companyState.findCompanyResponse !== undefined &&
        companyState.findCompanyStatus === 'successfully' &&
        dashboardState.findActivitiesDashboardStatus === 'successfully' &&
        activityState.findAllActivityCategoryStatus === 'successfully'
    ) {
        const companyGroups = dashboardState.findActivitiesDashboardResponse.find(company => company.id === companyState.findCompanyResponse?.id)

        let categories: { id: number, name: string }[] = []
        company.activityCategoryIds?.forEach(category => {
            const _category = activityState.findAllActivityCategoryResponse?.data.find(categoryResponse => categoryResponse.id === category)
            if (_category !== undefined)
                categories.push(_category)
        })

        withholdings = []

        for (let i = 0; i <= period; i++) {
            withholdings.push(
                <div style={{ flexGrow: companyState.findCompanyResponse.periodicity === PeriodicityEnum.Monthly ? 0 : 1 }} key={'ordinary-accounting-management-withholding' + i}>
                    <ButtonComponent
                        // disabled={keycloak.hasRealmRole('financial-advisor') || keycloak.hasRealmRole('operator')}
                        label={company.periodicity === PeriodicityEnum.Monthly ? (monthlyMap.get(i) || '') : (quarterlyMap.get(i) || '')}
                        key={'ordinary-accounting-management-withholding-button' + i}
                        onClick={() => {
                            dispatch(setOrdinaryAccountingCompany(companyState.findCompanyResponse))
                            dispatch(setWittholdingsCompanyId(companyState.findCompanyResponse?.id))
                            dispatch(setOrdinaryAccountingActivityCategories(categories))
                            dispatch(setOrdinaryControlsActivityCategories(categories))
                            dispatch(setWithholdingsForAccounting(companyGroups?.withholdings.filter(withholding => withholding.period === i)))
                            dispatch(setOrdinaryAccountingActivityGroups(companyGroups?.activityGroups.filter(group => group.period === i) || []))
                            dispatch(setOrdinaryControlsActivityGroups(companyGroups?.activityGroups.filter(group => group.period === i) || []))
                            dispatch(setOrdinaryAccountingPeriod(i))
                            dispatch(setWithholdingsPeriod(i))
                            dispatch(setOrdinaryAccountingYear(companyState.ordinaryAccountingYearFilter))
                            dispatch(setWittholdingsYear(companyState.ordinaryAccountingYearFilter))
                            dispatch(setOrdinaryAccountingInitialTab(company.activityCategoryIds !== null ? company.activityCategoryIds[0] : 0));
                            dispatch(setOpenOrdinaryAccountingModal(true));
                            dispatch(setInitialTabAccountingValue(1));
                        }
                        }
                        color={withholdingsColorMap(companyGroups?.withholdings.filter(withholding => withholding.period === i) || []).color}
                        pulsing={withholdingsColorMap(companyGroups?.withholdings.filter(withholding => withholding.period === i) || []).pulsing}
                        variant={withholdingsColorMap(companyGroups?.withholdings.filter(withholding => withholding.period === i) || []).variant}
                        size={companyState.findCompanyResponse?.periodicity === PeriodicityEnum.Monthly ? Size.SM : Size.FIT}
                        iconStyle={IconStyle.OFF}
                    />
                </div>
            )
        }

        categories.forEach((category, index) => {
            let buttonRegistrations: ReactNode[] = []
            let buttonControls: ReactNode[] = []
            for (let j = 0; j <= period; j++) {
                const groupRegistrations = companyGroups?.activityGroups.find(group => group.activityCategory.id === category.id && group.period === j && group.activityKind === ActivityKind.Records)
                const groupControls = companyGroups?.activityGroups.find(group => group.activityCategory.id === category.id && group.period === j && group.activityKind === ActivityKind.Controls)
                buttonRegistrations.push(
                    <div key={'ordinary-accounting-management-' + groupRegistrations?.id + j + '-' + index} style={{ flexGrow: companyState.findCompanyResponse?.periodicity === PeriodicityEnum.Monthly ? 0 : 1 }}>
                        <ButtonComponent
                            label={company.periodicity === PeriodicityEnum.Monthly ? (monthlyMap.get(j) || '') : (quarterlyMap.get(j) || '')}
                            key={'ordinary-accounting-management-button-' + groupRegistrations?.id + j + '-' + index}
                            onClick={() => {
                                dispatch(setOrdinaryAccountingCompany(companyState.findCompanyResponse))
                                dispatch(setWittholdingsCompanyId(companyState.findCompanyResponse?.id))
                                dispatch(setOrdinaryAccountingActivityCategories(categories))
                                dispatch(setOrdinaryControlsActivityCategories(categories))
                                dispatch(setWithholdingsForAccounting(companyGroups?.withholdings.filter(withholding => withholding.period === j)))
                                dispatch(setOrdinaryAccountingActivityGroups(companyGroups?.activityGroups.filter(group => group.period === j) || []))
                                dispatch(setOrdinaryControlsActivityGroups(companyGroups?.activityGroups.filter(group => group.period === j) || []))
                                dispatch(setOrdinaryAccountingPeriod(j))
                                dispatch(setWithholdingsPeriod(j))
                                dispatch(setOrdinaryAccountingYear(companyState.ordinaryAccountingYearFilter))
                                dispatch(setWittholdingsYear(companyState.ordinaryAccountingYearFilter))
                                dispatch(setOrdinaryAccountingInitialTab(category.id));
                                dispatch(setControlsInitialTab(category.id));
                                dispatch(setOpenOrdinaryAccountingModal(true));
                                dispatch(setInitialTabAccountingValue(0));
                            }
                            }
                            color={activityGroupsColorsMap(groupRegistrations).color}
                            variant={activityGroupsColorsMap(groupRegistrations).variant}
                            size={companyState.findCompanyResponse?.periodicity === PeriodicityEnum.Monthly ? Size.SM : Size.FIT}
                            iconStyle={IconStyle.OFF}
                        />
                    </div>
                )
                buttonControls.push(
                    <div key={'ordinary-controls-management-' + groupControls?.id + j + '-' + index} style={{ flexGrow: companyState.findCompanyResponse?.periodicity === PeriodicityEnum.Monthly ? 0 : 1 }}>
                        <ButtonComponent
                            label={company.periodicity === PeriodicityEnum.Monthly ? (monthlyMap.get(j) || '') : (quarterlyMap.get(j) || '')}
                            key={'ordinary-controls-management-button-' + groupControls?.id + j + '-' + index}
                            onClick={() => {
                                dispatch(setOrdinaryAccountingCompany(companyState.findCompanyResponse))
                                dispatch(setWittholdingsCompanyId(companyState.findCompanyResponse?.id))
                                dispatch(setOrdinaryAccountingActivityCategories(categories))
                                dispatch(setOrdinaryControlsActivityCategories(categories))
                                dispatch(setWithholdingsForAccounting(companyGroups?.withholdings.filter(withholding => withholding.period === j)))
                                dispatch(setOrdinaryAccountingActivityGroups(companyGroups?.activityGroups.filter(group => group.period === j) || []))
                                dispatch(setOrdinaryControlsActivityGroups(companyGroups?.activityGroups.filter(group => group.period === j) || []))
                                dispatch(setOrdinaryAccountingPeriod(j))
                                dispatch(setWithholdingsPeriod(j))
                                dispatch(setOrdinaryAccountingYear(companyState.ordinaryAccountingYearFilter))
                                dispatch(setWittholdingsYear(companyState.ordinaryAccountingYearFilter))
                                dispatch(setOrdinaryAccountingInitialTab(category.id));
                                dispatch(setControlsInitialTab(category.id));
                                dispatch(setOpenOrdinaryAccountingModal(true));
                                dispatch(setInitialTabAccountingValue(2));
                            }
                            }
                            color={controlsColorMap(groupControls).color}
                            variant={controlsColorMap(groupControls).variant}
                            pulsing={controlsColorMap(groupControls).pulsing}
                            size={companyState.findCompanyResponse?.periodicity === PeriodicityEnum.Monthly ? Size.SM : Size.FIT}
                            iconStyle={IconStyle.OFF}
                        />
                    </div>
                )
            }
            registrations.push(
                <div
                    className="column"
                    key={category.id}
                    style={{
                        width: '100%',
                        margin: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 0,
                        gap: '12px'
                    }}>
                    <div style={{ margin: 0, padding: 0 }} className={"col-12 col-xl-1"}>
                        <p style={{ margin: 0, padding: 0 }} className='text text--lg typography--semibold typography-neutral--700'>
                            {category.name}
                        </p>
                    </div>
                    <div style={{ margin: 0, padding: 0, width: '100%' }} className={"col-12 col-xl-auto"}>
                        <div className="row" style={{ margin: 0, padding: 0, gap: '8px', width: '100%' }}>
                            <div className='d-flex flex-wrap align-items-center justify-content-start m-0 p-0' style={{ gap: companyState.findCompanyResponse?.periodicity === PeriodicityEnum.Monthly ? '8px' : '20px', width: '100%' }}>
                                {buttonRegistrations}
                            </div>
                        </div>
                    </div>
                </div>
            )
            controls.push(
                <div
                    className="column"
                    key={category.id}
                    style={{
                        width: '100%',
                        margin: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 0,
                        gap: '12px'
                    }}>
                    <div style={{ margin: 0, padding: 0 }} className={"col-12 col-xl-1"}>
                        <p style={{ margin: 0, padding: 0 }} className='text text--lg typography--semibold typography-neutral--700'>
                            {category.name}
                        </p>
                    </div>
                    <div style={{ margin: 0, padding: 0, width: '100%' }} className={"col-12 col-xl-auto"}>
                        <div className="row" style={{ margin: 0, padding: 0, gap: '8px', width: '100%' }}>
                            <div className='d-flex flex-wrap align-items-center justify-content-start m-0 p-0' style={{ gap: companyState.findCompanyResponse?.periodicity === PeriodicityEnum.Monthly ? '8px' : '20px', width: '100%' }}>
                                {buttonControls}
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    const startYear = 2022
    let yearsMenuItems: ReactNode[] = []

    for (let year = startYear; year <= new Date().getFullYear() + 1; year++) {
        yearsMenuItems.push(<MenuItem key={year} value={year}>{year}</MenuItem>)
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
            <ErrorPopup
                active={withholdingState.deleteWithholdingStatus === "failed"}
                close={() => {
                    dispatch(setDeleteWithholdingStatus("idle"))
                    dispatch(setDeleteMessage(""))
                }}
                message={withholdingState.deleteMessage}
            />
            {
                dashboardState.findActivitiesDashboardStatus === 'loading' ?
                    <SpinnerComponent size={'small'} /> : (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    padding: '16px',
                                    border: '1px solid ' + colors.neutral80,
                                    borderRadius: '12px',
                                    width: '100%'
                                }}
                            >
                                <HeadingComponent
                                    size={HeadingSize.MD}
                                    label={'Registrazioni'}
                                    buttons={[
                                        <SelectComponent
                                            key={'ordinary-accounting-year-select'}
                                            id={'ordinary-accounting-year-select'}
                                            value={companyState.ordinaryAccountingYearFilter.toString()}
                                            menuItems={yearsMenuItems.reverse()}
                                            onChange={(e) => dispatch(setOrdinaryAccountingYearFilter(e.target.value))}
                                        />]}
                                />
                                <div style={{ display: 'flex', flexDirection: 'column', gap: 24, width: '100%' }}>
                                    {registrations}
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    padding: '16px',
                                    border: '1px solid ' + colors.neutral80,
                                    borderRadius: '12px',
                                    width: '100%'
                                }}
                            >
                                <HeadingComponent
                                    size={HeadingSize.MD}
                                    label={'Ritenute'}
                                    buttons={[
                                        <SelectComponent
                                            key={'ordinary-accounting-year-select'}
                                            id={'ordinary-accounting-year-select'}
                                            value={companyState.ordinaryAccountingYearFilter.toString()}
                                            menuItems={yearsMenuItems.reverse()}
                                            onChange={(e) => dispatch(setOrdinaryAccountingYearFilter(e.target.value))}
                                        />]}
                                />
                                <div style={{ margin: 0, padding: 0, width: '100%', marginTop: '20px' }} className={"col-12 col-xl-auto"}>
                                    <div className="row" style={{ margin: 0, padding: 0, gap: '8px', width: '100%' }}>
                                        <div className='d-flex flex-wrap align-items-center justify-content-start m-0 p-0' style={{ gap: companyState.findCompanyResponse.periodicity === PeriodicityEnum.Monthly ? '8px' : '20px', width: '100%' }}>
                                            {withholdings}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    padding: '16px',
                                    border: '1px solid ' + colors.neutral80,
                                    borderRadius: '12px',
                                    width: '100%'
                                }}
                            >
                                <HeadingComponent
                                    size={HeadingSize.MD}
                                    label={'Controlli'}
                                    buttons={[
                                        <SelectComponent
                                            key={'ordinary-accounting-year-select'}
                                            id={'ordinary-accounting-year-select'}
                                            value={companyState.ordinaryAccountingYearFilter.toString()}
                                            menuItems={yearsMenuItems.reverse()}
                                            onChange={(e) => dispatch(setOrdinaryAccountingYearFilter(e.target.value))}
                                        />]}
                                />
                                <div style={{ margin: 0, padding: 0, width: '100%', marginTop: '20px' }} className={"col-12 col-xl-auto"}>
                                    <div className="row" style={{ margin: 0, padding: 0, gap: '8px', width: '100%' }}>
                                        <div className='d-flex flex-wrap align-items-center justify-content-start m-0 p-0' style={{ gap: companyState.findCompanyResponse.periodicity === PeriodicityEnum.Monthly ? '8px' : '20px', width: '100%' }}>
                                            {controls}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <OrdinaryAccountingModal />
                        </>
                    )
            }
        </div>
    )
}