import { Box, FormControlLabel, Radio } from "@mui/material";
import { useState } from "react";
import { CheckboxSize, CheckboxStyle } from "../checkbox/dto";
import { CheckboxSizeMap, CheckboxStyleMap } from "../checkbox/style";
import { OnChange } from "../utils";
import colors from '../utils/index.module.scss';

interface Props {
    value?: string,
    placeholder?: string,
    supportingText?: string,
    checked?: boolean,
    size: CheckboxSize,
    disabled?: boolean,
    onChange?: OnChange
}

export function RadioComponent(props: Props) {
    const { value } = props
    const { placeholder } = props
    const { supportingText } = props
    const { size } = props
    const { checked } = props
    const { disabled } = props
    const { onChange } = props

    const [focused, setFocused] = useState<boolean>(false);
    const [hover, setHover] = useState<boolean>(false);

    const sizeStyle = CheckboxSizeMap.get(size)
    const checkboxStyle = CheckboxStyleMap.get(JSON.stringify({ size, style: CheckboxStyle.CIRCLE }))

    const icon = (
        <div
            style={{
                width: checkboxStyle?.outerDim,
                height: checkboxStyle?.outerDim,
                backgroundColor: focused ? colors.primary50 : '',
                borderRadius: checkboxStyle?.outerRadius,
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center'
            }}>
            <Box
                sx={{
                    zIndex: 2,
                    height: checkboxStyle?.dim,
                    width: checkboxStyle?.dim,
                    borderRadius: checkboxStyle?.borderRadius,
                    border: '1px solid ' + (focused ? colors.primary500 : colors.neutral100),
                    backgroundColor: colors.white,
                    "&:hover": {
                        borderColor: colors.primary500
                    },
                }}
            />
        </div>
    )

    const checkedIcon = (
        <div
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            style={{
                width: checkboxStyle?.outerDim,
                height: checkboxStyle?.outerDim,
                backgroundColor: focused ? colors.primary50 : '',
                borderRadius: checkboxStyle?.outerRadius,
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center'
            }}>
            <Box
                component='div'
                sx={{
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 2,
                    height: checkboxStyle?.dim,
                    width: checkboxStyle?.dim,
                    borderRadius: checkboxStyle?.borderRadius,
                    border: '1px solid ' + colors.primary500,
                    backgroundColor: focused ? colors.primary500 : colors.white,
                    "&:hover": {
                        backgroundColor: colors.primary500
                    },
                }}
            >
                {
                    size === CheckboxSize.MD ? (
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="5" cy="5" r="5" fill={focused || hover ? colors.white : colors.primary500} />
                        </svg>
                    ) : (
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="4" cy="4" r="4" fill={focused || hover ? colors.white : colors.primary500} />
                        </svg>
                    )
                }
            </Box>
        </div>
    )

    return (
        <FormControlLabel
            control={
                <Radio
                    value={value}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    checked={checked}
                    disabled={disabled}
                    icon={icon}
                    checkedIcon={checkedIcon}
                    onChange={(e) => onChange && onChange(e)}
                />
            }
            label={
                <p style={{ margin: 0, opacity: disabled ? 0.5 : 1 }} className={sizeStyle?.placeholder}>
                    {placeholder}
                    <br />
                    <span style={{ margin: 0 }} className={sizeStyle?.supportingText}>
                        {supportingText}
                    </span>
                </p>
            }
        />
    )
}