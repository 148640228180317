import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { SpinnerComponent } from "../../spinner";
import { ChatComponent } from "./chat";
import { findChats, setSelectedChat } from "./slice";
import { UsersList } from "./usersList";

export function ChatViewV2() {
    const companyState = useAppSelector(state => state.company)
    const chatState = useAppSelector(state => state.chatV2)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(setSelectedChat(0))
    }, [])

    useEffect(() => {
        if (companyState.findCompanyStatus === 'successfully' && companyState.findCompanyResponse !== undefined) {
            dispatch(findChats({ firstUser: '', secondUser: '', topic: companyState.findCompanyResponse.id }))
        }
    }, [companyState.findCompanyStatus])

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 16, height: 'calc(100vh - 250px)' }}>
            <UsersList />
            <ChatComponent />
        </div>
    )
}