
import { keycloak } from "../../keycloak";
import colors from "../../utils/index.module.scss";
import { FeesRegister } from "./feesRegister";
import { PurchaseRegister } from "./purchaseRegister";
import { RetentionManagement } from "./retentionManagement";
import { ReverseChange } from "./reverseChange";
import { SalesRegister } from "./salesRegister";

export function UpdateRegistry() {

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 24, width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '24px', width: '100%' }}>
                <div style={{ backgroundColor: colors.primary50, marginBottom: '16px', display: 'flex', flexDirection: 'row', gap: '24px', alignItems: 'center', padding: '8px 24px 8px 24px', borderRadius: '8px', border: '1px solid ' + colors.primary100, height: '58px', width: '100%' }}>
                    <span className="text text--lg typography--semibold">Gestione ritenute</span>
                    <RetentionManagement disabled={!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('admin-accountant') && !keycloak.hasRealmRole('accountant') && !keycloak.hasRealmRole('operator')} />
                </div>
            </div>
            <div className="row m-0 p-0" style={{ gap: 24, width: '100%' }}>
                <div className="col-12 col-xl p-0 m-0">
                    <SalesRegister disabled={!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('admin-accountant') && !keycloak.hasRealmRole('accountant') && !keycloak.hasRealmRole('operator')} />
                </div>
                <div className="col-12 col-xl p-0 m-0">
                    <PurchaseRegister disabled={!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('admin-accountant') && !keycloak.hasRealmRole('accountant') && !keycloak.hasRealmRole('operator')} />
                </div>
            </div>
            <div className="row m-0 p-0" style={{ gap: 24, width: '100%' }}>
                <div className="col-12 col-xl p-0 m-0">
                    <FeesRegister disabled={!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('admin-accountant') && !keycloak.hasRealmRole('accountant') && !keycloak.hasRealmRole('operator')} />
                </div>
                <div className="col-12 col-xl p-0 m-0">
                    <ReverseChange disabled={!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('admin-accountant') && !keycloak.hasRealmRole('accountant') && !keycloak.hasRealmRole('operator')} />
                </div>
            </div>
        </div>
    )
}