import { ReactNode, useEffect } from "react";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { findAllCompaniesToConfirm } from "../companyToConfirm/slice";
import { ArrowRightIcon } from "../icons/arrowRight";
import { CheckCircleGreenIcon } from "../icons/checkCircleGreen";
import { CloseIcon } from "../icons/close";
import { SendIcon } from "../icons/send";
import { keycloak } from "../keycloak";
import { ModalComponent } from "../modal";
import { ProgressStepComponent } from "../progressStep";
import { ProgressStepColor, ProgressStepSize } from "../progressStep/dto";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SpinnerComponent } from "../spinner";
import colors from '../utils/index.module.scss';
import { AccountingRegime } from "./accountingRegime";
import { AccountingSchemeEnum, AtecosEnum, BankEnum, PeriodicityEnum, atecoToNumberMap, bankToNumberMap } from "./dto";
import { JournalPeriodicityBanks } from "./journalPeriodicityBanks";
import { ServiceTypeAtecosInvoicesSpecialVat } from "./serviceTypeAtecosInvoicesSpecialVat";
import { createCompany, setCompanyQuotationWizardCreationDTO, setCompanyQuotationWizardIndex, setCompanyQuotationWizardStep, setOpenCompanyQuotationWizardModal } from "./slice";
import { CompanyQuotationSummary } from "./summary";

export function CompanyQuotationWizardModal() {
    const companyQuotationWizardState = useAppSelector(state => state.companyQuotation)
    const companyToConfirmState = useAppSelector(state => state.companyToConfirm)
    const authState = useAppSelector(state => state.auth)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (companyQuotationWizardState.openCompanyQuotationWizardModal) {
            const newCompany = [...companyQuotationWizardState.companiesToAdd]

            newCompany[companyQuotationWizardState.index] = {
                ...companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index],
                financialAdvisorId: authState.findMeResponse !== undefined ? authState.findMeResponse.id : 0
            };

            dispatch(setCompanyQuotationWizardCreationDTO([...newCompany]))
        }
    }, [companyQuotationWizardState.index, companyQuotationWizardState.openCompanyQuotationWizardModal])

    useEffect(() => {
        if (companyQuotationWizardState.saveCompaniesStatus === 'successfully') {
            dispatch(findAllCompaniesToConfirm(companyToConfirmState.companiesToAddFilters))
        }
    }, [companyQuotationWizardState.saveCompaniesStatus])

    const buttonMap = new Map<number, { first: ReactNode, last: ReactNode }>([
        [0, {
            first: <ButtonComponent label={"Annulla"} onClick={() => dispatch(setOpenCompanyQuotationWizardModal(false))} color={Colors.NEUTRAL} variant={Variant.OUTLINE} size={Size.FIT} iconStyle={IconStyle.OFF} />,
            last: <ButtonComponent label={"Successivo"} onClick={() => dispatch(setCompanyQuotationWizardStep(companyQuotationWizardState.step + 1))} color={Colors.PRIMARY} variant={Variant.SOLID} size={Size.FIT} iconStyle={IconStyle.RIGHT} icon={<ArrowRightIcon colorBase={""} />} />
        }],
        [1, {
            first: <ButtonComponent label={"Annulla"} onClick={() => dispatch(setOpenCompanyQuotationWizardModal(false))} color={Colors.NEUTRAL} variant={Variant.OUTLINE} size={Size.FIT} iconStyle={IconStyle.OFF} />,
            last:
                <ButtonComponent
                    label={"Successivo"}
                    onClick={() => {
                        if (companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].accountingScheme === AccountingSchemeEnum.Ordinary) {
                            dispatch(setCompanyQuotationWizardStep(companyQuotationWizardState.step + 1))
                        } else {
                            dispatch(setCompanyQuotationWizardStep(companyQuotationWizardState.step + 2))
                        }
                    }}
                    color={Colors.PRIMARY}
                    variant={Variant.SOLID}
                    size={Size.FIT}
                    iconStyle={IconStyle.RIGHT}
                    icon={<ArrowRightIcon colorBase={""} />}
                    disabled={
                        ((keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('admin-accountant')) && (
                            companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.companiesToAdd.length - 1].financialAdvisorId === 0 ||
                            companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.companiesToAdd.length - 1].financialAdvisorId === undefined
                        ))
                        || (
                            companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.companiesToAdd.length - 1].specialVatSchemes &&
                            companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.companiesToAdd.length - 1].specialVatScheme === null
                        )
                    }
                />
        }],
        [2, {
            first: <ButtonComponent label={"Annulla"} onClick={() => dispatch(setOpenCompanyQuotationWizardModal(false))} color={Colors.NEUTRAL} variant={Variant.OUTLINE} size={Size.FIT} iconStyle={IconStyle.OFF} />,
            last:
                <ButtonComponent
                    label={"Successivo"}
                    onClick={() => dispatch(setCompanyQuotationWizardStep(companyQuotationWizardState.step + 1))}
                    color={Colors.PRIMARY}
                    variant={Variant.SOLID}
                    size={Size.FIT}
                    iconStyle={IconStyle.RIGHT}
                    disabled={companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.companiesToAdd.length - 1].activityCategoryIds.length === 0}
                    icon={<ArrowRightIcon colorBase={""} />}
                />
        }],
        [3, {
            first: <ButtonComponent label={"Aggiungi un'altra azienda"}
                onClick={() => {
                    dispatch(setCompanyQuotationWizardCreationDTO([...companyQuotationWizardState.companiesToAdd, {
                        accountingScheme: AccountingSchemeEnum.Ordinary,
                        periodicity: PeriodicityEnum.Monthly,
                        annualForeignInvoices: 0,
                        atecoCodesNumber: atecoToNumberMap.get(AtecosEnum.ATECOS_0) || 0,
                        banksNumber: bankToNumberMap.get(BankEnum.BANK_0) || 0,
                        journal: 2500,
                        specialVatSchemes: false,
                        specialVatScheme: null,
                        professionistWithFund: false,
                        financialAdvisorId: authState.findMeResponse !== undefined ? authState.findMeResponse.id : 0,
                        activityCategoryIds: [],
                        activityNotifications: false,
                        operationsNotifications: false,
                    }]))
                    dispatch(setCompanyQuotationWizardIndex(companyQuotationWizardState.index + 1))
                    dispatch(setCompanyQuotationWizardStep(0))
                }}
                color={Colors.PRIMARY} variant={Variant.OUTLINE} size={Size.FIT} iconStyle={IconStyle.OFF} />,
            last: <ButtonComponent label={"Conferma e invia"} onClick={() => dispatch(createCompany(companyQuotationWizardState.companiesToAdd))} color={Colors.PRIMARY} variant={Variant.SOLID} size={Size.FIT} iconStyle={IconStyle.RIGHT} icon={<SendIcon colorBase={""} />} />
        }]
    ])

    if (companyQuotationWizardState.saveCompaniesStatus === 'successfully' || companyQuotationWizardState.saveCompaniesStatus === 'loading') {
        return (
            <ModalComponent
                open={companyQuotationWizardState.openCompanyQuotationWizardModal}
                handleClose={() => dispatch(setOpenCompanyQuotationWizardModal(false))}
            >
                <div
                    style={{
                        padding: '16px 24px 24px 24px',
                        backgroundColor: colors.white,
                        boxShadow: colors.boxShadowXL,
                        borderRadius: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '24px',
                        minWidth: '480px'
                    }}
                >
                    {
                        companyQuotationWizardState.saveCompaniesStatus === 'loading' ? (
                            <div className="d-flex m-0 p-0 align-items-center justify-content-center w-100" style={{ height: '30vh' }}>
                                <SpinnerComponent size={"medium"} />
                            </div>
                        ) : (
                            <>
                                <div className="d-flex m-0 p-0 align-items-center justify-content-between w-100">
                                    <span className="heading heading--xs typography--semibold typography-neutral-800">
                                        Richiesta inviata con successo
                                    </span>
                                    <ButtonComponent label={""} onClick={() => dispatch(setOpenCompanyQuotationWizardModal(false))} color={Colors.NEUTRAL} variant={Variant.LINK} size={Size.SM} iconStyle={IconStyle.ONLY} icon={<CloseIcon colorBase={""} />} />
                                </div>
                                <CheckCircleGreenIcon />
                                <span className="text text--lg typography--regular typography-neutral--500">
                                    Controlla lo stato della richiesta dalla tua dashboard.
                                </span>
                            </>
                        )
                    }
                </div>
            </ModalComponent>
        )
    }

    return (
        <ModalComponent
            open={companyQuotationWizardState.openCompanyQuotationWizardModal}
            handleClose={() => dispatch(setOpenCompanyQuotationWizardModal(false))}
        >
            <div
                style={{
                    padding: '16px 24px 24px 24px',
                    backgroundColor: colors.white,
                    boxShadow: colors.boxShadowXL,
                    borderRadius: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                    minWidth: '480px'
                }}
            >
                <div className="d-flex m-0 p-0 align-items-center justify-content-between">
                    <span className="heading heading--xs typography--semibold typography-neutral-800">
                        Quotazione nuova azienda
                    </span>
                    <ButtonComponent label={""} onClick={() => dispatch(setOpenCompanyQuotationWizardModal(false))} color={Colors.NEUTRAL} variant={Variant.LINK} size={Size.SM} iconStyle={IconStyle.ONLY} icon={<CloseIcon colorBase={""} />} />
                </div>
                {
                    (
                        (
                            companyQuotationWizardState.step < 3 &&
                            companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].accountingScheme === AccountingSchemeEnum.Ordinary
                        ) ||
                        (
                            companyQuotationWizardState.step < 2 &&
                            companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].accountingScheme === AccountingSchemeEnum.Simplified
                        )
                    ) &&
                    <ProgressStepComponent
                        bulletDisabled={(step: number) => {
                            if (
                                step === 3 &&
                                ((keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('admin-accountant'))) &&
                                (
                                    companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.companiesToAdd.length - 1].financialAdvisorId === 0 ||
                                    companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.companiesToAdd.length - 1].financialAdvisorId === undefined
                                )
                            ) {
                                return true
                            }
                            return false
                        }}
                        onClick={(_step: number) => dispatch(setCompanyQuotationWizardStep(_step))}
                        size={ProgressStepSize.SM}
                        color={ProgressStepColor.PRIMARY}
                        steps={companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].accountingScheme === AccountingSchemeEnum.Ordinary ? 3 : 2}
                        step={companyQuotationWizardState.step}
                    />
                }
                {companyQuotationWizardState.step === 0 && <AccountingRegime />}
                {companyQuotationWizardState.step === 1 && <ServiceTypeAtecosInvoicesSpecialVat />}
                {companyQuotationWizardState.step === 2 && <JournalPeriodicityBanks />}
                {companyQuotationWizardState.step === 3 && <CompanyQuotationSummary />}
                <div className="row m-0 p-0 w-100" style={{ gap: '12px' }}>
                    <div className="col m-0 p-0">
                        {buttonMap.get(companyQuotationWizardState.step)?.first}
                    </div>
                    <div className="col m-0 p-0">
                        {buttonMap.get(companyQuotationWizardState.step)?.last}
                    </div>
                </div>
            </div>
        </ModalComponent >
    )
}