import { MenuItem, TableBody } from "@mui/material";
import { format } from "date-fns";
import { ReactNode, useEffect } from "react";
import { ButtonComponent } from "../../button";
import { Colors, IconStyle, Size, Variant } from "../../button/dto";
import { EmptyList } from "../../emptyList/emptyList";
import { ErrorPopup } from "../../errorPopup";
import { HeadingComponent } from "../../heading";
import { HeadingSize } from "../../heading/dto";
import { DownloadIcon } from "../../icons/download";
import { SearchIcon } from "../../icons/search";
import { InfoTextPopup } from "../../infoTextPopup";
import { NewObjectService } from "../../objects/service";
import { findFileById, setFindDocumentStatus } from "../../objects/slice";
import { PaginationComponent } from "../../pagination";
import { PillComponent } from "../../pills";
import { PillsColor, PillsEmphasis, PillsOutline, PillsSize } from "../../pills/dto";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { SelectComponent } from "../../select";
import { SpinnerComponent } from "../../spinner";
import { SuccessPopup } from "../../successPopup";
import { TableComponent } from "../../table";
import { TableCellComponent } from "../../table/tableCell";
import { TableHeadComponent } from "../../table/tableHead";
import { TableRowComponent } from "../../table/tableRow";
import { TextInput } from "../../textInput";
import colors from '../../utils/index.module.scss';
import { FinancialStatementsMap, FinancialStatementsTypology, FiscalDocumentStatus, FiscalDocumentType, StatusMap } from "./dtoDocument";
import { FiscalDocumentAreaModal } from "./fiscalDocumentAreaModal";
import { MenuFiscalDocument } from "./menuFiscalDocument";
import { findAllFiscalDocumentsBalance, findByIdFiscalDocument, setDeleteFiscalDocumentStatus, setFileNameBalanceFilter, setFiscalDocumentBalanceFilterPage, setOpenFiscalDocumentAreaModal, setSaveFiscalDocumentStatus, setStatusBalanceFilter, setTypologyBalanceFilter, setYearBalanceFilter } from "./sliceDocument";

export function FinancialStatementsTab() {
    const dispatch = useAppDispatch()

    const fiscalDocumentState = useAppSelector(state => state.fiscalDocument)
    const companyState = useAppSelector(state => state.company)

    const objectService = NewObjectService()

    const startYear = 2022
    let yearsMenuItems: ReactNode[] = []

    for (let year = startYear; year <= new Date().getFullYear() + 1; year++) {
        yearsMenuItems.push(<MenuItem key={year} value={year}>{year}</MenuItem>)
    }

    useEffect(() => {
        if (companyState.findCompanyResponse !== undefined) {
            dispatch(findAllFiscalDocumentsBalance({ ...fiscalDocumentState.fiscalDocumentBalanceFilters, companyId: companyState.findCompanyResponse.id.toString() }))
        }
    }, [companyState.findCompanyResponse])

    useEffect(() => {
        if (fiscalDocumentState.findAllFiscalDocumentBalanceStatus === 'successfully' && fiscalDocumentState.findAllFiscalDocumentBalanceResponse !== undefined) {
            if (fiscalDocumentState.findAllFiscalDocumentBalanceResponse.page >= fiscalDocumentState.findAllFiscalDocumentBalanceResponse.totalPage) {
                dispatch(setFiscalDocumentBalanceFilterPage(0))
                dispatch(findAllFiscalDocumentsBalance({ ...fiscalDocumentState.fiscalDocumentBalanceFilters, page: 0 }))
            }
        }
    }, [fiscalDocumentState.findAllFiscalDocumentBalanceStatus])

    useEffect(() => {
        if (fiscalDocumentState.createFiscalDocumentsStatus === 'successfully' && companyState.findCompanyResponse !== undefined) {
            dispatch(findAllFiscalDocumentsBalance({ ...fiscalDocumentState.fiscalDocumentBalanceFilters, companyId: companyState.findCompanyResponse.id.toString() }))
        }
    }, [fiscalDocumentState.createFiscalDocumentsStatus, companyState.findCompanyResponse])

    useEffect(() => {
        if (fiscalDocumentState.deleteFiscalDocumentStatus === 'successfully' && companyState.findCompanyResponse !== undefined) {
            dispatch(findAllFiscalDocumentsBalance({ ...fiscalDocumentState.fiscalDocumentBalanceFilters, companyId: companyState.findCompanyResponse.id.toString() }))
        }
    }, [fiscalDocumentState.deleteFiscalDocumentStatus, companyState.findCompanyResponse])

    if (
        fiscalDocumentState.findAllFiscalDocumentBalanceStatus === 'loading' ||
        fiscalDocumentState.createFiscalDocumentsStatus === 'loading' ||
        fiscalDocumentState.deleteFiscalDocumentStatus === 'loading' ||
        companyState.findCompanyStatus === 'loading'
    ) {
        return (<div style={{ width: '100%', height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><SpinnerComponent size='small' /></div>)
    }

    let fiscalDocumentBalanceRows: ReactNode[] = []

    if (fiscalDocumentState.findAllFiscalDocumentBalanceStatus === 'successfully' &&
        fiscalDocumentState.findAllFiscalDocumentBalanceResponse !== undefined &&
        fiscalDocumentState.findAllFiscalDocumentBalanceResponse.data !== undefined &&
        fiscalDocumentState.findAllFiscalDocumentBalanceResponse.data.length > 0 &&
        companyState.taxAreaTabValue === 0
    ) {
        fiscalDocumentState.findAllFiscalDocumentBalanceResponse.data.forEach(data => {
            let typology = FinancialStatementsMap.get(data.typology as FinancialStatementsTypology)
            let status = StatusMap.get(data.status)
            fiscalDocumentBalanceRows.push(
                <TableRowComponent key={'fiscal-documents-balance' + data.id}>
                    <TableCellComponent label={data.fileName} cellType={"row-semibold"} alignLabel={"left"} />
                    <TableCellComponent content={<PillComponent label={typology !== undefined ? typology : ''} size={PillsSize.XS} color={PillsColor.NEUTRAL} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent content={<PillComponent label={status !== undefined ? status : ''} size={PillsSize.XS} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent label={data.referenceYear.toString()} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent label={data.date && format(new Date(data.date), 'dd/MM/yyyy')} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent label={data.lastDownloadDate !== null ? '' : '-'} cellType={"row-regular"} content={data.lastDownloadDate !== null ? <PillComponent label={format(new Date(data.lastDownloadDate), 'dd/MM/yyyy')} size={PillsSize.SM} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} /> : ''} alignLabel={"left"} />
                    <TableCellComponent
                        content={
                            <div className="d-flex justify-content-end align-items-center w-100">
                                <InfoTextPopup
                                    label={"Scarica"}
                                    position="bottom"
                                    children={
                                        <ButtonComponent
                                            icon={<DownloadIcon colorBase={colors.neutral600} />}
                                            onClick={() => {
                                                dispatch(findFileById(data.objectId.toString())).then((e) => {
                                                    //@ts-ignore
                                                    objectService.downloadBase64WithExtension(data.fileName, e.payload !== null ? e.payload : '')
                                                    dispatch(findByIdFiscalDocument(data.id))
                                                        .then(() => {
                                                            dispatch(setFindDocumentStatus('idle'))
                                                            dispatch(findAllFiscalDocumentsBalance({ ...fiscalDocumentState.fiscalDocumentBalanceFilters, companyId: companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse.id.toString() : '' }))
                                                        })
                                                });
                                            }}
                                            color={Colors.NEUTRAL}
                                            label=""
                                            variant={Variant.LINK}
                                            size={Size.SM}
                                            iconStyle={IconStyle.ONLY}
                                        />
                                    }
                                />
                                <MenuFiscalDocument fiscalDocument={data} />
                            </div>
                        }
                        cellType={"row-regular"}
                        alignLabel={"right"}
                    />
                </TableRowComponent>
            )
        })
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "flex-start",
            alignItems: 'flex-start',
            gap: '40px',
            width: '100%'
        }}>
            <ErrorPopup
                active={fiscalDocumentState.deleteFiscalDocumentStatus === 'failed'}
                close={() => dispatch(setDeleteFiscalDocumentStatus('idle'))}
                message="Si è verificato un errore durante l'eliminazione del documento"
            />
            <ErrorPopup
                active={fiscalDocumentState.createFiscalDocumentsStatus === 'failed'}
                close={() => dispatch(setSaveFiscalDocumentStatus('idle'))}
                message="Si è verificato un errore durante il salvataggio del documento"
            />
            <SuccessPopup
                active={fiscalDocumentState.deleteFiscalDocumentStatus === 'successfully'}
                close={() => dispatch(setDeleteFiscalDocumentStatus('idle'))}
                message="Documento eliminato correttamente"
            />
            <SuccessPopup
                active={fiscalDocumentState.createFiscalDocumentsStatus === 'successfully'}
                close={() => dispatch(setSaveFiscalDocumentStatus('idle'))}
                message="Documento salvato correttamente"
            />
            <div style={{ width: '100%', gap: '20px', display: 'flex', flexDirection: 'column' }}>
                <HeadingComponent label={"Bilanci e Dichiarativi"} buttons={[
                    <ButtonComponent
                        key={'financial-statements-button'}
                        label={"Aggiungi file"}
                        onClick={() => dispatch(setOpenFiscalDocumentAreaModal(true))}
                        color={Colors.PRIMARY}
                        variant={Variant.SOLID}
                        size={Size.SM}
                        iconStyle={IconStyle.OFF}
                    />
                ]} size={HeadingSize.MD} />
                <div style={{ marginTop: '8px', gap: '16px' }} className="row m-0 p-0`">
                    <div className="col-12 col-lg p-0 m-0">
                        <TextInput value={fiscalDocumentState.fiscalDocumentBalanceFilters.fileName} onChange={(e) => dispatch(setFileNameBalanceFilter(e.target.value))} id="financial-statements-name" type={"text"} placeholder={"Cerca nome del file"} startIcon={<SearchIcon colorBase={""} />} />
                    </div>
                    <div className="col-12 col-lg p-0 m-0">
                        <SelectComponent
                            value={fiscalDocumentState.fiscalDocumentBalanceFilters.typology}
                            onChange={(e) => {
                                dispatch(setTypologyBalanceFilter(e.target.value))
                                if (companyState.findCompanyResponse !== undefined) {
                                    dispatch(findAllFiscalDocumentsBalance({
                                        typology: e.target.value as FinancialStatementsTypology,
                                        fileName: fiscalDocumentState.fiscalDocumentBalanceFilters.fileName,
                                        itemsPerPage: fiscalDocumentState.fiscalDocumentBalanceFilters.itemsPerPage,
                                        page: fiscalDocumentState.fiscalDocumentBalanceFilters.page,
                                        month: fiscalDocumentState.fiscalDocumentBalanceFilters.month,
                                        year: fiscalDocumentState.fiscalDocumentBalanceFilters.year,
                                        status: fiscalDocumentState.fiscalDocumentBalanceFilters.status,
                                        type: fiscalDocumentState.fiscalDocumentBalanceFilters.type,
                                        companyId: companyState.findCompanyResponse.id.toString()
                                    }))
                                }
                            }}
                            id={"financial-statements-typology"}
                            menuItems={
                                [
                                    <MenuItem key='financial-statements-typology-filter' value=''>Seleziona tipologia</MenuItem>,
                                    <MenuItem key={FinancialStatementsTypology.unique} value={FinancialStatementsTypology.unique}>Unico</MenuItem>,
                                    <MenuItem key={FinancialStatementsTypology.iva} value={FinancialStatementsTypology.iva}>IVA</MenuItem>,
                                    <MenuItem key={FinancialStatementsTypology.document770} value={FinancialStatementsTypology.document770}>770</MenuItem>,
                                    <MenuItem key={FinancialStatementsTypology.balanceDocument} value={FinancialStatementsTypology.balanceDocument}>Bilancio</MenuItem>,
                                ]
                            } />
                    </div>
                    <div className="col-12 col-lg p-0 m-0">
                        <SelectComponent
                            value={fiscalDocumentState.fiscalDocumentBalanceFilters.status}
                            onChange={(e) => {
                                dispatch(setStatusBalanceFilter(e.target.value))
                                if (companyState.findCompanyResponse !== undefined) {
                                    dispatch(findAllFiscalDocumentsBalance({
                                        typology: fiscalDocumentState.fiscalDocumentBalanceFilters.typology,
                                        fileName: fiscalDocumentState.fiscalDocumentBalanceFilters.fileName,
                                        itemsPerPage: fiscalDocumentState.fiscalDocumentBalanceFilters.itemsPerPage,
                                        page: fiscalDocumentState.fiscalDocumentBalanceFilters.page,
                                        month: fiscalDocumentState.fiscalDocumentBalanceFilters.month,
                                        year: fiscalDocumentState.fiscalDocumentBalanceFilters.year,
                                        status: e.target.value,
                                        type: fiscalDocumentState.fiscalDocumentBalanceFilters.type,
                                        companyId: companyState.findCompanyResponse.id.toString()
                                    }))
                                }
                            }}
                            id={"financial-statements-status"}
                            menuItems={
                                [
                                    <MenuItem key='financial-statements-status-filter' value=''>Seleziona stato</MenuItem>,
                                    <MenuItem key={FiscalDocumentStatus.toRead} value={FiscalDocumentStatus.toRead}>Da leggere</MenuItem>,
                                    <MenuItem key={FiscalDocumentStatus.read} value={FiscalDocumentStatus.read}>Letto</MenuItem>,
                                ]
                            } />
                    </div>
                    <div className="col-12 col-lg p-0 m-0">
                        <SelectComponent
                            id={"financial-statements-year"}
                            value={fiscalDocumentState.fiscalDocumentBalanceFilters.year?.toString()}
                            onChange={(e) => {
                                dispatch(setYearBalanceFilter(e.target.value))
                                if (companyState.findCompanyResponse !== undefined) {
                                    dispatch(findAllFiscalDocumentsBalance({
                                        typology: fiscalDocumentState.fiscalDocumentBalanceFilters.typology,
                                        fileName: fiscalDocumentState.fiscalDocumentBalanceFilters.fileName,
                                        itemsPerPage: fiscalDocumentState.fiscalDocumentBalanceFilters.itemsPerPage,
                                        page: fiscalDocumentState.fiscalDocumentBalanceFilters.page,
                                        month: fiscalDocumentState.fiscalDocumentBalanceFilters.month,
                                        year: Number(e.target.value),
                                        status: fiscalDocumentState.fiscalDocumentBalanceFilters.status,
                                        type: fiscalDocumentState.fiscalDocumentBalanceFilters.type,
                                        companyId: companyState.findCompanyResponse.id.toString()
                                    }))
                                }
                            }}
                            menuItems={yearsMenuItems}
                        />
                    </div>
                    <div className="col-12 col-lg-auto p-0 m-0 d-flex align-items-center justify-content-center">
                        <div className="row p-0 m-0" style={{ gap: '8px' }}>
                            <div className="col p-0 m-0">
                                <ButtonComponent
                                    label={"Pulisci"}
                                    onClick={() => {
                                        dispatch(setFileNameBalanceFilter(''))
                                        dispatch(setTypologyBalanceFilter('' as FinancialStatementsTypology))
                                        dispatch(setYearBalanceFilter(new Date().getFullYear()))
                                        dispatch(setStatusBalanceFilter(''))
                                        if (companyState.findCompanyResponse !== undefined) {
                                            dispatch(findAllFiscalDocumentsBalance({
                                                typology: '' as FinancialStatementsTypology,
                                                fileName: '',
                                                itemsPerPage: fiscalDocumentState.fiscalDocumentBalanceFilters.itemsPerPage,
                                                page: fiscalDocumentState.fiscalDocumentBalanceFilters.page,
                                                month: fiscalDocumentState.fiscalDocumentBalanceFilters.month,
                                                year: new Date().getFullYear(),
                                                status: '',
                                                type: fiscalDocumentState.fiscalDocumentBalanceFilters.type,
                                                companyId: companyState.findCompanyResponse.id.toString()
                                            }))
                                        }
                                    }}
                                    color={Colors.NEUTRAL}
                                    variant={Variant.OUTLINE}
                                    size={Size.MD}
                                    iconStyle={IconStyle.OFF}
                                />
                            </div>
                            <div className="col p-0 m-0">
                                <ButtonComponent
                                    label={"Applica"}
                                    onClick={() => {
                                        if (companyState.findCompanyResponse !== undefined)
                                            dispatch(findAllFiscalDocumentsBalance({ ...fiscalDocumentState.fiscalDocumentBalanceFilters, companyId: companyState.findCompanyResponse.id.toString() }))
                                    }}
                                    color={Colors.PRIMARY} variant={Variant.OUTLINE} size={Size.MD} iconStyle={IconStyle.OFF} />
                            </div>
                        </div>
                    </div>
                </div>
                <TableComponent>
                    <TableHeadComponent>
                        <TableCellComponent label={"Nome del file"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"Tipologia"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"Stato"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"Anno"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"Data invio"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"ultimo download"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent cellType={"head"} alignLabel={"right"} size="context-menu" />
                    </TableHeadComponent>
                    <TableBody>
                        {fiscalDocumentBalanceRows}
                    </TableBody>
                </TableComponent>
            </div>
            <FiscalDocumentAreaModal type={FiscalDocumentType.balance} />
            {
                fiscalDocumentState.findAllFiscalDocumentBalanceResponse !== undefined &&
                fiscalDocumentState.findAllFiscalDocumentBalanceResponse.total > 0 &&
                <PaginationComponent
                    page={fiscalDocumentState.findAllFiscalDocumentBalanceResponse !== undefined ? fiscalDocumentState.findAllFiscalDocumentBalanceResponse.page : 0}
                    count={fiscalDocumentState.findAllFiscalDocumentBalanceResponse !== undefined ? fiscalDocumentState.findAllFiscalDocumentBalanceResponse.totalPage : 0}
                    onChange={(page) => dispatch(setFiscalDocumentBalanceFilterPage(page))}
                />
            }
            {
                fiscalDocumentState.findAllFiscalDocumentBalanceResponse !== undefined &&
                fiscalDocumentState.findAllFiscalDocumentBalanceResponse.total === 0 &&
                <div style={{ width: '100%', height: '50vh' }}>
                    <EmptyList />
                </div>
            }
        </div>
    )
}