import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmOrDenyModal } from "../confirmOrDenyModal.tsx";
import { ContextMenuComponent } from "../contextMenu";
import { MenuItemComponent } from "../contextMenu/menuItem";
import { EditIcon } from "../icons/edit";
import { TrashIcon } from "../icons/trash";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import colors from '../utils/index.module.scss';
import { deleteOperator, setDeleteOperatorId, setOpenDeleteOperatorModal, setOperatorSettedId } from "./slice";

interface Props {
    id: string
}

export function OperatorMenuComponent(props: Props) {
    const { id } = props
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const operatorState = useAppSelector(state => state.operator)

    return (
        <ContextMenuComponent anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
            <MenuItemComponent
                onClick={() => {
                    dispatch(setOperatorSettedId(id))
                    navigate('/edit-operator/' + id)
                }}>
                <EditIcon colorBase={colors.neutral600} />
                Modifica
            </MenuItemComponent>
            <MenuItemComponent
                onClick={() => {
                    dispatch(setDeleteOperatorId(id))
                    dispatch(setOpenDeleteOperatorModal(true))
                }}>
                <TrashIcon colorBase={colors.neutral600} />
                Cancella
            </MenuItemComponent>
            <ConfirmOrDenyModal
                open={operatorState.openDeleteOperatorModal}
                handleClose={() => dispatch(setOpenDeleteOperatorModal(false))}
                title={"Elimina collaboratore"}
                description={"Sei sicuro di voler eliminare questo collaboratore?"}
                labelDeny={"Annulla"}
                labelConfirm={"Elimina"}
                actionConfirm={() => {
                    dispatch(deleteOperator(id))
                    dispatch(setOpenDeleteOperatorModal(false))
                }}
            />
        </ContextMenuComponent>
    )
}