import { useEffect, useState } from "react";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { EmptyList } from "../emptyList/emptyList";
import { ErrorPopup } from "../errorPopup";
import { HeadingSize } from "../heading/dto";
import { LayoutComponent } from "../layout";
import { PaginationComponent } from "../pagination";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { MenuItems } from "../sidebar/dto";
import { SuccessPopup } from "../successPopup";
import { PromiseStatuses } from "../utils";
import { AccountantFiltersComponent } from "./filters";
import { AccountantListComponent } from "./list";
import { NewAccountantModal } from "./newAccountantModal";
import { findAllAccountant, setAccountantSettedId, setDeleteAccountantStatus, setOpenAccountantModal, setPageFilter } from "./slice";

export function AccountantView() {
    const dispatch = useAppDispatch()

    const accountantState = useAppSelector(state => state.accountant)

    const [accountantStatus, setAccountantStatus] = useState<PromiseStatuses>('idle')

    useEffect(() => {
        dispatch(setAccountantSettedId(''))
    }, [])

    useEffect(() => {
        if (accountantState.createAccountantStatus === "successfully") {
            setAccountantStatus("successfully")
        } else if (accountantState.createAccountantStatus === "failed") {
            setAccountantStatus("failed")
        }
    }, [accountantState.createAccountantStatus])

    useEffect(() => {
        dispatch(findAllAccountant(accountantState.findAllAccountantFilters))
    }, [accountantState.findAllAccountantFilters.page])

    useEffect(() => {
        if (accountantState.findAllAccountantStatus === 'successfully' && accountantState.findAllAccountantResponse !== undefined) {
            if (accountantState.findAllAccountantResponse.page >= accountantState.findAllAccountantResponse.totalPage) {
                dispatch(setPageFilter(0))
                dispatch(findAllAccountant({ ...accountantState.findAllAccountantFilters, page: 0 }))
            }
        }
    }, [accountantState.findAllAccountantStatus])

    return (
        <LayoutComponent
            menuItem={MenuItems.ACCOUNTANT}
            headingSize={HeadingSize.LG}
            headingLabel={"Contabili"}
            headingButtons={[
                <ButtonComponent label={"Aggiungi Contabile"} onClick={() => dispatch(setOpenAccountantModal(true))} color={Colors.PRIMARY} variant={Variant.SOLID} size={Size.MD} iconStyle={IconStyle.OFF} key={'new-accountant-button'} />
            ]}
            breadcrumbItems={["Contabili"]}
        >
            <SuccessPopup
                active={accountantStatus === 'successfully'}
                close={() => setAccountantStatus('idle')}
                message="Contabile creato correttamente"
            />
            <ErrorPopup
                active={accountantStatus === 'failed'}
                close={() => setAccountantStatus('idle')}
                message="Si è verificato un errore con la creazione del contabile"
            />
            <ErrorPopup
                active={accountantState.deleteAccountantStatus === 'failed'}
                close={() => dispatch(setDeleteAccountantStatus('idle'))}
                message="Si è verificato un errore durante l'eliminazione del contabile"
            />
            <SuccessPopup
                active={accountantState.deleteAccountantStatus === 'successfully'}
                close={() => dispatch(setDeleteAccountantStatus('idle'))}
                message="Contabile eliminato"
            />
            <AccountantFiltersComponent />
            <AccountantListComponent />
            <NewAccountantModal />
            {
                accountantState.findAllAccountantResponse !== undefined &&
                accountantState.findAllAccountantResponse.total > 0 &&
                <PaginationComponent
                    page={accountantState.findAllAccountantResponse !== undefined ? accountantState.findAllAccountantResponse.page : 0}
                    count={accountantState.findAllAccountantResponse !== undefined ? accountantState.findAllAccountantResponse.totalPage : 0}
                    onChange={(page) => dispatch(setPageFilter(page))}
                />
            }
            {
                accountantState.findAllAccountantResponse !== undefined &&
                accountantState.findAllAccountantResponse.total === 0 &&
                <EmptyList />
            }
        </LayoutComponent>
    )
}