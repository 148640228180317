import { useEffect } from "react";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { setDeleteCompanyStatus } from "../company/slice";
import { ConfirmOrDenyModal } from "../confirmOrDenyModal.tsx";
import { EmptyList } from "../emptyList/emptyList";
import { ErrorPopup } from "../errorPopup";
import { HeadingSize } from "../heading/dto";
import { keycloak } from "../keycloak";
import { LayoutComponent } from "../layout";
import { PaginationComponent } from "../pagination";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { MenuItems } from "../sidebar/dto";
import { SuccessPopup } from "../successPopup";
import { CompanyToConfirmModal } from "./companyToConfirmModal";
import { CompanyFiltersComponent } from "./filters";
import { CompanyToConfirmListComponent } from "./list";
import { companyConfirm, findAllCompaniesToConfirm, setCompaniesToAddPageFilter, setCompanyConfirmRequestStatus, setOpenBulkConfirmModal } from "./slice";

export function CompanyToConfirmView() {
    const companyToConfirmState = useAppSelector(state => state.companyToConfirm)
    const companyState = useAppSelector(state => state.company)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(findAllCompaniesToConfirm(companyToConfirmState.companiesToAddFilters))
    }, [companyToConfirmState.companiesToAddFilters.page])

    useEffect(() => {
        if (companyToConfirmState.findAllCompaniesToConfirmStatus === 'successfully' && companyToConfirmState.findAllCompaniesToConfirmResponse !== undefined) {
            if (companyToConfirmState.findAllCompaniesToConfirmResponse.page >= companyToConfirmState.findAllCompaniesToConfirmResponse.totalPage) {
                dispatch(setCompaniesToAddPageFilter(0))
                dispatch(findAllCompaniesToConfirm({ ...companyToConfirmState.companiesToAddFilters, page: 0 }))
            }
        }
    }, [companyToConfirmState.findAllCompaniesToConfirmStatus])


    useEffect(() => {
        if (companyState.deleteCompanyStatus === 'successfully') {
            dispatch(setCompaniesToAddPageFilter(0))
            dispatch(findAllCompaniesToConfirm({ ...companyToConfirmState.companiesToAddFilters, page: 0 }))
        }
    }, [companyState.deleteCompanyStatus])

    return (
        <LayoutComponent
            breadcrumbItems={[(keycloak.hasRealmRole('financial-advisor') ? 'Aziende in attesa di approvazione' : 'Aziende da approvare')]}
            headingSize={HeadingSize.LG}
            menuItem={MenuItems.COMPANY_TO_ADD}
            headingLabel={(keycloak.hasRealmRole('financial-advisor') ? 'Aziende in attesa di approvazione' : 'Aziende da approvare')}
            headingButtons={
                (companyToConfirmState.companyConfirmRequest.length > 0) ?
                    [<ButtonComponent key='heading-button-company-to-confirm-approve' label={"Approva Aziende"}
                        onClick={() => dispatch(setOpenBulkConfirmModal(true))}
                        color={Colors.PRIMARY} variant={Variant.SOLID} size={Size.SM} iconStyle={IconStyle.OFF} />]
                    : []
            }>
            <ErrorPopup
                active={companyToConfirmState.companyConfirmStatus === 'failed'}
                close={() => dispatch(setCompanyConfirmRequestStatus('idle'))}
                message="Si è verificato un errore durante la conferma dell'azienda"
            />
            <SuccessPopup
                active={companyToConfirmState.companyConfirmStatus === 'successfully'}
                close={() => dispatch(setCompanyConfirmRequestStatus('idle'))}
                message="Azienda confermata"
            />
            <ErrorPopup
                active={companyState.deleteCompanyStatus === 'failed'}
                close={() => dispatch(setDeleteCompanyStatus('idle'))}
                message="Si è verificato un errore durante l'eliminazione dell'azienda"
            />
            <SuccessPopup
                active={companyState.deleteCompanyStatus === 'successfully'}
                close={() => dispatch(setDeleteCompanyStatus('idle'))}
                message="Azienda cancellata"
            />
            <CompanyFiltersComponent />
            <CompanyToConfirmListComponent />
            <CompanyToConfirmModal />
            {
                companyToConfirmState.findAllCompaniesToConfirmResponse !== undefined &&
                companyToConfirmState.findAllCompaniesToConfirmResponse?.total > 0 &&
                <PaginationComponent
                    page={companyToConfirmState.findAllCompaniesToConfirmResponse !== undefined ? companyToConfirmState.findAllCompaniesToConfirmResponse.page : 0}
                    count={companyToConfirmState.findAllCompaniesToConfirmResponse !== undefined ? companyToConfirmState.findAllCompaniesToConfirmResponse.totalPage : 0}
                    onChange={(page) => dispatch(setCompaniesToAddPageFilter(page))}
                />
            }
            {
                companyToConfirmState.findAllCompaniesToConfirmResponse !== undefined &&
                companyToConfirmState.findAllCompaniesToConfirmResponse?.total === 0 &&
                <EmptyList />
            }
            <ConfirmOrDenyModal
                open={companyToConfirmState.openBulkConfirmModal}
                handleClose={() => dispatch(setOpenBulkConfirmModal(false))}
                title={"Approva aziende"}
                description={"Sei sicuro di voler approvare le aziende selezionate?"}
                labelDeny={"Annulla"}
                labelConfirm={"Approva"}
                actionConfirm={() => {
                    dispatch(companyConfirm({ data: companyToConfirmState.companyConfirmRequest }))
                    dispatch(setOpenBulkConfirmModal(false))
                }}
            />
        </LayoutComponent>
    )
}