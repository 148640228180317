import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { CompanyStatusEnum, FindAllCompanies, FindAllCompaniesFilters } from "../company/dto"
import { NewCompanyService } from "../company/service"
import { PromiseStatuses } from "../utils"
import { CompanyConfirmDTO } from "./dto"
import { NewCompanyToConfirmService } from "./service"

interface CompanyToConfirmState {
    companiesToAddFilters: FindAllCompaniesFilters
    findAllCompaniesToConfirmResponse?: FindAllCompanies
    findAllCompaniesToConfirmStatus: PromiseStatuses
    companyToConfirmModal: boolean
    companyToConfirmSettedId: string
    companyConfirmRequest: CompanyConfirmDTO[]
    companyConfirmStatus: PromiseStatuses
    openBulkConfirmModal: boolean
}

const initialState: CompanyToConfirmState = {
    companiesToAddFilters: {
        businessName: '',
        accountant: '',
        operators: '',
        financialAdvisor: '',
        status: [CompanyStatusEnum.Pending],
        page: 0,
        itemsPerPage: 25
    },
    findAllCompaniesToConfirmStatus: 'idle',
    companyToConfirmModal: false,
    companyToConfirmSettedId: '',
    companyConfirmRequest: [],
    companyConfirmStatus: 'idle',
    openBulkConfirmModal: false
}

export const findAllCompaniesToConfirm = createAsyncThunk(
    'companyToConfirm/findAllCompaniesToConfirm',
    async (request: FindAllCompaniesFilters, thunkApi): Promise<FindAllCompanies> => {
        const companyService = NewCompanyService()

        return companyService.findAllCompanies(request)
    },
)

export const companyConfirm = createAsyncThunk(
    'companyToConfirm/companyConfirm',
    async (request: { data: CompanyConfirmDTO[] }, thunkApi): Promise<void> => {
        const companyToConfirmService = NewCompanyToConfirmService()

        return companyToConfirmService.confirmCompany(request.data)
    },
)

const companyToConfirmSlice = createSlice({
    name: 'companyToConfirm/slice',
    initialState,
    reducers: {
        setCompaniesToAddCompanyFilter: (state, action) => {
            state.companiesToAddFilters.businessName = action.payload
        },
        setCompaniesToAddFinancialAdvisorFilter: (state, action) => {
            state.companiesToAddFilters.financialAdvisor = action.payload
        },
        setCompaniesToAddPageFilter: (state, action) => {
            state.companiesToAddFilters.page = action.payload
        },
        setCompanyToConfirmModal: (state, action) => {
            state.companyToConfirmModal = action.payload
        },
        setCompanyToConfirmSettedId: (state, action) => {
            state.companyToConfirmSettedId = action.payload
        },
        setCompanyConfirmRequest: (state, action) => {
            state.companyConfirmRequest = action.payload
        },
        setCompanyConfirmRequestStatus: (state, action) => {
            state.companyConfirmStatus = action.payload
        },
        setOpenBulkConfirmModal: (state, action) => {
            state.openBulkConfirmModal = action.payload
        },
    },
    extraReducers(builder) {
        builder
            .addCase(findAllCompaniesToConfirm.pending, (state) => {
                state.findAllCompaniesToConfirmStatus = 'loading'
            })
            .addCase(findAllCompaniesToConfirm.fulfilled, (state, action) => {
                state.findAllCompaniesToConfirmStatus = 'successfully'
                state.findAllCompaniesToConfirmResponse = action.payload
            })
            .addCase(findAllCompaniesToConfirm.rejected, (state) => {
                state.findAllCompaniesToConfirmStatus = 'failed'
            })
            .addCase(companyConfirm.pending, (state) => {
                state.companyConfirmStatus = 'loading'
            })
            .addCase(companyConfirm.fulfilled, (state) => {
                state.companyConfirmStatus = 'successfully'
            })
            .addCase(companyConfirm.rejected, (state) => {
                state.companyConfirmStatus = 'failed'
            })
    },
})

export const {
    setCompaniesToAddCompanyFilter,
    setCompaniesToAddFinancialAdvisorFilter,
    setCompaniesToAddPageFilter,
    setCompanyToConfirmModal,
    setCompanyToConfirmSettedId,
    setCompanyConfirmRequest,
    setCompanyConfirmRequestStatus,
    setOpenBulkConfirmModal
} = companyToConfirmSlice.actions

export default companyToConfirmSlice.reducer