import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { NewObjectService } from "../objects/service"
import { PromiseStatuses } from "../utils"
import { CreateOperatorRequestDTO, EditOperatorDTO, FindAllOperatorFiltersDTO, FindAllOperators, OperatorResponseDTO } from "./dto"
import { NewOperatorService } from "./service"

interface OperatorState {
    findAllOperatorResponse?: FindAllOperators
    findOperatorResponse?: OperatorResponseDTO
    createOperatorRequest: CreateOperatorRequestDTO
    editOperatorRequest: EditOperatorDTO
    findAllOperatorFilters: FindAllOperatorFiltersDTO

    findAllOperatorStatus: PromiseStatuses
    createOperatorStatus: PromiseStatuses
    findOperatorStatus: PromiseStatuses
    editOperatorStatus: PromiseStatuses

    editOperatorFindAvatarStatus: PromiseStatuses

    deleteOperatorStatus: PromiseStatuses
    openOperatorModal: boolean
    operatorSettedId: string
    editOperatorAvatar: string
    openDeleteOperatorModal: boolean
    deleteOperatorId: string
    errors: {
        createOperator: {
            email?: string
        }
    }
}

const initialState: OperatorState = {
    findAllOperatorResponse: undefined,
    findOperatorResponse: undefined,
    createOperatorRequest: {
        name: '',
        surname: '',
        email: '',
        financialAdvisorId: '',
        description: '',
        activityNotifications: true,
        operationsNotifications: true,
    },
    editOperatorRequest: {},
    findAllOperatorFilters: { itemsPerPage: 25, page: 0, operator: '', financialAdvisor: '' },
    findAllOperatorStatus: 'idle',
    createOperatorStatus: 'idle',
    findOperatorStatus: 'idle',
    editOperatorStatus: 'idle',
    editOperatorFindAvatarStatus: 'idle',
    deleteOperatorStatus: 'idle',
    openOperatorModal: false,
    operatorSettedId: '',
    editOperatorAvatar: '',
    openDeleteOperatorModal: false,
    deleteOperatorId: '',
    errors: {
        createOperator: {
            email: undefined
        }
    }
}

export const findAllOperators = createAsyncThunk(
    'operator/findAllOperators',
    async (filters: FindAllOperatorFiltersDTO, thunkApi): Promise<FindAllOperators> => {
        const operatorService = NewOperatorService()

        return operatorService.findAllOperators(filters)
    }
)

export const createOperator = createAsyncThunk(
    'operator/createOperator',
    async (request: FormData, thunkApi): Promise<void> => {
        const operatorService = NewOperatorService()

        return operatorService.createOperator(request)
            .catch((error) => {
                throw (thunkApi.rejectWithValue(error.response.data.errors))
            })
    },
)

export const findOperator = createAsyncThunk(
    'operator/findOperator',
    async (request: string, thunkApi): Promise<OperatorResponseDTO> => {
        const operatorService = NewOperatorService()

        return operatorService.findOperator(request).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const editOperator = createAsyncThunk(
    'operator/editOperator',
    async (request: { id: string, data: FormData }, thunkApi): Promise<void> => {
        const operatorService = NewOperatorService()

        return operatorService.editOperator(request.id, request.data).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const findOperatorAvatarById = createAsyncThunk(
    'operator/findOperatorAvatarById',
    async (request: string, thunkApi): Promise<string> => {
        const objectService = NewObjectService()

        return objectService.findFileById(request).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const deleteOperator = createAsyncThunk(
    'operator/deleteOperator',
    async (id: string, thunkApi): Promise<void> => {
        const operatorService = NewOperatorService()

        return operatorService.deleteOperator(id).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

const accountantSlice = createSlice({
    name: 'financialAdvisor/slice',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(createOperator.pending, (state) => {
                state.createOperatorStatus = 'loading'
            })
            .addCase(createOperator.fulfilled, (state) => {
                state.createOperatorStatus = 'successfully'
            })
            .addCase(createOperator.rejected, (state, action) => {
                //@ts-ignore
                const errorEmail = action.payload.find(error => error.message.includes('Error while creating user for operator') && error.message.includes('User with username') && error.message.includes('already exists'))
                if (errorEmail === undefined) {
                    state.createOperatorStatus = 'failed'
                } else {
                    state.createOperatorStatus = 'idle'
                }
                state.errors.createOperator.email = errorEmail
            })
            .addCase(findAllOperators.pending, (state) => {
                state.findAllOperatorStatus = 'loading'
            })
            .addCase(findAllOperators.fulfilled, (state, action) => {
                state.findAllOperatorStatus = 'successfully'
                state.findAllOperatorResponse = action.payload
            })
            .addCase(findAllOperators.rejected, (state) => {
                state.findAllOperatorStatus = 'failed'
            })
            .addCase(findOperator.pending, (state) => {
                state.findOperatorStatus = 'loading'
            })
            .addCase(findOperator.fulfilled, (state, action) => {
                state.findOperatorStatus = 'successfully'
                state.findOperatorResponse = action.payload
            })
            .addCase(findOperator.rejected, (state) => {
                state.findOperatorStatus = 'failed'
            })
            .addCase(editOperator.pending, (state) => {
                state.editOperatorStatus = 'loading'
            })
            .addCase(editOperator.fulfilled, (state, action) => {
                state.editOperatorStatus = 'successfully'
            })
            .addCase(editOperator.rejected, (state) => {
                state.editOperatorStatus = 'failed'
            })
            .addCase(findOperatorAvatarById.pending, (state) => {
                state.editOperatorFindAvatarStatus = 'loading'
            })
            .addCase(findOperatorAvatarById.fulfilled, (state, action) => {
                state.editOperatorFindAvatarStatus = 'successfully'
                state.editOperatorAvatar = action.payload
            })
            .addCase(findOperatorAvatarById.rejected, (state) => {
                state.editOperatorFindAvatarStatus = 'failed'
            })
            .addCase(deleteOperator.pending, (state) => {
                state.deleteOperatorStatus = 'loading'
            })
            .addCase(deleteOperator.fulfilled, (state, action) => {
                state.deleteOperatorStatus = 'successfully'
            })
            .addCase(deleteOperator.rejected, (state) => {
                state.deleteOperatorStatus = 'failed'
            })
    },
    reducers: {
        setOperatorFilter: (state, action) => {
            state.findAllOperatorFilters.operator = action.payload
        },
        setPageFilter: (state, action) => {
            state.findAllOperatorFilters.page = action.payload
        },
        setFinancialAdvisorFilter: (state, action) => {
            state.findAllOperatorFilters.financialAdvisor = action.payload
        },
        resetErrorCreateOperatorEmail: (state) => {
            state.errors.createOperator.email = undefined
        },
        setOpenOperatorModal: (state, action) => {
            state.openOperatorModal = action.payload
        },
        setCreateOperatorName: (state, action) => {
            state.createOperatorRequest.name = action.payload
        },
        setCreateOperatorSurname: (state, action) => {
            state.createOperatorRequest.surname = action.payload
        },
        setCreateOperatorEmail: (state, action) => {
            state.createOperatorRequest.email = action.payload
        },
        setCreateOperatorDescription: (state, action) => {
            state.createOperatorRequest.description = action.payload
        },
        setCreateOperatorPhoneNumber: (state, action) => {
            state.createOperatorRequest.phoneNumber = action.payload
        },
        setCreateOperatorFinancialAdvisorId: (state, action) => {
            state.createOperatorRequest.financialAdvisorId = action.payload
        },
        setCreateOperatorStatus: (state, action) => {
            state.createOperatorStatus = action.payload
        },
        resetCreateOperatorRequest: (state) => {
            state.createOperatorRequest = {
                name: '',
                surname: '',
                email: '',
                financialAdvisorId: '',
                description: '',
                activityNotifications: true,
                operationsNotifications: true,
            }
        },
        setFindAllOperatorStatus: (state, action) => {
            state.findAllOperatorStatus = action.payload
        },
        setOperatorSettedId: (state, action) => {
            state.operatorSettedId = action.payload
        },
        setEditOperatorName: (state, action) => {
            state.editOperatorRequest.name = action.payload
        },
        setEditOperatorSurname: (state, action) => {
            state.editOperatorRequest.surname = action.payload
        },
        setEditOperatorPhoneNumber: (state, action) => {
            state.editOperatorRequest.phoneNumber = action.payload
        },
        setEditOperatorDescription: (state, action) => {
            state.editOperatorRequest.description = action.payload
        },
        setEditOperatorStatus: (state, action) => {
            state.editOperatorStatus = action.payload
        },
        resetEditOperatorRequest: (state) => {
            state.editOperatorRequest = {}
        },
        setEditOperatorFindAvatarStatus: (state, action) => {
            state.editOperatorFindAvatarStatus = action.payload
        },
        setOpenDeleteOperatorModal: (state, action) => {
            state.openDeleteOperatorModal = action.payload
        },
        setDeleteOperatorId: (state, action) => {
            state.deleteOperatorId = action.payload
        },
        setDeleteOperatorStatus: (state, action) => {
            state.deleteOperatorStatus = action.payload
        }
    },
})

export const {
    resetErrorCreateOperatorEmail,
    setOpenOperatorModal,
    setCreateOperatorName,
    setCreateOperatorSurname,
    setCreateOperatorEmail,
    setCreateOperatorDescription,
    setCreateOperatorPhoneNumber,
    setCreateOperatorFinancialAdvisorId,
    setCreateOperatorStatus,
    resetCreateOperatorRequest,
    setFindAllOperatorStatus,
    setOperatorSettedId,
    setEditOperatorName,
    setEditOperatorSurname,
    setEditOperatorPhoneNumber,
    setEditOperatorDescription,
    setEditOperatorStatus,
    resetEditOperatorRequest,
    setEditOperatorFindAvatarStatus,
    setOpenDeleteOperatorModal,
    setDeleteOperatorId,
    setDeleteOperatorStatus,
    setFinancialAdvisorFilter,
    setOperatorFilter,
    setPageFilter
} = accountantSlice.actions

export default accountantSlice.reducer