import { Drawer } from "@mui/material";
import { ReactElement, ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { findAccountant, findAdminAccountantAvatarById } from "../accountant/slice";
import { AvatarComponent } from "../avatar";
import { AvatarSize, AvatarType } from "../avatar/dto";
import { CompanyStatusEnum } from "../company/dto";
import { findAllCompaniesToConfirm } from "../companyToConfirm/slice";
import { findFinancialAdvisor } from "../financialAdvisor/slice";
import { AlertOctagonIcon } from "../icons/alertOctacong";
import { AlertOctagonWithDotIcon } from "../icons/alertOctacongWithDot";
import { BarChartIcon } from "../icons/barChart";
import { BriefcaseIcon } from "../icons/briefcase";
import { BuildingIcon } from "../icons/building";
import { LifeBuoyIcon } from "../icons/lifeBuoy";
import { Logo } from "../icons/logo";
import { LogoExtendend } from "../icons/logoExtended";
import { PersonalcardIcon } from "../icons/personalcard";
import { TaskSquareIcon } from "../icons/taskSquare";
import { keycloak } from "../keycloak";
import { PillComponent } from "../pills";
import { PillsColor, PillsEmphasis, PillsOutline, PillsSize } from "../pills/dto";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SpinnerComponent } from "../spinner";
import colors from '../utils/index.module.scss';
import { MenuItems } from "./dto";
import { SidebarRow } from "./sidebarRow";

interface Props {
    menuItem?: MenuItems
}

export function SidebarComponent(props: Props) {
    const { menuItem } = props

    const sidebarState = useAppSelector(state => state.sidebar)
    const companyState = useAppSelector(state => state.companyToConfirm)
    const authState = useAppSelector(state => state.auth)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const accountantState = useAppSelector(state => state.accountant)

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('financial-advisor')) {
            dispatch(findAllCompaniesToConfirm({
                businessName: '',
                accountant: '',
                financialAdvisor: '',
                operators: '',
                status: [CompanyStatusEnum.Pending],
                page: 0,
                itemsPerPage: 0
            }))
        }

        if (keycloak.hasRealmRole('financial-advisor')) {
            dispatch(findAccountant(authState.findMeResponse?.accountantId !== null && authState.findMeResponse?.accountantId !== undefined ? authState.findMeResponse?.accountantId?.toString() : ''))
        }

        if (keycloak.hasRealmRole('operator')) {
            dispatch(findFinancialAdvisor(authState.findMeResponse?.financialAdvisorId !== null && authState.findMeResponse?.financialAdvisorId !== undefined ? authState.findMeResponse?.financialAdvisorId?.toString() : ''))
        }
    }, [])

    useEffect(() => {
        if (financialAdvisorState.findFinancialAdvisorStatus === 'successfully' && financialAdvisorState.findFinancialAdvisorResponse && keycloak.hasRealmRole('operator'))
            dispatch(findAccountant(financialAdvisorState.findFinancialAdvisorResponse.accountantId !== null && financialAdvisorState.findFinancialAdvisorResponse.accountantId !== undefined ? financialAdvisorState.findFinancialAdvisorResponse.accountantId.toString() : ''))
    }, [financialAdvisorState.findFinancialAdvisorStatus])

    useEffect(() => {
        if (accountantState.findAccountantStatus === 'successfully' && accountantState.findAccountantResponse !== undefined && keycloak.hasRealmRole('financial-advisor'))
            dispatch(findAdminAccountantAvatarById(accountantState.findAccountantResponse.avatarObjectId))
    }, [accountantState.findAccountantStatus])

    if (
        companyState.findAllCompaniesToConfirmStatus === 'loading' ||
        accountantState.findAccountantStatus === 'loading' ||
        accountantState.editAdminAccountantFindAvatarStatus === 'loading'
    ) {
        return (<div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}><SpinnerComponent size={"small"} /></div>)
    }

    const labelMap = new Map<MenuItems, string>([
        [MenuItems.DASHBOARD, 'Dashboard'],
        [MenuItems.COMPANY, 'Aziende'],
        [MenuItems.FINANCIAL_ADVISOR, 'Commercialisti'],
        [MenuItems.ACCOUNTANT, 'Contabili'],
        [MenuItems.OPERATOR, 'Collaboratori'],
        [MenuItems.ACTIVITY, 'Attività'],
        [MenuItems.SUPPORTO, 'Supporto'],
        [MenuItems.COMPANY_TO_ADD, (keycloak.hasRealmRole('financial-advisor') ? 'Aziende in attesa di approvazione' : 'Aziende da approvare')],
    ])

    const uriMap = new Map<MenuItems, string>([
        [MenuItems.DASHBOARD, '/'],
        [MenuItems.COMPANY, '/company'],
        [MenuItems.FINANCIAL_ADVISOR, '/financial-advisor'],
        [MenuItems.ACCOUNTANT, '/accountant'],
        [MenuItems.OPERATOR, '/operator'],
        [MenuItems.ACTIVITY, '/activity'],
        [MenuItems.SUPPORTO, '/supp'],
        [MenuItems.COMPANY_TO_ADD, '/company-to-add']
    ])

    const iconMap = new Map<string, ReactElement>([
        [MenuItems.DASHBOARD, <BarChartIcon colorBase={""} />],
        [MenuItems.COMPANY, <BuildingIcon colorBase={""} />],
        [MenuItems.FINANCIAL_ADVISOR, <BriefcaseIcon colorBase={""} />],
        [MenuItems.ACCOUNTANT, <PersonalcardIcon colorBase={""} />],
        [MenuItems.OPERATOR, <BriefcaseIcon colorBase={""} />],
        [MenuItems.ACTIVITY, <TaskSquareIcon colorBase={""} />],
        [MenuItems.SUPPORTO, <LifeBuoyIcon colorBase={""} />],
        [MenuItems.COMPANY_TO_ADD, companyState.findAllCompaniesToConfirmResponse !== undefined && companyState.findAllCompaniesToConfirmResponse.total > 0 && !sidebarState.open ? <AlertOctagonWithDotIcon colorBase={""} /> : <AlertOctagonIcon colorBase={""} />]
    ])

    const endIconMap = new Map<string, ReactNode>([
        [MenuItems.COMPANY_TO_ADD, companyState.findAllCompaniesToConfirmResponse !== undefined && companyState.findAllCompaniesToConfirmResponse.total > 0 ? <PillComponent label={companyState.findAllCompaniesToConfirmResponse.total.toString()} size={PillsSize.XS} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.TRUE} /> : <></>]
    ])

    return (
        <Drawer
            variant="permanent"
            open={sidebarState.open}
            PaperProps={{
                sx: {
                    position: 'fixed',
                    flexShrink: 0,
                    width: sidebarState.open ? 280 : 72,
                    backgroundColor: colors.primary50,
                    padding: '24px 16px',
                    display: "flex",
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    transition: '.2s width ease-in-out',
                    border: 0,
                    overflowX: 'hidden',
                    maxHeight: '100vh'
                }
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    height: '100%',
                    width: '100%'
                }}
            >
                <div style={{ marginBottom: '24px' }}>
                    {sidebarState.open ? <LogoExtendend /> : <Logo />}
                </div>
                <div style={{
                    gap: '4px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    padding: '0px',
                    width: '100%'
                }}>
                    {sidebarState.menuItems.map((item) => (
                        <SidebarRow
                            key={item}
                            open={sidebarState.open}
                            icon={iconMap.get(item)}
                            label={labelMap.get(item)}
                            active={menuItem === item}
                            endIcon={endIconMap.get(item)}
                            onClick={() => {
                                let uri = uriMap.get(item)
                                navigate(uri !== undefined ? uri : '')
                            }}
                        />
                    ))}
                </div>
            </div>
            <div
                style={{
                    gap: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    padding: '0px',
                    width: '100%',
                    marginTop: '24px'
                }}
            >
                {
                    (keycloak.hasRealmRole('financial-advisor') || keycloak.hasRealmRole('operator')) && sidebarState.open &&
                    (
                        financialAdvisorState.findFinancialAdvisorStatus === 'loading' ? (
                            <div
                                style={{
                                    background: colors.white,
                                    border: '1px solid ' + colors.primary200,
                                    borderRadius: '12px',
                                    padding: '4px 16px 12px 16px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '12px',
                                    width: '100%'
                                }}
                            >
                                <SpinnerComponent size={"small"} />
                            </div>
                        ) : (
                            <div
                                style={{
                                    background: colors.white,
                                    border: '1px solid ' + colors.primary200,
                                    borderRadius: '12px',
                                    padding: '4px 16px 12px 16px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '12px',
                                    width: '100%'
                                }}
                            >
                                <span
                                    className="text text--md typography--medium typography-neutral-500"
                                    style={{
                                        borderBottom: '1px solid ' + colors.primary100,
                                        padding: '8px 0px 8px 0px'
                                    }}
                                >
                                    Contabile di riferimento
                                </span>
                                <AvatarComponent
                                    type={AvatarType.USER}
                                    size={AvatarSize.MD}
                                    src={accountantState.editAdminAccountantAvatar}
                                    name={accountantState.findAccountantResponse?.name + ' ' + accountantState.findAccountantResponse?.surname}
                                    subLabel={accountantState.findAccountantResponse?.email}
                                    phoneNumber={accountantState.findAccountantResponse?.phoneNumber}
                                />
                            </div>
                        )
                    )
                }
                <a rel="noreferrer" target="_blank" style={{ textDecoration: 'unset', width: '100%' }} href="mailto:info@iltuocontabile.it?subject=[Il Tuo Contabile] Richiesta supporto&body=Gentile team di supporto, scrivo per segnalarvi che…">
                    <SidebarRow
                        open={sidebarState.open}
                        icon={iconMap.get(MenuItems.SUPPORTO)}
                        label={labelMap.get(MenuItems.SUPPORTO)}
                        active={menuItem === MenuItems.SUPPORTO}
                    />
                </a>
            </div>
        </Drawer >
    )
}