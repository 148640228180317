import { useEffect, useState } from "react";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { EmptyList } from "../emptyList/emptyList";
import { ErrorPopup } from "../errorPopup";
import { HeadingSize } from "../heading/dto";
import { LayoutComponent } from "../layout";
import { findAllOperators, setDeleteOperatorStatus, setOpenOperatorModal, setPageFilter } from "../operator/slice";
import { PaginationComponent } from "../pagination";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { MenuItems } from "../sidebar/dto";
import { SuccessPopup } from "../successPopup";
import { PromiseStatuses } from "../utils";
import { OperatorFiltersComponent } from "./filters";
import { OperatorListComponent } from "./list";
import { NewOperatorModal } from "./newOperatorModal";

export function OperatorView() {
    const dispatch = useAppDispatch()
    const operatorState = useAppSelector(state => state.operator)

    const [operatorStatus, setOperatorStatus] = useState<PromiseStatuses>('idle')

    useEffect(() => {
        dispatch(findAllOperators(operatorState.findAllOperatorFilters))
    }, [operatorState.findAllOperatorFilters.page])

    useEffect(() => {
        if (operatorState.findAllOperatorStatus === 'successfully' && operatorState.findAllOperatorResponse !== undefined) {
            if (operatorState.findAllOperatorResponse.page >= operatorState.findAllOperatorResponse.totalPage) {
                dispatch(setPageFilter(0))
                dispatch(findAllOperators({ ...operatorState.findAllOperatorFilters, page: 0 }))
            }
        }
    }, [operatorState.findAllOperatorStatus])

    useEffect(() => {
        if (operatorState.createOperatorStatus === "successfully") {
            setOperatorStatus("successfully")
        } else if (operatorState.createOperatorStatus === "failed") {
            setOperatorStatus("failed")
        }
    }, [operatorState.createOperatorStatus])

    return (
        <LayoutComponent
            menuItem={MenuItems.OPERATOR}
            headingSize={HeadingSize.LG}
            headingLabel={"Collaboratori"}
            headingButtons={[
                <ButtonComponent label={"Aggiungi Collaboratore"} onClick={() => dispatch(setOpenOperatorModal(true))} color={Colors.PRIMARY} variant={Variant.SOLID} size={Size.MD} iconStyle={IconStyle.OFF} key={'new-operator-button'} />
            ]}
            breadcrumbItems={["Collaboratori"]}
        >
            <SuccessPopup
                active={operatorStatus === 'successfully'}
                close={() => setOperatorStatus('idle')}
                message="Collaboratore creato correttamente"
            />
            <ErrorPopup
                active={operatorStatus === 'failed'}
                close={() => setOperatorStatus('idle')}
                message="Si è verificato un errore con la creazione del collaboratore"
            />
            <ErrorPopup
                active={operatorState.deleteOperatorStatus === 'failed'}
                close={() => dispatch(setDeleteOperatorStatus('idle'))}
                message="Si è verificato un errore durante l'eliminazione del collaboratore"
            />
            <SuccessPopup
                active={operatorState.deleteOperatorStatus === 'successfully'}
                close={() => dispatch(setDeleteOperatorStatus('idle'))}
                message="Collaboratore eliminato"
            />
            <OperatorFiltersComponent />
            <OperatorListComponent />
            <NewOperatorModal />
            {
                operatorState.findAllOperatorResponse !== undefined &&
                operatorState.findAllOperatorResponse.total > 0 &&
                <PaginationComponent
                    page={operatorState.findAllOperatorResponse !== undefined ? operatorState.findAllOperatorResponse.page : 0}
                    count={operatorState.findAllOperatorResponse !== undefined ? operatorState.findAllOperatorResponse.totalPage : 0}
                    onChange={(page) => dispatch(setPageFilter(page))}
                />
            }
            {
                operatorState.findAllOperatorResponse !== undefined &&
                operatorState.findAllOperatorResponse.total === 0 &&
                <EmptyList />
            }
        </LayoutComponent>
    )
}