import { Box } from "@mui/material";
import { useState } from "react";
import colors from '../utils/index.module.scss';
import { CheckboxSize } from "./dto";

interface Props {
    checkboxStyle: {
        dim: string;
        borderRadius: string;
        outerDim: string;
        outerRadius: string;
    } | undefined
    focused: boolean
    size: CheckboxSize
}

export function IndeterminateCheckboxIcon(props: Props) {
    const { checkboxStyle } = props
    const { focused } = props
    const { size } = props

    const [hover, setHover] = useState<Boolean>(false)

    return (
        <div
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            style={{
                width: checkboxStyle?.outerDim,
                height: checkboxStyle?.outerDim,
                backgroundColor: focused ? colors.primary50 : '',
                borderRadius: checkboxStyle?.outerRadius,
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center'
            }}>
            <Box
                component='div'
                sx={{
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 2,
                    height: checkboxStyle?.dim,
                    width: checkboxStyle?.dim,
                    borderRadius: checkboxStyle?.borderRadius,
                    border: '1px solid ' + colors.primary500,
                    backgroundColor: focused ? colors.primary500 : colors.white,
                    "&:hover": {
                        backgroundColor: colors.primary500
                    }
                }}
            >
                {
                    size === CheckboxSize.MD ? (
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.33334 8H12.6667" stroke={focused || hover ? colors.white : colors.primary500} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    ) : (
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.5 6H9.5" stroke={focused || hover ? colors.white : colors.primary500} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    )
                }
            </Box>
        </div>
    )
}