import { AvatarComponent } from "../avatar";
import { AvatarSize, AvatarType } from "../avatar/dto";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { FileInputComponent } from "../fileInput";
import { FormGroup } from "../formGroup";
import { HeadingComponent } from "../heading";
import { HeadingSize } from "../heading/dto";
import { MailIcon } from "../icons/mail";
import { SmartphoneIcon } from "../icons/smartphone";
import { keycloak } from "../keycloak";
import { LayoutComponent } from "../layout";
import { TextInput } from "../textInput";
import { EditAccountantView } from "./editAccountant";
import { EditFinancialAdvisorView } from "./editFinancialAdvisor";
import { EditOperatorView } from "./editOperator";

export function ProfileView() {
    if (keycloak.hasRealmRole('financial-advisor')) {
        return <EditFinancialAdvisorView />
    }

    if (keycloak.hasRealmRole('admin-accountant') || keycloak.hasRealmRole('accountant')) {
        return <EditAccountantView />
    }

    if (keycloak.hasRealmRole('operator')) {
        return <EditOperatorView />
    }

    return (
        <LayoutComponent
            headingLabel={"Impostazioni"}
            headingButtons={[<ButtonComponent key='heading-button-update-profile' label={"Aggiorna"} onClick={() => { }} color={Colors.PRIMARY} variant={Variant.SOLID} size={Size.SM} iconStyle={IconStyle.OFF} />]}
            breadcrumbItems={['Impostazioni']}
            headingSize={HeadingSize.LG}
        >
            <HeadingComponent label={"Profilo utente"} buttons={[]} size={HeadingSize.MD} />
            <FormGroup label={"Nome"}
                inputs={[
                    <TextInput id={"profile-edit-name"} key={"profile-edit-name"} type={"text"} placeholder={"nome"} />,
                    <TextInput id={"profile-edit-surname"} key={"profile-edit-surname"} type={"text"} placeholder={"cognome"} />
                ]}
                style={"row"}
            />
            <FormGroup label={"Email"}
                inputs={[
                    <TextInput startIcon={<MailIcon colorBase="" />} id={"profile-edit-email"} key={"profile-edit-email"} type={"text"} placeholder={"email"} />
                ]}
                style={"row"}
            />
            <FormGroup label={"Telefono"}
                inputs={[
                    <TextInput startIcon={<SmartphoneIcon colorBase="" />} id={"profile-edit-phone"} key={"profile-edit-phone"} type={"text"} placeholder={"telefono"} />
                ]}
                style={"row"}
            />
            <FormGroup label={"Avatar"} labelDescription="Verrà visualizzata sul vostro profilo"
                inputs={[
                    <div key={"profile-edit-avatar-upload"} className="d-flex align-items-center justify-content-center gap-2">
                        <AvatarComponent src="" key={"profile-edit-avatar"} type={AvatarType.SINGLE} size={AvatarSize.XXL} />
                        <FileInputComponent id={"profile-edit-upload"} key={"profile-edit-upload"} />
                    </div>
                ]}
                style={"row"}
            />
            <FormGroup label={"Bio"} labelDescription="Scrivi una breve introduzione"
                inputs={[
                    <TextInput label="Descrizione" id={"profile-edit-bio"} key={"profile-edit-bio"} type={"text"} multiline placeholder={"Un po' di informazioni su di te"} infoText="275 caratteri rimasti" />
                ]}
                style={"row"}
            />
        </LayoutComponent>
    )
}