import axios from "axios";
import { keycloak } from "../keycloak";
import { AccountingSchemeEnum, AnnualForeignInvoicesEnum, AtecosEnum, BankEnum, CompanyCreationDTO, JournalEnum, PeriodicityEnum, numberToAtecoMap, numberToBankMap, numberToForeignMap, numberToJournalMap } from "./dto";
import { CompanyQuotationService } from "./service";

export class CompanyQuotationServiceImpl implements CompanyQuotationService {

    public saveCompany(data: CompanyCreationDTO): Promise<void> {
        let url: string = "/api/companies"
        return axios({
            url: url,
            method: "POST",
            data: data,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public calculatePrice(company: CompanyCreationDTO): number {
        let price = 0

        if (company.accountingScheme === AccountingSchemeEnum.Simplified ||
            (
                company.activityCategoryIds.length === 1 &&
                company.accountingScheme === AccountingSchemeEnum.Ordinary &&
                company.activityCategoryIds.includes(1)
            )) {
            price += 500
            if (company.periodicity === PeriodicityEnum.Quarterly) {
                price += 0
            } else {
                price += 100
            }

            if (company.professionistWithFund) {
                price += 150
            } else {
                price += 0
            }
        } else {
            if (numberToJournalMap(company.journal) === JournalEnum.JOURNAL_0) {
                price += 850
            } else if (numberToJournalMap(company.journal) === JournalEnum.JOURNAL_1) {
                price += 950
            } else if (numberToJournalMap(company.journal) === JournalEnum.JOURNAL_2) {
                price += 1150
            } else if (numberToJournalMap(company.journal) === JournalEnum.JOURNAL_3) {
                price += 1350
            } else if (numberToJournalMap(company.journal) === JournalEnum.JOURNAL_4) {
                price += 1500
            } else if (numberToJournalMap(company.journal) === JournalEnum.JOURNAL_5) {
                price += 1650
            } else {
                price += 1800
            }

            if (company.periodicity === PeriodicityEnum.Quarterly) {
                price += 0
            } else {
                price += 100
            }

            if (numberToBankMap(company.banksNumber) === BankEnum.BANK_0) {
                price += 0
            } else if (numberToBankMap(company.banksNumber) === BankEnum.BANK_1) {
                price += 50
            } else if (numberToBankMap(company.banksNumber) === BankEnum.BANK_2) {
                price += 100
            }
        }

        if (numberToForeignMap(company.annualForeignInvoices) === AnnualForeignInvoicesEnum.FOREIGN_EMPTY) {
            price += 0
        } else if (numberToForeignMap(company.annualForeignInvoices) === AnnualForeignInvoicesEnum.FOREIGN_0) {
            price += 0
        } else if (numberToForeignMap(company.annualForeignInvoices) === AnnualForeignInvoicesEnum.FOREIGN_1) {
            price += 50
        } else if (numberToForeignMap(company.annualForeignInvoices) === AnnualForeignInvoicesEnum.FOREIGN_2) {
            price += 100
        }

        if (numberToAtecoMap(company.atecoCodesNumber) === AtecosEnum.ATECOS_0) {
            price += 0
        } else if (numberToAtecoMap(company.atecoCodesNumber) === AtecosEnum.ATECOS_1) {
            price += 100
        } else if (numberToAtecoMap(company.atecoCodesNumber) === AtecosEnum.ATECOS_2) {
            price += 250
        }

        if (company.specialVatSchemes) {
            price += 100
        } else {
            price += 0
        }

        if (company.activityCategoryIds.length === 1 &&
            company.accountingScheme === AccountingSchemeEnum.Ordinary &&
            company.activityCategoryIds.includes(2)) {
            price = price - (price / 100 * 15)
        }

        return price
    }
}
