import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { NewObjectService } from "../objects/service"
import { PromiseStatuses } from "../utils"
import { AccountantResponseDTO, CreateAccountantDTO, EditAccountantDTO, FindAllAccountantFiltersDTO, FindAllAccountants } from "./dto"
import { NewAccountantService } from "./service"

interface AccountantState {
    findAllAccountantResponse?: FindAllAccountants
    findAllAdminAccountantResponse?: FindAllAccountants
    findAllAccountantsWithoutPaginationResponse?: FindAllAccountants
    findAccountantResponse?: AccountantResponseDTO
    findAdminAccountantResponse?: AccountantResponseDTO
    createAccountantRequest: CreateAccountantDTO
    editAccountantRequest: EditAccountantDTO
    findAllAccountantFilters: FindAllAccountantFiltersDTO
    findAllAccountantStatus: PromiseStatuses
    findAllAdminAccountantStatus: PromiseStatuses
    createAccountantStatus: PromiseStatuses
    findAccountantStatus: PromiseStatuses
    findAdminAccountantStatus: PromiseStatuses
    editAccountantStatus: PromiseStatuses
    editAccountantFindAvatarStatus: PromiseStatuses
    editAdminAccountantFindAvatarStatus: PromiseStatuses
    findAllAdminAccountantsAvatarStatus: PromiseStatuses
    findAllAccountantsWithoutPaginationStatus: PromiseStatuses
    deleteAccountantStatus: PromiseStatuses
    openAccountantModal: boolean
    accountantSettedId: string
    editAccountantAvatar: string
    editAdminAccountantAvatar: string
    adminAccountantsListAvatarResponse: { userId: string, objectId: string | null }[]
    openDeleteAccountantModal: boolean
    deleteAccountantId: string
    errors: {
        createAccountant: {
            email?: string
        }
    }
}

const initialState: AccountantState = {
    findAllAccountantResponse: undefined,
    findAccountantResponse: undefined,
    createAccountantRequest: {
        name: '',
        surname: '',
        isAdmin: false,
        email: '',
        adminAccountantId: '',
        description: '',
        activityNotifications: true,
        operationsNotifications: true,
    },
    editAccountantRequest: {},
    findAllAccountantFilters: { itemsPerPage: 25, page: 0, accountant: '', financialAdvisor: '', isAdmin: '' },
    findAllAccountantStatus: 'idle',
    findAllAdminAccountantStatus: 'idle',
    createAccountantStatus: 'idle',
    findAccountantStatus: 'idle',
    editAccountantStatus: 'idle',
    editAccountantFindAvatarStatus: 'idle',
    findAllAdminAccountantsAvatarStatus: 'idle',
    findAllAccountantsWithoutPaginationStatus: 'idle',
    openAccountantModal: false,
    accountantSettedId: '',
    editAccountantAvatar: '',
    adminAccountantsListAvatarResponse: [],
    deleteAccountantStatus: 'idle',
    openDeleteAccountantModal: false,
    deleteAccountantId: '',
    findAdminAccountantStatus: 'idle',
    editAdminAccountantAvatar: '',
    editAdminAccountantFindAvatarStatus: 'idle',
    errors: {
        createAccountant: {
            email: undefined
        }
    }
}

export const findAllAccountant = createAsyncThunk(
    'accountant/findAllAccountant',
    async (findAllAccountantFilters: FindAllAccountantFiltersDTO, thunkApi): Promise<FindAllAccountants> => {
        const accountantService = NewAccountantService()

        return accountantService.findAllAccountant(findAllAccountantFilters).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const findAllAdminAccountant = createAsyncThunk(
    'accountant/findAllAdminAccountant',
    async (_, thunkApi): Promise<FindAllAccountants> => {
        const accountantService = NewAccountantService()

        return accountantService.findAllAccountant({ itemsPerPage: 0, page: 0, accountant: '', financialAdvisor: '', isAdmin: 'true' }).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const findAllAccountantWithoutPagination = createAsyncThunk(
    'accountant/findAllAccountantWithoutPagination',
    async (_, thunkApi): Promise<FindAllAccountants> => {
        const accountantService = NewAccountantService()

        return accountantService.findAllAccountant({ itemsPerPage: 0, page: 0, accountant: '', financialAdvisor: '', isAdmin: '' }).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const createAccountant = createAsyncThunk(
    'accountant/createAccountant',
    async (request: FormData, thunkApi): Promise<void> => {
        const accountantService = NewAccountantService()

        return accountantService.createAccountant(request).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error.response.data.errors))
        })
    },
)

export const findAccountant = createAsyncThunk(
    'accountant/findAccountant',
    async (request: string, thunkApi): Promise<AccountantResponseDTO> => {
        const accountantService = NewAccountantService()

        return accountantService.findAccountant(request).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const findAdminAccountant = createAsyncThunk(
    'accountant/findAdminAccountant',
    async (request: string, thunkApi): Promise<AccountantResponseDTO> => {
        const accountantService = NewAccountantService()

        return accountantService.findAccountant(request).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const editAccountant = createAsyncThunk(
    'accountant/editAccountant',
    async (request: { id: string, data: FormData }, thunkApi): Promise<void> => {
        const accountantService = NewAccountantService()

        return accountantService.editAccountant(request.id, request.data).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const findAccountantAvatarById = createAsyncThunk(
    'accountant/findAccountantAvatarById',
    async (request: string, thunkApi): Promise<string> => {
        const objectService = NewObjectService()

        return objectService.findFileById(request).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const findAdminAccountantAvatarById = createAsyncThunk(
    'accountant/findAdminAccountantAvatarById',
    async (request: string, thunkApi): Promise<string> => {
        const objectService = NewObjectService()

        return objectService.findFileById(request).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const findAccountantsListAvatarsById = createAsyncThunk(
    'accountant/findAccountantsListAvatarsById',
    async (request: { objectId: string | null, userId: string }[], thunkApi): Promise<{ userId: string, objectId: string | null }[]> => {
        let promises: Promise<{ userId: string, objectId: string | null }>[] = []
        promises = request.map(async (user) => {
            if (user.objectId !== null) {
                const objectService = NewObjectService()

                return objectService.findFileByIdWithUserId(user.objectId, user.userId)
            } else return Promise.resolve({ userId: user.userId, objectId: '' })
        })
        return Promise.all(promises)
    }
)

export const deleteAccountant = createAsyncThunk(
    'accountant/deleteAccountant',
    async (id: string, thunkApi): Promise<void> => {
        const accountantService = NewAccountantService()

        return accountantService.deleteAccountant(id).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

const accountantSlice = createSlice({
    name: 'financialAdvisor/slice',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(findAllAccountant.pending, (state) => {
                state.findAllAccountantStatus = 'loading'
            })
            .addCase(findAllAccountant.fulfilled, (state, action) => {
                state.findAllAccountantResponse = action.payload
                state.findAllAccountantStatus = 'successfully'
            })
            .addCase(findAllAccountant.rejected, (state, action) => {
                state.findAllAccountantStatus = 'failed'
            })
            .addCase(findAllAccountantWithoutPagination.pending, (state) => {
                state.findAllAccountantsWithoutPaginationStatus = 'loading'
            })
            .addCase(findAllAccountantWithoutPagination.fulfilled, (state, action) => {
                state.findAllAccountantsWithoutPaginationResponse = action.payload
                state.findAllAccountantsWithoutPaginationStatus = 'successfully'
            })
            .addCase(findAllAccountantWithoutPagination.rejected, (state, action) => {
                state.findAllAccountantsWithoutPaginationStatus = 'failed'
            })
            .addCase(findAllAdminAccountant.pending, (state) => {
                state.findAllAdminAccountantStatus = 'loading'
            })
            .addCase(findAllAdminAccountant.fulfilled, (state, action) => {
                state.findAllAdminAccountantResponse = action.payload
                state.findAllAdminAccountantStatus = 'successfully'
            })
            .addCase(findAllAdminAccountant.rejected, (state, action) => {
                state.findAllAdminAccountantStatus = 'failed'
            })
            .addCase(createAccountant.pending, (state) => {
                state.createAccountantStatus = 'loading'
            })
            .addCase(createAccountant.fulfilled, (state) => {
                state.createAccountantStatus = 'successfully'
            })
            .addCase(createAccountant.rejected, (state, action) => {
                //@ts-ignore
                const errorEmail = action.payload.find(error => error.message.includes('User with username') && error.message.includes('already exists'))
                if (errorEmail === undefined) {
                    state.createAccountantStatus = 'failed'
                } else {
                    state.createAccountantStatus = 'idle'
                }
                state.errors.createAccountant.email = errorEmail
            })
            .addCase(findAccountant.pending, (state) => {
                state.findAccountantStatus = 'loading'
            })
            .addCase(findAccountant.fulfilled, (state, action: PayloadAction<AccountantResponseDTO>) => {
                state.findAccountantResponse = action.payload
                state.findAccountantStatus = 'successfully'
            })
            .addCase(findAccountant.rejected, (state, action) => {
                state.findAccountantStatus = 'failed'
            })
            .addCase(editAccountant.pending, (state) => {
                state.editAccountantStatus = 'loading'
            })
            .addCase(editAccountant.fulfilled, (state) => {
                state.editAccountantStatus = 'successfully'
            })
            .addCase(editAccountant.rejected, (state, action) => {
                state.editAccountantStatus = 'failed'
            })
            .addCase(findAccountantAvatarById.pending, (state) => {
                state.editAccountantFindAvatarStatus = 'loading'
            })
            .addCase(findAccountantAvatarById.fulfilled, (state, action) => {
                state.editAccountantFindAvatarStatus = 'successfully'
                state.editAccountantAvatar = action.payload
            })
            .addCase(findAccountantAvatarById.rejected, (state) => {
                state.editAccountantFindAvatarStatus = 'failed'
            })
            .addCase(findAdminAccountantAvatarById.pending, (state) => {
                state.editAdminAccountantFindAvatarStatus = 'loading'
            })
            .addCase(findAdminAccountantAvatarById.fulfilled, (state, action) => {
                state.editAdminAccountantFindAvatarStatus = 'successfully'
                state.editAdminAccountantAvatar = action.payload
            })
            .addCase(findAdminAccountantAvatarById.rejected, (state) => {
                state.editAdminAccountantFindAvatarStatus = 'failed'
            })
            .addCase(findAccountantsListAvatarsById.pending, (state) => {
                state.findAllAdminAccountantsAvatarStatus = 'loading'
            })
            .addCase(findAccountantsListAvatarsById.fulfilled, (state, action) => {
                state.findAllAdminAccountantsAvatarStatus = 'successfully'
                state.adminAccountantsListAvatarResponse = action.payload
            })
            .addCase(findAccountantsListAvatarsById.rejected, (state) => {
                state.findAllAdminAccountantsAvatarStatus = 'failed'
            })
            .addCase(deleteAccountant.pending, (state) => {
                state.deleteAccountantStatus = 'loading'
            })
            .addCase(deleteAccountant.fulfilled, (state) => {
                state.deleteAccountantStatus = 'successfully'
            })
            .addCase(deleteAccountant.rejected, (state) => {
                state.deleteAccountantStatus = 'failed'
            })
            .addCase(findAdminAccountant.pending, (state) => {
                state.findAdminAccountantStatus = 'loading'
            })
            .addCase(findAdminAccountant.fulfilled, (state, action) => {
                state.findAdminAccountantStatus = 'successfully'
                state.findAdminAccountantResponse = action.payload
            })
            .addCase(findAdminAccountant.rejected, (state) => {
                state.findAdminAccountantStatus = 'failed'
            })
    },
    reducers: {
        setAccountantFilter: (state, action) => {
            state.findAllAccountantFilters.accountant = action.payload
        },
        setFinancialAdvisorFilter: (state, action) => {
            state.findAllAccountantFilters.financialAdvisor = action.payload
        },
        setRoleFilter: (state, action) => {
            state.findAllAccountantFilters.isAdmin = action.payload
        },
        setPageFilter: (state, action) => {
            state.findAllAccountantFilters.page = action.payload
        },
        setOpenAccountantModal: (state, action) => {
            state.openAccountantModal = action.payload
        },
        setCreateAccountantName: (state, action) => {
            state.createAccountantRequest.name = action.payload
        },
        setCreateAccountantSurname: (state, action) => {
            state.createAccountantRequest.surname = action.payload
        },
        setCreateAccountantEmail: (state, action) => {
            state.createAccountantRequest.email = action.payload
        },
        setCreateAccountantPhoneNumber: (state, action) => {
            state.createAccountantRequest.phoneNumber = action.payload
        },
        setCreateAccountantIsAdmin: (state, action) => {
            state.createAccountantRequest.isAdmin = action.payload
        },
        setCreateAccountantAdminAccountantId: (state, action) => {
            state.createAccountantRequest.adminAccountantId = action.payload
        },
        setCreateAccountantDescription: (state, action) => {
            state.createAccountantRequest.description = action.payload
        },
        setCreateAccountantStatus: (state, action) => {
            state.createAccountantStatus = action.payload
        },
        setAccountantSettedId: (state, action) => {
            state.accountantSettedId = action.payload
        },
        setEditAccountantName: (state, action) => {
            state.editAccountantRequest.name = action.payload
        },
        setEditAccountantSurname: (state, action) => {
            state.editAccountantRequest.surname = action.payload
        },
        setEditAccountantPhoneNumber: (state, action) => {
            state.editAccountantRequest.phoneNumber = action.payload
        },
        setEditAccountantDescription: (state, action) => {
            state.editAccountantRequest.description = action.payload
        },
        setEditAccountantStatus: (state, action) => {
            state.editAccountantStatus = action.payload
        },
        resetCreateAccountantRequest: (state) => {
            state.createAccountantRequest = {
                name: '',
                surname: '',
                isAdmin: false,
                email: '',
                adminAccountantId: '',
                description: '',
                activityNotifications: true,
                operationsNotifications: true,
            }
        },
        resetEditAccountantRequest: (state) => {
            state.editAccountantRequest = {}
        },
        setEditAccountantFindAvatarStatus: (state, action) => {
            state.editAccountantFindAvatarStatus = action.payload
        },
        setAdminAccountantsAvatarsList: (state, action) => {
            state.adminAccountantsListAvatarResponse = action.payload
        },
        setFindAllAdminAccountantsStatus: (state, action) => {
            state.findAllAdminAccountantStatus = action.payload
        },
        setFindAllAdminAccountantsAvatarStatus: (state, action) => {
            state.findAllAdminAccountantsAvatarStatus = action.payload
        },
        setOpenDeleteAccountantModal: (state, action) => {
            state.openDeleteAccountantModal = action.payload
        },
        setDeleteAccountantId: (state, action) => {
            state.deleteAccountantId = action.payload
        },
        setDeleteAccountantStatus: (state, action) => {
            state.deleteAccountantStatus = action.payload
        },
        resetErrorCreateAccountantEmail: (state) => {
            state.errors.createAccountant.email = undefined
        },
        setFindAllAccountantStatus: (state, action) => {
            state.findAllAccountantStatus = action.payload
        }
    },
})

export const {
    setAccountantFilter,
    setFinancialAdvisorFilter,
    setRoleFilter,
    setOpenAccountantModal,
    setCreateAccountantAdminAccountantId,
    setCreateAccountantDescription,
    setCreateAccountantEmail,
    setCreateAccountantIsAdmin,
    setCreateAccountantName,
    setCreateAccountantPhoneNumber,
    setCreateAccountantSurname,
    setCreateAccountantStatus,
    setAccountantSettedId,
    setEditAccountantDescription,
    setEditAccountantName,
    setEditAccountantPhoneNumber,
    setEditAccountantSurname,
    setEditAccountantStatus,
    resetCreateAccountantRequest,
    setEditAccountantFindAvatarStatus,
    setAdminAccountantsAvatarsList,
    setFindAllAdminAccountantsStatus,
    setFindAllAdminAccountantsAvatarStatus,
    setOpenDeleteAccountantModal,
    setDeleteAccountantId,
    setDeleteAccountantStatus,
    resetErrorCreateAccountantEmail,
    setFindAllAccountantStatus,
    setPageFilter
} = accountantSlice.actions

export default accountantSlice.reducer