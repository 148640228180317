import { FinancialAdvisorResponseDTO, FindAllFinancialAdvisorFiltersDTO, FindAllFinancialAdvisors, SetAdminAccountantDTO } from "./dto";
import { FinancialAdvisorServiceImpl } from "./serviceImpl";

export interface FinancialAdvisorService {
    findAllFinancialAdvisor(filters: FindAllFinancialAdvisorFiltersDTO): Promise<FindAllFinancialAdvisors>
    createFinancialAdvisor(request: FormData): Promise<void>
    findFinancialAdvisor(id: string): Promise<FinancialAdvisorResponseDTO>
    editFinancialAdvisor(request: FormData, id: string): Promise<void>
    deleteFinancialAdvisor(id: string): Promise<void>
    setAdminAccountant(id: string, adminAccountant: SetAdminAccountantDTO): Promise<void>
}

export function NewFinancialAdvisorService(): FinancialAdvisorService {
    return new FinancialAdvisorServiceImpl();
}
