
export function EmptyList() {

    return (
        <div className={'empty-list d-flex flex-column align-items-center justify-content-center p-0 m-0'} style={{ flexGrow: 1, padding: 10 }}>
            <div className="heading heading--sm typography--medium typography-neutral--800">
                Nessun risultato trovato
            </div>
            <div className="text text--lg typography--medium typography-neutral--400" style={{ textAlign: 'center' }}>La tua ricerca non ha prodotto risultati. Prova a modificare i termini di ricerca o a selezionare filtri diversi.</div>
        </div>
    );
}
