import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { AvatarComponent } from '../../avatar'
import { AvatarSize, AvatarType } from '../../avatar/dto'
import { SearchIcon } from '../../icons/search'
import { keycloak } from '../../keycloak'
import { PillComponent } from '../../pills'
import { PillsColor, PillsEmphasis, PillsOutline, PillsSize } from '../../pills/dto'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { TextInput } from '../../textInput'
import colors from '../../utils/index.module.scss'
import { getCompanyUnseenInfoMessages, setCompaniesUnseenMessagesStatus } from '../slice'
import { ChatDTO, UserInfoDTO, chatRoleMap } from './dto'
import { findChatImages, findChatUsers, findChats, findMessages, setAvatarInfos, setChatSeen, setChatSeenStatus, setSelectedChat, setUsersListAfterSend } from './slice'
export function UsersList() {
    const chatState = useAppSelector(state => state.chatV2)
    const companyState = useAppSelector(state => state.company)
    const [selected, setSelected] = useState<number>(chatState.selectedChat)
    // const [chatList, setChatList] = useState<{ chat: ChatDTO, keySearch: string }[]>([])
    const [filter, setFilter] = useState<string>('')

    let chatList: { chat: ChatDTO, keySearch: string }[] = []

    const dispatch = useAppDispatch()

    const loadAvatarData = (chat: ChatDTO, firstUser?: UserInfoDTO, secondUser?: UserInfoDTO): { first: string, second: string, third: string, userId: string } => {
        if (firstUser === undefined || secondUser === undefined) {
            return {
                first: 'Utente non disponibile',
                second: '',
                third: '',
                userId: ''
            }
        }
        const firstUserName = firstUser.role === 'companies' && firstUser.businessName ? firstUser.businessName : firstUser.role === 'admins' ? 'Amministratore' : (firstUser.name + ' ' + firstUser.surname)
        const secondUserName = secondUser.role === 'companies' && secondUser.businessName ? secondUser.businessName : secondUser.role === 'admins' ? 'Amministratore' : (secondUser.name + ' ' + secondUser.surname)
        if (firstUser.role === 'companies') {
            return {
                first: firstUserName,
                second: firstUser.mail,
                third: chatRoleMap.get(firstUser.role) || '',
                userId: firstUser.userId,
            }
        }
        if (secondUser.role === 'companies') {
            return {
                first: secondUserName,
                second: secondUser.mail,
                third: chatRoleMap.get(secondUser.role) || '',
                userId: secondUser.userId,
            }
        }
        let first = ''
        let second = ''
        let third = ''
        let userId = ''
        if (keycloak.subject !== chat.firstUser && keycloak.subject !== chat.secondUser) {
            first = firstUserName + ' · ' + secondUserName
            second = firstUser.mail + ' · ' + secondUser.mail
            third = chatRoleMap.get(firstUser.role) + ' · ' + chatRoleMap.get(secondUser.role)
        } else if (keycloak.subject === chat.firstUser) {
            first = secondUserName
            second = secondUser.mail
            third = chatRoleMap.get(secondUser.role) || ''
            userId = secondUser.userId
        } else {
            first = firstUserName
            second = firstUser.mail
            third = chatRoleMap.get(firstUser.role) || ''
            userId = firstUser.userId
        }
        return {
            first,
            second,
            third,
            userId
        }
    }

    useEffect(() => {
        let userList: string[] = []
        if (chatState.findChatsStatus === 'successfully' && chatState.findChatResponse.length > 0) {
            chatState.findChatResponse.forEach(user => {
                if (!user.active) {
                    userList.push('')
                } else {
                    if (!userList.includes(user.firstUser)) {
                        userList.push(user.firstUser)
                    }
                    if (!userList.includes(user.secondUser)) {
                        userList.push(user.secondUser)
                    }
                    if (user.carbonCopy !== null) {
                        user.carbonCopy.forEach(carbonUser => {
                            if (!userList.includes(carbonUser)) {
                                userList.push(carbonUser)
                            }
                        })
                    }
                }
            })
        }
        if (chatState.usersListAfterSend.length === 0)
            dispatch(findChatUsers(userList))
        dispatch(setUsersListAfterSend([...userList]))
    }, [chatState.findChatsStatus])

    useEffect(() => {
        if (chatState.findChatUserStatus === 'successfully' && chatState.findChatUserResponse !== undefined && chatState.findChatUserResponse.length > 0) {
            let usersImages: { userId: string, objectId: string | null }[] = []
            chatState.findChatUserResponse.forEach(user => {
                usersImages.push({ userId: user.userId, objectId: user.avatarObjectId })
            })
            dispatch(findChatImages(usersImages))
        }
    }, [chatState.findChatUserStatus])

    useEffect(() => {
        if (chatState.setChatSeenStatus === 'successfully') {
            const companiesId = companyState.findAllCompaniesResponse?.data.map(company => ({ companyId: company.id, year: new Date().getFullYear() }))
            dispatch(getCompanyUnseenInfoMessages([...companiesId?.map(company => company.companyId.toString()) || [], companyState.findCompanyResponse?.id.toString() || '']))
            dispatch(setCompaniesUnseenMessagesStatus('idle'))
            dispatch(findChats({ firstUser: '', secondUser: '', topic: companyState.findCompanyResponse?.id || 0 }))
            dispatch(setChatSeenStatus('idle'))
        }
    }, [chatState.setChatSeenStatus])

    chatList = (chatState.findChatResponse.map(chat => ({ chat: chat, keySearch: loadAvatarData(chat, chatState.findChatUserResponse.find(user => user.userId === chat.firstUser), chatState.findChatUserResponse.find(user => user.userId === chat.secondUser)).first })))

    return (
        <div style={{
            backgroundColor: colors.neutral20,
            width: '40%',
            padding: '24px 16px',
            borderRadius: 12,
            border: '1px solid ' + colors.neutral80,
            overflow: 'auto'
        }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '16px', flexGrow: 1 }}>
                <TextInput
                    id={'chat-find-user'}
                    type={'text'}
                    onChange={e => {
                        setFilter(e.target.value.toLowerCase())
                        chatList = ([...chatState.findChatResponse.map(chat => ({ chat: chat, keySearch: loadAvatarData(chat, chatState.findChatUserResponse.find(user => user.userId === chat.firstUser), chatState.findChatUserResponse.find(user => user.userId === chat.secondUser)).first }))])
                    }
                    }
                    placeholder={'Cerca'}
                    startIcon={<SearchIcon colorBase={colors.neutral20} />}
                />
                <div style={{ overflow: 'auto', height: 'calc(100% - 50px)' }}>
                    {
                        chatList.filter(chat => chat.keySearch.toLowerCase().includes(filter)).map((chat) => (
                            <div
                                onClick={() => {
                                    if (chat.chat.id !== chatState.selectedChat) {
                                        setSelected(chat.chat.id)
                                        dispatch(findMessages({ chatId: chat.chat.id, page: 0, itemsPerPage: (chat && chat.chat.notSeenMessages > 0 ? (chat.chat.notSeenMessages + 25) : 25) }))
                                        dispatch(setSelectedChat(chat.chat.id))
                                        dispatch(setChatSeen(chat.chat.id))
                                        dispatch(setAvatarInfos(loadAvatarData(chat.chat, chatState.findChatUserResponse.find(user => user.userId === chat.chat.firstUser), chatState.findChatUserResponse.find(user => user.userId === chat.chat.secondUser))))
                                    }
                                }}
                                key={chat.chat.id}
                                style={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    backgroundColor: (selected === chat.chat.id ? colors.primary50 : 'transparent'),
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    gap: 12,
                                    padding: '5px 12px 5px 8px',
                                    borderRadius: '8px',
                                    border: '1px solid ' + (selected === chat.chat.id ? colors.primary400 : 'transparent'),
                                    height: '70px'
                                }}>
                                <div style={{ flexGrow: 1 }}>
                                    <AvatarComponent
                                        type={AvatarType.USER}
                                        size={AvatarSize.SM}
                                        src={chatState.findChatAvatarResponse.find(avatar => avatar.userId === loadAvatarData(chat.chat, chatState.findChatUserResponse.find(user => user.userId === chat.chat.firstUser), chatState.findChatUserResponse.find(user => user.userId === chat.chat.secondUser)).userId)?.objectId || ''}
                                        name={loadAvatarData(chat.chat, chatState.findChatUserResponse.find(user => user.userId === chat.chat.firstUser), chatState.findChatUserResponse.find(user => user.userId === chat.chat.secondUser)).first}
                                        phoneNumber={loadAvatarData(chat.chat, chatState.findChatUserResponse.find(user => user.userId === chat.chat.firstUser), chatState.findChatUserResponse.find(user => user.userId === chat.chat.secondUser)).third}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                    <div className='text-overflow text text--sm typography--regular typography-neutral--500'>
                                        {chat.chat.lastMessageDate && (format(new Date(chat.chat.lastMessageDate), 'ddMMyyyy') === format(new Date(), 'ddMMyyyy') ? format(new Date(chat.chat.lastMessageDate), 'dd/mm') : format(new Date(chat.chat.lastMessageDate), 'dd/MM'))}
                                    </div>
                                    {
                                        chat.chat.notSeenMessages > 0 &&
                                        <div style={{ width: 'auto' }}>
                                            <PillComponent label={chat.chat.notSeenMessages > 100 ? '99+' : chat.chat.notSeenMessages.toString()} size={PillsSize.XS} color={PillsColor.DESTRUCTIVE} emphasis={PillsEmphasis.HIGH} outline={PillsOutline.FALSE} />
                                        </div>
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}