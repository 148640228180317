import { MenuItem, TableBody } from "@mui/material";
import { compareAsc, format } from "date-fns";
import { ReactNode, useEffect } from "react";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { PeriodicityEnum } from "../companyQuotationWizard/dto";
import { EmptyList } from "../emptyList/emptyList";
import { HeadingComponent } from "../heading";
import { HeadingSize } from "../heading/dto";
import { DownloadIcon } from "../icons/download";
import { InfoTextPopup } from "../infoTextPopup";
import { keycloak } from "../keycloak";
import { NewObjectService } from "../objects/service";
import { findFileById } from "../objects/slice";
import { PaginationComponent } from "../pagination";
import { PillComponent } from "../pills";
import { PillsColor, PillsEmphasis, PillsOutline, PillsSize } from "../pills/dto";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SelectComponent } from "../select";
import { SpinnerComponent } from "../spinner";
import { TableComponent } from "../table";
import { TableCellComponent } from "../table/tableCell";
import { TableHeadComponent } from "../table/tableHead";
import { TableRowComponent } from "../table/tableRow";
import colors from '../utils/index.module.scss';
import { AccountingAreaDocumentsModal } from "./accountingAreaDocumentsModal";
import { findAllReceivedDocuments, findDocumentById, setDocumentReceivedFilterPage, setDocumentReceivedFilterPeriod, setDocumentReceivedFilterYear, setFindDocumentStatus } from "./slice";

export function DocumentReceived() {
    const dispatch = useAppDispatch()

    const companyState = useAppSelector(state => state.company)
    const authState = useAppSelector(state => state.auth)

    const objectService = NewObjectService()

    useEffect(() => {
        if (companyState.findCompanyResponse)
            dispatch(findAllReceivedDocuments({ companyId: companyState.findCompanyResponse.id, period: companyState.documentReceivedFilters.period, year: companyState.documentReceivedFilters.year, page: companyState.documentReceivedFilters.page }))
    }, [companyState.documentReceivedFilters.page])

    useEffect(() => {
        if (companyState.findAllReceivedDocumentsStatus === 'successfully' && companyState.findAllRecivedDocumentsResponse !== undefined) {
            if (companyState.findAllRecivedDocumentsResponse.page >= companyState.findAllRecivedDocumentsResponse.totalPage) {
                dispatch(setDocumentReceivedFilterPage(0))
                dispatch(findAllReceivedDocuments({ companyId: companyState.findCompanyResponse?.id || 0, period: companyState.documentReceivedFilters.period, year: companyState.documentReceivedFilters.year, page: 0 }))
            }
        }
    }, [companyState.findAllReceivedDocumentsStatus])

    useEffect(() => {
        if (
            companyState.findCompanyStatus === 'successfully' &&
            companyState.findCompanyResponse !== undefined &&
            companyState.operationsTabValue === 1 &&
            companyState.documentTabValue === 0
        ) {
            dispatch(findAllReceivedDocuments({ companyId: companyState.findCompanyResponse.id, year: companyState.documentReceivedFilters.year, period: companyState.documentReceivedFilters.period, page: companyState.documentReceivedFilters.page }))
        }
    }, [companyState.findCompanyStatus, companyState.operationsTabValue, companyState.documentTabValue, companyState.documentReceivedFilters.year])

    if (
        companyState.findAllReceivedDocumentsStatus === 'loading' ||
        authState.findMeStatus === 'loading' ||
        companyState.findCompanyStatus === 'loading'
    ) {
        return (<div style={{ width: '100%', height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><SpinnerComponent size='small' /></div>)
    }

    let receivedRows: ReactNode[] = []

    const monthlyMap = new Map<number, string>([
        [0, '01'],
        [1, '02'],
        [2, '03'],
        [3, '04'],
        [4, '05'],
        [5, '06'],
        [6, '07'],
        [7, '08'],
        [8, '09'],
        [9, '10'],
        [10, '11'],
        [11, '12'],
    ])

    const quarterlyMap = new Map<number, string>([
        [0, 'I trimestre'],
        [1, 'II trimestre'],
        [2, 'III trimestre'],
        [3, 'IV trimestre'],
    ])

    if (
        companyState.findAllReceivedDocumentsStatus === 'successfully' &&
        authState.findMeStatus === 'successfully' &&
        companyState.findAllRecivedDocumentsResponse !== undefined &&
        companyState.findAllRecivedDocumentsResponse.data !== undefined &&
        companyState.findAllRecivedDocumentsResponse.data.length > 0 &&
        authState.findMeResponse !== undefined &&
        companyState.operationsTabValue === 1 &&
        companyState.documentTabValue === 0
    ) {
        companyState.findAllRecivedDocumentsResponse.data
            .forEach(data => {
                const operatorsReceiverDate = data.operatorsDownloadDate
                const companiesReceiverDate = data.companiesDownloadDate
                const accountantsReceiverDate = data.accountantsDownloadDate

                let lastDownloadDate: Date | null = null

                if (keycloak.hasRealmRole('admin')) {
                    if (operatorsReceiverDate !== null)
                        lastDownloadDate = operatorsReceiverDate
                    if (companiesReceiverDate !== null) {
                        if (lastDownloadDate === null) {
                            lastDownloadDate = companiesReceiverDate
                        } else if (compareAsc(new Date(lastDownloadDate), new Date(companiesReceiverDate)) === -1) {
                            lastDownloadDate = companiesReceiverDate
                        }
                    }
                    if (accountantsReceiverDate !== null) {
                        if (lastDownloadDate === null) {
                            lastDownloadDate = accountantsReceiverDate
                        } else if (compareAsc(new Date(lastDownloadDate), new Date(accountantsReceiverDate)) === -1) {
                            lastDownloadDate = accountantsReceiverDate
                        }
                    }
                }
                if (keycloak.hasRealmRole('financial-advisor') || keycloak.hasRealmRole('operator'))
                    lastDownloadDate = operatorsReceiverDate
                if (keycloak.hasRealmRole('admin-accountant') || keycloak.hasRealmRole('accountant')) {
                    lastDownloadDate = accountantsReceiverDate
                }
                receivedRows.push(
                    <TableRowComponent key={'received-documents-' + data.id}>
                        <TableCellComponent label={data.fileName} cellType={"row-medium"} alignLabel={"left"} />
                        <TableCellComponent label={data.note.length < 40 ? data.note : data.note.substring(0, 40).concat("...")} cellType={"row-regular"} alignLabel={"left"} />
                        <TableCellComponent content={<PillComponent label={data.activity.activityType.name} size={PillsSize.SM} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"left"} />
                        <TableCellComponent label={(companyState.findCompanyResponse?.periodicity === PeriodicityEnum.Monthly ? monthlyMap.get(data.period) : quarterlyMap.get(data.period)) + '/' + data.year.toString()} cellType={"row-regular"} alignLabel={"right"} />
                        <TableCellComponent label={data.date && format(new Date(data.date), 'dd/MM/yyyy')} cellType={"row-regular"} alignLabel={"left"} />
                        <TableCellComponent label={lastDownloadDate !== null ? '' : '-'} cellType={"row-regular"} content={lastDownloadDate !== null ? <PillComponent label={format(new Date(lastDownloadDate), 'dd/MM/yyyy')} size={PillsSize.SM} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} /> : ''} alignLabel={"left"} />
                        <TableCellComponent
                            content={
                                <div className="d-flex justify-content-end align-items-center w-100">
                                    <InfoTextPopup
                                        label={"Scarica"}
                                        position="bottom-left"
                                        children={
                                            <ButtonComponent
                                                icon={<DownloadIcon colorBase={colors.neutral600} />}
                                                onClick={() => {
                                                    dispatch(findFileById(data.objectId.toString())).then((e) => {
                                                        //@ts-ignore
                                                        objectService.downloadBase64WithExtension(data.fileName, e.payload !== null ? e.payload : '')
                                                        dispatch(findDocumentById(data.id.toString()))
                                                            .then(() => {
                                                                dispatch(setFindDocumentStatus('idle'))
                                                                dispatch(findAllReceivedDocuments({ companyId: companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse.id : 0, period: companyState.documentReceivedFilters.period, year: companyState.documentReceivedFilters.year, page: companyState.documentReceivedFilters.page }))
                                                            })
                                                    });
                                                }}
                                                color={Colors.NEUTRAL}
                                                label=""
                                                variant={Variant.LINK}
                                                size={Size.SM}
                                                iconStyle={IconStyle.ONLY}
                                            />
                                        }
                                    />
                                </div>
                            }
                            cellType={"row-regular"}
                            alignLabel={"right"}
                        />
                    </TableRowComponent>
                )
            })
    }

    const startYear = 2022
    let yearsMenuItems: ReactNode[] = []

    for (let year = startYear; year <= new Date().getFullYear() + 1; year++) {
        yearsMenuItems.push(<MenuItem key={year} value={year}>{year}</MenuItem>)
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "flex-start",
            alignItems: 'flex-start',
            gap: '40px',
            width: '100%'
        }}>
            <div style={{ width: '100%', gap: '20px', display: 'flex', flexDirection: 'column' }}>
                <HeadingComponent label={"Documenti ricevuti"} buttons={[]} size={HeadingSize.MD} />
                <div style={{ marginTop: '8px', gap: '16px' }} className="row m-0 p-0`">
                    <div className="col-12 col-lg p-0 m-0">
                        <SelectComponent
                            value={companyState.documentReceivedFilters.period.toString()}
                            onChange={(e) => {
                                dispatch(setDocumentReceivedFilterPeriod(e.target.value))
                                if (companyState.findCompanyResponse)
                                    dispatch(findAllReceivedDocuments({
                                        companyId: companyState.findCompanyResponse.id,
                                        year: companyState.documentReceivedFilters.year,
                                        period: e.target.value as '' | number,
                                        page: companyState.documentReceivedFilters.page
                                    }))
                            }}
                            id={"received-document-period"}
                            menuItems={
                                [
                                    <MenuItem key='document-received-period-filter' value=''>Seleziona mese</MenuItem>,
                                    <MenuItem key='document-received-period-filter-0' value={0}>Gennaio</MenuItem>,
                                    <MenuItem key='document-received-period-filter-1' value={1}>Febbraio</MenuItem>,
                                    <MenuItem key='document-received-period-filter-2' value={2}>Marzo</MenuItem>,
                                    <MenuItem key='document-received-period-filter-3' value={3}>Aprile</MenuItem>,
                                    <MenuItem key='document-received-period-filter-4' value={4}>Maggio</MenuItem>,
                                    <MenuItem key='document-received-period-filter-5' value={5}>Giugno</MenuItem>,
                                    <MenuItem key='document-received-period-filter-6' value={6}>Luglio</MenuItem>,
                                    <MenuItem key='document-received-period-filter-7' value={7}>Agosto</MenuItem>,
                                    <MenuItem key='document-received-period-filter-8' value={8}>Settembre</MenuItem>,
                                    <MenuItem key='document-received-period-filter-9' value={9}>Ottobre</MenuItem>,
                                    <MenuItem key='document-received-period-filter-10' value={10}>Novembre</MenuItem>,
                                    <MenuItem key='document-received-period-filter-11' value={11}>Dicembre</MenuItem>,
                                ]
                            } />
                    </div>
                    <div className="col-12 col-lg p-0 m-0">
                        <SelectComponent
                            id={"received-document-year"}
                            value={companyState.documentReceivedFilters.year.toString()}
                            onChange={(e) => {
                                dispatch(setDocumentReceivedFilterYear(e.target.value))
                                if (companyState.findCompanyResponse)
                                    dispatch(findAllReceivedDocuments({
                                        companyId: companyState.findCompanyResponse.id,
                                        year: Number(e.target.value),
                                        period: companyState.documentReceivedFilters.period,
                                        page: companyState.documentReceivedFilters.page
                                    }))
                            }}
                            menuItems={yearsMenuItems}
                        />
                    </div>
                    <div className="col-12 col-lg-auto p-0 m-0 d-flex align-items-center justify-content-center">
                        <div className="row p-0 m-0" style={{ gap: '8px' }}>
                            <div className="col p-0 m-0">
                                <ButtonComponent
                                    label={"Pulisci"}
                                    onClick={() => {
                                        dispatch(setDocumentReceivedFilterPeriod(''))
                                        dispatch(setDocumentReceivedFilterYear(new Date().getFullYear()))
                                        if (companyState.findCompanyResponse)
                                            dispatch(findAllReceivedDocuments({ companyId: companyState.findCompanyResponse.id, period: '', year: new Date().getFullYear(), page: companyState.documentReceivedFilters.page }))
                                    }}
                                    color={Colors.NEUTRAL}
                                    variant={Variant.OUTLINE}
                                    size={Size.MD}
                                    iconStyle={IconStyle.OFF}
                                />
                            </div>
                            <div className="col p-0 m-0">
                                <ButtonComponent
                                    label={"Applica"}
                                    onClick={() => {
                                        if (companyState.findCompanyResponse)
                                            dispatch(findAllReceivedDocuments({ companyId: companyState.findCompanyResponse.id, year: companyState.documentReceivedFilters.year, period: companyState.documentReceivedFilters.period, page: companyState.documentReceivedFilters.page }))
                                    }}
                                    color={Colors.PRIMARY} variant={Variant.OUTLINE} size={Size.MD} iconStyle={IconStyle.OFF} />
                            </div>
                        </div>
                    </div>
                </div>
                <TableComponent>
                    <TableHeadComponent>
                        <TableCellComponent label={"Nome del file"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"Descrizione del file"} size="large" cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"tipologia"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={companyState.findCompanyResponse?.periodicity === PeriodicityEnum.Monthly ? "mese/anno" : "trimestre/anno"} cellType={"head"} alignLabel={"right"} />
                        <TableCellComponent label={"ricevuto"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"ultimo download"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent cellType={"head"} alignLabel={"right"} size="context-menu" />
                    </TableHeadComponent>
                    <TableBody>
                        {receivedRows}
                    </TableBody>
                </TableComponent>
            </div>
            <AccountingAreaDocumentsModal />
            {
                companyState.findAllRecivedDocumentsResponse !== undefined &&
                companyState.findAllRecivedDocumentsResponse.total > 0 &&
                <PaginationComponent
                    page={companyState.findAllRecivedDocumentsResponse !== undefined ? companyState.findAllRecivedDocumentsResponse.page : 0}
                    count={companyState.findAllRecivedDocumentsResponse !== undefined ? companyState.findAllRecivedDocumentsResponse.totalPage : 0}
                    onChange={(page) => dispatch(setDocumentReceivedFilterPage(page))}
                />
            }
            {
                companyState.findAllRecivedDocumentsResponse !== undefined &&
                companyState.findAllRecivedDocumentsResponse.total === 0 &&
                <div style={{ width: '100%', height: '50vh' }}>
                    <EmptyList />
                </div>
            }
        </div>
    )
}