import colors from '../utils/index.module.scss'

export enum StatusIndicatorIcon {
    ONLY = 'ONLY',
    OFF = 'OFF',
    RIGHT = 'RIGHT',
    LEFT = 'LEFT'
}

export enum StatusIndicatorColor {
    NEUTRAL = 'NEUTRAL',
    PRIMARY = 'PRIMARY',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

export enum StatusIndicatorSize {
    SM = 'SM',
    MD = 'MD',
    LG = 'LG'
}

export const StatusIndicatorColorMap = new Map<StatusIndicatorColor, string>([
    [StatusIndicatorColor.NEUTRAL, colors.neutral500],
    [StatusIndicatorColor.PRIMARY, colors.primary500],
    [StatusIndicatorColor.SUCCESS, colors.success500],
    [StatusIndicatorColor.ERROR, colors.destructive500],
    [StatusIndicatorColor.ACTIVE, colors.primary600],
    [StatusIndicatorColor.INACTIVE, colors.neutral100],
])

export const StatusIndicatorSizeMap = new Map<StatusIndicatorSize, string>([
    [StatusIndicatorSize.SM, '6px'],
    [StatusIndicatorSize.MD, '8px'],
    [StatusIndicatorSize.LG, '12px'],
])