
export type PromiseStatuses = 'idle' | 'loading' | 'successfully' | 'failed'
export type OnButtonClick = React.MouseEventHandler<HTMLButtonElement> | undefined
export type OnMenuItemClick = React.MouseEventHandler<HTMLLIElement> | undefined
export type OnChange = React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
export type OnFocus = React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
export type OnKeyDown = React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
export type Operation = 'save' | 'edit'
export const BACKEND_HOST = 'http://localhost:8080'
export type QuarkusError = { message: string }[]

export async function toBase64(file: File): Promise<string> {
    const bytes = new Uint8Array(await file.arrayBuffer());

    let binary = '';

    for (let i = 0; i < bytes.byteLength; i++) {

        binary += String.fromCharCode(bytes[i]);

    }
    return btoa(binary);
}