import { useEffect } from "react";
import { HeadingComponent } from "../../heading";
import { HeadingSize } from "../../heading/dto";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { SpinnerComponent } from "../../spinner";
import { SwitchComponent } from "../../switch";
import { TextInput } from "../../textInput";
import { setRegisterChange, setRegisterChangeName } from "../slice";

interface Props {
    disabled: boolean
}

export function ReverseChange(props: Props) {
    const { disabled } = props
    const companyState = useAppSelector(state => state.company)
    const dispatch = useAppDispatch()

    const registerCharge = companyState.findCompanyResponse?.registerCharge
    const registerChargeName = companyState.findCompanyResponse?.registerChargeName
    const registerChargeRequest = companyState.companyRegistryDTO.registerCharge
    const registerChargeNameRequest = companyState.companyRegistryDTO.registerChargeName

    useEffect(() => {
        if (companyState.findCompanyStatus === 'successfully' && companyState.findCompanyResponse !== undefined) {
            if (registerCharge === undefined || registerCharge === null) {
                dispatch(setRegisterChange(false))
            } else {
                dispatch(setRegisterChange(registerCharge))
                dispatch(setRegisterChangeName(registerChargeName))
            }
        }
    }, [registerCharge, companyState.findCompanyResponse, companyState.findCompanyResponse])

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <HeadingComponent size={HeadingSize.SM} label={"Reverse Charge"} buttons={[]}
            />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 24
                }}
            >
                <TextInput
                    id={"reverse-change-input"}
                    type={"text"}
                    disabled={!registerChargeRequest || disabled}
                    placeholder={"Inserire nome registro"}
                    value={registerChargeNameRequest === null ? '' : registerChargeNameRequest}
                    onChange={(e) => {
                        dispatch(setRegisterChangeName(e.target.value))
                    }}
                />
                <SwitchComponent
                    checked={registerChargeRequest}
                    disabled={disabled}
                    onChange={(e) => {
                        if (e.target.checked) {
                            dispatch(setRegisterChange(true))
                        } else {
                            dispatch(setRegisterChange(false))
                            dispatch(setRegisterChangeName(''))
                        }
                    }}
                />
            </div>
        </div>
    )
}