import { FindAllFiscalDocumentDTO, FiscalDocumentFilters, FiscalDocumentResponseDTO } from "./dtoDocument";
import { FiscalDocumentServiceImpl } from "./serviceImplDocument";

export interface FiscalDocumentService {
    findAllFiscalDocuments(request: FiscalDocumentFilters): Promise<FindAllFiscalDocumentDTO>
    createFiscalDocuments(request: FormData): Promise<void>
    findByIdFiscalDocument(id: string): Promise<FiscalDocumentResponseDTO>
    deleteFiscalDocuments(id: string): Promise<void>
}

export function NewFiscalDocumentService(): FiscalDocumentService {
    return new FiscalDocumentServiceImpl();
}