import { useDispatch } from "react-redux"
import { useAppSelector } from "../redux/hooks"
import colors from '../utils/index.module.scss'
import { AccountingSchemeEnum } from "./dto"
import { setCompanyQuotationWizardCreationDTO } from "./slice"

export function AccountingRegime() {
    const companyQuotationWizardState = useAppSelector(state => state.companyQuotation)
    const activityState = useAppSelector(state => state.activity)
    const dispatch = useDispatch()

    const accountingContainer = (selected: boolean, label: string, value: AccountingSchemeEnum) => {
        return (
            <div
                onClick={() => {
                    const iva = activityState.findAllActivityCategoryResponse?.data.find(category => category.name === "IVA")
                    const newCompany = [...companyQuotationWizardState.companiesToAdd]

                    newCompany[companyQuotationWizardState.index] = {
                        ...companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index],
                        accountingScheme: value,
                        journal: value === AccountingSchemeEnum.Ordinary ? 2500 : null,
                        banksNumber: value === AccountingSchemeEnum.Ordinary ? 1 : 0,
                        activityCategoryIds: value === AccountingSchemeEnum.Ordinary ? [] : [iva !== undefined ? iva.id : 0]
                    };

                    dispatch(setCompanyQuotationWizardCreationDTO([...newCompany]))
                }
                }
                style={{
                    border: ('1px solid ' + (selected ? colors.primary500 : colors.neutral80)),
                    borderRadius: '12px',
                    padding: '24px 0 24px 0',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer'
                }}
            >
                <p className={"m-0 p-0 text text--lg typography--regular " + (selected ? 'typography-primary--600' : 'typography-neutral--400')} style={{ textAlign: 'center', cursor: 'pointer' }}>
                    Contabilità
                    <br />
                    <span className="typography--bold">
                        {label}
                    </span>
                </p>
            </div>
        )
    }

    return (
        <div className="d-flex w-100 flex-column align-items-start" style={{ gap: 8 }}>
            <span className="text text--md typography--semibold typography-neutral--700">
                Regime contabile
            </span>
            <div className="d-flex flex-row w-100" style={{ gap: 18 }}>
                {accountingContainer(companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].accountingScheme === AccountingSchemeEnum.Ordinary, 'Ordinaria', AccountingSchemeEnum.Ordinary)}
                {accountingContainer(companyQuotationWizardState.companiesToAdd[companyQuotationWizardState.index].accountingScheme === AccountingSchemeEnum.Simplified, 'Semplificata', AccountingSchemeEnum.Simplified)}
            </div>
        </div>
    )
}