import { TableCell } from "@mui/material";
import { ReactNode, useState } from "react";
import colors from '../utils/index.module.scss';

interface Props {
    label?: string
    content?: ReactNode
    cellType: 'head' | 'row-bold' | 'row-medium' | 'row-regular' | 'row-semibold'
    alignLabel: 'left' | 'center' | 'right'
    size?: 'large' | 'medium' | 'small' | 'context-menu'
    clickable?: boolean
    onClick?: () => void
}

export function TableCellComponent(props: Props) {
    const { label } = props
    const { content } = props
    const { cellType } = props
    const { alignLabel } = props
    const { size } = props
    const { clickable } = props
    const { onClick } = props

    const cellTypeFontWeightMap = new Map<'row-bold' | 'row-medium' | 'row-regular' | 'row-semibold', string>([
        ['row-bold', 'typography--bold'],
        ['row-medium', 'typography--medium'],
        ['row-regular', 'typography--regular'],
        ['row-semibold', 'typography--semibold'],
    ])

    const cellSizeMap = new Map<'large' | 'medium' | 'small' | 'context-menu' | undefined, string>([
        ['large', '20%'],
        ['medium', 'auto'],
        ['small', '100px'],
        ['context-menu', '24px'],
        [undefined, 'auto'],
    ])

    const alignItemsMap = new Map<'left' | 'center' | 'right' | undefined, string>([
        ['left', 'justify-content-start'],
        ['center', 'justify-content-center'],
        ['right', 'justify-content-ende'],
    ])

    const [over, setOver] = useState<boolean>(false)

    return (
        <TableCell
            onClick={() => clickable && onClick && onClick()}
            width={cellSizeMap.get(size)}
            align={alignLabel} sx={{ padding: '16px 8px', minHeight: '10px !important' }}
            onMouseOver={() => {
                if (clickable && cellType !== 'head') {
                    setOver(true)
                }
            }}
            onMouseOut={() => {
                if (clickable && cellType !== 'head')
                    setOver(false)
            }}
        >
            <div
                className={"d-flex flex-row align-items-center gap-2 " + alignItemsMap.get(alignLabel)}
            >
                {
                    label !== undefined &&
                    <span
                        style={{ cursor: clickable ? 'pointer' : 'auto', color: (over ? colors.primary500 : colors.neutral600) }}
                        className={cellType === 'head' ?
                            (
                                'overline overline--xs typography--medium typography-neutral--400 text-overflow'
                            ) : (
                                ('text text--md text-overflow ' + cellTypeFontWeightMap.get(cellType))
                            )}
                    >
                        {cellType === 'head' && label ? label.toUpperCase() : label}
                    </span>
                }
                {content}
            </div>
        </TableCell>
    )
}