import { useEffect } from "react";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { CompanyQuotationWizardModal } from "../companyQuotationWizard";
import { resetCompanyQuotationWizardCreationDTO, setCompanyQuotationCreateCompaniesStatus, setCompanyQuotationWizardIndex, setCompanyQuotationWizardStep, setOpenCompanyQuotationWizardModal } from "../companyQuotationWizard/slice";
import { EmptyList } from "../emptyList/emptyList";
import { ErrorPopup } from "../errorPopup";
import { HeadingSize } from "../heading/dto";
import { keycloak } from "../keycloak";
import { LayoutComponent } from "../layout";
import { PaginationComponent } from "../pagination";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { MenuItems } from "../sidebar/dto";
import { SuccessPopup } from "../successPopup";
import { CompanyFiltersComponent } from "./filters";
import { CompanyListComponent } from "./list";
import { findAllCompanies, getCompanyUnseenInfoMessages, resetAtecosByIdResponse, resetAtecosByIdStatus, resetCompanyRegistryDTO, resetCompanyUpdateRegistryRequest, resetErrorUpdateRegistryEmail, resetErrorUpdateRegistryVat, resetFindCompanyResponse, setDeleteCompanyStatus, setFindCompanyStatus, setFindFundingsResponse, setFindLeasingsResponse, setFindLoansResponse, setFundings, setLeasings, setLoans, setPageFilter } from "./slice";

export function CompanyView() {
    const dispatch = useAppDispatch()

    const companyState = useAppSelector(state => state.company)

    useEffect(() => {
        dispatch(setFindCompanyStatus('idle'))
        dispatch(resetCompanyRegistryDTO())
        dispatch(setLoans([]))
        dispatch(setFundings([]))
        dispatch(setLeasings([]))
        dispatch(setFindLoansResponse([]))
        dispatch(setFindFundingsResponse([]))
        dispatch(setFindLeasingsResponse([]))
        dispatch(resetFindCompanyResponse())
        dispatch(resetAtecosByIdResponse())
        dispatch(resetAtecosByIdStatus())
        dispatch(resetCompanyUpdateRegistryRequest())
        dispatch(resetErrorUpdateRegistryEmail())
        dispatch(resetErrorUpdateRegistryVat())
    }, [])

    useEffect(() => {
        dispatch(findAllCompanies(companyState.companyFilters))
    }, [companyState.companyFilters.page])

    useEffect(() => {
        if (companyState.findAllCompaniesStatus === 'successfully' && companyState.findAllCompaniesResponse !== undefined) {
            if (companyState.findAllCompaniesResponse.page >= companyState.findAllCompaniesResponse.totalPage) {
                dispatch(setPageFilter(0))
                dispatch(findAllCompanies({ ...companyState.companyFilters, page: 0 }))
            }
            dispatch(getCompanyUnseenInfoMessages(companyState.findAllCompaniesResponse.data.map(data => data.id.toString())))
        }
    }, [companyState.findAllCompaniesStatus])

    return (
        <>
            <SuccessPopup
                active={companyState.deleteCompanyStatus === "successfully"}
                close={() => dispatch(setDeleteCompanyStatus("idle"))}
                message="Azienda eliminata con successo."
            />
            <ErrorPopup
                active={companyState.deleteCompanyStatus === "failed"}
                close={() => dispatch(setDeleteCompanyStatus("idle"))}
                message="Si è verificato un errore durante l'eliminazione dell'azienda."
            />
            <LayoutComponent
                breadcrumbItems={['Aziende']}
                headingSize={HeadingSize.LG}
                menuItem={MenuItems.COMPANY}
                headingLabel={"Aziende"}
                headingButtons={
                    (keycloak.hasRealmRole('financial-advisor') || keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('admin-accountant')) ?
                        [<ButtonComponent key='heading-button-company-add' label={"Aggiungi Azienda"}
                            onClick={() => {
                                dispatch(setCompanyQuotationWizardStep(0))
                                dispatch(setCompanyQuotationWizardIndex(0))
                                dispatch(setCompanyQuotationCreateCompaniesStatus('idle'))
                                dispatch(resetCompanyQuotationWizardCreationDTO())
                                dispatch(setOpenCompanyQuotationWizardModal(true))
                            }}
                            color={Colors.PRIMARY} variant={Variant.SOLID} size={Size.SM} iconStyle={IconStyle.OFF} />]
                        : []
                }>
                <CompanyFiltersComponent />
                <CompanyListComponent />
                <CompanyQuotationWizardModal />
                {
                    companyState.findAllCompaniesResponse !== undefined &&
                    companyState.findAllCompaniesResponse.total > 0 &&
                    <PaginationComponent
                        page={companyState.findAllCompaniesResponse !== undefined ? companyState.findAllCompaniesResponse.page : 0}
                        count={companyState.findAllCompaniesResponse !== undefined ? companyState.findAllCompaniesResponse.totalPage : 0}
                        onChange={(page) => dispatch(setPageFilter(page))}
                    />
                }
                {
                    companyState.findAllCompaniesResponse !== undefined &&
                    companyState.findAllCompaniesResponse.total === 0 &&
                    <EmptyList />
                }
            </LayoutComponent>
        </>
    )
}