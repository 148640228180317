import { MenuItem, TableBody } from "@mui/material";
import { format } from "date-fns";
import { ReactNode, useEffect } from "react";
import { ButtonComponent } from "../../button";
import { Colors, IconStyle, Size, Variant } from "../../button/dto";
import { EmptyList } from "../../emptyList/emptyList";
import { ErrorPopup } from "../../errorPopup";
import { HeadingComponent } from "../../heading";
import { HeadingSize } from "../../heading/dto";
import { DownloadIcon } from "../../icons/download";
import { SearchIcon } from "../../icons/search";
import { InfoTextPopup } from "../../infoTextPopup";
import { findFileById, setFindDocumentStatus } from "../../objects/slice";
import { PaginationComponent } from "../../pagination";
import { PillComponent } from "../../pills";
import { PillsColor, PillsEmphasis, PillsOutline, PillsSize } from "../../pills/dto";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { SelectComponent } from "../../select";
import { SpinnerComponent } from "../../spinner";
import { SuccessPopup } from "../../successPopup";
import { TableComponent } from "../../table";
import { TableCellComponent } from "../../table/tableCell";
import { TableHeadComponent } from "../../table/tableHead";
import { TableRowComponent } from "../../table/tableRow";
import { TextInput } from "../../textInput";
import { FiscalDocumentStatus, FiscalDocumentType, OtherTypology, OthersMap, StatusMap } from "./dtoDocument";
import { FiscalDocumentAreaModal } from "./fiscalDocumentAreaModal";
import { MenuFiscalDocument } from "./menuFiscalDocument";
import { findAllFiscalDocumentsOthers, findByIdFiscalDocument, setDeleteFiscalDocumentStatus, setFileNameOthersFilter, setFiscalDocumentOthersFilterPage, setOpenFiscalDocumentAreaModal, setSaveFiscalDocumentStatus, setStatusOthersFilter, setTypologyOthersFilter } from "./sliceDocument";
import { NewObjectService } from "../../objects/service";
import colors from '../../utils/index.module.scss';

export function OthersTab() {
    const dispatch = useAppDispatch()

    const fiscalDocumentState = useAppSelector(state => state.fiscalDocument)
    const companyState = useAppSelector(state => state.company)

    const objectService = NewObjectService()

    const startYear = 2022
    let yearsMenuItems: ReactNode[] = []

    for (let year = startYear; year <= new Date().getFullYear() + 1; year++) {
        yearsMenuItems.push(<MenuItem key={year} value={year}>{year}</MenuItem>)
    }

    useEffect(() => {
        if (companyState.findCompanyResponse !== undefined) {
            dispatch(findAllFiscalDocumentsOthers({ ...fiscalDocumentState.fiscalDocumentOthersFilters, companyId: companyState.findCompanyResponse.id.toString() }))
        }
    }, [companyState.findCompanyResponse])

    useEffect(() => {
        if (fiscalDocumentState.findAllFiscalDocumentOthersStatus === 'successfully' && fiscalDocumentState.findAllFiscalDocumentOthersResponse !== undefined) {
            if (fiscalDocumentState.findAllFiscalDocumentOthersResponse.page >= fiscalDocumentState.findAllFiscalDocumentOthersResponse.totalPage) {
                dispatch(setFiscalDocumentOthersFilterPage(0))
                dispatch(findAllFiscalDocumentsOthers({ ...fiscalDocumentState.fiscalDocumentOthersFilters, page: 0 }))
            }
        }
    }, [fiscalDocumentState.findAllFiscalDocumentOthersStatus])

    useEffect(() => {
        if (fiscalDocumentState.createFiscalDocumentsStatus === 'successfully' && companyState.findCompanyResponse !== undefined) {
            dispatch(findAllFiscalDocumentsOthers({ ...fiscalDocumentState.fiscalDocumentOthersFilters, companyId: companyState.findCompanyResponse.id.toString() }))
        }
    }, [fiscalDocumentState.createFiscalDocumentsStatus, companyState.findCompanyResponse])

    useEffect(() => {
        if (fiscalDocumentState.deleteFiscalDocumentStatus === 'successfully' && companyState.findCompanyResponse !== undefined) {
            dispatch(findAllFiscalDocumentsOthers({ ...fiscalDocumentState.fiscalDocumentOthersFilters, companyId: companyState.findCompanyResponse.id.toString() }))
        }
    }, [fiscalDocumentState.deleteFiscalDocumentStatus, companyState.findCompanyResponse])

    if (
        fiscalDocumentState.findAllFiscalDocumentOthersStatus === 'loading' ||
        fiscalDocumentState.createFiscalDocumentsStatus === 'loading' ||
        fiscalDocumentState.deleteFiscalDocumentStatus === 'loading' ||
        companyState.findCompanyStatus === 'loading'
    ) {
        return (<div style={{ width: '100%', height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><SpinnerComponent size='small' /></div>)
    }

    let fiscalDocumentOthersRows: ReactNode[] = []

    if (fiscalDocumentState.findAllFiscalDocumentOthersStatus === 'successfully' &&
        fiscalDocumentState.findAllFiscalDocumentOthersResponse !== undefined &&
        fiscalDocumentState.findAllFiscalDocumentOthersResponse.data !== undefined &&
        fiscalDocumentState.findAllFiscalDocumentOthersResponse.data.length > 0 &&
        companyState.taxAreaTabValue === 4
    ) {
        fiscalDocumentState.findAllFiscalDocumentOthersResponse.data.forEach(data => {
            let typology = OthersMap.get(data.typology as OtherTypology)
            let status = StatusMap.get(data.status)
            fiscalDocumentOthersRows.push(
                <TableRowComponent key={'fiscal-documents-others' + data.id}>
                    <TableCellComponent label={data.fileName} cellType={"row-semibold"} alignLabel={"left"} />
                    <TableCellComponent content={<PillComponent label={typology !== undefined ? typology : ''} size={PillsSize.XS} color={PillsColor.NEUTRAL} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent content={<PillComponent label={status !== undefined ? status : ''} size={PillsSize.XS} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent label={data.referenceDate && format(new Date(data.referenceDate), 'dd/MM/yyyy')} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent label={data.lastDownloadDate !== null ? '' : '-'} cellType={"row-regular"} content={data.lastDownloadDate !== null ? <PillComponent label={format(new Date(data.lastDownloadDate), 'dd/MM/yyyy')} size={PillsSize.SM} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} /> : ''} alignLabel={"left"} />
                    <TableCellComponent
                        content={
                            <div className="d-flex justify-content-end align-items-center w-100">
                                <InfoTextPopup
                                    label={"Scarica"}
                                    position="bottom"
                                    children={
                                        <ButtonComponent
                                            icon={<DownloadIcon colorBase={colors.neutral600} />}
                                            onClick={() => {
                                                dispatch(findFileById(data.objectId.toString())).then((e) => {
                                                    //@ts-ignore
                                                    objectService.downloadBase64WithExtension(data.fileName, e.payload !== null ? e.payload : '')
                                                    dispatch(findByIdFiscalDocument(data.id))
                                                        .then(() => {
                                                            dispatch(setFindDocumentStatus('idle'))
                                                            dispatch(findAllFiscalDocumentsOthers({ ...fiscalDocumentState.fiscalDocumentOthersFilters, companyId: companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse.id.toString() : '' }))
                                                        })
                                                });
                                            }}
                                            color={Colors.NEUTRAL}
                                            label=""
                                            variant={Variant.LINK}
                                            size={Size.SM}
                                            iconStyle={IconStyle.ONLY}
                                        />
                                    }
                                />
                                <MenuFiscalDocument fiscalDocument={data} />
                            </div>
                        }
                        cellType={"row-regular"}
                        alignLabel={"right"}
                    />
                </TableRowComponent>
            )
        })
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "flex-start",
            alignItems: 'flex-start',
            gap: '40px',
            width: '100%'
        }}>
            <ErrorPopup
                active={fiscalDocumentState.deleteFiscalDocumentStatus === 'failed'}
                close={() => dispatch(setDeleteFiscalDocumentStatus('idle'))}
                message="Si è verificato un errore durante l'eliminazione del documento"
            />
            <ErrorPopup
                active={fiscalDocumentState.createFiscalDocumentsStatus === 'failed'}
                close={() => dispatch(setSaveFiscalDocumentStatus('idle'))}
                message="Si è verificato un errore durante il salvataggio del documento"
            />
            <SuccessPopup
                active={fiscalDocumentState.deleteFiscalDocumentStatus === 'successfully'}
                close={() => dispatch(setDeleteFiscalDocumentStatus('idle'))}
                message="Documento eliminato correttamente"
            />
            <SuccessPopup
                active={fiscalDocumentState.createFiscalDocumentsStatus === 'successfully'}
                close={() => dispatch(setSaveFiscalDocumentStatus('idle'))}
                message="Documento salvato correttamente"
            />
            <div style={{ width: '100%', gap: '20px', display: 'flex', flexDirection: 'column' }}>
                <HeadingComponent label={"Altro"} buttons={[
                    <ButtonComponent
                        key={'others-button'}
                        label={"Aggiungi file"}
                        onClick={() => dispatch(setOpenFiscalDocumentAreaModal(true))}
                        color={Colors.PRIMARY}
                        variant={Variant.SOLID}
                        size={Size.SM}
                        iconStyle={IconStyle.OFF}
                    />
                ]} size={HeadingSize.MD} />
                <div style={{ marginTop: '8px', gap: '16px' }} className="row m-0 p-0`">
                    <div className="col-12 col-lg p-0 m-0">
                        <TextInput value={fiscalDocumentState.fiscalDocumentOthersFilters.fileName} onChange={(e) => dispatch(setFileNameOthersFilter(e.target.value))} id="others-name" type={"text"} placeholder={"Cerca nome del file"} startIcon={<SearchIcon colorBase={""} />} />
                    </div>
                    <div className="col-12 col-lg p-0 m-0">
                        <SelectComponent
                            value={fiscalDocumentState.fiscalDocumentOthersFilters.typology}
                            onChange={(e) => {
                                dispatch(setTypologyOthersFilter(e.target.value))
                                if (companyState.findCompanyResponse !== undefined) {
                                    dispatch(findAllFiscalDocumentsOthers({
                                        typology: e.target.value as OtherTypology,
                                        fileName: fiscalDocumentState.fiscalDocumentOthersFilters.fileName,
                                        itemsPerPage: fiscalDocumentState.fiscalDocumentOthersFilters.itemsPerPage,
                                        page: fiscalDocumentState.fiscalDocumentOthersFilters.page,
                                        month: fiscalDocumentState.fiscalDocumentOthersFilters.month,
                                        status: fiscalDocumentState.fiscalDocumentOthersFilters.status,
                                        type: fiscalDocumentState.fiscalDocumentOthersFilters.type,
                                        companyId: companyState.findCompanyResponse.id.toString()
                                    }))
                                }
                            }}
                            id={"others-typology"}
                            menuItems={
                                [
                                    <MenuItem key='others-typology-filter' value=''>Seleziona tipologia</MenuItem>,
                                    <MenuItem key={OtherTypology.inspection} value={OtherTypology.inspection}>Visura</MenuItem>,
                                    <MenuItem key={OtherTypology.durc} value={OtherTypology.durc}>DURC</MenuItem>,
                                ]
                            } />
                    </div>
                    <div className="col-12 col-lg p-0 m-0">
                        <SelectComponent
                            value={fiscalDocumentState.fiscalDocumentOthersFilters.status}
                            onChange={(e) => {
                                dispatch(setStatusOthersFilter(e.target.value))
                                if (companyState.findCompanyResponse !== undefined) {
                                    dispatch(findAllFiscalDocumentsOthers({
                                        typology: fiscalDocumentState.fiscalDocumentOthersFilters.typology,
                                        fileName: fiscalDocumentState.fiscalDocumentOthersFilters.fileName,
                                        itemsPerPage: fiscalDocumentState.fiscalDocumentOthersFilters.itemsPerPage,
                                        page: fiscalDocumentState.fiscalDocumentOthersFilters.page,
                                        month: fiscalDocumentState.fiscalDocumentOthersFilters.month,
                                        status: e.target.value,
                                        type: fiscalDocumentState.fiscalDocumentOthersFilters.type,
                                        companyId: companyState.findCompanyResponse.id.toString()
                                    }))
                                }
                            }}
                            id={"others-status"}
                            menuItems={
                                [
                                    <MenuItem key='others-status-filter' value=''>Seleziona stato</MenuItem>,
                                    <MenuItem key={FiscalDocumentStatus.toRead} value={FiscalDocumentStatus.toRead}>Da leggere</MenuItem>,
                                    <MenuItem key={FiscalDocumentStatus.read} value={FiscalDocumentStatus.read}>Letto</MenuItem>,
                                ]
                            } />
                    </div>
                    <div className="col-12 col-lg-auto p-0 m-0 d-flex align-items-center justify-content-center">
                        <div className="row p-0 m-0" style={{ gap: '8px' }}>
                            <div className="col p-0 m-0">
                                <ButtonComponent
                                    label={"Pulisci"}
                                    onClick={() => {
                                        dispatch(setFileNameOthersFilter(''))
                                        dispatch(setTypologyOthersFilter('' as OtherTypology))
                                        dispatch(setStatusOthersFilter(''))
                                        if (companyState.findCompanyResponse !== undefined) {
                                            dispatch(findAllFiscalDocumentsOthers({
                                                typology: '' as OtherTypology,
                                                fileName: '',
                                                itemsPerPage: fiscalDocumentState.fiscalDocumentOthersFilters.itemsPerPage,
                                                page: fiscalDocumentState.fiscalDocumentOthersFilters.page,
                                                month: fiscalDocumentState.fiscalDocumentOthersFilters.month,
                                                status: '',
                                                type: fiscalDocumentState.fiscalDocumentOthersFilters.type,
                                                companyId: companyState.findCompanyResponse.id.toString()
                                            }))
                                        }
                                    }}
                                    color={Colors.NEUTRAL}
                                    variant={Variant.OUTLINE}
                                    size={Size.MD}
                                    iconStyle={IconStyle.OFF}
                                />
                            </div>
                            <div className="col p-0 m-0">
                                <ButtonComponent
                                    label={"Applica"}
                                    onClick={() => {
                                        if (companyState.findCompanyResponse !== undefined)
                                            dispatch(findAllFiscalDocumentsOthers({ ...fiscalDocumentState.fiscalDocumentOthersFilters, companyId: companyState.findCompanyResponse.id.toString() }))
                                    }}
                                    color={Colors.PRIMARY} variant={Variant.OUTLINE} size={Size.MD} iconStyle={IconStyle.OFF} />
                            </div>
                        </div>
                    </div>
                </div>
                <TableComponent>
                    <TableHeadComponent>
                        <TableCellComponent label={"Nome del file"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"Tipologia"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"Stato"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"Data di riferimento"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"ultimo download"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent cellType={"head"} alignLabel={"right"} size="context-menu" />
                    </TableHeadComponent>
                    <TableBody>
                        {fiscalDocumentOthersRows}
                    </TableBody>
                </TableComponent>
            </div>
            <FiscalDocumentAreaModal type={FiscalDocumentType.other} />
            {
                fiscalDocumentState.findAllFiscalDocumentOthersResponse !== undefined &&
                fiscalDocumentState.findAllFiscalDocumentOthersResponse.total > 0 &&
                <PaginationComponent
                    page={fiscalDocumentState.findAllFiscalDocumentOthersResponse !== undefined ? fiscalDocumentState.findAllFiscalDocumentOthersResponse.page : 0}
                    count={fiscalDocumentState.findAllFiscalDocumentOthersResponse !== undefined ? fiscalDocumentState.findAllFiscalDocumentOthersResponse.totalPage : 0}
                    onChange={(page) => dispatch(setFiscalDocumentOthersFilterPage(page))}
                />
            }
            {
                fiscalDocumentState.findAllFiscalDocumentOthersResponse !== undefined &&
                fiscalDocumentState.findAllFiscalDocumentOthersResponse.total === 0 &&
                <div style={{ width: '100%', height: '50vh' }}>
                    <EmptyList />
                </div>
            }
        </div>
    )
}