import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { PromiseStatuses } from "../utils"
import { ActivityTypeCreationDTO, ActivityTypeFilters, FindAllActivityCategory, FindAllActivityType } from "./dto"
import { NewActivityService } from "./service"

interface ActivityState {
    activityTypeFilters: ActivityTypeFilters
    findAllActivityCategoryResponse?: FindAllActivityCategory
    findAllActivityTypeResponseWithoutPagination?: FindAllActivityType
    findAllActivityTypeResponse?: FindAllActivityType
    createActivityTypeRequest: ActivityTypeCreationDTO
    findAllActivityCategoryStatus: PromiseStatuses
    findAllActivityTypeStatusWithoutPagination: PromiseStatuses
    findAllActivityTypeStatus: PromiseStatuses
    createActivityTypeStatus: PromiseStatuses
    newActivityModalOpen: boolean
    deleteActivityTypeStatus: PromiseStatuses
    activityTypeSettedId: string
    openDeleteActivityTypeModal: boolean
}

const initialState: ActivityState = {
    findAllActivityCategoryStatus: 'idle',
    findAllActivityTypeStatusWithoutPagination: 'idle',
    createActivityTypeStatus: 'idle',
    createActivityTypeRequest: {
        name: '',
        activityCategoryId: 0,
        activityKind: ""
    },
    newActivityModalOpen: false,
    activityTypeFilters: {
        name: '',
        page: 0,
        itemsPerPage: 25,
        activityCategoryId: ''
    },
    deleteActivityTypeStatus: 'idle',
    activityTypeSettedId: '',
    openDeleteActivityTypeModal: false,
    findAllActivityTypeStatus: 'idle',
}

export const findAllActivityCategory = createAsyncThunk(
    'activity/findAllActivityCategory',
    async (_, thunkApi): Promise<FindAllActivityCategory> => {
        const activityService = NewActivityService()

        return activityService.findAllActivityCategory().catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const findAllActivityTypeWithoutPagination = createAsyncThunk(
    'activity/findAllActivityTypeWithoutPagination',
    async (request: ActivityTypeFilters, thunkApi): Promise<FindAllActivityType> => {
        const activityService = NewActivityService()

        return activityService.findAllActivityType(request).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const findAllActivityType = createAsyncThunk(
    'activity/findAllActivityType',
    async (request: ActivityTypeFilters, thunkApi): Promise<FindAllActivityType> => {
        const activityService = NewActivityService()

        return activityService.findAllActivityType(request).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const createActivityType = createAsyncThunk(
    'activity/createActivityType',
    async (activityTypeCreationDTO: ActivityTypeCreationDTO, thunkApi): Promise<void> => {
        const activityService = NewActivityService()

        return activityService.createActivityType(activityTypeCreationDTO).catch((error: any) => {
            if (error.response.status === 401) {
                //keycloak.login()
            }
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const deleteActivityType = createAsyncThunk(
    'activity/deleteActivityType',
    async (id: string): Promise<void> => {
        const activityService = NewActivityService()

        return activityService.deleteActivityType(id)
    }
)

const activitySlice = createSlice({
    name: 'activity/slice',
    initialState,
    reducers: {
        setActivityFilter: (state, action) => {
            state.activityTypeFilters.name = action.payload
        },
        setTypeFilter: (state, action) => {
            state.activityTypeFilters.activityCategoryId = action.payload
        },
        setPageFilter: (state, action) => {
            state.activityTypeFilters.page = action.payload
        },
        setNewActivityModalOpen: (state, action) => {
            state.newActivityModalOpen = action.payload
        },
        setNewActivityTypeName: (state, action) => {
            state.createActivityTypeRequest.name = action.payload
        },
        setNewActivityTypeActivityCategoryId: (state, action) => {
            state.createActivityTypeRequest.activityCategoryId = action.payload
        },
        setNewActivityTypeActivityKind: (state, action) => {
            state.createActivityTypeRequest.activityKind = action.payload
        },
        resetNewActivityTypeRequest: (state) => {
            state.createActivityTypeRequest = {
                name: '',
                activityCategoryId: 0,
                activityKind: ""
            }
        },
        setCreateActivityTypeState: (state, action) => {
            state.createActivityTypeStatus = action.payload
        },
        setActivityTypeSettedId: (state, action) => {
            state.activityTypeSettedId = action.payload
        },
        setOpenDeleteActivityTypeModal: (state, action) => {
            state.openDeleteActivityTypeModal = action.payload
        },
        setDeleteActivityTypeStatus: (state, action) => {
            state.deleteActivityTypeStatus = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(findAllActivityCategory.pending, (state) => {
                state.findAllActivityCategoryStatus = 'loading'
            })
            .addCase(findAllActivityCategory.fulfilled, (state, action) => {
                state.findAllActivityCategoryStatus = 'successfully'
                state.findAllActivityCategoryResponse = action.payload
            })
            .addCase(findAllActivityCategory.rejected, (state) => {
                state.findAllActivityCategoryStatus = 'failed'
            })
            .addCase(findAllActivityTypeWithoutPagination.pending, (state) => {
                state.findAllActivityTypeStatusWithoutPagination = 'loading'
            })
            .addCase(findAllActivityTypeWithoutPagination.fulfilled, (state, action) => {
                state.findAllActivityTypeStatusWithoutPagination = 'successfully'
                state.findAllActivityTypeResponseWithoutPagination = action.payload
            })
            .addCase(findAllActivityTypeWithoutPagination.rejected, (state) => {
                state.findAllActivityTypeStatusWithoutPagination = 'failed'
            })
            .addCase(createActivityType.pending, (state) => {
                state.createActivityTypeStatus = 'loading'
            })
            .addCase(createActivityType.fulfilled, (state) => {
                state.createActivityTypeStatus = 'successfully'
            })
            .addCase(createActivityType.rejected, (state) => {
                state.createActivityTypeStatus = 'failed'
            })
            .addCase(deleteActivityType.pending, (state) => {
                state.deleteActivityTypeStatus = 'loading'
            })
            .addCase(deleteActivityType.fulfilled, (state) => {
                state.deleteActivityTypeStatus = 'successfully'
            })
            .addCase(deleteActivityType.rejected, (state) => {
                state.deleteActivityTypeStatus = 'failed'
            })
            .addCase(findAllActivityType.pending, (state) => {
                state.findAllActivityTypeStatus = 'loading'
            })
            .addCase(findAllActivityType.fulfilled, (state, action) => {
                state.findAllActivityTypeStatus = 'successfully'
                state.findAllActivityTypeResponse = action.payload
            })
            .addCase(findAllActivityType.rejected, (state) => {
                state.findAllActivityTypeStatus = 'failed'
            })
    },
})

export const {
    setActivityFilter,
    setTypeFilter,
    setNewActivityModalOpen,
    setNewActivityTypeActivityCategoryId,
    setNewActivityTypeName,
    resetNewActivityTypeRequest,
    setCreateActivityTypeState,
    setActivityTypeSettedId,
    setOpenDeleteActivityTypeModal,
    setDeleteActivityTypeStatus,
    setPageFilter,
    setNewActivityTypeActivityKind
} = activitySlice.actions

export default activitySlice.reducer