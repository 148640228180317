import { useEffect, useState } from "react";
import { IconsProps } from "./dto";

export function BuildingIcon(props: IconsProps) {
    const [color, setColor] = useState<string>(props.colorBase)

    useEffect(() => {
        setColor(props.colorBase)
    }, [props.colorBase])

    return (
        <svg
            onMouseOver={() => setColor(props.colorHover !== undefined ? props.colorHover : props.colorBase)}
            onMouseOut={() => setColor(props.colorBase)}
            width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.3333 18.9583H1.66663C1.32496 18.9583 1.04163 18.675 1.04163 18.3333C1.04163 17.9916 1.32496 17.7083 1.66663 17.7083H18.3333C18.675 17.7083 18.9583 17.9916 18.9583 18.3333C18.9583 18.675 18.675 18.9583 18.3333 18.9583Z" fill={color} />
            <path d="M17.5 18.9584H2.5C2.15833 18.9584 1.875 18.675 1.875 18.3334V5.00002C1.875 2.48335 3.31667 1.04169 5.83333 1.04169H14.1667C16.6833 1.04169 18.125 2.48335 18.125 5.00002V18.3334C18.125 18.675 17.8417 18.9584 17.5 18.9584ZM3.125 17.7084H16.875V5.00002C16.875 3.15002 16.0167 2.29169 14.1667 2.29169H5.83333C3.98333 2.29169 3.125 3.15002 3.125 5.00002V17.7084Z" fill={color} />
            <path d="M8.33337 14.375H5.83337C5.49171 14.375 5.20837 14.0917 5.20837 13.75C5.20837 13.4083 5.49171 13.125 5.83337 13.125H8.33337C8.67504 13.125 8.95837 13.4083 8.95837 13.75C8.95837 14.0917 8.67504 14.375 8.33337 14.375Z" fill={color} />
            <path d="M14.1666 14.375H11.6666C11.325 14.375 11.0416 14.0917 11.0416 13.75C11.0416 13.4083 11.325 13.125 11.6666 13.125H14.1666C14.5083 13.125 14.7916 13.4083 14.7916 13.75C14.7916 14.0917 14.5083 14.375 14.1666 14.375Z" fill={color} />
            <path d="M8.33337 10.625H5.83337C5.49171 10.625 5.20837 10.3417 5.20837 10C5.20837 9.65833 5.49171 9.375 5.83337 9.375H8.33337C8.67504 9.375 8.95837 9.65833 8.95837 10C8.95837 10.3417 8.67504 10.625 8.33337 10.625Z" fill={color} />
            <path d="M14.1666 10.625H11.6666C11.325 10.625 11.0416 10.3417 11.0416 10C11.0416 9.65833 11.325 9.375 11.6666 9.375H14.1666C14.5083 9.375 14.7916 9.65833 14.7916 10C14.7916 10.3417 14.5083 10.625 14.1666 10.625Z" fill={color} />
            <path d="M8.33337 6.875H5.83337C5.49171 6.875 5.20837 6.59167 5.20837 6.25C5.20837 5.90833 5.49171 5.625 5.83337 5.625H8.33337C8.67504 5.625 8.95837 5.90833 8.95837 6.25C8.95837 6.59167 8.67504 6.875 8.33337 6.875Z" fill={color} />
            <path d="M14.1666 6.875H11.6666C11.325 6.875 11.0416 6.59167 11.0416 6.25C11.0416 5.90833 11.325 5.625 11.6666 5.625H14.1666C14.5083 5.625 14.7916 5.90833 14.7916 6.25C14.7916 6.59167 14.5083 6.875 14.1666 6.875Z" fill={color} />
        </svg>
    )
}