import { useEffect } from "react";
import { HeadingComponent } from "../../heading";
import { HeadingSize } from "../../heading/dto";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { SpinnerComponent } from "../../spinner";
import { SwitchComponent } from "../../switch";
import { TextInput } from "../../textInput";
import { setNotInstrumentalVehicles, setNotInstrumentalVehiclesLicensePlate } from "../slice";

interface Props {
    disabled: boolean
}

export function NotInstrumentalVehicles(props: Props) {
    const { disabled } = props
    const companyState = useAppSelector(state => state.company)
    const dispatch = useAppDispatch()

    const notInstrumentalVehicles = companyState.findCompanyResponse?.notInstrumentalVehicles
    const notInstrumentalVehiclesLicensePlate = companyState.findCompanyResponse?.notInstrumentalVehiclesLicensePlate
    const notInstrumentalVehiclesRequest = companyState.companyRegistryDTO.notInstrumentalVehicles
    const notInstrumentalVehiclesLicensePlateRequest = companyState.companyRegistryDTO.notInstrumentalVehiclesLicensePlate

    useEffect(() => {
        if (companyState.findCompanyStatus === 'successfully' && companyState.findCompanyResponse !== undefined) {
            if (notInstrumentalVehicles === undefined || notInstrumentalVehicles === null) {
                dispatch(setNotInstrumentalVehicles(false))
            } else {
                dispatch(setNotInstrumentalVehicles(notInstrumentalVehicles))
                dispatch(setNotInstrumentalVehiclesLicensePlate(notInstrumentalVehiclesLicensePlate))
            }
        }
    }, [notInstrumentalVehicles, companyState.findCompanyStatus, companyState.findCompanyResponse])

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <HeadingComponent size={HeadingSize.SM} label={"Automezzi Non Strumentali"} buttons={[]}
            />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 24
                }}
            >
                <TextInput
                    id={"reverse-change-input"}
                    type={"text"}
                    disabled={!notInstrumentalVehiclesRequest || disabled}
                    placeholder={"Inserisci targa (Usa la “,” per separarle)"}
                    value={notInstrumentalVehiclesLicensePlateRequest === null ? '' : notInstrumentalVehiclesLicensePlateRequest}
                    onChange={(e) => {
                        dispatch(setNotInstrumentalVehiclesLicensePlate(e.target.value))
                    }}
                />
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8 }}>
                    <SwitchComponent
                        disabled={disabled}
                        checked={notInstrumentalVehiclesRequest}
                        onChange={(e) => {
                            if (e.target.checked) {
                                dispatch(setNotInstrumentalVehicles(true))
                            } else {
                                dispatch(setNotInstrumentalVehicles(false))
                                dispatch(setNotInstrumentalVehiclesLicensePlate(''))
                            }
                        }}
                    />
                    <span className="text text--md typography--medium typography-neutral--500">Attiva</span>
                </div>
            </div>
        </div>
    )
}