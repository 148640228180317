import { differenceInDays } from "date-fns";
import { useState } from "react";
import { ContextMenuComponent } from "../contextMenu";
import { MenuItemComponent } from "../contextMenu/menuItem";
import { DownloadIcon } from "../icons/download";
import { keycloak } from "../keycloak";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import colors from '../utils/index.module.scss';
import { DownloadFileAcubeAccountsModal } from "./downloadFileAcubeAccountsModal";
import { acubeReconnectRequest, setOpenDownloadPdfAcubeAccountsModal } from "./slice";

interface Props {
    uuid: string
    name: string
    fiscalId: string
    consentExpiresAt: Date
}

export function CompanyMenuBankAccountComponent(props: Props) {
    const { uuid } = props
    const { name } = props
    const { fiscalId } = props
    const { consentExpiresAt } = props

    const dispatch = useAppDispatch()

    const companyState = useAppSelector(state => state.company)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const expirationDate = new Date(consentExpiresAt)
    const difference = differenceInDays(expirationDate, new Date())

    return (
        <ContextMenuComponent anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
            <MenuItemComponent
                onClick={() => {
                    dispatch(setOpenDownloadPdfAcubeAccountsModal(true));
                }}>
                <DownloadIcon colorBase={colors.neutral600} />
                Scarica
            </MenuItemComponent>
            {
                difference <= 20 && keycloak.hasRealmRole('financial-advisor') && (
                    <MenuItemComponent
                        onClick={() => {
                            dispatch(acubeReconnectRequest({ id: companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse?.id.toString() : '', uuid: uuid }))
                        }}>
                        <DownloadIcon colorBase={colors.neutral600} />
                        Rinnova consenso
                    </MenuItemComponent>
                )
            }
            <DownloadFileAcubeAccountsModal
                uuid={uuid}
                name={name}
                fiscalId={fiscalId}
                open={companyState.openDownloadPdfAcubeAccountsModal}
                handleClose={() => { dispatch(setOpenDownloadPdfAcubeAccountsModal(false)); setAnchorEl(null) }}
            />
        </ContextMenuComponent>
    )
}