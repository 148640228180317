import colors from '../utils/index.module.scss'

export enum ProgressBarSize {
    XS = 'XS',
    SM = 'SM',
    MD = 'MD',
    LG = 'LG',
    XL = 'XL'
}

export enum ProgressBarColor {
    PRIMARY = 'PRIMARY',
    SUCCESS = 'SUCCESS'
}

export const ProgressBarSizeMap = new Map<ProgressBarSize | undefined, string>([
    [ProgressBarSize.XS, '80px'],
    [ProgressBarSize.SM, '120px'],
    [ProgressBarSize.MD, '160px'],
    [ProgressBarSize.LG, '200px'],
    [ProgressBarSize.XL, '240px'],
    [undefined, '100%']
])

export const ProgressBarColorMap = new Map<ProgressBarColor, string>([
    [ProgressBarColor.PRIMARY, colors.primary500],
    [ProgressBarColor.SUCCESS, colors.success500]
])