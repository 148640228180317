import { useEffect } from "react";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { EmptyList } from "../emptyList/emptyList";
import { ErrorPopup } from "../errorPopup";
import { HeadingSize } from "../heading/dto";
import { LayoutComponent } from "../layout";
import { PaginationComponent } from "../pagination";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { MenuItems } from "../sidebar/dto";
import { SuccessPopup } from "../successPopup";
import { ActivityFiltersComponent } from "./filters";
import { ActivityListComponent } from "./list";
import { NewActivityModal } from "./newActivityModal";
import { findAllActivityType, setCreateActivityTypeState, setDeleteActivityTypeStatus, setNewActivityModalOpen, setNewActivityTypeActivityCategoryId, setNewActivityTypeName, setPageFilter } from "./slice";

export function ActivityView() {
    const dispatch = useAppDispatch()

    const activityState = useAppSelector(state => state.activity)

    useEffect(() => {
        dispatch(findAllActivityType(activityState.activityTypeFilters))
    }, [activityState.activityTypeFilters.page])

    useEffect(() => {
        if (activityState.findAllActivityTypeStatus === 'successfully' && activityState.findAllActivityTypeResponse !== undefined) {
            if (activityState.findAllActivityTypeResponse.page >= activityState.findAllActivityTypeResponse.totalPage) {
                dispatch(setPageFilter(0))
                dispatch(findAllActivityType({ ...activityState.activityTypeFilters, page: 0 }))
            }
        }
    }, [activityState.findAllActivityTypeStatus])


    return (
        <LayoutComponent
            breadcrumbItems={['Attività']}
            headingSize={HeadingSize.LG}
            menuItem={MenuItems.ACTIVITY}
            headingLabel={"Attività"}
            headingButtons={[<ButtonComponent key='heading-button-company-add' label={"Aggiungi Attività"} onClick={() => { dispatch(setNewActivityModalOpen(true)); dispatch(setNewActivityTypeActivityCategoryId('0')); dispatch(setNewActivityTypeName('')) }} color={Colors.PRIMARY} variant={Variant.SOLID} size={Size.SM} iconStyle={IconStyle.OFF} />]}>
            <ErrorPopup
                active={activityState.createActivityTypeStatus === 'failed'}
                close={() => dispatch(setCreateActivityTypeState('idle'))}
                message="Si è verificato un errore durante la creazione dell'attività"
            />
            <SuccessPopup
                active={activityState.createActivityTypeStatus === 'successfully'}
                close={() => dispatch(setCreateActivityTypeState('idle'))}
                message="Attività aggiunta"
            />
            <ErrorPopup
                active={activityState.deleteActivityTypeStatus === 'failed'}
                close={() => dispatch(setDeleteActivityTypeStatus('idle'))}
                message="Si è verificato un errore durante l'eliminazione dell'attività"
            />
            <SuccessPopup
                active={activityState.deleteActivityTypeStatus === 'successfully'}
                close={() => dispatch(setDeleteActivityTypeStatus('idle'))}
                message="Attività rimossa"
            />
            <ActivityFiltersComponent />
            <ActivityListComponent />
            <NewActivityModal />
            {
                activityState.findAllActivityTypeResponse !== undefined &&
                activityState.findAllActivityTypeResponse.total > 0 &&
                <PaginationComponent
                    page={activityState.findAllActivityTypeResponse !== undefined ? activityState.findAllActivityTypeResponse.page : 0}
                    count={activityState.findAllActivityTypeResponse !== undefined ? activityState.findAllActivityTypeResponse.totalPage : 0}
                    onChange={(page) => dispatch(setPageFilter(page))}
                />
            }
            {
                activityState.findAllActivityTypeResponse !== undefined &&
                activityState.findAllActivityTypeResponse.total === 0 &&
                <EmptyList />
            }
        </LayoutComponent>
    )
}