import React, { useState } from 'react'
import { CheckboxComponent } from '../../checkbox'
import { CheckboxSize, CheckboxStyle } from '../../checkbox/dto'
import { HasDocumentIcon } from '../../icons/hasDocument'
import colors from '../../utils/index.module.scss'

interface Props {
    label: string
    count: number
    checked?: boolean
    disabled?: boolean
    hasDocument: boolean
    setCount: (count: number) => void
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export function OrdinaryAccountingRow(props: Props) {
    const { label } = props
    const { count } = props
    const { checked } = props
    const { disabled } = props
    const { hasDocument } = props
    const { setCount } = props
    const { onChange } = props

    const [active, setActive] = useState<boolean>(checked ? checked : false)

    return (
        <div style={{
            backgroundColor: (active ? colors.primary50 : colors.white),
            border: '1px solid ' + (active ? colors.primary200 : colors.neutral80),
            borderRadius: '8px',
            padding: '0px 16px 0px 12px',
            width: '100%',
            minHeight: '40px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '8px',
            color: (active ? colors.primary600 : colors.neutral600),
            transition: 'all 0.2s ease-in-out'
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}>
                <CheckboxComponent
                    checked={checked}
                    size={CheckboxSize.MD}
                    disabled={disabled}
                    style={CheckboxStyle.CIRCLE}
                    onChange={(e, _checked) => {
                        setActive(_checked);
                        setCount(_checked ? (count + 1) : (count - 1))
                        onChange(e)
                    }}
                />
                <span className='button button--md typography--medium'>
                    {label}
                </span>
            </div>
            {hasDocument && <HasDocumentIcon />}
        </div >
    )
}