import { FindMeResponseDTO } from "./dto";
import { AuthServiceImpl } from "./serviceImpl";

export interface AuthService {
    findMe(): Promise<FindMeResponseDTO>
}

export function NewAuthService(): AuthService {
    return new AuthServiceImpl();
}
