import { useEffect } from "react";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { FormGroup } from "../formGroup";
import { ModalComponent } from "../modal";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { TextInput } from "../textInput";
import colors from '../utils/index.module.scss';
import { editBankAccounts, setCompanyBankIban, setCompanyBankName, setOpenBankModal } from "./slice";

export function BankModal() {
    const companyState = useAppSelector(state => state.company)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (companyState.editBankStatus === 'successfully') {
            dispatch(setCompanyBankIban(''))
            dispatch(setCompanyBankName(''))
            dispatch(setOpenBankModal(false))
        }
    }, [companyState.editBankStatus])

    if (companyState.findCompanyResponse === undefined) {
        return <div></div>
    }

    return (
        <ModalComponent
            open={companyState.openBankModal}
            handleClose={() => dispatch(setOpenBankModal(false))}
        >
            <div
                style={{
                    padding: '20px',
                    backgroundColor: colors.white,
                    boxShadow: colors.boxShadowXL,
                    borderRadius: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                    width: '100%'
                }}
            >
                <div className="d-flex align-items-center justify-content-between">
                    <span className="text text--lg typography--semibold typography--black">Aggiungi Banca</span>
                </div>
                <div className="d-flex flex-column align-items-start justify-content-between" style={{ gap: '16px', width: '100%' }}>
                    <FormGroup label={"Banca"} inputs={[
                        <TextInput id={"add-bank-name"} type={"text"} placeholder={"Nome"} onChange={(e) => dispatch(setCompanyBankName(e.target.value))} />
                    ]} style={"column"} />
                    <FormGroup label={"IBAN"} inputs={[
                        <TextInput id={"add-bank-iban"} type={"text"} placeholder={"IBAN"} onChange={(e) => dispatch(setCompanyBankIban(e.target.value))} />
                    ]} style={"column"} />
                </div>
                <div style={{ margin: 0, padding: 0, gap: '12px' }} className="row">
                    <div className="col" style={{ margin: 0, padding: 0 }}><ButtonComponent label={"Annulla"} onClick={() => dispatch(setOpenBankModal(false))} color={Colors.NEUTRAL} variant={Variant.OUTLINE} size={Size.FIT} iconStyle={IconStyle.OFF} /></div>
                    <div
                        className="col"
                        style={{ margin: 0, padding: 0 }}
                    >
                        <ButtonComponent
                            label={"Aggiungi"}
                            onClick={() => {
                                let bankAccounts: string[] = []
                                if (companyState.findCompanyResponse !== undefined && companyState.findCompanyResponse.bankingAccounts !== undefined && companyState.findCompanyResponse.bankingAccounts !== null) {
                                    bankAccounts = [...companyState.findCompanyResponse.bankingAccounts]
                                }
                                bankAccounts.push(
                                    companyState.addBankRequest.name + '::' + companyState.addBankRequest.iban
                                )
                                dispatch(editBankAccounts(
                                    {
                                        companyId: companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse.id.toString() : '',
                                        data: {
                                            bankingAccounts: bankAccounts
                                        }
                                    }))
                            }}
                            color={Colors.PRIMARY}
                            variant={Variant.SOLID}
                            size={Size.FIT}
                            iconStyle={IconStyle.OFF}
                        />
                    </div>
                </div>
            </div >
        </ModalComponent >
    )
}