import axios from "axios"
import { keycloak } from "../../keycloak"
import { FindAllWithholdingsResponse, WithholdingCreationDTO, WithholdingsDTO, WithholdingsFilters } from "./dto"
import { WithholdingsService } from "./service"

export class WithholdingsServiceImpl implements WithholdingsService {

    public findAllWithholdings(filters: WithholdingsFilters): Promise<FindAllWithholdingsResponse> {
        let url: string = "/api/withholding?" +
            "page=" + filters.page +
            "&itemsPerPage=" + filters.itemsPerPage +
            (filters.companyId !== undefined ? '&companyId=' + filters.companyId : '') +
            (filters.period !== undefined ? '&period=' + filters.period : '') +
            (filters.year !== undefined ? '&year=' + filters.year : '')
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public deleteWithholding(id: number): Promise<void> {
        let url: string = "/api/withholding/" + id
        return axios({
            url: url,
            method: "DELETE",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public createWithholding(request: FormData): Promise<void> {
        let url: string = "/api/withholding"
        return axios({
            url: url,
            method: "POST",
            data: request,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findWithholding(id: number): Promise<WithholdingsDTO> {
        let url: string = "/api/withholding/" + id
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}