import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmOrDenyModal } from "../confirmOrDenyModal.tsx";
import { ContextMenuComponent } from "../contextMenu";
import { MenuItemComponent } from "../contextMenu/menuItem";
import { ActivityIcon } from "../icons/activity";
import { SettingsIcon } from "../icons/settings";
import { TrashIcon } from "../icons/trash";
import { keycloak } from "../keycloak";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import colors from '../utils/index.module.scss';
import { CompanyStatusEnum } from "./dto";
import { deleteCompany, setOpenDeleteCompanyModal, setOperationsTabValue, setSettingsTabValue } from "./slice";
interface Props {
    id: string,
    status: CompanyStatusEnum
}

export function CompanyMenuComponent(props: Props) {
    const { id } = props
    const { status } = props

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const companyState = useAppSelector(state => state.company)

    return (
        <ContextMenuComponent anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
            {
                status === CompanyStatusEnum.Active &&
                <MenuItemComponent
                    onClick={() => {
                        navigate('/company/operation/' + id + '/activity')
                        dispatch(setOperationsTabValue(0))
                    }}>
                    <ActivityIcon colorBase={colors.neutral600} />
                    Attività
                </MenuItemComponent>
            }
            <MenuItemComponent
                onClick={() => {
                    navigate('/company/settings/' + id + '/profile')
                    dispatch(setSettingsTabValue(0))
                }}>
                <SettingsIcon colorBase={colors.neutral600} />
                Impostazioni
            </MenuItemComponent>
            {
                status === CompanyStatusEnum.Active &&
                keycloak.hasRealmRole('admin') &&
                <MenuItemComponent
                    onClick={() => {
                        dispatch(setOpenDeleteCompanyModal(true))
                    }}>
                    <TrashIcon colorBase={colors.neutral600} />
                    Cancella
                </MenuItemComponent>
            }
            <ConfirmOrDenyModal
                open={companyState.openDeleteCompanyModal}
                handleClose={() => dispatch(setOpenDeleteCompanyModal(false))}
                title={"Elimina azienda"}
                description={"Sei sicuro di voler eliminare questa azienda?"}
                labelDeny={"Annulla"}
                labelConfirm={"Elimina"}
                actionConfirm={() => {
                    dispatch(deleteCompany(id))
                    dispatch(setOpenDeleteCompanyModal(false))
                }}
            />
        </ContextMenuComponent>
    )
}