import { useState } from "react";
import { deleteCompany } from "../company/slice";
import { ConfirmOrDenyModal } from "../confirmOrDenyModal.tsx";
import { ContextMenuComponent } from "../contextMenu";
import { MenuItemComponent } from "../contextMenu/menuItem";
import { CheckCircleIcon } from "../icons/checkCircle";
import { TrashIcon } from "../icons/trash";
import { useAppDispatch } from "../redux/hooks";
import colors from '../utils/index.module.scss';
import { setCompanyToConfirmModal, setCompanyToConfirmSettedId } from "./slice";

interface Props {
    id: number
}

export function CompanyToConfirmMenuComponent(props: Props) {
    const { id } = props
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)

    const dispatch = useAppDispatch()

    return (
        <ContextMenuComponent anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
            <MenuItemComponent
                onClick={() => {
                    dispatch(setCompanyToConfirmSettedId(id))
                    dispatch(setCompanyToConfirmModal(true))
                }}>
                <CheckCircleIcon colorBase={colors.neutral600} />
                Approva
            </MenuItemComponent>
            <MenuItemComponent
                onClick={() => {
                    setOpenDeleteModal(true)
                }}>
                <TrashIcon colorBase={colors.neutral600} />
                Cancella
            </MenuItemComponent>
            <ConfirmOrDenyModal
                open={openDeleteModal}
                handleClose={() => setOpenDeleteModal(false)}
                title={"Elimina azienda"}
                description={"Sei sicuro di voler eliminare questa azienda?"}
                labelDeny={"Annulla"}
                labelConfirm={"Elimina"}
                actionConfirm={() => {
                    dispatch(deleteCompany(id.toString()))
                    setOpenDeleteModal(false)
                }}
            />
        </ContextMenuComponent>
    )
}