import { ReactNode, useEffect } from "react";
import { ButtonComponent } from "../../button";
import { Colors, IconStyle, Size, Variant } from "../../button/dto";
import { FileInputComponent } from "../../fileInput";
import { HeadingComponent } from "../../heading";
import { HeadingSize } from "../../heading/dto";
import { DownloadIcon } from "../../icons/download";
import { HasDocumentIcon } from "../../icons/hasDocument";
import { PlusIcon } from "../../icons/plus";
import { TrashIcon } from "../../icons/trash";
import { NewObjectService } from "../../objects/service";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { SpinnerComponent } from "../../spinner";
import colors from '../../utils/index.module.scss';
import { findLoans, setFindLoansStatus, setLoans, setLoansNumber } from "../slice";

interface Props {
    disabled: boolean
}

export function Loans(props: Props) {
    const { disabled } = props
    const companyState = useAppSelector(state => state.company)
    const dispatch = useAppDispatch()

    const objectService = NewObjectService()

    const loans = companyState.findCompanyResponse?.loansObjectIds
    const loansRequest = companyState.loans

    useEffect(() => {
        if (companyState.findCompanyStatus === 'successfully' && companyState.findCompanyResponse !== undefined) {
            dispatch(setFindLoansStatus('idle'))
            if (loans !== undefined && loans !== null && loans.length > 0) {
                dispatch(findLoans(loans.map(id => id.toString())))
            }
            if (loansRequest === null || loansRequest.length === 0) {
                dispatch(setLoans([null]))
                dispatch(setLoansNumber(0))
            }
        }
    }, [companyState.findCompanyStatus, companyState.findCompanyResponse])

    useEffect(() => {
        if (companyState.findLoansStatus === 'successfully') {
            dispatch(setFindLoansStatus('idle'))
            if (companyState.findLoansResponse !== undefined && companyState.findLoansResponse.length > 0) {
                const objectStorage = NewObjectService()
                let newLoan: File[] = []
                companyState.findLoansResponse.forEach((file, index) => {
                    newLoan.push(objectStorage.base64ToFile(file))
                })
                dispatch(setLoans(newLoan))
                dispatch(setLoansNumber(newLoan.filter(loan => loan !== null).length))
            }
            else {
                dispatch(setLoans([null]))
                dispatch(setLoansNumber(0))
            }
        }
    }, [companyState.findLoansStatus])

    async function handleClick(loan: File, index: number) {
        const buffer = await loan.arrayBuffer()
        objectService.downloadPdf('Mutuo-' + (index + 1), buffer, loan.type)
    }

    if (companyState.findCompanyStatus === 'loading' || companyState.findLoansStatus === 'loading') {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <SpinnerComponent size="small" />
            </div>
        )
    }

    let loansList: ReactNode[] = []
    if (loansRequest !== undefined && loansRequest !== null && loansRequest.length > 0) {
        loansRequest.forEach((loan, index) => {
            if (loan === null) {
                loansList.push(
                    <div
                        key={'loan-' + index}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: 8,
                            borderRadius: '8px'
                        }}
                    >
                        <FileInputComponent id={"loan-" + index} disabled={disabled} onChange={e => {
                            if (e !== null && e !== undefined) {
                                const newLoan = [...loansRequest]
                                newLoan[index] = e[0]
                                dispatch(setLoans(newLoan))
                                dispatch(setLoansNumber(newLoan.filter(loan => loan !== null).length))
                            }
                        }} />
                        <ButtonComponent
                            label={""}
                            disabled={disabled}
                            onClick={() => {
                                let newLoans = [...loansRequest]
                                newLoans = newLoans.slice(0, index).concat(newLoans.slice(index + 1));
                                dispatch(setLoans(newLoans.length !== 0 ? newLoans : [null]))
                                dispatch(setLoansNumber(newLoans.filter(loan => loan !== null).length))
                            }}
                            color={Colors.PRIMARY}
                            variant={Variant.LINK}
                            size={Size.SM}
                            iconStyle={IconStyle.ONLY}
                            icon={<TrashIcon colorBase={colors.primary500} />}
                        />
                    </div>
                )
            } else {
                const size = (loan.size / (1024 * 1024)).toFixed(2)
                loansList.push(
                    <div
                        key={'loan-' + index}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: 8,
                            border: '1px solid ' + colors.neutral80,
                            borderRadius: '8px',
                            padding: '10px 12px 10px 12px'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: 8
                            }}
                        >
                            <HasDocumentIcon />
                            <span className="text text--md typography--medium thypography-neutral--800">{loansRequest[index]?.name === 'unknown' ? 'Mutuo-' + (index + 1) : loansRequest[index]?.name}</span>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: 8
                            }}
                        >
                            <span className="text text--md typography--regular thypography-neutral--400">{size + ' MB'}</span>
                            <ButtonComponent
                                label={""}
                                disabled={disabled}
                                onClick={() => {
                                    let newLoans = [...loansRequest]
                                    newLoans = newLoans.slice(0, index).concat(newLoans.slice(index + 1));
                                    dispatch(setLoans(newLoans.length !== 0 ? newLoans : [null]))
                                    dispatch(setLoansNumber(newLoans.filter(loan => loan !== null).length))
                                }}
                                color={Colors.PRIMARY}
                                variant={Variant.LINK}
                                size={Size.SM}
                                iconStyle={IconStyle.ONLY}
                                icon={<TrashIcon colorBase={colors.primary500} />}
                            />
                            <ButtonComponent
                                label={""}
                                disabled={disabled}
                                onClick={() => handleClick(loan, index)}
                                color={Colors.PRIMARY}
                                variant={Variant.LINK}
                                size={Size.SM}
                                iconStyle={IconStyle.ONLY}
                                icon={<DownloadIcon colorBase={colors.primary500} />}
                            />
                        </div>
                    </div>
                )
            }
        })
    }

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <HeadingComponent size={HeadingSize.SM} label={"Mutui"} buttons={[
                <ButtonComponent
                    disabled={loansRequest.length >= 5 || disabled}
                    label={"Aggiungi Mutuo"}
                    icon={<PlusIcon colorBase={""} />}
                    onClick={() => {
                        const newLoan = [...loansRequest]
                        newLoan.push(null)
                        dispatch(setLoans([...newLoan]))
                        dispatch(setLoansNumber(newLoan.filter(loan => loan !== null).length))
                    }}
                    color={Colors.PRIMARY}
                    variant={Variant.OUTLINE}
                    size={Size.SM}
                    iconStyle={IconStyle.LEFT}
                />
            ]}
            />
            {loansList}
        </div>
    )
}