import { useEffect, useState } from "react";
import { IconsProps } from "./dto";

export function BriefcaseIcon(props: IconsProps) {
    const [color, setColor] = useState<string>(props.colorBase)

    useEffect(() => {
        setColor(props.colorBase)
    }, [props.colorBase])

    return (
        <svg
            onMouseOver={() => setColor(props.colorHover !== undefined ? props.colorHover : props.colorBase)}
            onMouseOut={() => setColor(props.colorBase)}
            width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3333 18.9584H6.66665C2.81665 18.9584 2.09999 17.1667 1.91665 15.425L1.29165 8.75003C1.19999 7.87503 1.17499 6.58336 2.04165 5.61669C2.79165 4.78336 4.03332 4.38336 5.83332 4.38336H14.1667C15.975 4.38336 17.2167 4.79169 17.9583 5.61669C18.825 6.58336 18.8 7.87503 18.7083 8.75836L18.0833 15.4167C17.9 17.1667 17.1833 18.9584 13.3333 18.9584ZM5.83332 5.62503C4.42499 5.62503 3.45832 5.90003 2.96665 6.45003C2.55832 6.90003 2.42499 7.5917 2.53332 8.62503L3.15832 15.3C3.29999 16.6167 3.65832 17.7084 6.66665 17.7084H13.3333C16.3333 17.7084 16.7 16.6167 16.8417 15.2917L17.4667 8.63336C17.575 7.59169 17.4417 6.90003 17.0333 6.45003C16.5417 5.90003 15.575 5.62503 14.1667 5.62503H5.83332Z" fill={color} />
            <path d="M13.3333 5.62502C12.9916 5.62502 12.7083 5.34169 12.7083 5.00002V4.33335C12.7083 2.85002 12.7083 2.29169 10.6666 2.29169H9.33329C7.29163 2.29169 7.29163 2.85002 7.29163 4.33335V5.00002C7.29163 5.34169 7.00829 5.62502 6.66663 5.62502C6.32496 5.62502 6.04163 5.34169 6.04163 5.00002V4.33335C6.04163 2.86669 6.04163 1.04169 9.33329 1.04169H10.6666C13.9583 1.04169 13.9583 2.86669 13.9583 4.33335V5.00002C13.9583 5.34169 13.675 5.62502 13.3333 5.62502Z" fill={color} />
            <path d="M10 13.9583C7.70837 13.9583 7.70837 12.5417 7.70837 11.6917V10.8333C7.70837 9.65833 7.99171 9.375 9.16671 9.375H10.8334C12.0084 9.375 12.2917 9.65833 12.2917 10.8333V11.6667C12.2917 12.5333 12.2917 13.9583 10 13.9583ZM8.95837 10.625C8.95837 10.6917 8.95837 10.7667 8.95837 10.8333V11.6917C8.95837 12.55 8.95837 12.7083 10 12.7083C11.0417 12.7083 11.0417 12.575 11.0417 11.6833V10.8333C11.0417 10.7667 11.0417 10.6917 11.0417 10.625C10.975 10.625 10.9 10.625 10.8334 10.625H9.16671C9.10004 10.625 9.02504 10.625 8.95837 10.625Z" fill={color} />
            <path d="M11.6666 12.3083C11.3583 12.3083 11.0833 12.075 11.05 11.7583C11.0083 11.4166 11.25 11.1 11.5916 11.0583C13.7916 10.7833 15.9 9.94998 17.675 8.65831C17.95 8.44998 18.3416 8.51664 18.55 8.79998C18.75 9.07498 18.6916 9.46664 18.4083 9.67498C16.4583 11.0916 14.1583 12 11.7416 12.3083C11.7166 12.3083 11.6916 12.3083 11.6666 12.3083Z" fill={color} />
            <path d="M8.33333 12.3167C8.30833 12.3167 8.28333 12.3167 8.25833 12.3167C5.97499 12.0583 3.74999 11.225 1.82499 9.90833C1.54166 9.71667 1.46666 9.325 1.65833 9.04167C1.84999 8.75833 2.24166 8.68333 2.52499 8.875C4.28333 10.075 6.30833 10.8333 8.39166 11.075C8.73333 11.1167 8.98333 11.425 8.94166 11.7667C8.91666 12.0833 8.64999 12.3167 8.33333 12.3167Z" fill={color} />
        </svg>
    )
}