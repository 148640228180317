import { MenuItem } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { findAllAccountant } from "../accountant/slice";
import { AvatarComponent } from "../avatar";
import { AvatarSize, AvatarType } from "../avatar/dto";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { ErrorPopup } from "../errorPopup";
import { FileInputComponent } from "../fileInput";
import { FormGroup } from "../formGroup";
import { HeadingSize } from "../heading/dto";
import { MailIcon } from "../icons/mail";
import { SmartphoneIcon } from "../icons/smartphone";
import { LayoutComponent } from "../layout";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SelectComponent } from "../select";
import { MenuItems } from "../sidebar/dto";
import { SuccessPopup } from "../successPopup";
import { TextInput } from "../textInput";
import { toBase64 } from "../utils";
import { editFinancialAdvisorComplete, findAllFinancialAdvisor, findFinancialAdvisor, findFinancialAdvisorAvatarById, setEditFinancialAdvisorAdminAccountant, setEditFinancialAdvisorFindAvatarStatus, setEditFinancialAdvisorRequestBusinessName, setEditFinancialAdvisorRequestDescription, setEditFinancialAdvisorRequestName, setEditFinancialAdvisorRequestPhoneNumber, setEditFinancialAdvisorRequestSurname, setEditFinancialAdvisorStatus } from "./slice";

export function EditFinancialAdvisorView() {
    const dispatch = useAppDispatch()

    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const accountantState = useAppSelector(state => state.accountant)

    let formData = new FormData();
    let adminAccountantsMenuItems: ReactNode[] = []

    const [file, setFile] = useState<File | null>(null)
    const [avatarToBase64, setAvatarToBase64] = useState<string>('')

    useEffect(() => {
        dispatch(findFinancialAdvisor(''))
        dispatch(findAllAccountant({ page: 0, itemsPerPage: 0, isAdmin: 'true', financialAdvisor: '', accountant: '' }))
        dispatch(setEditFinancialAdvisorAdminAccountant(0))
        dispatch(setEditFinancialAdvisorRequestBusinessName(undefined))
        dispatch(setEditFinancialAdvisorRequestDescription(undefined))
        dispatch(setEditFinancialAdvisorRequestName(undefined))
        dispatch(setEditFinancialAdvisorRequestPhoneNumber(undefined))
        dispatch(setEditFinancialAdvisorRequestSurname(undefined))
    }, [])

    useEffect(() => {
        if (financialAdvisorState.financialAdvisorSettedId !== '') {
            dispatch(findFinancialAdvisor(financialAdvisorState.financialAdvisorSettedId))
            dispatch(findAllAccountant({ page: 0, itemsPerPage: 0, isAdmin: 'true', financialAdvisor: '', accountant: '' }))
        }
    }, [financialAdvisorState.financialAdvisorSettedId])

    useEffect(() => {
        if (financialAdvisorState.editFinancialAdvisorComplete === 'successfully') {
            dispatch(findFinancialAdvisor(financialAdvisorState.financialAdvisorSettedId))
            dispatch(findAllFinancialAdvisor(financialAdvisorState.findAllFinancialAdvisorFilters))
        }
    }, [financialAdvisorState.editFinancialAdvisorComplete])

    useEffect(() => {
        if (financialAdvisorState.findFinancialAdvisorStatus === 'successfully' && financialAdvisorState.findFinancialAdvisorResponse !== undefined && financialAdvisorState.findFinancialAdvisorResponse.avatarObjectId !== null) {
            dispatch(findFinancialAdvisorAvatarById(financialAdvisorState.findFinancialAdvisorResponse.avatarObjectId))
        }
    }, [financialAdvisorState.findFinancialAdvisorStatus])

    useEffect(() => {
        if (financialAdvisorState.editFinancialAdvisorFindAvatarStatus === 'successfully') {
            dispatch(setEditFinancialAdvisorFindAvatarStatus('idle'))
            if (financialAdvisorState.editFinancialAdvisorAvatar !== undefined && financialAdvisorState.editFinancialAdvisorAvatar !== null) {
                setAvatarToBase64(financialAdvisorState.editFinancialAdvisorAvatar)
            }
        }
    }, [financialAdvisorState.editFinancialAdvisorFindAvatarStatus])

    //TODO
    if (accountantState.findAllAccountantStatus === 'successfully' && accountantState.findAllAccountantResponse !== undefined) {
        accountantState.findAllAccountantResponse.data
            .filter(accountant => accountant.isAdmin)
            .forEach(adminAccountant => {
                adminAccountantsMenuItems.push(
                    <MenuItem value={adminAccountant.id}>
                        <AvatarComponent type={AvatarType.USER} size={AvatarSize.XS} name={adminAccountant.name + ' ' + adminAccountant.surname} src="" />
                    </MenuItem>
                )
            })
    }

    return (
        <LayoutComponent
            headingLabel={'Modifica Commercialista'}
            menuItem={MenuItems.FINANCIAL_ADVISOR}
            showSpinner={
                financialAdvisorState.findFinancialAdvisorStatus === 'loading' ||
                financialAdvisorState.editFinancialAdvisorComplete === 'loading' ||
                financialAdvisorState.findFinancialAdvisorStatus === 'failed'
            }
            headingButtons={[
                <ButtonComponent
                    disabled={
                        (financialAdvisorState.editFinancialAdvisorRequest.businessName !== undefined && financialAdvisorState.editFinancialAdvisorRequest.businessName === '') ||
                        (financialAdvisorState.editFinancialAdvisorRequest.name !== undefined && financialAdvisorState.editFinancialAdvisorRequest.name === '') ||
                        (financialAdvisorState.editFinancialAdvisorRequest.surname !== undefined && financialAdvisorState.editFinancialAdvisorRequest.surname === '') || (
                            financialAdvisorState.editFinancialAdvisorRequest.phoneNumber !== undefined &&
                            financialAdvisorState.editFinancialAdvisorRequest.phoneNumber !== null &&
                            financialAdvisorState.editFinancialAdvisorRequest.phoneNumber !== '' && (
                                financialAdvisorState.editFinancialAdvisorRequest.phoneNumber.length < 9 ||
                                financialAdvisorState.editFinancialAdvisorRequest.phoneNumber.length > 10
                            ))
                    }
                    key='heading-button-update-accountant'
                    label={"Aggiorna"}
                    onClick={() => {
                        if (file !== null)
                            formData.append('file', file)
                        formData.append('financialAdvisorUpdateDTO', JSON.stringify(financialAdvisorState.editFinancialAdvisorRequest))
                        dispatch(editFinancialAdvisorComplete({ id: financialAdvisorState.financialAdvisorSettedId, formData: formData, setAccountant: financialAdvisorState.setAdminAccountantRequest }))
                    }} color={Colors.PRIMARY} variant={Variant.SOLID} size={Size.SM} iconStyle={IconStyle.OFF}
                />]}
            breadcrumbItems={['Commercialista', 'Modifica Commercialista']}
            headingSize={HeadingSize.LG}
        >
            <ErrorPopup
                active={financialAdvisorState.editFinancialAdvisorComplete === 'failed'}
                close={() => dispatch(setEditFinancialAdvisorStatus('idle'))}
                message="Si è verificato un errore durante la modifica del commercialista"
            />
            <SuccessPopup
                active={financialAdvisorState.editFinancialAdvisorComplete === 'successfully'}
                close={() => dispatch(setEditFinancialAdvisorStatus('idle'))}
                message="Commercialista modificato"
            />
            <FormGroup required label={"Business name"}
                inputs={[
                    <TextInput id={"financial-advisor-edit-business-name"} defaultValue={financialAdvisorState.findFinancialAdvisorResponse?.businessName} onChange={e => dispatch(setEditFinancialAdvisorRequestBusinessName(e.target.value))} key={"financial-advisor-edit-name"} type={"text"} placeholder={"Business name"} />
                ]}
                style={"row"}
            />
            <FormGroup required label={"Nome"}
                inputs={[
                    <TextInput id={"financial-advisor-edit-name"} defaultValue={financialAdvisorState.findFinancialAdvisorResponse?.name} onChange={e => dispatch(setEditFinancialAdvisorRequestName(e.target.value))} key={"financial-advisor-edit-name"} type={"text"} placeholder={"Nome"} />,
                    <TextInput id={"financial-advisor-edit-surname"} defaultValue={financialAdvisorState.findFinancialAdvisorResponse?.surname} onChange={e => dispatch(setEditFinancialAdvisorRequestSurname(e.target.value))} key={"financial-advisor-edit-surname"} type={"text"} placeholder={"Cognome"} />
                ]}
                style={"row"}
            />
            <FormGroup label={"Email"}
                inputs={[
                    <TextInput startIcon={<MailIcon colorBase="" />} defaultValue={financialAdvisorState.findFinancialAdvisorResponse?.email} disabled id={"financial-advisor-edit-email"} key={"financial-advisor-edit-email"} type={"text"} placeholder={"Email"} />
                ]}
                style={"row"}
            />
            <FormGroup label={"Telefono"}
                inputs={[
                    <TextInput startIcon={<SmartphoneIcon colorBase="" />} infoText="Inserire da 9 a 10 caratteri" defaultValue={financialAdvisorState.findFinancialAdvisorResponse?.phoneNumber} onChange={e => dispatch(setEditFinancialAdvisorRequestPhoneNumber(e.target.value === '' ? null : e.target.value))} id={"financial-advisor-edit-phone"} key={"financial-advisor-edit-phone"} type={"text"} placeholder={"Telefono"} />
                ]}
                style={"row"}
            />
            <FormGroup label={"Avatar"} labelDescription="Verrà visualizzata sul vostro profilo"
                inputs={[
                    <div key={"financial-advisor-edit-avatar-upload"} className="d-flex align-items-center justify-content-center gap-2">
                        <AvatarComponent src={avatarToBase64} key={"financial-advisor-edit-avatar"} type={AvatarType.SINGLE} size={AvatarSize.XXL} />
                        <FileInputComponent onChange={e => { setFile(e[0]); toBase64(e[0]).then(data => setAvatarToBase64('data:' + e[0].type + ';base64,' + data)) }} id={"financial-advisor-edit-upload"} key={"financial-advisor-edit-upload"} />
                    </div>
                ]}
                style={"row"}
            />
            <FormGroup label={"Bio"} labelDescription="Scrivi una breve introduzione"
                inputs={[
                    <TextInput label="Descrizione" defaultValue={financialAdvisorState.findFinancialAdvisorResponse?.description} onChange={e => dispatch(setEditFinancialAdvisorRequestDescription(e.target.value))} id={"financial-advisor-edit-bio"} key={"financial-advisor-edit-bio"} type={"text"} multiline placeholder={"Un po' di informazioni su di te"} infoText="275 caratteri rimasti" />
                ]}
                style={"row"}
            />
            <FormGroup label={"Account"}
                inputs={[
                    <SelectComponent
                        id={"edit-accountant-admin-select"}
                        onChange={(e) => dispatch(setEditFinancialAdvisorAdminAccountant(e.target.value))}
                        defaultValue={financialAdvisorState.findFinancialAdvisorResponse?.accountantId}
                        menuItems={[adminAccountantsMenuItems]}
                    />
                ]}
                style={"row"}
            />
        </LayoutComponent>
    )
}