import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { SpinnerComponent } from "../../spinner";
import { SwitchComponent } from "../../switch";
import { setRetentionManagement } from "../slice";

interface Props {
    disabled: boolean
}

export function RetentionManagement(props: Props) {
    const { disabled } = props
    const companyState = useAppSelector(state => state.company)
    const dispatch = useAppDispatch()

    const retentionManagement = companyState.findCompanyResponse?.retentionManagement
    const retentionManagementRequest = companyState.companyRegistryDTO.retentionManagement

    useEffect(() => {
        if (companyState.findCompanyStatus === 'successfully' && companyState.findCompanyResponse !== undefined) {
            if (retentionManagement === undefined || retentionManagement === null) {
                dispatch(setRetentionManagement(false))
            } else {
                dispatch(setRetentionManagement(retentionManagement))
            }
        }
    }, [retentionManagement, companyState.findCompanyStatus, companyState.findCompanyResponse])

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '16px' }}>
            <SwitchComponent
                disabled={disabled}
                checked={retentionManagementRequest}
                onChange={(e) => {
                    if (e.target.checked) {
                        dispatch(setRetentionManagement(true))
                    } else {
                        dispatch(setRetentionManagement(false))
                    }
                }}
            />
            <span className="text text--lg typography--regular typography-neutral--500">Crea documento di sintesi per pagamento ritenute</span>
        </div>
    )
}