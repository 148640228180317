import { useEffect, useState } from "react"
import { IconsProps } from "./dto"

export function AlertOctagonIcon(props: IconsProps) {
    const [color, setColor] = useState<string>(props.colorBase)

    useEffect(() => {
        setColor(props.colorBase)
    }, [props.colorBase])

    return (
        <svg onMouseOver={() => setColor(props.colorHover !== undefined ? props.colorHover : props.colorBase)}
            onMouseOut={() => setColor(props.colorBase)}
            width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_224_69874)">
                <path d="M10 6.66699V10.0003M10 13.3337H10.0083M6.55 1.66699H13.45L18.3333 6.55033V13.4503L13.45 18.3337H6.55L1.66666 13.4503V6.55033L6.55 1.66699Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_224_69874">
                    <rect width="20" height="20" fill={color} />
                </clipPath>
            </defs>
        </svg>
    )
}