import { TableBody } from "@mui/material";
import { ReactNode, useEffect } from "react";
import { PillComponent } from "../pills";
import { PillsColor, PillsEmphasis, PillsOutline, PillsSize } from "../pills/dto";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SpinnerComponent } from "../spinner";
import { TableComponent } from "../table";
import { TableCellComponent } from "../table/tableCell";
import { TableHeadComponent } from "../table/tableHead";
import { TableRowComponent } from "../table/tableRow";
import { ActivityKindMap } from "./dto";
import { ActivityMenuComponent } from "./menu";
import { findAllActivityCategory, findAllActivityType } from "./slice";

export function ActivityListComponent() {
    const activityState = useAppSelector(state => state.activity)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(findAllActivityType(activityState.activityTypeFilters))
        dispatch(findAllActivityCategory())
    }, [])

    useEffect(() => {
        if (activityState.deleteActivityTypeStatus === 'successfully' || activityState.createActivityTypeStatus === 'successfully') {
            dispatch(findAllActivityType(activityState.activityTypeFilters))
            dispatch(findAllActivityCategory())
        }
    }, [activityState.deleteActivityTypeStatus, activityState.createActivityTypeStatus])

    if (
        activityState.findAllActivityTypeStatus === 'loading' ||
        activityState.findAllActivityCategoryStatus === 'loading'
    ) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                <SpinnerComponent size={"small"} />
            </div>
        )
    }

    if (
        activityState.findAllActivityTypeStatus === 'failed' ||
        activityState.findAllActivityCategoryStatus === 'failed'
    ) {
        return (<div>Si è verificato un errore durante il caricamento dei dati</div>)
    }

    let activityTypeRows: ReactNode[] = []
    if (
        activityState.findAllActivityTypeResponse !== undefined &&
        activityState.findAllActivityTypeResponse.data !== undefined &&
        activityState.findAllActivityTypeResponse.data.length > 0 &&
        activityState.findAllActivityCategoryResponse !== undefined &&
        activityState.findAllActivityCategoryResponse.data !== undefined &&
        activityState.findAllActivityCategoryResponse.data.length > 0
    ) {
        activityState.findAllActivityTypeResponse.data.forEach(activity => {
            const categoryType = activityState.findAllActivityCategoryResponse?.data.find(category => category.id === activity.activityCategoryId)?.name
            let activityKind = ActivityKindMap.get(activity.activityKind)
            activityTypeRows.push(
                <TableRowComponent key={'activity-type-row-list-' + activity.id}>
                    <TableCellComponent label={activity.name} cellType={"row-medium"} alignLabel={"left"} />
                    <TableCellComponent content={<PillComponent label={categoryType !== undefined ? categoryType : ''} size={PillsSize.XS} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent content={<PillComponent label={activityKind ?? ""} size={PillsSize.XS} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"left"} />
                    <TableCellComponent content={<ActivityMenuComponent id={activity.id.toString()} />} cellType={"row-regular"} alignLabel={"right"} />
                </TableRowComponent>
            )
        })
    }

    return (
        <TableComponent>
            <TableHeadComponent>
                <TableCellComponent label={"Nome"} cellType={"head"} alignLabel={"left"} />
                <TableCellComponent size='large' label={"categoria"} cellType={"head"} alignLabel={"left"} />
                <TableCellComponent size='large' label={"tipologia"} cellType={"head"} alignLabel={"left"} />
                <TableCellComponent cellType={"head"} alignLabel={"right"} size="context-menu" />
            </TableHeadComponent>
            <TableBody>
                {activityTypeRows}
            </TableBody>
        </TableComponent>
    )
}