import { MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { AvatarComponent } from "../avatar";
import { AvatarSize, AvatarType } from "../avatar/dto";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { ErrorPopup } from "../errorPopup";
import { FileInputComponent } from "../fileInput";
import { findAllFinancialAdvisorWithoutPagination } from "../financialAdvisor/slice";
import { FormGroup } from "../formGroup";
import { HeadingSize } from "../heading/dto";
import { MailIcon } from "../icons/mail";
import { SmartphoneIcon } from "../icons/smartphone";
import { LayoutComponent } from "../layout";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SelectComponent } from "../select";
import { MenuItems } from "../sidebar/dto";
import { SuccessPopup } from "../successPopup";
import { TextInput } from "../textInput";
import { toBase64 } from "../utils";
import { editOperator, findOperator, findOperatorAvatarById, setEditOperatorDescription, setEditOperatorFindAvatarStatus, setEditOperatorName, setEditOperatorPhoneNumber, setEditOperatorStatus, setEditOperatorSurname } from "./slice";

export function EditOperatorView() {
    const dispatch = useAppDispatch()

    const operatorState = useAppSelector(state => state.operator)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)

    let formData = new FormData();

    const [file, setFile] = useState<File | null>(null)
    const [avatarToBase64, setAvatarToBase64] = useState<string>('')

    useEffect(() => {
        dispatch(findOperator(operatorState.operatorSettedId))
        dispatch(findAllFinancialAdvisorWithoutPagination())
        dispatch(setEditOperatorDescription(undefined))
        dispatch(setEditOperatorName(undefined))
        dispatch(setEditOperatorPhoneNumber(undefined))
        dispatch(setEditOperatorSurname(undefined))
    }, [])

    useEffect(() => {
        if (operatorState.operatorSettedId !== '') {
            dispatch(findOperator(operatorState.operatorSettedId))
            dispatch(findAllFinancialAdvisorWithoutPagination())
        }
    }, [operatorState.operatorSettedId])

    useEffect(() => {
        if (operatorState.editOperatorStatus === 'successfully') {
            dispatch(findOperator(operatorState.operatorSettedId))
        }
    }, [operatorState.editOperatorStatus])

    useEffect(() => {
        if (operatorState.findOperatorStatus === 'successfully' && operatorState.findOperatorResponse !== undefined && operatorState.findOperatorResponse.avatarObjectId !== null) {
            dispatch(findOperatorAvatarById(operatorState.findOperatorResponse.avatarObjectId.toString()))
        }
    }, [operatorState.findOperatorStatus])

    useEffect(() => {
        if (operatorState.editOperatorFindAvatarStatus === 'successfully') {
            dispatch(setEditOperatorFindAvatarStatus('idle'))
            if (operatorState.editOperatorAvatar !== undefined && operatorState.editOperatorAvatar !== null) {
                setAvatarToBase64(operatorState.editOperatorAvatar)
            }
        }
    }, [operatorState.editOperatorFindAvatarStatus])

    return (
        <LayoutComponent
            headingLabel={'Modifica Collaboratore'}
            menuItem={MenuItems.OPERATOR}
            showSpinner={
                operatorState.findOperatorStatus === 'loading' ||
                operatorState.editOperatorStatus === 'loading' ||
                operatorState.editOperatorAvatar === 'loading' ||
                financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'loading' ||
                operatorState.findOperatorStatus === 'failed'
            }
            headingButtons={[
                <ButtonComponent
                    key='heading-button-update-operator'
                    label={"Aggiorna"}
                    onClick={() => {
                        if (file !== null)
                            formData.append('file', file)
                        formData.append('operatorUpdateDTO', JSON.stringify(operatorState.editOperatorRequest))
                        dispatch(editOperator({ id: operatorState.operatorSettedId, data: formData }))
                    }} color={Colors.PRIMARY} variant={Variant.SOLID} size={Size.SM} iconStyle={IconStyle.OFF}
                    disabled={
                        (operatorState.editOperatorRequest.name !== undefined && operatorState.editOperatorRequest.name === '') ||
                        (operatorState.editOperatorRequest.surname !== undefined && operatorState.editOperatorRequest.surname === '') || (
                            (
                                operatorState.editOperatorRequest.phoneNumber !== undefined && operatorState.editOperatorRequest.phoneNumber !== '' && operatorState.editOperatorRequest.phoneNumber !== null &&
                                (operatorState.editOperatorRequest.phoneNumber.length < 9 || operatorState.editOperatorRequest.phoneNumber.length > 10)
                            )

                        )
                    }
                />]}
            breadcrumbItems={['Collaboratore', 'Modifica Collaboratore']}
            headingSize={HeadingSize.LG}
        >
            <ErrorPopup
                active={operatorState.editOperatorStatus === 'failed'}
                close={() => dispatch(setEditOperatorStatus('idle'))}
                message="Si è verificato un errore durante la modifica del collaboratore"
            />
            <SuccessPopup
                active={operatorState.editOperatorStatus === 'successfully'}
                close={() => dispatch(setEditOperatorStatus('idle'))}
                message="Collaboratore modificato"
            />
            <FormGroup label={"Nome"} required
                inputs={[
                    <TextInput id={"operator-edit-name"} defaultValue={operatorState.findOperatorResponse?.name} onChange={e => dispatch(setEditOperatorName(e.target.value))} key={"operator-edit-name"} type={"text"} placeholder={"nome"} />,
                    <TextInput id={"operator-edit-surname"} defaultValue={operatorState.findOperatorResponse?.surname} onChange={e => dispatch(setEditOperatorSurname(e.target.value))} key={"operator-edit-surname"} type={"text"} placeholder={"cognome"} />
                ]}
                style={"row"}
            />
            <FormGroup label={"Email"}
                inputs={[
                    <TextInput startIcon={<MailIcon colorBase="" />} defaultValue={operatorState.findOperatorResponse?.email} disabled id={"operator-edit-email"} key={"operator-edit-email"} type={"text"} placeholder={"email"} />
                ]}
                style={"row"}
            />
            <FormGroup label={"Telefono"}
                inputs={[
                    <TextInput infoText="Inserire da 9 a 10 caratteri" startIcon={<SmartphoneIcon colorBase="" />} defaultValue={operatorState.findOperatorResponse?.phoneNumber} onChange={e => dispatch(setEditOperatorPhoneNumber(e.target.value === '' ? null : e.target.value))} id={"operator-edit-phone"} key={"operator-edit-phone"} type={"text"} placeholder={"telefono"} />
                ]}
                style={"row"}
            />
            <FormGroup label={"Avatar"} labelDescription="Verrà visualizzata sul vostro profilo"
                inputs={[
                    <div key={"operator-edit-avatar-upload"} className="d-flex align-items-center justify-content-center gap-2">
                        <AvatarComponent src={avatarToBase64} key={"operator-edit-avatar"} type={AvatarType.SINGLE} size={AvatarSize.XXL} />
                        <FileInputComponent onChange={e => { setFile(e[0]); toBase64(e[0]).then(data => setAvatarToBase64('data:' + e[0].type + ';base64,' + data)) }} id={"operator-edit-upload"} key={"operator-edit-upload"} />
                    </div>
                ]}
                style={"row"}
            />
            <FormGroup label={"Bio"} labelDescription="Scrivi una breve introduzione"
                inputs={[
                    <TextInput label="Descrizione" defaultValue={operatorState.findOperatorResponse?.description} onChange={e => dispatch(setEditOperatorDescription(e.target.value))} id={"operator-edit-bio"} key={"operator-edit-bio"} type={"text"} multiline placeholder={"Un po' di informazioni su di te"} infoText="275 caratteri rimasti" />
                ]}
                style={"row"}
            />
            <FormGroup label={"Commercialista"}
                inputs={[
                    <SelectComponent id={"edit-operator-admin-select"}
                        defaultValue={operatorState.findOperatorResponse?.financialAdvisorId.toString()}
                        menuItems={[
                            <MenuItem
                                key={'select-operator-financial-advisor'}
                                value={financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse?.data.find(financial => financial.id.toString() === operatorState.findOperatorResponse?.financialAdvisorId.toString())?.id}
                            >
                                {financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse?.data.find(financial => financial.id.toString() === operatorState.findOperatorResponse?.financialAdvisorId.toString())?.name + ' ' + financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse?.data.find(financial => financial.id.toString() === operatorState.findOperatorResponse?.financialAdvisorId.toString())?.surname}
                            </MenuItem>]}
                        disabled />]}
                style={"row"}
            />
        </LayoutComponent>
    )
}