import { MenuItem } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { AvatarComponent } from "../avatar";
import { AvatarSize, AvatarType } from "../avatar/dto";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { ErrorPopup } from "../errorPopup";
import { FileInputComponent } from "../fileInput";
import { findAllFinancialAdvisorWithoutPagination, findFinancialAdvisorsListAvatarsById } from "../financialAdvisor/slice";
import { FormGroup } from "../formGroup";
import { HeadingSize } from "../heading/dto";
import { MailIcon } from "../icons/mail";
import { SmartphoneIcon } from "../icons/smartphone";
import { LayoutComponent } from "../layout";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SelectComponent } from "../select";
import { MenuItems } from "../sidebar/dto";
import { SuccessPopup } from "../successPopup";
import { SwitchComponent } from "../switch";
import { TextInput } from "../textInput";
import { toBase64 } from "../utils";
import { editAccountant, findAccountant, findAccountantAvatarById, findAllAccountant, setEditAccountantDescription, setEditAccountantFindAvatarStatus, setEditAccountantName, setEditAccountantPhoneNumber, setEditAccountantStatus, setEditAccountantSurname } from "./slice";

export function EditAccountantView() {
    const dispatch = useAppDispatch()

    const accountantState = useAppSelector(state => state.accountant)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)

    let formData = new FormData();
    let adminAccountantsMenuItems: ReactNode[] = []

    const [file, setFile] = useState<File | null>(null)
    const [avatarToBase64, setAvatarToBase64] = useState<string>('')

    useEffect(() => {
        dispatch(findAccountant(accountantState.accountantSettedId))
        dispatch(findAllAccountant(accountantState.findAllAccountantFilters))
        dispatch(setEditAccountantName(undefined))
        dispatch(setEditAccountantSurname(undefined))
        dispatch(setEditAccountantDescription(undefined))
        dispatch(setEditAccountantPhoneNumber(undefined))
        dispatch(findAllFinancialAdvisorWithoutPagination())
    }, [])

    useEffect(() => {
        if (accountantState.accountantSettedId !== '') {
            dispatch(findAccountant(accountantState.accountantSettedId))
            dispatch(findAllAccountant(accountantState.findAllAccountantFilters))
        }
    }, [accountantState.accountantSettedId])

    useEffect(() => {
        if (accountantState.editAccountantStatus === 'successfully') {
            dispatch(findAccountant(accountantState.accountantSettedId))
        }
    }, [accountantState.editAccountantStatus])

    useEffect(() => {
        if (accountantState.findAccountantStatus === 'successfully' && accountantState.findAccountantResponse !== undefined && accountantState.findAccountantResponse.avatarObjectId !== null) {
            dispatch(findAccountantAvatarById(accountantState.findAccountantResponse.avatarObjectId))
        }
    }, [accountantState.findAccountantStatus])

    useEffect(() => {
        if (accountantState.editAccountantFindAvatarStatus === 'successfully') {
            dispatch(setEditAccountantFindAvatarStatus('idle'))
            if (accountantState.editAccountantAvatar !== undefined && accountantState.editAccountantAvatar !== null) {
                setAvatarToBase64(accountantState.editAccountantAvatar)
            }
        }
    }, [accountantState.editAccountantFindAvatarStatus])

    useEffect(() => {
        if (
            financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === "successfully" &&
            financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse !== undefined &&
            financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data !== undefined &&
            financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data.length > 0
        ) {
            dispatch(findFinancialAdvisorsListAvatarsById(financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data.filter(data => data.accountantId === accountantState.findAccountantResponse?.id).map(data => data.avatarObjectId)))
        }
    }, [financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus])

    //TODO
    if (accountantState.findAllAccountantStatus === 'successfully' && accountantState.findAllAccountantResponse !== undefined) {
        accountantState.findAllAccountantResponse.data
            .filter(accountant => accountant.isAdmin)
            .forEach(adminAccountant => {
                adminAccountantsMenuItems.push(
                    <MenuItem value={adminAccountant.id}>
                        <AvatarComponent type={AvatarType.USER} size={AvatarSize.XS} name={adminAccountant.name + ' ' + adminAccountant.surname} src={adminAccountant.avatarObjectId} />
                    </MenuItem>
                )
            })
    }

    return (
        <LayoutComponent
            headingLabel={'Modifica Contabile'}
            menuItem={MenuItems.ACCOUNTANT}
            showSpinner={
                accountantState.findAccountantStatus === 'failed' ||
                accountantState.findAccountantStatus === 'loading' ||
                accountantState.editAccountantStatus === 'loading' ||
                financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'loading'
            }
            headingButtons={[
                <ButtonComponent
                    key='heading-button-update-accountant'
                    label={"Aggiorna"}
                    onClick={() => {
                        if (file !== null)
                            formData.append('file', file)
                        formData.append('accountantUpdateDTO', JSON.stringify(accountantState.editAccountantRequest))
                        dispatch(editAccountant({ id: accountantState.accountantSettedId, data: formData }))
                    }} color={Colors.PRIMARY} variant={Variant.SOLID} size={Size.SM} iconStyle={IconStyle.OFF}
                    disabled={
                        (accountantState.editAccountantRequest.name !== undefined && accountantState.editAccountantRequest.name === '') ||
                        (accountantState.editAccountantRequest.surname !== undefined && accountantState.editAccountantRequest.surname === '') ||
                        (
                            accountantState.editAccountantRequest.phoneNumber !== undefined &&
                            accountantState.editAccountantRequest.phoneNumber !== null &&
                            accountantState.editAccountantRequest.phoneNumber !== ''
                            && (
                                accountantState.editAccountantRequest.phoneNumber.length < 9 ||
                                accountantState.editAccountantRequest.phoneNumber.length > 10)
                        )
                    }
                />]}
            breadcrumbItems={['Contabile', 'Modifica Contabile']}
            headingSize={HeadingSize.LG}
        >
            <ErrorPopup
                active={accountantState.editAccountantStatus === 'failed'}
                close={() => dispatch(setEditAccountantStatus('idle'))}
                message="Si è verificato un errore durante la modifica del contabile"
            />
            <SuccessPopup
                active={accountantState.editAccountantStatus === 'successfully'}
                close={() => dispatch(setEditAccountantStatus('idle'))}
                message="Contabile modificato"
            />
            <FormGroup label={"Account"}
                inputs={[
                    <SwitchComponent id={"accountant-edit-is-admin"} checked={accountantState.findAccountantResponse?.isAdmin} key={"accountant-edit-surname"} disabled />
                ]}
                style={"row"}
            />
            <FormGroup label={"Nome"} required
                inputs={[
                    <TextInput id={"accountant-edit-name"} defaultValue={accountantState.findAccountantResponse?.name} onChange={e => dispatch(setEditAccountantName(e.target.value))} key={"accountant-edit-name"} type={"text"} placeholder={"nome"} />,
                    <TextInput id={"accountant-edit-surname"} defaultValue={accountantState.findAccountantResponse?.surname} onChange={e => dispatch(setEditAccountantSurname(e.target.value))} key={"accountant-edit-surname"} type={"text"} placeholder={"cognome"} />
                ]}
                style={"row"}
            />
            <FormGroup label={"Email"}
                inputs={[
                    <TextInput startIcon={<MailIcon colorBase="" />} defaultValue={accountantState.findAccountantResponse?.email} disabled id={"accountant-edit-email"} key={"accountant-edit-email"} type={"text"} placeholder={"email"} />
                ]}
                style={"row"}
            />
            <FormGroup label={"Telefono"}
                inputs={[
                    <TextInput infoText="Inserire da 9 a 10 caratteri" startIcon={<SmartphoneIcon colorBase="" />} defaultValue={accountantState.findAccountantResponse?.phoneNumber} onChange={e => dispatch(setEditAccountantPhoneNumber(e.target.value === '' ? null : e.target.value))} id={"accountant-edit-phone"} key={"accountant-edit-phone"} type={"text"} placeholder={"telefono"} />
                ]}
                style={"row"}
            />
            <FormGroup label={"Avatar"} labelDescription="Verrà visualizzata sul vostro profilo"
                inputs={[
                    <div key={"accountant-edit-avatar-upload"} className="d-flex align-items-center justify-content-center gap-2">
                        <AvatarComponent src={avatarToBase64} key={"accountant-edit-avatar"} type={AvatarType.SINGLE} size={AvatarSize.XXL} />
                        <FileInputComponent onChange={e => { setFile(e[0]); toBase64(e[0]).then(data => setAvatarToBase64('data:' + e[0].type + ';base64,' + data)) }} id={"accountant-edit-upload"} key={"accountant-edit-upload"} />
                    </div>
                ]}
                style={"row"}
            />
            <FormGroup label={"Bio"} labelDescription="Scrivi una breve introduzione"
                inputs={[
                    <TextInput label="Descrizione" defaultValue={accountantState.findAccountantResponse?.description} onChange={e => dispatch(setEditAccountantDescription(e.target.value))} id={"accountant-edit-bio"} key={"accountant-edit-bio"} type={"text"} multiline placeholder={"Un po' di informazioni su di te"} infoText="275 caratteri rimasti" />
                ]}
                style={"row"}
            />
            {
                !accountantState.findAccountantResponse?.isAdmin && (
                    <FormGroup label={"Account"}
                        inputs={[<SelectComponent id={"edit-accountant-admin-select"} defaultValue={accountantState.findAccountantResponse?.adminAccountantId} menuItems={[<MenuItem key={'select-admin-accountant'} value=''>Seleziona il l'account...</MenuItem>, adminAccountantsMenuItems]} disabled />]}
                        style={"row"}
                    />
                )
            }
            {
                accountantState.findAccountantResponse?.isAdmin && (
                    <FormGroup label={"Commercialisti associati"} labelDescription="Elenco dei commercialisti associati all'Account"
                        inputs={
                            [
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                    {financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse?.data
                                        .filter(data => data.accountantId === accountantState.findAccountantResponse?.id)
                                        .map((financialAdvisor, index) => (
                                            <div className="company-roles-frame--active">
                                                <AvatarComponent
                                                    src={financialAdvisorState.findFinancialAdvisorsListAvatarsByIdResponse[index] || ''}
                                                    type={AvatarType.USER} size={AvatarSize.MD}
                                                    name={financialAdvisor.name + ' ' + financialAdvisor.surname}
                                                    subLabel={financialAdvisor.email}
                                                />
                                            </div>
                                        ))}
                                </div>
                            ]
                        }
                        style={"row"}
                    />
                )
            }
        </LayoutComponent>
    )
}