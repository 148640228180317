import { Tabs } from "@mui/material";
import { ReactNode } from "react";
import colors from '../utils/index.module.scss';

interface Props {
    children: ReactNode
    value: number
    negativeMargin: number
    handleChange: (event: React.SyntheticEvent, newValue: number) => void
}

export function HorizontalTabSecondaryComponent(props: Props) {
    const { children } = props;
    const { value } = props;
    const { negativeMargin } = props;
    const { handleChange } = props;

    return (
        <Tabs
            variant="scrollable"
            sx={{
                '& .MuiTabs-indicator': {
                    display: 'none',
                },
                '& .MuiTabs-scroller': {
                    minHeight: '10px !important',
                    height: '52px',
                },
                "& .MuiButtonBase-root": {
                    background: colors.primary50,
                    padding: '16px 24px 16px 24px',
                    minHeight: '10px !important',
                    height: '52px !important',
                    minWidth: '10px !important',
                    color: colors.neutral400,
                    fontFamily: 'Figtree',
                    fontWeight: 500,
                    fontSize: '14px !important',
                    lineHeight: '20px !important',
                    textTransform: 'none',
                    transition: '.2s all ease',
                    width: 'auto !important',
                    gap: '8px',
                    borderBottom: '2px solid ' + colors.primary50,
                    '&.Mui-selected': {
                        background: colors.primary50,
                        color: colors.primary500,
                        borderBottom: '2px solid ' + colors.primary500,
                    },
                    '&:hover': {
                        background: colors.primary50,
                        color: colors.primary500
                    },
                    '&:disabled': {
                        color: colors.neutral200
                    }
                },
                '& .MuiTabs-flexContainer': {
                    display: 'flex',
                    justifyContent: 'flex-start',
                    height: '52px',
                    backgroundColor: colors.primary50
                },
                '& .MuiTabs-root': {
                    minHeight: '10px !important',
                    height: '52px',
                },
                minHeight: '10px !important',
                height: '52px',
                width: 'calc(100% + 2 * ' + negativeMargin + 'px)',
                margin: '-16px -' + negativeMargin + 'px 0 -' + negativeMargin + 'px !important',
                backgroundColor: colors.primary50,
                borderRadius: '0px 0px 8px 8px'
            }}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
        >
            {children}
        </Tabs>
    )
}
