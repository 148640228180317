import axios from "axios"
import { keycloak } from "../../keycloak"
import { ActivityDTO } from "../dto"
import { ControlsService } from "./service"

export class ControlsServiceImpl implements ControlsService {

    public findActivityById(id: number): Promise<ActivityDTO> {
        let url: string = "/api/activities/" + id
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}