import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import AccountantReducer from '../accountant/slice';
import ActivityReducer from '../activity/slice';
import AuthReducer from '../auth/slice';
import ChatReducerV2 from '../company/chatV2/slice';
import CompanyReducer from '../company/slice';
import FiscalDocumentsReducer from '../company/taxArea/sliceDocument';
import EventsReducer from '../company/taxArea/sliceEvents';
import WithholdingsSlice from '../company/withholdings/slice';
import CompanyQuotationReducer from '../companyQuotationWizard/slice';
import CompanyToConfirmReducer from '../companyToConfirm/slice';
import DashboardReducer from '../dashboard/slice';
import FinancialAdvisorReducer from '../financialAdvisor/slice';
import SecuredReducer from '../keycloak/slice';
import ObjectReducer from '../objects/slice';
import OperatorReducer from '../operator/slice';
import SidebarReducer from '../sidebar/slice';
import ControlsSlice from '../company/controls/slice'

export const store = configureStore({
    reducer: {
        secured: SecuredReducer,
        sidebar: SidebarReducer,
        dashboard: DashboardReducer,
        company: CompanyReducer,
        accountant: AccountantReducer,
        financialAdvisor: FinancialAdvisorReducer,
        activity: ActivityReducer,
        object: ObjectReducer,
        companyQuotation: CompanyQuotationReducer,
        companyToConfirm: CompanyToConfirmReducer,
        auth: AuthReducer,
        operator: OperatorReducer,
        fiscalDocument: FiscalDocumentsReducer,
        events: EventsReducer,
        withholdings: WithholdingsSlice,
        chatV2: ChatReducerV2,
        controls: ControlsSlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

