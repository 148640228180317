import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './index.scss';
import { keycloak } from './keycloak';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';

keycloak.init({
  onLoad: 'login-required',
  redirectUri: window.location.href,
  pkceMethod: "S256"
})
  .then()
  .catch((error) => { })

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

keycloak.onTokenExpired = () => {
  keycloak.updateToken(300)
    .catch(() => { keycloak.logout() });
}

keycloak.onAuthRefreshError = () => {
  keycloak.logout()
}

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
