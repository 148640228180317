import { MenuItem } from "@mui/material";
import { ReactNode, useEffect } from "react";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { findAllFinancialAdvisorWithoutPagination } from "../financialAdvisor/slice";
import { SearchIcon } from "../icons/search";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SelectComponent } from "../select";
import { SpinnerComponent } from "../spinner";
import { TextInput } from "../textInput";
import { findAllAccountant, setAccountantFilter, setFinancialAdvisorFilter, setRoleFilter } from "./slice";

export function AccountantFiltersComponent() {
    const accountantState = useAppSelector(state => state.accountant)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)

    let financialAdvisorsMenuItems: ReactNode[] = []

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(findAllFinancialAdvisorWithoutPagination())
    }, [])

    if (financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'loading') {
        return (
            <div>
                <SpinnerComponent size={"small"} />
            </div>
        )
    }

    if (financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'failed') {
        return (<div>Si è verificato un errore</div>)
    }

    if (financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'successfully' && financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse !== undefined && financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data !== undefined && financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data.length > 0) {
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data.forEach(financialAdvisor => {
            financialAdvisorsMenuItems.push(
                <MenuItem key={'accountant-financial-advisor-filter-' + financialAdvisor.id} value={financialAdvisor.id}>{financialAdvisor.name + ' ' + financialAdvisor.surname}</MenuItem>
            )
        })
    }

    return (
        <div style={{ marginTop: '8px', gap: '16px' }} className="row m-0 p-0`">
            <div className="col-12 col-lg-5 p-0 m-0">
                <TextInput
                    id="accountant-accountant-filter"
                    type={"text"}
                    placeholder={"Cerca un contabile"}
                    startIcon={<SearchIcon colorBase={""} />}
                    onChange={(e) => dispatch(setAccountantFilter(e.target.value))}
                    value={accountantState.findAllAccountantFilters.accountant}
                />
            </div>
            <div className="col-12 col-lg p-0 m-0">
                <SelectComponent
                    value={accountantState.findAllAccountantFilters.isAdmin}
                    id={"accountant-role-filter"}
                    onChange={(e) => {
                        dispatch(setRoleFilter(e.target.value))
                        dispatch(findAllAccountant({
                            itemsPerPage: accountantState.findAllAccountantFilters.itemsPerPage,
                            page: accountantState.findAllAccountantFilters.page,
                            accountant: accountantState.findAllAccountantFilters.accountant,
                            financialAdvisor: accountantState.findAllAccountantFilters.financialAdvisor,
                            isAdmin: e.target.value as 'true' | 'false' | ''
                        }))
                    }}
                    menuItems={
                        [
                            <MenuItem key='' value=''>Ruolo</MenuItem>,
                            <MenuItem key='true' value='true'>Account</MenuItem>,
                            <MenuItem key='false' value='false'>Contabile</MenuItem>
                        ]
                    } />
            </div>
            <div className="col-12 col-lg p-0 m-0">
                <SelectComponent
                    id={"accountant-financial-advisor-filter"}
                    onChange={(e) => {
                        dispatch(setFinancialAdvisorFilter(e.target.value))
                        dispatch(findAllAccountant({
                            itemsPerPage: accountantState.findAllAccountantFilters.itemsPerPage,
                            page: accountantState.findAllAccountantFilters.page,
                            accountant: accountantState.findAllAccountantFilters.accountant,
                            financialAdvisor: e.target.value,
                            isAdmin: accountantState.findAllAccountantFilters.isAdmin
                        }))
                    }}
                    value={accountantState.findAllAccountantFilters.financialAdvisor}
                    menuItems={
                        [
                            <MenuItem key='' value=''>Tutti i commercialisti</MenuItem>,
                            financialAdvisorsMenuItems
                        ]
                    }
                />
            </div>
            <div className="col-12 col-lg-auto p-0 m-0 d-flex align-items-center justify-content-center">
                <div className="row p-0 m-0" style={{ gap: '8px' }}>
                    <div className="col p-0 m-0">
                        <ButtonComponent
                            label={"Pulisci"}
                            onClick={() => {
                                dispatch(setFinancialAdvisorFilter(''))
                                dispatch(setAccountantFilter(''))
                                dispatch(setRoleFilter(''))
                                dispatch(findAllAccountant({ accountant: '', financialAdvisor: '', isAdmin: '', itemsPerPage: accountantState.findAllAccountantFilters.itemsPerPage, page: accountantState.findAllAccountantFilters.page }))
                            }}
                            color={Colors.NEUTRAL}
                            variant={Variant.OUTLINE}
                            size={Size.MD}
                            iconStyle={IconStyle.OFF}
                        />
                    </div>
                    <div className="col p-0 m-0">
                        <ButtonComponent label={"Applica"} onClick={() => dispatch(findAllAccountant(accountantState.findAllAccountantFilters))} color={Colors.PRIMARY} variant={Variant.OUTLINE} size={Size.MD} iconStyle={IconStyle.OFF} />
                    </div>
                </div>
            </div>
        </div>
    )
}