import { ReactNode, useEffect } from "react";
import { ButtonComponent } from "../../button";
import { Colors, IconStyle, Size, Variant } from "../../button/dto";
import { HeadingComponent } from "../../heading";
import { HeadingSize } from "../../heading/dto";
import { PlusIcon } from "../../icons/plus";
import { TrashIcon } from "../../icons/trash";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { SpinnerComponent } from "../../spinner";
import { TextInput } from "../../textInput";
import colors from '../../utils/index.module.scss';
import { setMembers, setMembersNumber } from "../slice";

interface Props {
    disabled: boolean
}

export function Partners(props: Props) {
    const { disabled } = props
    const companyState = useAppSelector(state => state.company)
    const dispatch = useAppDispatch()

    const members = companyState.findCompanyResponse?.members
    const membersRequest = companyState.companyRegistryDTO.members

    useEffect(() => {
        if (companyState.findCompanyStatus === 'successfully' && companyState.findCompanyResponse !== undefined) {
            if (members === undefined || members === null || members.length === 0) {
                dispatch(setMembers(['']))
                dispatch(setMembersNumber(0))
            } else {
                dispatch(setMembers(members))
                dispatch(setMembersNumber(members.length))
            }
        }
    }, [members, companyState.findCompanyStatus, companyState.findCompanyResponse])

    let membersList: ReactNode[] = []
    if (membersRequest !== undefined && membersRequest !== null && membersRequest.length > 0) {
        membersRequest.forEach((admin, index) => {
            membersList.push(
                <div
                    key={'member-' + index}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 24
                    }}
                >
                    <TextInput
                        id={"member-input-" + index}
                        disabled={disabled}
                        type={"text"}
                        placeholder={"Nome amministratore"}
                        value={membersRequest[index]}
                        onChange={(e) => {
                            const newMember = [...membersRequest]
                            newMember[index] = e.target.value
                            dispatch(setMembers(newMember))
                        }}
                    />
                    <ButtonComponent
                        label={""}
                        disabled={disabled}
                        onClick={() => {
                            let newMembers = [...membersRequest]
                            newMembers = newMembers.slice(0, index).concat(newMembers.slice(index + 1));
                            dispatch(setMembers(newMembers.length === 0 ? [""] : newMembers))
                            dispatch(setMembersNumber(newMembers.length))
                        }}
                        color={Colors.PRIMARY}
                        variant={Variant.LINK}
                        size={Size.SM}
                        iconStyle={IconStyle.ONLY}
                        icon={<TrashIcon colorBase={colors.primary500} />}
                    />
                </div>
            )
        })
    }

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <HeadingComponent size={HeadingSize.SM} label={"Soci"} buttons={[
                <ButtonComponent
                    disabled={membersRequest.length >= 10 || disabled}
                    label={"Aggiungi Socio"}
                    icon={<PlusIcon colorBase={""} />}
                    onClick={() => {
                        const newMember = [...membersRequest]
                        newMember.push('')
                        dispatch(setMembers(newMember))
                        dispatch(setMembersNumber(newMember.length))
                    }}
                    color={Colors.PRIMARY}
                    variant={Variant.OUTLINE}
                    size={Size.SM}
                    iconStyle={IconStyle.LEFT}
                />
            ]}
            />
            {membersList}
        </div>
    )
}