import { useEffect } from "react";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { EmptyList } from "../emptyList/emptyList";
import { ErrorPopup } from "../errorPopup";
import { HeadingSize } from "../heading/dto";
import { LayoutComponent } from "../layout";
import { PaginationComponent } from "../pagination";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { MenuItems } from "../sidebar/dto";
import { SuccessPopup } from "../successPopup";
import { FinancialAdvisorFiltersComponent } from "./filters";
import { FinancialAdvisorListComponent } from "./list";
import { NewFinancialAdvisorModal } from "./newFinancialAdvisorModal";
import { findAllFinancialAdvisor, setCreateFinancialAdvisorStatus, setDeleteFiancialAdvisorStatus, setFinancialAdvisorSettedId, setOpenFinancialAdvisorModal, setPageFilter } from "./slice";

export function FinancialAdvisorView() {
    const dispatch = useAppDispatch()

    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)

    useEffect(() => {
        dispatch(setFinancialAdvisorSettedId(''))
    }, [])

    useEffect(() => {
        dispatch(findAllFinancialAdvisor(financialAdvisorState.findAllFinancialAdvisorFilters))
    }, [financialAdvisorState.findAllFinancialAdvisorFilters.page])

    useEffect(() => {
        if (financialAdvisorState.findAllFinancialAdvisorStatus === 'successfully' && financialAdvisorState.findAllFinancialAdvisorResponse !== undefined) {
            if (financialAdvisorState.findAllFinancialAdvisorResponse.page >= financialAdvisorState.findAllFinancialAdvisorResponse.totalPage) {
                dispatch(setPageFilter(0))
                dispatch(findAllFinancialAdvisor({ ...financialAdvisorState.findAllFinancialAdvisorFilters, page: 0 }))
            }
        }
    }, [financialAdvisorState.findAllFinancialAdvisorStatus])

    return (
        <LayoutComponent
            menuItem={MenuItems.FINANCIAL_ADVISOR}
            headingSize={HeadingSize.LG}
            headingLabel={"Commercialisti"}
            headingButtons={[
                <ButtonComponent label={"Aggiungi Commercialista"} onClick={() => dispatch(setOpenFinancialAdvisorModal(true))} color={Colors.PRIMARY} variant={Variant.SOLID} size={Size.MD} iconStyle={IconStyle.OFF} key={'new-accountant-button'} />
            ]}
            breadcrumbItems={["Commercialisti"]}
        >
            <ErrorPopup
                active={financialAdvisorState.createFinancialAdvisorStatus === 'failed'}
                close={() => dispatch(setCreateFinancialAdvisorStatus('idle'))}
                message="Si è verificato un errore durante la creazione del commercialista"
            />
            <SuccessPopup
                active={financialAdvisorState.createFinancialAdvisorStatus === 'successfully'}
                close={() => dispatch(setCreateFinancialAdvisorStatus('idle'))}
                message="Commercialista aggiunto"
            />
            <ErrorPopup
                active={financialAdvisorState.deleteFinancialAdvisorStatus === 'failed'}
                close={() => dispatch(setDeleteFiancialAdvisorStatus('idle'))}
                message="Si è verificato un errore durante l'eliminazione del commercialista"
            />
            <SuccessPopup
                active={financialAdvisorState.deleteFinancialAdvisorStatus === 'successfully'}
                close={() => dispatch(setDeleteFiancialAdvisorStatus('idle'))}
                message="Commercialista eliminato"
            />
            <FinancialAdvisorFiltersComponent />
            <FinancialAdvisorListComponent />
            <NewFinancialAdvisorModal />
            {
                financialAdvisorState.findAllFinancialAdvisorResponse !== undefined &&
                financialAdvisorState.findAllFinancialAdvisorResponse.total > 0 &&
                <PaginationComponent
                    page={financialAdvisorState.findAllFinancialAdvisorResponse !== undefined ? financialAdvisorState.findAllFinancialAdvisorResponse.page : 0}
                    count={financialAdvisorState.findAllFinancialAdvisorResponse !== undefined ? financialAdvisorState.findAllFinancialAdvisorResponse.totalPage : 0}
                    onChange={(page) => dispatch(setPageFilter(page))}
                />
            }
            {
                financialAdvisorState.findAllFinancialAdvisorResponse !== undefined &&
                financialAdvisorState.findAllFinancialAdvisorResponse.total === 0 &&
                <EmptyList />
            }
        </LayoutComponent>
    )
}