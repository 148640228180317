import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { format, lastDayOfMonth } from "date-fns"
import { PromiseStatuses } from "../../utils"
import { EditEventDTO, EventStatusEnum, EventTypeEnum, EventsFilters, FindAllEventsDTO, NewEventDTO, UpdateEventStatusRequest } from "./dtoEvents"
import { NewEventsService } from "./serviceEvents"

interface EventsState {
    month: number
    year: number
    openNewEventsModal: boolean
    newEventRequest: NewEventDTO,
    newEventState: PromiseStatuses
    findEventsFilters: EventsFilters
    findEventsStatus: PromiseStatuses
    findEventsResponse?: FindAllEventsDTO
    deleteEventModalOpen: boolean
    deleteEventStatus: PromiseStatuses
    modalEventType: 'save' | 'edit'
    editEventRequest: EditEventDTO
    editEventStatus: PromiseStatuses
    editEventId: number
    updateEventStatusRequest: UpdateEventStatusRequest
    updateEventStatusStatus: PromiseStatuses
}

const initialState: EventsState = {
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
    openNewEventsModal: false,
    newEventRequest: { type: EventTypeEnum.Other },
    newEventState: 'idle',
    findEventsFilters: {
        fromDate: format(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'yyyy-MM-dd'),
        toDate: format(lastDayOfMonth(new Date(new Date().getFullYear(), new Date().getMonth(), 1)), 'yyyy-MM-dd'),
        itemsPerPage: 0,
        page: 0,
        companyId: 0
    },
    findEventsStatus: "idle",
    deleteEventModalOpen: false,
    deleteEventStatus: 'idle',
    modalEventType: 'save',
    editEventRequest: {},
    editEventStatus: 'idle',
    editEventId: 0,
    updateEventStatusRequest: {
        status: EventStatusEnum.Resolved
    },
    updateEventStatusStatus: 'idle'
}

export const createEvent = createAsyncThunk(
    'events/createEvent',
    async (request: NewEventDTO): Promise<void> => {
        const eventsService = NewEventsService()
        return eventsService.createEvent(request)
    },
)

export const findEvents = createAsyncThunk(
    'events/findEvents',
    async (request: EventsFilters): Promise<FindAllEventsDTO> => {
        const eventsService = NewEventsService()
        return eventsService.findEvents(request)
    },
)

export const deleteEvent = createAsyncThunk(
    'events/deleteEvent',
    async (request: number): Promise<FindAllEventsDTO> => {
        const eventsService = NewEventsService()
        return eventsService.deleteEvent(request)
    },
)

export const editEvent = createAsyncThunk(
    'events/editEvent',
    async (request: { id: number, data: EditEventDTO }): Promise<FindAllEventsDTO> => {
        const eventsService = NewEventsService()
        return eventsService.editEvent(request.id, request.data)
    },
)

export const updateEventStatus = createAsyncThunk(
    'events/updateEventStatus',
    async (request: { id: number, data: UpdateEventStatusRequest }): Promise<void> => {
        const eventsService = NewEventsService()
        return eventsService.updateStatus(request.id, request.data)
    },
)

const eventsSlice = createSlice({
    name: 'events/slice',
    initialState,
    reducers: {
        setMonth: (state, action) => {
            state.month = action.payload
        },
        setYear: (state, action) => {
            state.year = action.payload
        },
        setOpenNewEventsModal: (state, action) => {
            state.openNewEventsModal = action.payload
        },
        setNewEventName: (state, action) => {
            state.newEventRequest.name = action.payload
        },
        setNewEventNote: (state, action) => {
            state.newEventRequest.note = action.payload
        },
        setNewEventDate: (state, action) => {
            state.newEventRequest.date = action.payload
        },
        setNewEventCompanyId: (state, action) => {
            state.newEventRequest.companyId = action.payload
        },
        resetNewEventRequest: (state) => {
            state.newEventRequest.name = undefined
            state.newEventRequest.date = undefined
            state.newEventRequest.note = undefined
        },
        setNewEventState: (state, action) => {
            state.newEventState = action.payload
        },
        setFromDateFilter: (state, action) => {
            state.findEventsFilters.fromDate = action.payload
        },
        setToDateFilter: (state, action) => {
            state.findEventsFilters.toDate = action.payload
        },
        setCompanyIdFilter: (state, action) => {
            state.findEventsFilters.companyId = action.payload
        },
        setDeleteEventModalOpen: (state, action) => {
            state.deleteEventModalOpen = action.payload
        },
        setDeleteEventStatus: (state, action) => {
            state.deleteEventStatus = action.payload
        },
        setModalEventType: (state, action) => {
            state.modalEventType = action.payload
        },
        setEditEventName: (state, action) => {
            state.editEventRequest.name = action.payload
        },
        setEditEventNote: (state, action) => {
            state.editEventRequest.note = action.payload
        },
        setEditEventDate: (state, action) => {
            state.editEventRequest.date = action.payload
        },
        resetEditEventRequest: (state) => {
            state.editEventRequest.name = undefined
            state.editEventRequest.date = undefined
            state.editEventRequest.note = undefined
        },
        setEditEventStatus: (state, action) => {
            state.editEventStatus = action.payload
        },
        setEditEventId: (state, action) => {
            state.editEventId = action.payload
        },
        setEventStatusRequest: (state, action) => {
            state.updateEventStatusRequest.status = action.payload
        },
        setEventStatusStatus: (state, action) => {
            state.updateEventStatusStatus = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(createEvent.pending, (state) => {
                state.newEventState = 'loading'
            })
            .addCase(createEvent.fulfilled, (state) => {
                state.newEventState = 'successfully'
            })
            .addCase(createEvent.rejected, (state) => {
                state.newEventState = 'failed'
            })
            .addCase(findEvents.pending, (state) => {
                state.findEventsStatus = 'loading'
            })
            .addCase(findEvents.fulfilled, (state, action) => {
                state.findEventsStatus = 'successfully'
                state.findEventsResponse = action.payload
            })
            .addCase(findEvents.rejected, (state) => {
                state.findEventsStatus = 'failed'
            })
            .addCase(deleteEvent.pending, (state) => {
                state.deleteEventStatus = 'loading'
            })
            .addCase(deleteEvent.fulfilled, (state) => {
                state.deleteEventStatus = 'successfully'
            })
            .addCase(deleteEvent.rejected, (state) => {
                state.deleteEventStatus = 'failed'
            })
            .addCase(editEvent.pending, (state) => {
                state.editEventStatus = 'loading'
            })
            .addCase(editEvent.fulfilled, (state) => {
                state.editEventStatus = 'successfully'
            })
            .addCase(editEvent.rejected, (state) => {
                state.editEventStatus = 'failed'
            })
            .addCase(updateEventStatus.pending, (state) => {
                state.updateEventStatusStatus = 'loading'
            })
            .addCase(updateEventStatus.fulfilled, (state) => {
                state.updateEventStatusStatus = 'successfully'
            })
            .addCase(updateEventStatus.rejected, (state) => {
                state.updateEventStatusStatus = 'failed'
            })
    },
})

export const {
    setMonth,
    setYear,
    setOpenNewEventsModal,
    setNewEventDate,
    setNewEventName,
    setNewEventNote,
    resetNewEventRequest,
    setNewEventState,
    setNewEventCompanyId,
    setFromDateFilter,
    setToDateFilter,
    setDeleteEventModalOpen,
    setDeleteEventStatus,
    setModalEventType,
    setEditEventDate,
    setEditEventName,
    setEditEventNote,
    resetEditEventRequest,
    setEditEventStatus,
    setEditEventId,
    setCompanyIdFilter,
    setEventStatusRequest,
    setEventStatusStatus
} = eventsSlice.actions

export default eventsSlice.reducer