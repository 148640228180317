import { CompanyCountDTO, DashboardActivitiesResponse, FiltersCounterDTO } from "./dto";
import { DashboardServiceImpl } from "./serviceImpl";

export interface DashboardService {
    activityDashboard(companyIds: number[], year: number): Promise<DashboardActivitiesResponse[]>
    getAllCount(request: FiltersCounterDTO): Promise<CompanyCountDTO>
}

export function NewDashboardService(): DashboardService {
    return new DashboardServiceImpl();
}
