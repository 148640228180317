import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { ActivityCategory, FindAllActivityType } from "../activity/dto"
import { NewActivityService } from "../activity/service"
import { PeriodicityEnum } from "../companyQuotationWizard/dto"
import { ActvityGroupDashboard } from "../dashboard/dto"
import { DocumentsResponseDTO, FindAllDocumentsResponse } from "../objects/dto"
import { NewObjectService } from "../objects/service"
import { Operation, PromiseStatuses } from "../utils"
import { ActivityCanBeDeletedResponse, ActivityDTO, ActivityGroupResponse, ActivityResponse, ActivityTypeDTO, ActivityTypesRequestDTO, AcubeAccountDTO, AcubeReconnectResponseDTO, AddAccountantIdDTO, AddAtecoDTO, AddOperatorDTO, AtecoResponseDTO, BankRequestDTO, BankRequestModel, CompanyEditPremiumDTO, CompanyRegistryDTO, CompanyResponseDTO, CompanyStatusEnum, CompanyUnseenInfoDocumentsDTO, CompanyUnseenInfoMessagesDTO, CompanyUpdateDTO, CompanyUpdateRegistryDTO, FindAllAtecos, FindAllCompanies, FindAllCompaniesFilters, RegistryEmailDTO, TodosDTO } from "./dto"
import { NewCompanyService } from "./service"

interface CompanyState {
    ordinaryAccountingYearFilter: number
    openOrdinaryAccountingModal: boolean
    openActivityManagementModal: boolean
    openCriticityModal: boolean
    openAtecoModal: boolean
    openBankModal: boolean
    documentTabValue: number
    taxAreaTabValue: number
    ordinaryAccountingTabValue: number
    criticityOperation: Operation
    companyFilters: FindAllCompaniesFilters
    findCompanyResponse?: CompanyResponseDTO
    findAllCompaniesResponse?: FindAllCompanies
    findAllCompaniesWithoutPaginationResponse?: FindAllCompanies
    findCompanyStatus: PromiseStatuses
    findAllCompaniesStatus: PromiseStatuses
    findAllCompaniesWithoutPaginationStatus: PromiseStatuses
    companyUpdateRegistryRequest: CompanyUpdateRegistryDTO
    companyUpdateRegistryStatus: PromiseStatuses
    findOperatorsListAvatarsByIdResponse?: { userId: string, objectId: string | null }[]
    findOperatorsListAvatarsByIdStatus: PromiseStatuses
    operatorsToAddList: AddOperatorDTO
    findAllAtecosResponse?: FindAllAtecos
    findAllAtecosStatus: PromiseStatuses
    atecoToAddList: AddAtecoDTO[]
    accountantToAdd: AddAccountantIdDTO
    saveRolesStatus: PromiseStatuses
    findCompanyCategoryStatus: PromiseStatuses,
    findCompanyCategoryResponse: ActivityCategory[]
    findCompanyTypesStatus: PromiseStatuses,
    findCompanyTypesResponse: FindAllActivityType[]
    addBankRequest: BankRequestModel
    editBankStatus: PromiseStatuses
    openDeleteBankModal: boolean
    openDeleteTodoModal: boolean
    activityCanBeDeletedResponse: ActivityCanBeDeletedResponse[]
    activityCanBeDeletedStatus: PromiseStatuses
    editCompanyActivityTypesStatus: PromiseStatuses
    editTodosStatus: PromiseStatuses
    todoIndexToDelete: number
    todoToEdit: { todo: string, index: number }
    findActivityGroupsStatus: PromiseStatuses
    findActivityGroupResponse?: ActivityGroupResponse
    findActivityByGroupStatus: PromiseStatuses
    findActivityByGroupResponse: ActivityResponse[]
    findActivityTypeByIdStatus: PromiseStatuses
    findActivityTypeByIdResponse?: ActivityTypeDTO[]
    selectedPeriod: number
    setActivityCompleteStatus: PromiseStatuses
    setActivityOpenStatus: PromiseStatuses
    openSendDocumentModal: boolean
    findAllSentDocumentsResponse?: FindAllDocumentsResponse
    findAllSentDocumentsStatus: PromiseStatuses
    findAllRecivedDocumentsResponse?: FindAllDocumentsResponse
    findAllReceivedDocumentsStatus: PromiseStatuses
    deleteCompanyStatus: PromiseStatuses
    openDeleteCompanyModal: boolean
    findDocumentByIdResponse?: DocumentsResponseDTO
    findDocumentByIdStatus: PromiseStatuses
    deleteDocumentOpenModal: boolean
    deleteDocumentStatus: PromiseStatuses
    selectedActivities?: { period: number, activities: { group?: number, list: ActivityDTO[] }[] }
    companyCategories: ActivityCategory[]
    companyPeriodicity: PeriodicityEnum,
    documentSentFilters: {
        period: number | ''
        year: number,
        page: number,
        received: 'false'
    },
    documentReceivedFilters: {
        period: number | ''
        year: number,
        page: number,
        received: 'true'
    }
    groupToComplete: string,
    completeGroupStatus: PromiseStatuses
    completeGroupModal: boolean
    companyTodos: string[]
    companyRegistryPdfResponse: string
    companyRegistryPdfStatus: PromiseStatuses
    updateRegistryTabValue: number
    companyRegistryDTO: CompanyRegistryDTO
    saveAtecosStatus: PromiseStatuses
    loans: (File | null)[]
    fundings: (File | null)[]
    leasings: (File | null)[]
    editRegistryStaus: PromiseStatuses
    findLoansStatus: PromiseStatuses
    findLoansResponse: string[]
    findFundingsStatus: PromiseStatuses
    findFundingsResponse: string[]
    findLeasingsStatus: PromiseStatuses
    findLeasingsResponse: string[]
    findAcubeConnectRequestStatus: PromiseStatuses
    findByIdAcubeGetAccountStatus: PromiseStatuses
    findByIdAcubeGetAccountResponse: AcubeAccountDTO[],
    openDownloadPdfAcubeAccountsModal: boolean
    companyUpdateDTO: CompanyUpdateDTO
    companyUpdateStatus: PromiseStatuses
    acubeGetTransactionsResponse: string
    acubeGetTransactionsStatus: PromiseStatuses
    acubeReconnectRequestStatus: PromiseStatuses
    acubeReconnectRequestResponse?: AcubeReconnectResponseDTO
    errors: {
        updateRegistry: {
            email?: string,
            vat?: string
        },
        registryEmail: {
            email?: string,
        }
    }
    findAllOperatorsFinancialAdvisorFilter: string
    companyAvatar: string
    findCompanyAvatarStatus: PromiseStatuses
    companyIdActivities: string
    companiesUnseenDocumentsStatus: PromiseStatuses
    companiesUnseenDocumentsResponse: CompanyUnseenInfoDocumentsDTO[]
    companiesUnseenMessagesStatus: PromiseStatuses
    companiesUnseenMessagesResponse: CompanyUnseenInfoMessagesDTO[]
    findAtecoByIdStatus: PromiseStatuses,
    findAtecoByIdResponse: AtecoResponseDTO[]
    companyEditPremium: CompanyEditPremiumDTO
    companyEditPremiumStatus: PromiseStatuses,
    settingsTabValue: number,
    operationsTabValue: number,
    companyRegistryEmailRequest: RegistryEmailDTO
    companyRegistryEmailStatus: PromiseStatuses,

    ordinaryAccountingUser?: CompanyResponseDTO
    ordinaryAccountingActivityCategories: ActivityCategory[]
    ordinaryAccountingActivityGroups: ActvityGroupDashboard[]
    ordinaryAccountingInitialTab: number
    ordinaryAccountingPeriod: number
    ordinaryAccountingYear: number
    initialTabAccountingActivities: number
}

const initialState: CompanyState = {
    ordinaryAccountingYearFilter: new Date().getFullYear(),
    openOrdinaryAccountingModal: false,
    openActivityManagementModal: false,
    openCriticityModal: false,
    openAtecoModal: false,
    openBankModal: false,
    documentTabValue: 0,
    ordinaryAccountingTabValue: 0,
    criticityOperation: 'save',
    findCompanyStatus: 'idle',
    findAllCompaniesWithoutPaginationStatus: 'idle',
    findAllCompaniesStatus: 'idle',
    companyFilters: {
        accountant: '',
        financialAdvisor: '',
        businessName: '',
        operators: '',
        page: 0,
        itemsPerPage: 25,
        status: [CompanyStatusEnum.Active, CompanyStatusEnum.RegistryPending]
    },
    companyUpdateRegistryRequest: {
        businessName: '',
        email: undefined,
    },
    companyUpdateRegistryStatus: 'idle',
    findOperatorsListAvatarsByIdStatus: 'idle',
    operatorsToAddList: { operatorIds: [] },
    findAllAtecosStatus: 'idle',
    atecoToAddList: [],
    accountantToAdd: { accountantId: '' },
    saveRolesStatus: 'idle',
    findCompanyCategoryStatus: 'idle',
    findCompanyCategoryResponse: [],
    findCompanyTypesStatus: 'idle',
    findCompanyTypesResponse: [],
    addBankRequest: {
        name: '',
        iban: ''
    },
    editBankStatus: 'idle',
    openDeleteBankModal: false,
    activityCanBeDeletedResponse: [],
    activityCanBeDeletedStatus: 'idle',
    editCompanyActivityTypesStatus: 'idle',
    editTodosStatus: 'idle',
    openDeleteTodoModal: false,
    todoIndexToDelete: -1,
    todoToEdit: { index: -1, todo: '' },
    findActivityGroupsStatus: 'idle',
    findActivityByGroupStatus: 'idle',
    findActivityTypeByIdStatus: 'idle',
    findActivityByGroupResponse: [],
    selectedPeriod: -1,
    setActivityCompleteStatus: 'idle',
    setActivityOpenStatus: 'idle',
    openSendDocumentModal: false,
    findAllReceivedDocumentsStatus: 'idle',
    findAllSentDocumentsStatus: 'idle',
    deleteCompanyStatus: 'idle',
    openDeleteCompanyModal: false,
    findDocumentByIdStatus: 'idle',
    deleteDocumentOpenModal: false,
    deleteDocumentStatus: 'idle',
    companyCategories: [],
    companyPeriodicity: PeriodicityEnum.Monthly,
    documentSentFilters: {
        period: '',
        year: new Date().getFullYear(),
        page: 0,
        received: 'false'
    },
    documentReceivedFilters: {
        period: '',
        year: new Date().getFullYear(),
        page: 0,
        received: 'true'
    },
    groupToComplete: '',
    completeGroupStatus: 'idle',
    completeGroupModal: false,
    companyTodos: [],
    companyRegistryPdfStatus: 'idle',
    companyRegistryPdfResponse: '',
    updateRegistryTabValue: 0,
    companyRegistryDTO: {
        salesRegisterNumber: 0,
        salesRegister: [],
        purchasesRegisterNumer: 0,
        purchasesRegister: [],
        feesRegisterNumber: 0,
        feesRegister: [],
        registerCharge: false,
        registerChargeName: '',
        adminsNumber: 0,
        admins: [],
        membersNumber: 0,
        members: [],
        instrumentalVehicles: false,
        instrumentalVehiclesLicensePlate: '',
        notInstrumentalVehicles: false,
        notInstrumentalVehiclesLicensePlate: '',
        loansNumber: 0,
        fundingNumber: 0,
        leasingNumber: 0,
        retentionManagement: false
    },
    saveAtecosStatus: 'idle',
    loans: [null],
    fundings: [null],
    leasings: [null],
    editRegistryStaus: 'idle',
    findLoansStatus: 'idle',
    findLoansResponse: [],
    findFundingsResponse: [],
    findFundingsStatus: 'idle',
    findLeasingsResponse: [],
    findLeasingsStatus: 'idle',
    findAcubeConnectRequestStatus: 'idle',
    findByIdAcubeGetAccountStatus: 'idle',
    findByIdAcubeGetAccountResponse: [],
    openDownloadPdfAcubeAccountsModal: false,
    companyUpdateDTO: {},
    companyUpdateStatus: 'idle',
    acubeGetTransactionsResponse: '',
    acubeGetTransactionsStatus: 'idle',
    acubeReconnectRequestStatus: 'idle',
    errors: {
        updateRegistry: {
            email: undefined,
            vat: undefined
        },
        registryEmail: {
            email: undefined
        }
    },
    findAllOperatorsFinancialAdvisorFilter: '',
    companyAvatar: '',
    findCompanyAvatarStatus: 'idle',
    companyIdActivities: '',
    companiesUnseenDocumentsResponse: [],
    companiesUnseenDocumentsStatus: 'idle',
    companiesUnseenMessagesResponse: [],
    companiesUnseenMessagesStatus: 'idle',
    findAtecoByIdResponse: [],
    findAtecoByIdStatus: 'idle',
    companyEditPremium: { premium: false },
    companyEditPremiumStatus: 'idle',
    taxAreaTabValue: 0,
    settingsTabValue: 0,
    operationsTabValue: 0,
    companyRegistryEmailRequest: {},
    companyRegistryEmailStatus: 'idle',
    ordinaryAccountingActivityCategories: [],
    ordinaryAccountingActivityGroups: [],
    ordinaryAccountingInitialTab: 0,
    ordinaryAccountingPeriod: 0,
    ordinaryAccountingYear: 0,
    initialTabAccountingActivities: 0
}

export const findCompanyById = createAsyncThunk(
    'company/findCompanyBycompanySettedId',
    async (request: string, thunkApi): Promise<CompanyResponseDTO> => {
        const companyService = NewCompanyService()

        return companyService.findCompany(request)
    },
)

export const companyUpdateRegistry = createAsyncThunk(
    'company/companyUpdateRegistry',
    async (request: { id: string, data: FormData }, thunkApi): Promise<void> => {
        const companyService = NewCompanyService()

        return companyService.updateRegistry(request.id, request.data)
            .catch((error) => {
                throw (thunkApi.rejectWithValue(error.response.data.errors))
            })
    },
)

export const findAllCompanies = createAsyncThunk(
    'company/findAllCompanies',
    async (request: FindAllCompaniesFilters): Promise<FindAllCompanies> => {
        const companyService = NewCompanyService()

        return companyService.findAllCompanies(request)
    },
)

export const findAllCompanyWithoutPagination = createAsyncThunk(
    'company/findAllCompanyWithoutPagination',
    async (): Promise<FindAllCompanies> => {
        const companyService = NewCompanyService()

        return companyService.findAllCompanies(
            {
                accountant: '',
                financialAdvisor: '',
                businessName: '',
                operators: '',
                itemsPerPage: 0,
                page: 0,
                status: [CompanyStatusEnum.Active, CompanyStatusEnum.Pending, CompanyStatusEnum.RegistryPending]
            })
    },
)

export const findCompanyActivityCategories = createAsyncThunk(
    'company/findCompanyActivityCategories',
    async (request: number[], thunkApi): Promise<ActivityCategory[]> => {
        let promises: Promise<ActivityCategory>[] = []
        promises = request.map(async (id) => {
            const activitySerivce = NewActivityService()

            return activitySerivce.findActivityCategory(id)
        })
        return Promise.all(promises)
    }
)

export const findCompanyActivityTypes = createAsyncThunk(
    'company/findCompanyActivityTypes',
    async (request: number[], thunkApi): Promise<FindAllActivityType[]> => {
        let promises: Promise<FindAllActivityType>[] = []
        promises = request.map(async (id) => {
            const activitySerivce = NewActivityService()

            return activitySerivce.findAllActivityType({ name: '', activityCategoryId: id.toString(), page: 0, itemsPerPage: 0 })
        })
        return Promise.all(promises)
    }
)

export const findOperatorsListAvatarsById = createAsyncThunk(
    'company/findOperatorsListAvatarsById',
    async (request: { objectId: string | null, userId: string }[], thunkApi): Promise<{ userId: string, objectId: string | null }[]> => {
        let promises: Promise<{ userId: string, objectId: string | null }>[] = []
        promises = request.map(async (user) => {
            if (user.objectId !== null) {
                const objectService = NewObjectService()

                return objectService.findFileByIdWithUserId(user.objectId, user.userId)
            } else return Promise.resolve({ userId: user.userId, objectId: '' })
        })
        return Promise.all(promises)
    }
)

export const findAtecosById = createAsyncThunk(
    'company/findAtecosById',
    async (request: number[], thunkApi): Promise<AtecoResponseDTO[]> => {
        let promises: Promise<AtecoResponseDTO>[] = []
        promises = request.map(async (id) => {
            const companyService = NewCompanyService()

            return companyService.findAteco(id)

        })
        return Promise.all(promises)
    }
)

export const findCompanyAvatar = createAsyncThunk(
    'company/findCompanyAvatar',
    async (request: string | null, thunkApi): Promise<string> => {
        if (request !== null) {
            const objectService = NewObjectService()

            return objectService.findFileById(request)
        } else return ''
    }
)

export const findAllAtecos = createAsyncThunk(
    'company/findAllAtecos',
    async (request: { page: number, itemsPerPage: number }, thunkApi): Promise<FindAllAtecos> => {
        const companyService = NewCompanyService()

        return companyService.findAllAtecos(request.page, request.itemsPerPage)
    }
)

export const saveRoles = createAsyncThunk(
    'company/saveRoles',
    async (request: { companyId: string, accountant?: AddAccountantIdDTO, operatorsToAdd?: AddOperatorDTO }): Promise<void[]> => {
        let promises: Promise<void>[] = []
        const companyService = NewCompanyService()
        if (request.accountant !== undefined)
            promises.push(companyService.addAccountant(request.companyId, request.accountant))
        if (request.operatorsToAdd !== undefined)
            promises.push(companyService.addOperators(request.companyId, request.operatorsToAdd))
        return Promise.all(promises)
    }
)

export const editBankAccounts = createAsyncThunk(
    'company/editBankAccounts',
    async (request: { companyId: string, data: BankRequestDTO }, thunkApi): Promise<void> => {
        const companyService = NewCompanyService()

        return companyService.editBankAccounts(request.companyId, request.data)
    }
)

export const activityCanBeDeleted = createAsyncThunk(
    'company/activityCanBeDeleted',
    async (companyId: string, thunkApi): Promise<ActivityCanBeDeletedResponse[]> => {
        const companyService = NewCompanyService()

        return companyService.activityCanBeDeleted(companyId)
    }
)

export const editCompanyActivityTypes = createAsyncThunk(
    'company/editCompanyActivityTypes',
    async (request: { companyId: string, data: ActivityTypesRequestDTO }, thunkApi): Promise<void> => {
        const companyService = NewCompanyService()

        return companyService.editCompanyActivityType(request.companyId, request.data)
    }
)

export const editTodos = createAsyncThunk(
    'company/editTodos',
    async (request: { companyId: string, data: TodosDTO }, thunkApi): Promise<void> => {
        const companyService = NewCompanyService()

        return companyService.editTodos(request.companyId, request.data)
    }
)

export const findActivityGroup = createAsyncThunk(
    'company/findActivityGroup',
    async (request: { companyId: string, year: number }, thunkApi): Promise<ActivityGroupResponse> => {
        const companyService = NewCompanyService()

        return companyService.findActivityGroup(request.year, request.companyId)
    }
)

export const findAllSentDocuments = createAsyncThunk(
    'company/findAllSentDocuments',
    async (request: { companyId: number, year: number, period: number | '', page: number }, thunkApi): Promise<FindAllDocumentsResponse> => {
        const companyService = NewCompanyService()

        return companyService.findAllDocuments(request.companyId, request.year, request.period, request.page, 'false')
    }
)

export const findAllReceivedDocuments = createAsyncThunk(
    'company/findAllReceivedDocuments',
    async (request: { companyId: number, year: number, period: number | '', page: number }, thunkApi): Promise<FindAllDocumentsResponse> => {
        const companyService = NewCompanyService()

        return companyService.findAllDocuments(request.companyId, request.year, request.period, request.page, 'true')
    }
)

export const findActivityByGroup = createAsyncThunk(
    'company/findActivityByGroup',
    async (groupId: number[]): Promise<ActivityResponse[]> => {
        let promises: Promise<ActivityResponse>[] = []
        promises = groupId.map(async (id) => {
            const companyService = NewCompanyService()

            return companyService.findActivityByGroup(id)
        })
        return Promise.all(promises)
    }
)

export const findCompanyActivityTypeById = createAsyncThunk(
    'company/findCompanyActivityTypeById',
    async (request: number[], thunkApi): Promise<ActivityTypeDTO[]> => {
        let promises: Promise<ActivityTypeDTO>[] = []
        promises = request.map(async (id) => {
            const companyService = NewCompanyService()

            return companyService.findActivityTypeById(id)
        })
        return Promise.all(promises)
    }
)

export const setActivityCompleted = createAsyncThunk(
    'company/setActivityCompleted',
    async (request: { ids: number[], notes: (string | null)[] }, thunkApi): Promise<void> => {
        const companyService = NewCompanyService()

        return companyService.setActivityCompleted(request.ids, request.notes)
    }
)

export const setActivityOpen = createAsyncThunk(
    'company/setActivityOpen',
    async (request: { ids: number[], notes: (string | null)[] }, thunkApi): Promise<void> => {
        const companyService = NewCompanyService()

        return companyService.setActivityOpen(request.ids, request.notes)
    }
)

export const deleteCompany = createAsyncThunk(
    'company/deleteCompany',
    async (id: string, thunkApi): Promise<void> => {
        const companyService = NewCompanyService()

        return companyService.deleteCompany(id)
    }
)

export const findDocumentById = createAsyncThunk(
    'company/findDocumentById',
    async (id: string, thunkApi): Promise<DocumentsResponseDTO> => {
        const companyService = NewCompanyService()

        return companyService.findDocumentById(id)
    }
)

export const deleteDocument = createAsyncThunk(
    'company/deleteDocument',
    async (id: string, thunkApi): Promise<void> => {
        const companyService = NewCompanyService()

        return companyService.deleteDocument(id)
    }
)

export const completeGroup = createAsyncThunk(
    'company/completeGroup',
    async (id: string, thunkApi): Promise<void> => {
        const companyService = NewCompanyService()

        return companyService.completeGroup(id)
    }
)

export const getCompanyRegistryPdf = createAsyncThunk(
    'company/getCompanyRegistryPdf',
    async (id: string, thunkApi): Promise<string> => {
        const companyService = NewCompanyService()

        return companyService.getCompanyRegistryPdf(id)
    }
)

export const saveAtecos = createAsyncThunk(
    'company/saveAtecos',
    async (request: { data: number[], id: string }, thunkApi): Promise<void> => {
        const companyService = NewCompanyService()

        return companyService.setAtecos(request.data, request.id)
    }
)

export const editRegistry = createAsyncThunk(
    'company/editRegistry',
    async (request: { data: FormData, id: string }, thunkApi): Promise<void> => {
        const companyService = NewCompanyService()

        return companyService.editRegistry(request.id, request.data)
    }
)

export const findLoans = createAsyncThunk(
    'company/findLoans',
    async (request: string[], thunkApi): Promise<string[]> => {
        let promises: Promise<string>[] = []
        promises = request.map(async (id) => {
            const objectService = NewObjectService()

            return objectService.findFileById(id)
        })
        return Promise.all(promises)
    }
)

export const findFundings = createAsyncThunk(
    'company/findFundings',
    async (request: string[], thunkApi): Promise<string[]> => {
        let promises: Promise<string>[] = []
        promises = request.map(async (id) => {
            const objectService = NewObjectService()

            return objectService.findFileById(id)
        })
        return Promise.all(promises)
    }
)

export const findLeasings = createAsyncThunk(
    'company/findLeasings',
    async (request: string[], thunkApi): Promise<string[]> => {
        let promises: Promise<string>[] = []
        promises = request.map(async (id) => {
            const objectService = NewObjectService()

            return objectService.findFileById(id)
        })
        return Promise.all(promises)
    }
)

export const findAcubeConnectRequest = createAsyncThunk(
    'company/findAcubeConnectRequest',
    async (id: string): Promise<string> => {
        const companyService = NewCompanyService()

        return companyService.findAcubeConnectRequest(id)
    }
)

export const findByIdAcubeGetAccount = createAsyncThunk(
    'company/findByIdAcubeGetAccount',
    async (request: { id: string, uuid: string[] }): Promise<AcubeAccountDTO[]> => {
        let promises: Promise<AcubeAccountDTO>[] = []
        promises = request.uuid.map(async (id) => {
            const companyService = NewCompanyService()

            return companyService.findByIdAcubeGetAccount(request.id, id)
        })
        return Promise.all(promises)
    }
)

export const updateCompany = createAsyncThunk(
    'company/updateCompany',
    async (request: { id: string, data: FormData }, thunkApi): Promise<void> => {
        const companyService = NewCompanyService()

        return companyService.editCompany(request.id, request.data)
    }
)

export const acubeGetTransactions = createAsyncThunk(
    'company/acubeGetTransactions',
    async (request: { fiscalId: string, uuid: string, madeOnAfter: string, madeOnBefore: string }, thunkApi): Promise<string> => {
        const companyService = NewCompanyService()

        return companyService.acubeGetTransaction(request.fiscalId, request.uuid, request.madeOnAfter, request.madeOnBefore)
    }
)

export const acubeReconnectRequest = createAsyncThunk(
    'company/acubeReconnectRequest',
    async (request: { id: string, uuid: string }, thunkApi): Promise<AcubeReconnectResponseDTO> => {
        const companyService = NewCompanyService()

        return companyService.acubeReconnectRequest(request.id, request.uuid)
    }
)

export const getCompanyUnseenInfoDocuments = createAsyncThunk(
    'company/getCompanyUnseenInfoDocuments',
    async (): Promise<CompanyUnseenInfoDocumentsDTO[]> => {
        const companyService = NewCompanyService()

        return companyService.getCompanyUnseenInfoDocuments()
    }
)

export const getCompanyUnseenInfoMessages = createAsyncThunk(
    'company/getCompanyUnseenInfoMessages',
    async (topics: string[]): Promise<CompanyUnseenInfoMessagesDTO[]> => {
        const companyService = NewCompanyService()

        return companyService.getCompanyUnseenInfoMessages(topics)
    }
)

export const companyEditPremium = createAsyncThunk(
    'company/companyEditPremium',
    async (request: { data: CompanyEditPremiumDTO, id: number }): Promise<void> => {
        const companyService = NewCompanyService()

        return companyService.setCompanyEditPremium(request.data, request.id)
    }
)

export const companyRegistryEmail = createAsyncThunk(
    'company/companyRegistryEmail',
    async (request: { data: RegistryEmailDTO, id: number }, thunkApi): Promise<void> => {
        const companyService = NewCompanyService()

        return companyService.registryEmail(request.data, request.id)
            .catch((error) => {
                throw (thunkApi.rejectWithValue(error.response.data.errors))
            })
    }
)

const companySlice = createSlice({
    name: 'company/slice',
    initialState,
    reducers: {
        setFindCompanyAvatarStatus: (state, action) => {
            state.findCompanyAvatarStatus = action.payload
        },
        setOrdinaryAccountingYearFilter: (state, action) => {
            state.ordinaryAccountingYearFilter = action.payload
        },
        setStatusFilter: (state, action) => {
            state.companyFilters.status = action.payload
        },
        setPageFilter: (state, action) => {
            state.companyFilters.page = action.payload
        },
        setAccountantFilter: (state, action) => {
            state.companyFilters.accountant = action.payload
        },
        setOperatorsFilter: (state, action) => {
            state.companyFilters.operators = action.payload
        },
        setFinancialAdvisorFilter: (state, action) => {
            state.companyFilters.financialAdvisor = action.payload
        },
        setBusinessNameFilter: (state, action) => {
            state.companyFilters.businessName = action.payload
        },
        setOpenOrdinaryAccountingModal: (state, action) => {
            state.openOrdinaryAccountingModal = action.payload
        },
        setOpenActivityManagementModal: (state, action) => {
            state.openActivityManagementModal = action.payload
        },
        setOpenCriticityModal: (state, action) => {
            state.openCriticityModal = action.payload
        },
        setOpenAtecoModal: (state, action) => {
            state.openAtecoModal = action.payload
        },
        setOpenBankModal: (state, action) => {
            state.openBankModal = action.payload
        },
        setDocumentTabValue: (state, action) => {
            state.documentTabValue = action.payload
        },
        setTaxAreaTabValue: (state, action) => {
            state.taxAreaTabValue = action.payload
        },
        setOrdinaryAccountingTabValue: (state, action) => {
            state.ordinaryAccountingTabValue = action.payload
        },
        setCriticityOperation: (state, action) => {
            state.criticityOperation = action.payload
        },
        setCompanyUpdateRegistryBusinessName: (state, action) => {
            state.companyUpdateRegistryRequest.businessName = action.payload
        },
        setCompanyUpdateRegistryName: (state, action) => {
            state.companyUpdateRegistryRequest.name = action.payload
        },
        setCompanyUpdateRegistryEmail: (state, action) => {
            state.companyUpdateRegistryRequest.email = action.payload
        },
        setCompanyUpdateRegistrySurname: (state, action) => {
            state.companyUpdateRegistryRequest.surname = action.payload
        },
        setRegistryEmail: (state, action) => {
            state.companyRegistryEmailRequest.email = action.payload
        },
        setCompanyUpdateRegistryPhoneNumber: (state, action) => {
            state.companyUpdateRegistryRequest.phoneNumber = action.payload
        },
        setCompanyUpdateRegistryVatNumber: (state, action) => {
            state.companyUpdateRegistryRequest.vatNumber = action.payload
        },
        setCompanyUpdateRegistryFiscalCode: (state, action) => {
            state.companyUpdateRegistryRequest.fiscalCode = action.payload
        },
        setCompanyUpdateRegistryEmployeesNumber: (state, action) => {
            state.companyUpdateRegistryRequest.employeesNumber = action.payload
        },
        setCompanyUpdateRegistryNote: (state, action) => {
            state.companyUpdateRegistryRequest.note = action.payload
        },
        resetCompanyUpdateRegistryRequest: (state) => {
            state.companyUpdateRegistryRequest = {
                businessName: '',
                name: undefined,
                surname: undefined,
                vatNumber: undefined,
                fiscalCode: undefined,
                email: undefined,
                phoneNumber: undefined,
                employeesNumber: undefined,
                note: undefined,
            }
        },
        setOperatorsToAddList: (state, action) => {
            state.operatorsToAddList = action.payload
        },
        setAccountantToAdd: (state, action: PayloadAction<AddAccountantIdDTO>) => {
            state.accountantToAdd = action.payload
        },
        setFindCompanyStatus: (state, action) => {
            state.findCompanyStatus = action.payload
        },
        setCompanyBankName: (state, action) => {
            state.addBankRequest.name = action.payload
        },
        setCompanyBankIban: (state, action) => {
            state.addBankRequest.iban = action.payload
        },
        setOpenDeleteBankModal: (state, action) => {
            state.openDeleteBankModal = action.payload
        },
        setOpenDeleteTodoModal: (state, action) => {
            state.openDeleteTodoModal = action.payload
        },
        setTodoIndexToDelete: (state, action) => {
            state.todoIndexToDelete = action.payload
        },
        setTodoToEdit: (state, action) => {
            state.todoToEdit = action.payload
        },
        setSelectedPeriod: (state, action) => {
            state.selectedPeriod = action.payload
        },
        setOpenDocumentModal: (state, action) => {
            state.openSendDocumentModal = action.payload
        },
        setOpenDeleteCompanyModal: (state, action) => {
            state.openDeleteCompanyModal = action.payload
        },
        setFindDocumentStatus: (state, action) => {
            state.findDocumentByIdStatus = action.payload
        },
        setDeleteDocumentOpenModal: (state, action) => {
            state.deleteDocumentOpenModal = action.payload
        },
        resetFindActivityGroupStatus: (state) => {
            state.findActivityGroupsStatus = 'idle'
        },
        setSelectedActivity: (state, action) => {
            state.selectedActivities = action.payload
        },
        setCompanyCategories: (state, action) => {
            state.companyCategories = action.payload
        },
        setCompanyPeriodicity: (state, action) => {
            state.companyPeriodicity = action.payload
        },
        setDocumentSentFilterPeriod: (state, action) => {
            state.documentSentFilters.period = action.payload
        },
        setDocumentSentFilterYear: (state, action) => {
            state.documentSentFilters.year = action.payload
        },
        setDocumentReceivedFilterPeriod: (state, action) => {
            state.documentReceivedFilters.period = action.payload
        },
        setDocumentReceivedFilterYear: (state, action) => {
            state.documentReceivedFilters.year = action.payload
        },
        setGroupToComplete: (state, action) => {
            state.groupToComplete = action.payload
        },
        setCompleteGroupModal: (state, action) => {
            state.completeGroupModal = action.payload
        },
        setCompanyTodos: (state, action) => {
            state.companyTodos = action.payload
        },
        setUpdateRegistryTabValue: (state, action) => {
            state.updateRegistryTabValue = action.payload
        },
        setSalesRegisters: (state, action) => {
            state.companyRegistryDTO.salesRegister = action.payload
        },
        setSalesRegistersNumber: (state, action) => {
            state.companyRegistryDTO.salesRegisterNumber = action.payload
        },
        setPurchaseRegisters: (state, action) => {
            state.companyRegistryDTO.purchasesRegister = action.payload
        },
        setPurchaseRegistersNumber: (state, action) => {
            state.companyRegistryDTO.purchasesRegisterNumer = action.payload
        },
        setFeesRegisters: (state, action) => {
            state.companyRegistryDTO.feesRegister = action.payload
        },
        setFeesRegistersNumber: (state, action) => {
            state.companyRegistryDTO.feesRegisterNumber = action.payload
        },
        setRegisterChange: (state, action) => {
            state.companyRegistryDTO.registerCharge = action.payload
        },
        setRegisterChangeName: (state, action) => {
            state.companyRegistryDTO.registerChargeName = action.payload
        },
        setAdmins: (state, action) => {
            state.companyRegistryDTO.admins = action.payload
        },
        setAdminsNumber: (state, action) => {
            state.companyRegistryDTO.adminsNumber = action.payload
        },
        setMembers: (state, action) => {
            state.companyRegistryDTO.members = action.payload
        },
        setMembersNumber: (state, action) => {
            state.companyRegistryDTO.membersNumber = action.payload
        },
        setLoans: (state, action) => {
            state.loans = action.payload
        },
        setLoansNumber: (state, action) => {
            state.companyRegistryDTO.loansNumber = action.payload
        },
        setFundings: (state, action) => {
            state.fundings = action.payload
        },
        setFundingsNumber: (state, action) => {
            state.companyRegistryDTO.fundingNumber = action.payload
        },
        setLeasings: (state, action) => {
            state.leasings = action.payload
        },
        setLeasingsNumber: (state, action) => {
            state.companyRegistryDTO.leasingNumber = action.payload
        },
        setInstrumentalVehicles: (state, action) => {
            state.companyRegistryDTO.instrumentalVehicles = action.payload
        },
        setInstrumentalVehiclesLicensePlate: (state, action) => {
            state.companyRegistryDTO.instrumentalVehiclesLicensePlate = action.payload
        },
        setNotInstrumentalVehicles: (state, action) => {
            state.companyRegistryDTO.notInstrumentalVehicles = action.payload
        },
        setNotInstrumentalVehiclesLicensePlate: (state, action) => {
            state.companyRegistryDTO.notInstrumentalVehiclesLicensePlate = action.payload
        },
        setRetentionManagement: (state, action) => {
            state.companyRegistryDTO.retentionManagement = action.payload
        },
        resetCompanyRegistryDTO: (state) => {
            state.companyRegistryDTO = {
                salesRegisterNumber: 0,
                salesRegister: [],
                purchasesRegisterNumer: 0,
                purchasesRegister: [],
                feesRegisterNumber: 0,
                feesRegister: [],
                registerCharge: false,
                registerChargeName: '',
                adminsNumber: 0,
                admins: [],
                membersNumber: 0,
                members: [],
                instrumentalVehicles: false,
                instrumentalVehiclesLicensePlate: '',
                notInstrumentalVehicles: false,
                notInstrumentalVehiclesLicensePlate: '',
                loansNumber: 0,
                fundingNumber: 0,
                leasingNumber: 0,
                retentionManagement: false
            }
        },
        setOpenDownloadPdfAcubeAccountsModal: (state, action) => {
            state.openDownloadPdfAcubeAccountsModal = action.payload
        },
        setCompanyUpdateRegistryStatus: (state, action) => {
            state.companyUpdateRegistryStatus = action.payload
        },
        setUpdateCompanyStatus: (state, action) => {
            state.companyUpdateStatus = action.payload
        },
        setUpdateCompanyName: (state, action) => {
            state.companyUpdateDTO.name = action.payload
        },
        setUpdateCompanySurname: (state, action) => {
            state.companyUpdateDTO.surname = action.payload
        },
        setUpdateCompanyPhoneNumber: (state, action) => {
            state.companyUpdateDTO.phoneNumber = action.payload
        },
        setUpdateCompanyEmployeesNumber: (state, action) => {
            state.companyUpdateDTO.employeesNumber = action.payload
        },
        setUpdateCompanyFiscalCode: (state, action) => {
            state.companyUpdateDTO.fiscalCode = action.payload
        },
        setUpdateCompanyNote: (state, action) => {
            state.companyUpdateDTO.note = action.payload
        },
        setUpdateCompanyVatNumber: (state, action) => {
            state.companyUpdateDTO.vatNumber = action.payload
        },
        setSaveAtecosStatus: (state, action) => {
            state.saveAtecosStatus = action.payload
        },
        setEditBankStatus: (state, action) => {
            state.editBankStatus = action.payload
        },
        setEditTodosStatus: (state, action) => {
            state.editTodosStatus = action.payload
        },
        setEditRegistryStatus: (state, action) => {
            state.editRegistryStaus = action.payload
        },
        setAcubeGetTransactionsStatus: (state, action) => {
            state.acubeGetTransactionsStatus = action.payload
        },
        setAcubeReconnectRequestStatus: (state, action) => {
            state.acubeReconnectRequestStatus = action.payload
        },
        setGetCompanyRegistryPdf: (state, action) => {
            state.companyRegistryPdfStatus = action.payload
        },
        setFindAcubeConnectRequestStatus: (state, action) => {
            state.findAcubeConnectRequestStatus = action.payload
        },
        resetErrorUpdateRegistryEmail: (state) => {
            state.errors.updateRegistry.email = undefined
        },
        resetErrorUpdateRegistryVat: (state) => {
            state.errors.updateRegistry.vat = undefined
        },
        resetErrorRegistryEmail: (state) => {
            state.errors.registryEmail.email = undefined
        },
        setActivityCompleteStatus: (state, action) => {
            state.setActivityCompleteStatus = action.payload
        },
        setActivityOpenStatus: (state, action) => {
            state.setActivityOpenStatus = action.payload
        },
        setCompleteGroupStatus: (state, action) => {
            state.completeGroupStatus = action.payload
        },
        setFindAllOperatorsFinancialAdvisorFilter: (state, action) => {
            state.findAllOperatorsFinancialAdvisorFilter = action.payload
        },
        resetLeasingsResponse: (state) => {
            state.findLeasingsResponse = []
        },
        resetLoansResponse: (state) => {
            state.findLoansResponse = []
        },
        resetFundingsResponse: (state) => {
            state.findFundingsResponse = []
        },
        setCompanyIdActivities: (state, action) => {
            state.companyIdActivities = action.payload
        },
        setSaveRolesStatus: (state, action) => {
            state.saveRolesStatus = action.payload
        },
        setEditCompanyActivityTypesStatus: (state, action) => {
            state.editCompanyActivityTypesStatus = action.payload
        },
        setDeleteDocumentStatus: (state, action) => {
            state.deleteDocumentStatus = action.payload
        },
        setFindLoansStatus: (state, action) => {
            state.findLoansStatus = action.payload
        },
        setFindFundingsStatus: (state, action) => {
            state.findFundingsStatus = action.payload
        },
        setFindLeasingsStatus: (state, action) => {
            state.findLeasingsStatus = action.payload
        },
        setFindLoansResponse: (state, action) => {
            state.findLoansResponse = action.payload
        },
        setFindFundingsResponse: (state, action) => {
            state.findFundingsResponse = action.payload
        },
        setFindLeasingsResponse: (state, action) => {
            state.findLeasingsResponse = action.payload
        },
        resetFindCompanyResponse: (state) => {
            state.findCompanyResponse = undefined
        },
        setCompaniesUnseenMessagesStatus: (state, action) => {
            state.companiesUnseenMessagesStatus = action.payload
        },
        setDocumentReceivedFilterPage: (state, action) => {
            state.documentReceivedFilters.page = action.payload
        },
        setDocumentSentFilterPage: (state, action) => {
            state.documentSentFilters.page = action.payload
        },
        resetAtecosByIdResponse: (state) => {
            state.findAtecoByIdResponse = []
        },
        resetAtecosByIdStatus: (state) => {
            state.findAtecoByIdStatus = 'idle'
        },
        setCompanyEditPremium: (state, action) => {
            state.companyEditPremium.premium = action.payload
        },
        setCompanyEditPremiumStatus: (state, action) => {
            state.companyEditPremiumStatus = action.payload
        },
        setSettingsTabValue: (state, action) => {
            state.settingsTabValue = action.payload
        },
        setOperationsTabValue: (state, action) => {
            state.operationsTabValue = action.payload
        },
        setRegistryEmailStatus: (state, action) => {
            state.companyRegistryEmailStatus = action.payload
        },
        setOrdinaryAccountingCompany: (state, action) => {
            state.ordinaryAccountingUser = action.payload
        },
        setOrdinaryAccountingActivityCategories: (state, action) => {
            state.ordinaryAccountingActivityCategories = action.payload
        },
        setOrdinaryAccountingActivityGroups: (state, action) => {
            state.ordinaryAccountingActivityGroups = action.payload
        },
        setOrdinaryAccountingInitialTab: (state, action) => {
            state.ordinaryAccountingInitialTab = action.payload
        },
        setOrdinaryAccountingPeriod: (state, action) => {
            state.ordinaryAccountingPeriod = action.payload
        },
        setOrdinaryAccountingYear: (state, action) => {
            state.ordinaryAccountingYear = action.payload
        },
        setInitialTabAccountingValue: (state, action) => {
            state.initialTabAccountingActivities = action.payload
        },
        setDeleteCompanyStatus: (state, action) => {
            state.deleteCompanyStatus = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(findCompanyById.pending, (state) => {
                state.findCompanyStatus = 'loading'
            })
            .addCase(findCompanyById.fulfilled, (state, action) => {
                state.findCompanyStatus = 'successfully'
                state.findCompanyResponse = action.payload
            })
            .addCase(findCompanyById.rejected, (state) => {
                state.findCompanyStatus = 'failed'
            })
            .addCase(findAllCompanyWithoutPagination.pending, (state) => {
                state.findAllCompaniesWithoutPaginationStatus = 'loading'
            })
            .addCase(findAllCompanyWithoutPagination.fulfilled, (state, action) => {
                state.findAllCompaniesWithoutPaginationStatus = 'successfully'
                state.findAllCompaniesWithoutPaginationResponse = action.payload
            })
            .addCase(findAllCompanyWithoutPagination.rejected, (state) => {
                state.findAllCompaniesWithoutPaginationStatus = 'failed'
            })
            .addCase(findAllCompanies.pending, (state) => {
                state.findAllCompaniesStatus = 'loading'
            })
            .addCase(findAllCompanies.fulfilled, (state, action) => {
                state.findAllCompaniesStatus = 'successfully'
                state.findAllCompaniesResponse = action.payload
            })
            .addCase(findAllCompanies.rejected, (state) => {
                state.findAllCompaniesStatus = 'failed'
            })
            .addCase(companyUpdateRegistry.pending, (state) => {
                state.companyUpdateRegistryStatus = 'loading'
            })
            .addCase(companyUpdateRegistry.fulfilled, (state, action) => {
                state.companyUpdateRegistryStatus = 'successfully'
            })
            .addCase(companyUpdateRegistry.rejected, (state, action) => {
                //@ts-ignore
                const errorEmail = action.payload.find(error => error.message.includes('Error while creating user for company with id') && error.message.includes('User with username') && error.message.includes('already exists'))
                //@ts-ignore
                const errorVat = action.payload.find(error => error.message.includes("Unprocessable Content, status code 422' when invoking: Rest Client method: 'org.iltuocontabile.services.AcubeService#postBusinessRegistry"))
                if (errorEmail === undefined && errorVat === undefined) {
                    state.companyUpdateRegistryStatus = 'failed'
                } else {
                    state.companyUpdateRegistryStatus = 'idle'
                }
                state.errors.updateRegistry.email = errorEmail
                state.errors.updateRegistry.vat = errorVat
            })
            .addCase(companyRegistryEmail.pending, (state) => {
                state.companyRegistryEmailStatus = 'loading'
            })
            .addCase(companyRegistryEmail.fulfilled, (state, action) => {
                state.companyRegistryEmailStatus = 'successfully'
            })
            .addCase(companyRegistryEmail.rejected, (state, action) => {
                //@ts-ignore
                const errorEmail = action.payload.find(error => error.message.includes('Error while creating user for company with id') && error.message.includes('User with username') && error.message.includes('already exists'))
                if (errorEmail === undefined) {
                    state.companyRegistryEmailStatus = 'failed'
                } else {
                    state.companyRegistryEmailStatus = 'idle'
                }
                state.errors.registryEmail.email = errorEmail
            })
            .addCase(findOperatorsListAvatarsById.pending, (state) => {
                state.findOperatorsListAvatarsByIdStatus = 'loading'
            })
            .addCase(findOperatorsListAvatarsById.fulfilled, (state, action) => {
                state.findOperatorsListAvatarsByIdStatus = 'successfully'
                state.findOperatorsListAvatarsByIdResponse = action.payload
            })
            .addCase(findOperatorsListAvatarsById.rejected, (state) => {
                state.findOperatorsListAvatarsByIdStatus = 'failed'
            })
            .addCase(findAllAtecos.pending, (state) => {
                state.findAllAtecosStatus = 'loading'
            })
            .addCase(findAllAtecos.fulfilled, (state, action) => {
                state.findAllAtecosStatus = 'successfully'
                state.findAllAtecosResponse = action.payload
            })
            .addCase(findAllAtecos.rejected, (state) => {
                state.findAllAtecosStatus = 'failed'
            })
            .addCase(saveRoles.pending, (state) => {
                state.saveRolesStatus = 'loading'
            })
            .addCase(saveRoles.fulfilled, (state) => {
                state.saveRolesStatus = 'successfully'
            })
            .addCase(saveRoles.rejected, (state) => {
                state.saveRolesStatus = 'failed'
            })
            .addCase(findCompanyActivityCategories.pending, (state) => {
                state.findCompanyCategoryStatus = 'loading'
            })
            .addCase(findCompanyActivityCategories.fulfilled, (state, action) => {
                state.findCompanyCategoryStatus = 'successfully'
                state.findCompanyCategoryResponse = action.payload
            })
            .addCase(findCompanyActivityCategories.rejected, (state) => {
                state.findCompanyCategoryStatus = 'failed'
            })
            .addCase(findCompanyActivityTypes.pending, (state) => {
                state.findCompanyTypesStatus = 'loading'
            })
            .addCase(findCompanyActivityTypes.fulfilled, (state, action) => {
                state.findCompanyTypesStatus = 'successfully'
                state.findCompanyTypesResponse = action.payload
            })
            .addCase(findCompanyActivityTypes.rejected, (state) => {
                state.findCompanyTypesStatus = 'failed'
            })
            .addCase(editBankAccounts.pending, (state) => {
                state.editBankStatus = 'loading'
            })
            .addCase(editBankAccounts.fulfilled, (state) => {
                state.editBankStatus = 'successfully'
            })
            .addCase(editBankAccounts.rejected, (state) => {
                state.editBankStatus = 'failed'
            })
            .addCase(activityCanBeDeleted.pending, (state) => {
                state.activityCanBeDeletedStatus = 'loading'
            })
            .addCase(activityCanBeDeleted.fulfilled, (state, action) => {
                state.activityCanBeDeletedStatus = 'successfully'
                state.activityCanBeDeletedResponse = action.payload
            })
            .addCase(activityCanBeDeleted.rejected, (state) => {
                state.activityCanBeDeletedStatus = 'failed'
            })
            .addCase(editCompanyActivityTypes.pending, (state) => {
                state.editCompanyActivityTypesStatus = 'loading'
            })
            .addCase(editCompanyActivityTypes.fulfilled, (state, action) => {
                state.editCompanyActivityTypesStatus = 'successfully'
            })
            .addCase(editCompanyActivityTypes.rejected, (state) => {
                state.editCompanyActivityTypesStatus = 'failed'
            })
            .addCase(editTodos.pending, (state) => {
                state.editTodosStatus = 'loading'
            })
            .addCase(editTodos.fulfilled, (state, action) => {
                state.editTodosStatus = 'successfully'
            })
            .addCase(editTodos.rejected, (state) => {
                state.editTodosStatus = 'failed'
            })
            .addCase(findActivityGroup.pending, (state) => {
                state.findActivityGroupsStatus = 'loading'
            })
            .addCase(findActivityGroup.fulfilled, (state, action) => {
                state.findActivityGroupsStatus = 'successfully'
                state.findActivityGroupResponse = action.payload
            })
            .addCase(findActivityGroup.rejected, (state) => {
                state.findActivityGroupsStatus = 'failed'
            })
            .addCase(findActivityByGroup.pending, (state) => {
                state.findActivityByGroupStatus = 'loading'
            })
            .addCase(findActivityByGroup.fulfilled, (state, action) => {
                state.findActivityByGroupStatus = 'successfully'
                state.findActivityByGroupResponse = action.payload
            })
            .addCase(findActivityByGroup.rejected, (state) => {
                state.findActivityByGroupStatus = 'failed'
            })
            .addCase(findCompanyActivityTypeById.pending, (state) => {
                state.findActivityTypeByIdStatus = 'loading'
            })
            .addCase(findCompanyActivityTypeById.fulfilled, (state, action) => {
                state.findActivityTypeByIdStatus = 'successfully'
                state.findActivityTypeByIdResponse = action.payload
            })
            .addCase(findCompanyActivityTypeById.rejected, (state) => {
                state.findActivityTypeByIdStatus = 'failed'
            })
            .addCase(setActivityCompleted.pending, (state) => {
                state.setActivityCompleteStatus = 'loading'
            })
            .addCase(setActivityCompleted.fulfilled, (state, action) => {
                state.setActivityCompleteStatus = 'successfully'
            })
            .addCase(setActivityCompleted.rejected, (state) => {
                state.setActivityCompleteStatus = 'failed'
            })
            .addCase(setActivityOpen.pending, (state) => {
                state.setActivityOpenStatus = 'loading'
            })
            .addCase(setActivityOpen.fulfilled, (state, action) => {
                state.setActivityOpenStatus = 'successfully'
            })
            .addCase(setActivityOpen.rejected, (state) => {
                state.setActivityOpenStatus = 'failed'
            })
            .addCase(findAllReceivedDocuments.pending, (state) => {
                state.findAllReceivedDocumentsStatus = 'loading'
            })
            .addCase(findAllReceivedDocuments.fulfilled, (state, action) => {
                state.findAllReceivedDocumentsStatus = 'successfully'
                state.findAllRecivedDocumentsResponse = action.payload
            })
            .addCase(findAllReceivedDocuments.rejected, (state) => {
                state.findAllReceivedDocumentsStatus = 'failed'
            })
            .addCase(findAllSentDocuments.pending, (state) => {
                state.findAllSentDocumentsStatus = 'loading'
            })
            .addCase(findAllSentDocuments.fulfilled, (state, action) => {
                state.findAllSentDocumentsStatus = 'successfully'
                state.findAllSentDocumentsResponse = action.payload
            })
            .addCase(findAllSentDocuments.rejected, (state) => {
                state.findAllSentDocumentsStatus = 'failed'
            })
            .addCase(deleteCompany.pending, (state) => {
                state.deleteCompanyStatus = 'loading'
            })
            .addCase(deleteCompany.fulfilled, (state) => {
                state.deleteCompanyStatus = 'successfully'
            })
            .addCase(deleteCompany.rejected, (state) => {
                state.deleteCompanyStatus = 'failed'
            })
            .addCase(findDocumentById.pending, (state) => {
                state.findDocumentByIdStatus = 'loading'
            })
            .addCase(findDocumentById.fulfilled, (state, action) => {
                state.findDocumentByIdStatus = 'successfully'
                state.findDocumentByIdResponse = action.payload
            })
            .addCase(findDocumentById.rejected, (state) => {
                state.findDocumentByIdStatus = 'failed'
            })
            .addCase(deleteDocument.pending, (state) => {
                state.deleteDocumentStatus = 'loading'
            })
            .addCase(deleteDocument.fulfilled, (state, action) => {
                state.deleteDocumentStatus = 'successfully'
            })
            .addCase(deleteDocument.rejected, (state) => {
                state.deleteDocumentStatus = 'failed'
            })
            .addCase(completeGroup.pending, (state) => {
                state.completeGroupStatus = 'loading'
            })
            .addCase(completeGroup.fulfilled, (state, action) => {
                state.completeGroupStatus = 'successfully'
            })
            .addCase(completeGroup.rejected, (state) => {
                state.completeGroupStatus = 'failed'
            })
            .addCase(getCompanyRegistryPdf.pending, (state) => {
                state.companyRegistryPdfStatus = 'loading'
            })
            .addCase(getCompanyRegistryPdf.fulfilled, (state, action) => {
                state.companyRegistryPdfStatus = 'successfully'
                state.companyRegistryPdfResponse = action.payload
            })
            .addCase(getCompanyRegistryPdf.rejected, (state) => {
                state.companyRegistryPdfStatus = 'failed'
            })
            .addCase(saveAtecos.pending, (state) => {
                state.saveAtecosStatus = 'loading'
            })
            .addCase(saveAtecos.fulfilled, (state, action) => {
                state.saveAtecosStatus = 'successfully'
            })
            .addCase(saveAtecos.rejected, (state) => {
                state.saveAtecosStatus = 'failed'
            })
            .addCase(editRegistry.pending, (state) => {
                state.editRegistryStaus = 'loading'
            })
            .addCase(editRegistry.fulfilled, (state, action) => {
                state.editRegistryStaus = 'successfully'
            })
            .addCase(editRegistry.rejected, (state) => {
                state.editRegistryStaus = 'failed'
            })
            .addCase(findLoans.pending, (state) => {
                state.findLoansStatus = 'loading'
            })
            .addCase(findLoans.fulfilled, (state, action) => {
                state.findLoansStatus = 'successfully'
                state.findLoansResponse = action.payload
            })
            .addCase(findLoans.rejected, (state) => {
                state.findLoansStatus = 'failed'
            })
            .addCase(findFundings.pending, (state) => {
                state.findFundingsStatus = 'loading'
            })
            .addCase(findFundings.fulfilled, (state, action) => {
                state.findFundingsStatus = 'successfully'
                state.findFundingsResponse = action.payload
            })
            .addCase(findFundings.rejected, (state) => {
                state.findFundingsStatus = 'failed'
            })
            .addCase(findLeasings.pending, (state) => {
                state.findLeasingsStatus = 'loading'
            })
            .addCase(findLeasings.fulfilled, (state, action) => {
                state.findLeasingsStatus = 'successfully'
                state.findLeasingsResponse = action.payload
            })
            .addCase(findLeasings.rejected, (state) => {
                state.findLeasingsStatus = 'failed'
            })
            .addCase(findAcubeConnectRequest.pending, (state) => {
                state.findAcubeConnectRequestStatus = 'loading'
            })
            .addCase(findAcubeConnectRequest.fulfilled, (state, action) => {
                state.findAcubeConnectRequestStatus = 'successfully'
            })
            .addCase(findAcubeConnectRequest.rejected, (state) => {
                state.findAcubeConnectRequestStatus = 'failed'
            })
            .addCase(findByIdAcubeGetAccount.pending, (state) => {
                state.findByIdAcubeGetAccountStatus = 'loading'
            })
            .addCase(findByIdAcubeGetAccount.fulfilled, (state, action) => {
                state.findByIdAcubeGetAccountStatus = 'successfully'
                state.findByIdAcubeGetAccountResponse = action.payload
            })
            .addCase(findByIdAcubeGetAccount.rejected, (state) => {
                state.findByIdAcubeGetAccountStatus = 'failed'
            })
            .addCase(updateCompany.pending, (state) => {
                state.companyUpdateStatus = 'loading'
            })
            .addCase(updateCompany.fulfilled, (state, action) => {
                state.companyUpdateStatus = 'successfully'
            })
            .addCase(updateCompany.rejected, (state) => {
                state.companyUpdateStatus = 'failed'
            })
            .addCase(acubeGetTransactions.pending, (state) => {
                state.acubeGetTransactionsStatus = 'loading'
            })
            .addCase(acubeGetTransactions.fulfilled, (state, action) => {
                state.acubeGetTransactionsStatus = 'successfully'
                state.acubeGetTransactionsResponse = action.payload
            })
            .addCase(acubeGetTransactions.rejected, (state) => {
                state.acubeGetTransactionsStatus = 'failed'
            })
            .addCase(acubeReconnectRequest.pending, (state) => {
                state.acubeReconnectRequestStatus = 'loading'
            })
            .addCase(acubeReconnectRequest.fulfilled, (state, action) => {
                state.acubeReconnectRequestStatus = 'successfully'
                state.acubeReconnectRequestResponse = action.payload
            })
            .addCase(acubeReconnectRequest.rejected, (state) => {
                state.acubeReconnectRequestStatus = 'failed'
            })
            .addCase(findCompanyAvatar.pending, (state) => {
                state.findCompanyAvatarStatus = 'loading'
            })
            .addCase(findCompanyAvatar.fulfilled, (state, action) => {
                state.findCompanyAvatarStatus = 'successfully'
                state.companyAvatar = action.payload
            })
            .addCase(findCompanyAvatar.rejected, (state) => {
                state.findCompanyAvatarStatus = 'failed'
            })
            .addCase(getCompanyUnseenInfoDocuments.pending, (state) => {
                state.companiesUnseenDocumentsStatus = 'loading'
            })
            .addCase(getCompanyUnseenInfoDocuments.fulfilled, (state, action) => {
                state.companiesUnseenDocumentsStatus = 'successfully'
                state.companiesUnseenDocumentsResponse = action.payload
            })
            .addCase(getCompanyUnseenInfoDocuments.rejected, (state) => {
                state.companiesUnseenDocumentsStatus = 'failed'
            })
            .addCase(getCompanyUnseenInfoMessages.pending, (state) => {
                state.companiesUnseenMessagesStatus = 'loading'
            })
            .addCase(getCompanyUnseenInfoMessages.fulfilled, (state, action) => {
                state.companiesUnseenMessagesStatus = 'successfully'
                state.companiesUnseenMessagesResponse = action.payload
            })
            .addCase(getCompanyUnseenInfoMessages.rejected, (state) => {
                state.companiesUnseenMessagesStatus = 'failed'
            })
            .addCase(findAtecosById.pending, (state) => {
                state.findAtecoByIdStatus = 'loading'
            })
            .addCase(findAtecosById.fulfilled, (state, action) => {
                state.findAtecoByIdStatus = 'successfully'
                state.findAtecoByIdResponse = action.payload
            })
            .addCase(findAtecosById.rejected, (state) => {
                state.findAtecoByIdStatus = 'failed'
            })
            .addCase(companyEditPremium.pending, (state) => {
                state.companyEditPremiumStatus = 'loading'
            })
            .addCase(companyEditPremium.fulfilled, (state) => {
                state.companyEditPremiumStatus = 'successfully'
            })
            .addCase(companyEditPremium.rejected, (state) => {
                state.companyEditPremiumStatus = 'failed'
            })
    },
})

export const {
    setOrdinaryAccountingYearFilter,
    setStatusFilter,
    setAccountantFilter,
    setFinancialAdvisorFilter,
    setOpenOrdinaryAccountingModal,
    setDocumentTabValue,
    setOpenCriticityModal,
    setCriticityOperation,
    setOpenAtecoModal,
    setOpenBankModal,
    setOpenActivityManagementModal,
    setOrdinaryAccountingTabValue,
    setBusinessNameFilter,
    setCompanyUpdateRegistryBusinessName,
    setRegistryEmail,
    setCompanyUpdateRegistryEmployeesNumber,
    setCompanyUpdateRegistryFiscalCode,
    setCompanyUpdateRegistryName,
    setCompanyUpdateRegistryPhoneNumber,
    setCompanyUpdateRegistrySurname,
    setCompanyUpdateRegistryVatNumber,
    setCompanyUpdateRegistryNote,
    setOperatorsToAddList,
    setAccountantToAdd,
    setFindCompanyStatus,
    setCompanyBankIban,
    setCompanyBankName,
    setOpenDeleteBankModal,
    setOpenDeleteTodoModal,
    setTodoIndexToDelete,
    setTodoToEdit,
    setOpenDocumentModal,
    setOpenDeleteCompanyModal,
    setFindDocumentStatus,
    setDeleteDocumentOpenModal,
    setDocumentSentFilterPeriod,
    setDocumentSentFilterYear,
    setDocumentReceivedFilterPeriod,
    setDocumentReceivedFilterYear,

    setSelectedPeriod,
    resetFindActivityGroupStatus,
    setSelectedActivity,
    setCompanyCategories,
    setCompanyPeriodicity,
    setGroupToComplete,
    setCompleteGroupModal,
    setCompanyTodos,
    setActivityCompleteStatus,
    setActivityOpenStatus,
    setCompanyIdActivities,
    setCompleteGroupStatus,

    setUpdateRegistryTabValue,
    setSalesRegisters,
    setSalesRegistersNumber,
    setPurchaseRegisters,
    setPurchaseRegistersNumber,
    setFeesRegisters,
    setFeesRegistersNumber,
    setRegisterChange,
    setRegisterChangeName,
    setAdmins,
    setAdminsNumber,
    setMembers,
    setMembersNumber,
    setLoans,
    setLoansNumber,
    setFundings,
    setFundingsNumber,
    setLeasings,
    setLeasingsNumber,
    setInstrumentalVehicles,
    setInstrumentalVehiclesLicensePlate,
    setNotInstrumentalVehicles,
    setNotInstrumentalVehiclesLicensePlate,
    setRetentionManagement,
    setOpenDownloadPdfAcubeAccountsModal,
    resetCompanyRegistryDTO,
    setCompanyUpdateRegistryStatus,
    setUpdateCompanyStatus,
    setUpdateCompanyEmployeesNumber,
    setUpdateCompanyFiscalCode,
    setUpdateCompanyName,
    setUpdateCompanyNote,
    setUpdateCompanyPhoneNumber,
    setUpdateCompanySurname,
    setUpdateCompanyVatNumber,
    setSaveAtecosStatus,
    setEditBankStatus,
    setEditTodosStatus,
    setEditRegistryStatus,
    setAcubeGetTransactionsStatus,
    setAcubeReconnectRequestStatus,
    setGetCompanyRegistryPdf,
    setFindAcubeConnectRequestStatus,
    resetErrorUpdateRegistryEmail,
    resetErrorUpdateRegistryVat,
    setFindAllOperatorsFinancialAdvisorFilter,
    setOperatorsFilter,
    setFindCompanyAvatarStatus,
    resetFundingsResponse,
    resetLeasingsResponse,
    resetLoansResponse,
    setSaveRolesStatus,
    setEditCompanyActivityTypesStatus,
    setDeleteDocumentStatus,
    setFindFundingsStatus,
    setFindLeasingsStatus,
    setFindLoansStatus,
    setFindFundingsResponse,
    setFindLeasingsResponse,
    setFindLoansResponse,
    resetFindCompanyResponse,
    setCompaniesUnseenMessagesStatus,
    setPageFilter,
    setDocumentReceivedFilterPage,
    setDocumentSentFilterPage,
    resetAtecosByIdResponse,
    resetAtecosByIdStatus,
    setCompanyEditPremium,
    setCompanyEditPremiumStatus,
    setTaxAreaTabValue,
    resetCompanyUpdateRegistryRequest,
    setSettingsTabValue,
    setOperationsTabValue,
    resetErrorRegistryEmail,
    setCompanyUpdateRegistryEmail,
    setRegistryEmailStatus,

    setOrdinaryAccountingActivityCategories,
    setOrdinaryAccountingActivityGroups,
    setOrdinaryAccountingCompany,
    setOrdinaryAccountingInitialTab,
    setOrdinaryAccountingPeriod,
    setOrdinaryAccountingYear,
    setInitialTabAccountingValue,
    setDeleteCompanyStatus
} = companySlice.actions

export default companySlice.reducer