import { MenuItem } from "@mui/material";
import { ReactNode, useEffect } from "react";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { SearchIcon } from "../icons/search";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SelectComponent } from "../select";
import { SpinnerComponent } from "../spinner";
import { TextInput } from "../textInput";
import { findAllActivityCategory, findAllActivityType, setActivityFilter, setTypeFilter } from "./slice";

export function ActivityFiltersComponent() {
    const activityState = useAppSelector(state => state.activity)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(findAllActivityCategory())
    }, [])

    if (activityState.findAllActivityCategoryStatus === 'loading') {
        return (
            <div>
                <SpinnerComponent size={"small"} />
            </div>
        )
    }

    if (activityState.findAllActivityCategoryStatus === 'failed') {
        return (<div>Si è verificato un errore durante il caricamento dei dati</div>)
    }

    let activityCategoryMenuItems: ReactNode[] = []
    if (
        activityState.findAllActivityCategoryStatus === 'successfully' &&
        activityState.findAllActivityCategoryResponse !== undefined &&
        activityState.findAllActivityCategoryResponse.data !== undefined &&
        activityState.findAllActivityCategoryResponse.data !== null &&
        activityState.findAllActivityCategoryResponse.data.length > 0
    ) {
        activityState.findAllActivityCategoryResponse.data.forEach(category => {
            activityCategoryMenuItems.push(
                <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>,
            )
        })
    }

    return (
        <div style={{ marginTop: '8px', gap: '16px' }} className="row m-0 p-0`">
            <div className="col-12 col-lg p-0 m-0">
                <TextInput id="activity-activity-filter" value={activityState.activityTypeFilters.name} onChange={(e) => dispatch(setActivityFilter(e.target.value))} type={"text"} placeholder={"Cerca attività"} startIcon={<SearchIcon colorBase={""} />} />
            </div>
            <div className="col-12 col-lg-2 p-0 m-0">
                <SelectComponent
                    id={"dashboard-financial-advisor-filter"}
                    value={activityState.activityTypeFilters.activityCategoryId}
                    onChange={(e) => {
                        dispatch(setTypeFilter(e.target.value))
                        dispatch(findAllActivityType({
                            page: activityState.activityTypeFilters.page,
                            activityCategoryId: e.target.value,
                            name: activityState.activityTypeFilters.name,
                            itemsPerPage: activityState.activityTypeFilters.itemsPerPage
                        }))
                    }}
                    menuItems={
                        [
                            <MenuItem key='' value=''>Tipologia</MenuItem>,
                            activityCategoryMenuItems
                        ]
                    }
                />
            </div>
            <div className="col-12 col-lg-auto p-0 m-0 d-flex align-items-center justify-content-center">
                <div className="row p-0 m-0" style={{ gap: '8px' }}>
                    <div className="col p-0 m-0">
                        <ButtonComponent
                            label={"Pulisci"}
                            onClick={() => {
                                dispatch(setActivityFilter(''))
                                dispatch(setTypeFilter(''))
                                dispatch(findAllActivityType({ name: '', activityCategoryId: '', page: activityState.activityTypeFilters.page, itemsPerPage: activityState.activityTypeFilters.itemsPerPage }))
                            }}
                            color={Colors.NEUTRAL}
                            variant={Variant.OUTLINE}
                            size={Size.MD}
                            iconStyle={IconStyle.OFF}
                        />
                    </div>
                    <div className="col p-0 m-0">
                        <ButtonComponent label={"Applica"} onClick={() => dispatch(findAllActivityType(activityState.activityTypeFilters))} color={Colors.PRIMARY} variant={Variant.OUTLINE} size={Size.MD} iconStyle={IconStyle.OFF} />
                    </div>
                </div>
            </div>
        </div>
    )
}