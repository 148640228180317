import { AccountantResponseDTO, FindAllAccountantFiltersDTO, FindAllAccountants } from "./dto";
import { AccountantServiceImpl } from "./serviceImpl";

export interface AccountantService {
    findAllAccountant(filters: FindAllAccountantFiltersDTO): Promise<FindAllAccountants>
    createAccountant(request: FormData): Promise<void>
    findAccountant(id: string): Promise<AccountantResponseDTO>
    editAccountant(id: string, data: FormData): Promise<void>
    deleteAccountant(id: string): Promise<void>;
}

export function NewAccountantService(): AccountantService {
    return new AccountantServiceImpl();
}
