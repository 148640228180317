import { format, isValid, lastDayOfMonth } from "date-fns"
import { useEffect, useState } from "react"
import { ButtonComponent } from "../button"
import { Colors, IconStyle, Size, Variant } from "../button/dto"
import { ModalComponent } from "../modal"
import { NewObjectService } from "../objects/service"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import { TextInput } from "../textInput"
import colors from '../utils/index.module.scss'
import { acubeGetTransactions, setAcubeGetTransactionsStatus } from "./slice"

interface Props {
    open: boolean
    uuid: string
    name: string
    fiscalId: string
    handleClose: (open: boolean) => void
}

export function DownloadFileAcubeAccountsModal(props: Props) {
    const { open } = props
    const { uuid } = props
    const { name } = props
    const { fiscalId } = props
    const { handleClose } = props

    const dispatch = useAppDispatch()
    const objectService = NewObjectService()

    const companyState = useAppSelector(state => state.company)

    const [fromDate, setFromDate] = useState<string>(format(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'yyyy-MM-dd'))
    const [toDate, setToDate] = useState<string>(format(lastDayOfMonth(new Date(new Date().getFullYear(), new Date().getMonth(), 1)), 'yyyy-MM-dd'))

    useEffect(() => {
        if (fromDate === undefined) {
            setFromDate(format(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'yyyy-MM-dd'))
        }
    }, [fromDate])

    return (
        <ModalComponent
            open={open}
            handleClose={handleClose}
        >
            <div
                style={{
                    padding: '20px',
                    backgroundColor: colors.white,
                    boxShadow: colors.boxShadowXL,
                    borderRadius: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '100%'
                }}
            >
                <div>
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="text text--lg typography--semibold typography--black">Scarica tracciato</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between" style={{ gap: '16px', width: '100%' }}>
                        <span className="text text--lg typography--regular typography--neutral-400">Scegli intervallo di tempo</span>
                    </div>
                </div>
                <div style={{ margin: 0, padding: 0, gap: '12px' }} className="row">
                    <div
                        className="col"
                        style={{ margin: 0, padding: 0 }}
                    >
                        <TextInput id={""} type={"date"} placeholder={""} value={fromDate} onChange={e => {
                            if (isValid(new Date(e.target.value)))
                                setFromDate(format(new Date(e.target.value), 'yyyy-MM-dd'))
                            else {
                                setFromDate(format(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'yyyy-MM-dd'))
                            }
                        }} />
                    </div>
                    <div
                        className="col"
                        style={{ margin: 0, padding: 0 }}
                    >
                        <TextInput id={""} type={"date"} placeholder={""} value={toDate} onChange={e => {
                            if (isValid(new Date(e.target.value)))
                                setToDate(format(new Date(e.target.value), 'yyyy-MM-dd'))
                            else {
                                setToDate(format(lastDayOfMonth(new Date(new Date().getFullYear(), new Date().getMonth(), 1)), 'yyyy-MM-dd'))
                            }
                        }} />
                    </div>
                </div>
                <div style={{ margin: 0, padding: 0, gap: '12px' }} className="row">
                    <div
                        className="col"
                        style={{ margin: 0, padding: 0 }}
                    >
                        <ButtonComponent
                            label={"Annulla"}
                            onClick={() => handleClose(false)}
                            color={Colors.NEUTRAL}
                            variant={Variant.OUTLINE}
                            size={Size.FIT}
                            iconStyle={IconStyle.OFF}
                        />
                    </div>
                    <div
                        className="col"
                        style={{ margin: 0, padding: 0 }}
                    >
                        <ButtonComponent
                            label={"Avvia procedura"}
                            onClick={() => {
                                dispatch(acubeGetTransactions({ fiscalId: fiscalId, uuid: uuid, madeOnAfter: fromDate, madeOnBefore: toDate })).then((e) => {
                                    //@ts-ignore
                                    objectService.downloadBase64WithoutExtension('transazioni-' + name, e.payload, 'text/csv')
                                    dispatch(setAcubeGetTransactionsStatus('idle'))
                                    handleClose(false)
                                })
                            }}
                            color={Colors.PRIMARY}
                            variant={Variant.SOLID}
                            size={Size.FIT}
                            iconStyle={IconStyle.OFF}
                        />
                    </div>
                </div>
            </div>
        </ModalComponent >
    )
}