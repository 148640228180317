import { TableBody } from "@mui/material";
import { ReactNode, useEffect } from "react";
import { findAllActivityCategory } from "../activity/slice";
import { CheckboxComponent } from "../checkbox";
import { CheckboxSize, CheckboxStyle } from "../checkbox/dto";
import { CompanyResponseDTO } from "../company/dto";
import { AccountingSchemeEnum } from "../companyQuotationWizard/dto";
import { findAllFinancialAdvisorWithoutPagination } from "../financialAdvisor/slice";
import { keycloak } from "../keycloak";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SpinnerComponent } from "../spinner";
import { TableComponent } from "../table";
import { TableCellComponent } from "../table/tableCell";
import { TableHeadComponent } from "../table/tableHead";
import { TableRowComponent } from "../table/tableRow";
import { CompanyToConfirmMenuComponent } from "./menu";
import { findAllCompaniesToConfirm, setCompanyConfirmRequest, setCompanyToConfirmModal, setCompanyToConfirmSettedId } from "./slice";

export function CompanyToConfirmListComponent() {
    const companyToConfirmState = useAppSelector(state => state.companyToConfirm)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const activityState = useAppSelector(state => state.activity)
    const companyState = useAppSelector(state => state.company)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(findAllCompaniesToConfirm(companyToConfirmState.companiesToAddFilters))
        dispatch(findAllFinancialAdvisorWithoutPagination())
        dispatch(findAllActivityCategory())
    }, [])

    useEffect(() => {
        if (companyToConfirmState.companyConfirmStatus === 'successfully') {
            dispatch(setCompanyToConfirmModal(false))
            dispatch(setCompanyConfirmRequest([]))
            dispatch(setCompanyToConfirmSettedId(''))
            dispatch(findAllCompaniesToConfirm(companyToConfirmState.companiesToAddFilters))
        }
    }, [companyToConfirmState.companyConfirmStatus])

    useEffect(() => {
        if (companyToConfirmState.companyConfirmStatus === 'failed') {
            dispatch(setCompanyToConfirmModal(false))
            dispatch(setCompanyConfirmRequest([]))
            dispatch(setCompanyToConfirmSettedId(''))
        }
    }, [companyToConfirmState.companyConfirmStatus])

    if (
        companyToConfirmState.findAllCompaniesToConfirmStatus === 'loading' ||
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'loading' ||
        activityState.findAllActivityCategoryStatus === 'loading' ||
        companyToConfirmState.companyConfirmStatus === 'loading' ||
        companyState.deleteCompanyStatus === 'loading'
    ) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                <SpinnerComponent size={"small"} />
            </div>
        )
    }

    if (companyToConfirmState.findAllCompaniesToConfirmStatus === 'failed' || financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'failed' || activityState.findAllActivityCategoryStatus === 'failed') {
        return (<div>Si è verificato un errore durante il caricamento dei dati</div>)
    }

    let companyRows: ReactNode[] = []

    const activityCategory = (company?: CompanyResponseDTO) => {
        let activityHeader: ReactNode[] = []
        let activityRow: ReactNode[] = []

        if (
            activityState.findAllActivityCategoryStatus === 'successfully' &&
            activityState.findAllActivityCategoryResponse !== undefined &&
            activityState.findAllActivityCategoryResponse.data !== undefined &&
            activityState.findAllActivityCategoryResponse.data.length > 0
        ) {
            activityState.findAllActivityCategoryResponse.data.forEach(activity => {
                activityHeader.push(
                    <TableCellComponent key={activity.id} size='small' label={activity.name} cellType={"head"} alignLabel={"center"} />
                )
                if (company !== undefined)
                    activityRow.push(
                        <TableCellComponent key={'row-' + company.id + activity.id} content={<CheckboxComponent size={CheckboxSize.SM} disabled checked={company.activityCategoryIds?.includes(activity.id)} style={CheckboxStyle.SQUARE} />} cellType={"row-regular"} alignLabel={"center"} />
                    )
            })
        }
        return { header: activityHeader, row: activityRow }
    }

    if (
        companyToConfirmState.findAllCompaniesToConfirmStatus === 'successfully' &&
        companyToConfirmState.findAllCompaniesToConfirmResponse !== undefined &&
        companyToConfirmState.findAllCompaniesToConfirmResponse.data !== undefined &&
        companyToConfirmState.findAllCompaniesToConfirmResponse.data.length > 0 &&
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationStatus === 'successfully' &&
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse !== undefined &&
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data !== undefined &&
        financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse.data.length > 0
    ) {
        companyToConfirmState.findAllCompaniesToConfirmResponse.data.forEach((company, index) => {
            const financialAdvisor = financialAdvisorState.findAllFinancialAdvisorWithoutPaginationResponse?.data.find(financialAdvisor => financialAdvisor.id === company.financialAdvisorId)
            companyRows.push(
                <TableRowComponent key={'company-to-confirm-' + company.id}>
                    {
                        keycloak.hasRealmRole('admin') &&
                        <TableCellComponent
                            content={
                                <CheckboxComponent
                                    size={CheckboxSize.SM}
                                    style={CheckboxStyle.SQUARE}
                                    checked={companyToConfirmState.companyConfirmRequest.find(_company => _company.companyId === company.id) !== undefined}
                                    onChange={(_, checked) => {
                                        if (checked) {
                                            dispatch(setCompanyConfirmRequest([...companyToConfirmState.companyConfirmRequest, { companyId: company.id, price: company.price }]))
                                        } else {
                                            const remove = companyToConfirmState.companyConfirmRequest.filter(_company => _company.companyId !== company.id)
                                            dispatch(setCompanyConfirmRequest(remove))
                                        }
                                    }}
                                />
                            }
                            cellType={"row-regular"}
                            alignLabel={"right"}
                        />
                    }
                    <TableCellComponent label={'Azienda ' + (index + 1)} cellType={"row-medium"} alignLabel={"left"} />
                    {
                        keycloak.hasRealmRole('admin') &&
                        <TableCellComponent label={financialAdvisor?.name + ' ' + financialAdvisor?.surname} cellType={"row-regular"} alignLabel={"left"} />
                    }
                    <TableCellComponent label={company.accountingScheme === AccountingSchemeEnum.Ordinary ? 'Ordinario' : 'Semplificato'} cellType={"row-regular"} alignLabel={"left"} />
                    {activityCategory(company).row}
                    <TableCellComponent label={'€ ' + company.price.toLocaleString('it-IT', { useGrouping: true })} cellType={"row-regular"} alignLabel={"center"} />
                    {
                        keycloak.hasRealmRole('admin') &&
                        <TableCellComponent content={<CompanyToConfirmMenuComponent id={company.id} />} cellType={"row-regular"} alignLabel={"right"} />
                    }
                </TableRowComponent>
            )
        })
    }

    return (
        <TableComponent>
            <TableHeadComponent>
                {
                    keycloak.hasRealmRole('admin') &&
                    <TableCellComponent
                        cellType={"head"}
                        alignLabel={"right"}
                        size="context-menu"
                        content={
                            <CheckboxComponent
                                size={CheckboxSize.SM}
                                disabled={companyToConfirmState.findAllCompaniesToConfirmResponse?.total === 0}
                                checked={companyToConfirmState.companyConfirmRequest.length !== 0 && companyToConfirmState.companyConfirmRequest.length === companyToConfirmState.findAllCompaniesToConfirmResponse?.total}
                                indeterminate={companyToConfirmState.companyConfirmRequest.length < (companyToConfirmState.findAllCompaniesToConfirmResponse?.total || 0) && companyToConfirmState.companyConfirmRequest.length > 0}
                                style={CheckboxStyle.SQUARE}
                                onChange={(_, checked) => {
                                    if (checked) {
                                        const total = companyToConfirmState.findAllCompaniesToConfirmResponse?.data.map(company => { return ({ companyId: company.id, price: company.price }) })
                                        dispatch(setCompanyConfirmRequest(total))
                                    } else {
                                        dispatch(setCompanyConfirmRequest([]))
                                    }
                                }}
                            />
                        }
                    />
                }
                <TableCellComponent label={"ragione sociale"} cellType={"head"} alignLabel={"left"} />
                {
                    keycloak.hasRealmRole('admin') &&
                    <TableCellComponent size='large' label={"commercialista"} cellType={"head"} alignLabel={"left"} />
                }
                <TableCellComponent size='large' label={"regime"} cellType={"head"} alignLabel={"left"} />
                {activityCategory().header}
                <TableCellComponent size='small' label={"prezzo"} cellType={"head"} alignLabel={"center"} />
                {
                    keycloak.hasRealmRole('admin') &&
                    <TableCellComponent cellType={"head"} alignLabel={"right"} size="context-menu" />
                }
            </TableHeadComponent>
            <TableBody>
                {companyRows}
            </TableBody>
        </TableComponent>
    )
}