import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WithholdingsDashboard } from "../../dashboard/dto";
import { PromiseStatuses } from "../../utils";
import { FindAllWithholdingsResponse, WithholdingCreationDTO, WithholdingsDTO, WithholdingsFilters } from "./dto";
import { NewWithholdingsService } from "./service";

export interface WithholdingsState {
    withholdingsCreationRequest: WithholdingCreationDTO[]
    deleteWithholdingStatus: PromiseStatuses
    createWithholdingStatus: PromiseStatuses
    markWithholdingsAsReadStatus: PromiseStatuses
    markWithholdingsAsReadResponse: WithholdingsDTO[]
    withholdingsForAccounting: WithholdingsDashboard[]
    deleteMessage: string
    openConfirmWithholdingsDenyModal: boolean
    findAllWithholdingsResponse?: FindAllWithholdingsResponse
    findAllWithholdingsStatus: PromiseStatuses
    withholdingsFilter: WithholdingsFilters
    findWithholdingByIdResponse?: WithholdingsDTO
    findWithholdingByIdStatus: PromiseStatuses
}

const initialState: WithholdingsState = {
    withholdingsCreationRequest: [],
    deleteWithholdingStatus: 'idle',
    createWithholdingStatus: 'idle',
    markWithholdingsAsReadStatus: 'idle',
    markWithholdingsAsReadResponse: [],
    withholdingsForAccounting: [],
    deleteMessage: "",
    openConfirmWithholdingsDenyModal: false,
    findAllWithholdingsStatus: "idle",
    withholdingsFilter: {
        companyId: 0,
        page: 0,
        itemsPerPage: 0,
        period: 1,
        year: new Date().getFullYear(),
    },
    findWithholdingByIdStatus: "idle",
}

export const findAllWithholdings = createAsyncThunk(
    'withholdings/findAllWithholdings',
    async (request: WithholdingsFilters): Promise<FindAllWithholdingsResponse> => {
        const withholdingsService = NewWithholdingsService()

        return withholdingsService.findAllWithholdings(request)
    }
)

export const findWithholdingById = createAsyncThunk(
    'withholdings/findWithholdingById',
    async (id: number): Promise<WithholdingsDTO> => {
        const withholdingsService = NewWithholdingsService()

        return withholdingsService.findWithholding(id)
    }
)

export const markWithholdingsAsRead = createAsyncThunk(
    'withholdings/markWithholdingsAsRead',
    async (ids: number[], thunkApi): Promise<WithholdingsDTO[]> => {
        let promises: Promise<WithholdingsDTO>[] = []
        promises = ids.map(async (id) => {
            const withholdingsService = NewWithholdingsService()

            return withholdingsService.findWithholding(id)
        })

        return Promise.all(promises)
    }
)

export const deleteWithholding = createAsyncThunk(
    'withholdings/deleteWithholding',
    async (request: number): Promise<void> => {
        const withholdingsService = NewWithholdingsService()

        return withholdingsService.deleteWithholding(request)
    }
)

export const createWithholdings = createAsyncThunk(
    'withholdings/createWithholdings',
    async (request: FormData[]): Promise<void[]> => {
        let promises: Promise<void>[] = []
        promises = request.map(async (withholding) => {
            const withholdingsService = NewWithholdingsService()

            return withholdingsService.createWithholding(withholding)
        })
        return Promise.all(promises)
    }
)

const withholdingsSlice = createSlice({
    name: 'withholdings/slice',
    initialState,
    reducers: {
        setWithholdingsCreationRequest: (state, action) => {
            state.withholdingsCreationRequest = action.payload
        },
        setWithholdingsForAccounting: (state, action) => {
            state.withholdingsForAccounting = action.payload
        },
        setDeleteWithholdingStatus: (state, action) => {
            state.deleteWithholdingStatus = action.payload
        },
        setCreateWithholdingStatus: (state, action) => {
            state.createWithholdingStatus = action.payload
        },
        setMarkAsWithholdingStatus: (state, action) => {
            state.markWithholdingsAsReadStatus = action.payload
        },
        setDeleteMessage: (state, action) => {
            state.deleteMessage = action.payload
        },
        setOpenConfirmWithholdingsDenyModal: (state, action) => {
            state.openConfirmWithholdingsDenyModal = action.payload
        },
        setWithholdingsPeriod: (state, action) => {
            state.withholdingsFilter.period = action.payload
        },
        setWittholdingsCompanyId: (state, action) => {
            state.withholdingsFilter.companyId = action.payload
        },
        setWittholdingsYear: (state, action) => {
            state.withholdingsFilter.year = action.payload
        },
        setFindByIdWithholdingStatus: (state, action) => {
            state.findWithholdingByIdStatus = action.payload
        },
    },
    extraReducers(builder) {
        builder
            .addCase(deleteWithholding.pending, (state => {
                state.deleteWithholdingStatus = 'loading'
            }))
            .addCase(deleteWithholding.fulfilled, ((state) => {
                state.deleteWithholdingStatus = 'successfully'
            }))
            .addCase(deleteWithholding.rejected, ((state, action) => {
                state.deleteWithholdingStatus = 'failed'
                if (action.error.message === "Request failed with status code 403") {
                    state.deleteMessage = "Non puoi eliminare questa ritenuta"
                }
            }))
            .addCase(createWithholdings.pending, (state => {
                state.createWithholdingStatus = 'loading'
            }))
            .addCase(createWithholdings.fulfilled, ((state) => {
                state.createWithholdingStatus = 'successfully'
            }))
            .addCase(createWithholdings.rejected, (state => {
                state.createWithholdingStatus = 'failed'
            }))
            .addCase(markWithholdingsAsRead.pending, (state => {
                state.markWithholdingsAsReadStatus = 'loading'
            }))
            .addCase(markWithholdingsAsRead.fulfilled, ((state, action) => {
                state.markWithholdingsAsReadStatus = 'successfully'
                state.markWithholdingsAsReadResponse = action.payload
            }))
            .addCase(markWithholdingsAsRead.rejected, (state => {
                state.markWithholdingsAsReadStatus = 'failed'
            }))
            .addCase(findAllWithholdings.pending, (state => {
                state.findAllWithholdingsStatus = 'loading'
            }))
            .addCase(findAllWithholdings.fulfilled, ((state, action) => {
                state.findAllWithholdingsStatus = 'successfully'
                state.findAllWithholdingsResponse = action.payload
            }))
            .addCase(findAllWithholdings.rejected, (state => {
                state.findAllWithholdingsStatus = 'failed'
            }))
            .addCase(findWithholdingById.pending, (state => {
                state.findWithholdingByIdStatus = 'loading'
            }))
            .addCase(findWithholdingById.fulfilled, ((state, action) => {
                state.findWithholdingByIdStatus = 'successfully'
                state.findWithholdingByIdResponse = action.payload
            }))
            .addCase(findWithholdingById.rejected, (state => {
                state.findWithholdingByIdStatus = 'failed'
            }))
    },
})

export const {
    setWithholdingsCreationRequest,
    setWithholdingsForAccounting,
    setDeleteWithholdingStatus,
    setCreateWithholdingStatus,
    setMarkAsWithholdingStatus,
    setDeleteMessage,
    setOpenConfirmWithholdingsDenyModal,
    setWithholdingsPeriod,
    setWittholdingsCompanyId,
    setWittholdingsYear,
    setFindByIdWithholdingStatus
} = withholdingsSlice.actions

export default withholdingsSlice.reducer