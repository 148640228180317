import { Tab } from "@mui/material";
import { compareAsc, format } from "date-fns";
import { ReactNode, useEffect, useState } from "react";
import { ButtonComponent } from "../../button";
import { Colors, IconStyle, Size, Variant } from "../../button/dto";
import { TabPanelComponent } from "../../horizontalTabPrimary/tabPanel";
import { HorizontalTabSecondaryComponent } from "../../horizontalTabSecondary";
import { keycloak } from "../../keycloak";
import { ProgressBarComponent } from "../../progressBar";
import { ProgressBarColor } from "../../progressBar/dto";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import colors from '../../utils/index.module.scss';
import { setOrdinaryControlsActivityGroups } from "../controls/slice";
import { ActivityGroupStatus, ActivityKind, ActivityStatus } from "./../dto";
import { completeGroup, setActivityCompleted, setActivityOpen, setCompleteGroupModal, setOpenOrdinaryAccountingModal, setOrdinaryAccountingActivityCategories, setOrdinaryAccountingActivityGroups, setOrdinaryAccountingCompany, setOrdinaryAccountingInitialTab, setOrdinaryAccountingPeriod, setOrdinaryAccountingYear } from "./../slice";
import { OrdinaryAccountingRow } from './ordinaryAccountingRow';

export function RecordingsPanel() {
    const companyState = useAppSelector(state => state.company)
    const dashboardState = useAppSelector(state => state.dashboard)

    const company = companyState.ordinaryAccountingUser
    const activityGroups = companyState.ordinaryAccountingActivityGroups.filter(group => group.activityKind === ActivityKind.Records)
    const initialTab = companyState.ordinaryAccountingInitialTab
    const categories = companyState.ordinaryAccountingActivityCategories

    const dispatch = useAppDispatch()

    const [count, setCount] = useState<number>(0)
    const [selectedActivities, setSelectedActivities] = useState<number[]>([])
    const [activitiesToOpen, setActivitiesToOpen] = useState<{ id: number, note: string | null }[]>([])
    const [activitiesToComplete, setActivitiesToComplete] = useState<{ activityGroup: number, activityId: number, note: string | null }[]>([])
    const [todoCount, setTodoCount] = useState<number>(0)

    const handleChangeActivity = (event: React.SyntheticEvent, newValue: number) => {
        dispatch(setOrdinaryAccountingInitialTab(newValue));
    };

    useEffect(() => {
        if (dashboardState.findActivitiesDashboardStatus === 'successfully' && companyState.initialTabAccountingActivities === 0 && companyState.openOrdinaryAccountingModal && dashboardState.findActivitiesDashboardResponse.length > 0) {
            const activities = dashboardState.findActivitiesDashboardResponse.find(company => company.id === companyState.ordinaryAccountingUser?.id)?.activityGroups.filter(group => group.period === companyState.ordinaryAccountingPeriod && group.activityKind === ActivityKind.Records)
            dispatch(setOrdinaryAccountingActivityGroups(activities))
        }
    }, [dashboardState.findActivitiesDashboardStatus, dashboardState.findActivitiesDashboardResponse, dispatch])

    useEffect(() => {
        if (
            companyState.setActivityOpenStatus === 'successfully' ||
            companyState.setActivityCompleteStatus === 'successfully' ||
            companyState.completeGroupStatus === 'successfully'
        ) {
            setActivitiesToComplete([])
            setActivitiesToOpen([])
        }
    }, [companyState.setActivityOpenStatus, companyState.setActivityCompleteStatus, companyState.completeGroupStatus])

    useEffect(() => {
        if (companyState.openOrdinaryAccountingModal) {
            let selectedIndexes: number[] = []
            setTodoCount(0)

            activityGroups.flatMap(group => group.activities).forEach(
                activity => {
                    if (activity.state === ActivityStatus.Completed) {
                        selectedIndexes.push(activity.id)
                    }
                }
            )
            setSelectedActivities([...selectedIndexes])
        } else {
            dispatch(setOrdinaryAccountingCompany(undefined))
            dispatch(setOrdinaryAccountingActivityCategories([]))
            dispatch(setOrdinaryAccountingActivityGroups([]))
            dispatch(setOrdinaryControlsActivityGroups([]))
            dispatch(setOrdinaryAccountingPeriod(0))
            dispatch(setOrdinaryAccountingYear(new Date().getFullYear()))
            dispatch(setOrdinaryAccountingInitialTab(0));
            setTodoCount(0)
        }
    }, [companyState.openOrdinaryAccountingModal])

    if (company === undefined) {
        return (
            <></>
        )
    }

    let groupTab: ReactNode[] = []
    let groupPanel: ReactNode[] = []

    if (
        companyState.openOrdinaryAccountingModal &&
        company !== undefined
    ) {
        categories.forEach(category => {
            const group = activityGroups.find(_group => _group.activityCategory.id === category.id)
            let selectedForPanel = 0
            group?.activities.forEach(activity => {
                selectedActivities.forEach(selected => {
                    if (selected === activity.id)
                        selectedForPanel++
                })
            })

            groupTab.push(<Tab label={category.name} value={category.id} key={category.id + '-tab'} />)
            groupPanel.push(
                <TabPanelComponent value={initialTab} index={category.id} key={category.id + '-panel'}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            padding: '24px 0px',
                            gap: '4px',
                            width: '100%',
                            background: colors.white,
                            maxHeight: '45vh',
                            overflow: 'auto'
                        }}
                    >
                        {
                            group === undefined || group.activities.length === 0 ? (
                                <div className="text text--md typography--regular typography-neutral-500">
                                    {"L'azienda non ha ancora nessuna attività registrata."}
                                </div>
                            ) :
                                <>
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <div style={{
                                            padding: '8px 20px', width: '75%'
                                        }}>
                                            <ProgressBarComponent value={(selectedForPanel / group.activities.length) * 100} color={ProgressBarColor.PRIMARY} />
                                        </div>
                                        <div style={{ width: '25%', verticalAlign: 'middle' }}>
                                            <span
                                                style={{ verticalAlign: 'middle' }}
                                                className={compareAsc(new Date(), new Date(group?.dueDate !== undefined ? group.dueDate : '')) === 1 ? 'text text--sm typography--medium typography-destructive--400' : 'text text--sm typography--medium typography-neutral--400'}
                                            >
                                                {'Scad: ' + format(new Date(group?.dueDate !== undefined ? group.dueDate : 50), 'dd/MM/yy')}
                                            </span>
                                        </div>
                                    </div>
                                    {
                                        group.activities.map(activity => (
                                            <OrdinaryAccountingRow
                                                hasDocument={activity.hasDocument}
                                                key={activity.id}
                                                disabled={!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('admin-accountant') && !keycloak.hasRealmRole('accountant')}
                                                checked={selectedActivities.includes(activity.id)}
                                                label={activity.activityType.name}
                                                count={count}
                                                setCount={(e) => setCount(e)}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    if (event.target.checked) {
                                                        if (activity.state === ActivityStatus.Pending) {
                                                            setActivitiesToComplete([...activitiesToComplete, { activityGroup: group.id, activityId: activity.id, note: null }])
                                                        } else {
                                                            setActivitiesToOpen([...activitiesToOpen.filter(_activity => _activity.toString() !== activity.id.toString())])
                                                        }
                                                        setSelectedActivities([...selectedActivities, activity.id])
                                                    }
                                                    else {
                                                        if (activity.state === ActivityStatus.Pending) {
                                                            setActivitiesToComplete([...activitiesToComplete.filter(_activity => _activity.activityId.toString() !== activity.id.toString())])
                                                        } else {
                                                            setActivitiesToOpen([...activitiesToOpen, { id: activity.id, note: null }])
                                                        }
                                                        setSelectedActivities([...selectedActivities.filter(_activity => _activity.toString() !== activity?.id.toString())])
                                                    }
                                                }}
                                            />
                                        ))
                                    }
                                </>
                        }
                    </div>
                </TabPanelComponent>
            )
        })
    }

    let todoListCheck: ReactNode[] = []
    if (company.todos !== null)
        company.todos.filter(todo => todo.split('::')[1].toString() === initialTab.toString()).forEach(todo => {
            todoListCheck.push(
                <OrdinaryAccountingRow
                    hasDocument={false}
                    key={todo}
                    label={todo.split('::')[0]}
                    count={0}
                    setCount={(e) => setCount(e)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (event.target.checked) {
                            setTodoCount(todoCount + 1)
                        }
                        else {
                            setTodoCount(todoCount - 1)
                        }
                    }}
                />
            )
        })

    return (
        <div>
            <HorizontalTabSecondaryComponent
                value={initialTab}
                handleChange={handleChangeActivity}
                negativeMargin={20}
            >
                {groupTab}
            </HorizontalTabSecondaryComponent>
            {groupPanel}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '12px',
                    width: '100%',
                    height: '80px',
                    borderTop: '1px solid ' + colors.neutral80
                }}
            >
                {
                    (
                        keycloak.hasRealmRole('admin') ||
                        keycloak.hasRealmRole('admin-accountant') ||
                        keycloak.hasRealmRole('accountant')
                    ) &&
                    <div style={{ margin: 0, padding: 0, gap: '12px', width: '100%' }} className="row">
                        <div className="col" style={{ margin: 0, padding: 0 }}>
                            <ButtonComponent
                                key={'modal-button-1'}
                                label={"Annulla"}
                                onClick={() => dispatch(setOpenOrdinaryAccountingModal(false))}
                                color={Colors.NEUTRAL}
                                variant={Variant.OUTLINE}
                                size={Size.FIT}
                                iconStyle={IconStyle.OFF}
                            />
                        </div>
                        <div className="col" style={{ margin: 0, padding: 0 }}>
                            <ButtonComponent
                                disabled={
                                    (activityGroups.find(group => group.activityCategory.id === initialTab)?.state === ActivityGroupStatus.Completed && activitiesToOpen.length === 0) ||
                                    (activityGroups.find(group => group.activityCategory.id === initialTab)?.activities.length === 0)
                                }
                                key={'modal-button-2'}
                                label={(activityGroups.find(group => group.activityCategory.id === initialTab)?.activities.every(activity => activity.state === ActivityStatus.Completed) && activitiesToComplete.length === 0 && activitiesToOpen.length === 0) ? ("Chiudi " + activityGroups.find(group => group.activityCategory.id === initialTab)?.activityCategory.name) : "Aggiorna"}
                                onClick={() => {
                                    if ((activityGroups.find(group => group.activityCategory.id === initialTab)?.activities.every(activity => activity.state === ActivityStatus.Completed) && activitiesToComplete.length === 0 && activitiesToOpen.length === 0)) {
                                        if (todoListCheck.length === 0) {
                                            if (activitiesToComplete.filter(activity => activity.activityGroup === activityGroups.find(group => group.activityCategory.id === initialTab)?.id).length > 0) {
                                                dispatch(setActivityCompleted({ ids: activitiesToComplete.map(activity => activity.activityId), notes: activitiesToComplete.map(activity => activity.note) }))
                                                dispatch(setOpenOrdinaryAccountingModal(true))
                                            } else {
                                                dispatch(completeGroup(activityGroups.find(group => group.activityCategory.id === initialTab)?.id.toString() || ''))
                                                dispatch(setOpenOrdinaryAccountingModal(false))
                                            }
                                        } else {
                                            if (activitiesToComplete.filter(activity => activity.activityGroup === activityGroups.find(group => group.activityCategory.id === initialTab)?.id).length > 0) {
                                                dispatch(setOpenOrdinaryAccountingModal(true))
                                                dispatch(setActivityCompleted({ ids: activitiesToComplete.map(activity => activity.activityId), notes: activitiesToComplete.map(activity => activity.note) }))
                                            } else {
                                                dispatch(setOpenOrdinaryAccountingModal(true))
                                                dispatch(setCompleteGroupModal(true))
                                            }
                                        }
                                    } else {
                                        if (activitiesToOpen.length > 0) {
                                            setActivitiesToComplete([])
                                            setActivitiesToOpen([])
                                            dispatch(setActivityOpen({ ids: activitiesToOpen.map(activity => activity.id), notes: activitiesToOpen.map(activity => activity.note) }))
                                            dispatch(setOpenOrdinaryAccountingModal(false))
                                        }
                                        if (activitiesToComplete.filter(activity => activity.activityGroup === activityGroups.find(group => group.activityCategory.id === initialTab)?.id).length > 0) {
                                            setActivitiesToComplete([])
                                            setActivitiesToOpen([])
                                            dispatch(setActivityCompleted({ ids: activitiesToComplete.map(activity => activity.activityId), notes: activitiesToComplete.map(activity => activity.note) }))
                                            dispatch(setOpenOrdinaryAccountingModal(false))
                                        }
                                    }
                                }}
                                color={Colors.PRIMARY}
                                variant={Variant.SOLID}
                                size={Size.FIT}
                                iconStyle={IconStyle.OFF}
                            />
                        </div>
                    </div>
                }
            </div>
        </div>

    )
}