import { ReactNode } from 'react'
import colors from '../utils/index.module.scss'
import { ProgressStepColor, ProgressStepSize } from "./dto"

interface Props {
    size: ProgressStepSize
    color: ProgressStepColor
    steps: number
    step: number
    bulletDisabled: (step: number) => boolean
    onClick: (step: number) => void
}

export function ProgressStepComponent(props: Props) {
    const { size } = props
    const { color } = props
    const { steps } = props
    const { step } = props
    const { bulletDisabled } = props
    const { onClick } = props

    const progressStepSizeMap = new Map<ProgressStepSize, { diameter: string, font: string }>([
        [ProgressStepSize.SM, { diameter: '20px', font: 'text--sm' }],
        [ProgressStepSize.MD, { diameter: '24px', font: 'text--md' }],
        [ProgressStepSize.LG, { diameter: '28px', font: 'text--lg' }]
    ])

    const progressStepColorMap = new Map<ProgressStepColor, { active: string, default: string }>([
        [ProgressStepColor.PRIMARY, { active: colors.primary500, default: colors.primary200 }],
        [ProgressStepColor.NEUTRAL, { active: colors.neutral400, default: colors.neutral100 }],
        [ProgressStepColor.SUCCESS, { active: colors.success500, default: colors.success200 }]
    ])

    const bullet = (active: boolean, _step: number, disabled: (step: number) => boolean) => {
        return (
            <div
                onClick={() => !disabled(_step) && onClick((_step - 1))}
                style={{
                    width: progressStepSizeMap.get(size)?.diameter,
                    height: progressStepSizeMap.get(size)?.diameter,
                    borderRadius: '50%',
                    backgroundColor: (active ? progressStepColorMap.get(color)?.active : progressStepColorMap.get(color)?.default),
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer'
                }}>
                <span className={progressStepSizeMap.get(size)?.font + ' text typography--white typography--bold'}>
                    {_step}
                </span>
            </div>
        )
    }

    const connector = (active: boolean) => {
        return (
            <div
                style={{
                    transition: 'all ease-out 1s',
                    width: '100%',
                    height: 2,
                    backgroundColor: (active ? progressStepColorMap.get(color)?.active : progressStepColorMap.get(color)?.default),
                }}
            />
        )
    }

    let progressStep: ReactNode[] = []

    for (let i = 1; i <= steps; i++) {
        progressStep.push(
            <div style={{ transition: 'all ease-in-out 1s' }} key={i} className={'m-0 p-0 ' + (i === steps ? 'col-auto' : 'col')}>
                <div className='row m-0 p-0 align-items-center'>
                    <div className='col-auto m-0 p-0'>
                        {bullet(i <= (step + 1), i, bulletDisabled)}
                    </div>
                    {
                        i !== steps && (
                            <div className='col m-0 p-0'>
                                {connector(i <= step)}
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }

    return (
        <div className='row m-0 p-0' style={{ transition: 'all ease-in-out 1s' }}>
            {progressStep}
        </div>
    )
}