import axios from "axios";
import { keycloak } from "../keycloak";
import { FindAllOperatorFiltersDTO, FindAllOperators, OperatorResponseDTO } from "./dto";
import { OperatorService } from "./service";

export class OperatorServiceImpl implements OperatorService {

    public findAllOperators(filters: FindAllOperatorFiltersDTO): Promise<FindAllOperators> {
        let url: string = "/api/operators?" +
            "page=" + filters.page +
            "&itemsPerPage=" + filters.itemsPerPage +
            (filters.operator !== '' ? '&operator=' + filters.operator : '') +
            (filters.financialAdvisor !== '' ? '&financialAdvisor=' + filters.financialAdvisor : '')
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public createOperator(request: FormData): Promise<void> {
        let url: string = "/api/operators"
        return axios({
            url: url,
            method: "POST",
            data: request,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findOperator(id: string): Promise<OperatorResponseDTO> {
        let url: string = "/api/operators/" + (window.location.pathname.split('edit-operator/')[1] === undefined ? id : window.location.pathname.split('edit-operator/')[1])
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public editOperator(id: string, data: FormData): Promise<void> {
        let url: string = "/api/operators/" + (window.location.pathname.split('edit-operator/')[1] === undefined ? id : window.location.pathname.split('edit-operator/')[1])
        return axios({
            url: url,
            method: "PUT",
            data: data,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public deleteOperator(id: string): Promise<void> {
        let url: string = "/api/operators/" + id
        return axios({
            url: url,
            method: "DELETE",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then(() => undefined)
    }
}
