import { ReactNode, useEffect } from "react";
import { ButtonComponent } from "../../button";
import { Colors, IconStyle, Size, Variant } from "../../button/dto";
import { HeadingComponent } from "../../heading";
import { HeadingSize } from "../../heading/dto";
import { PlusIcon } from "../../icons/plus";
import { TrashIcon } from "../../icons/trash";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { SpinnerComponent } from "../../spinner";
import { TextInput } from "../../textInput";
import colors from '../../utils/index.module.scss';
import { setFeesRegisters, setFeesRegistersNumber } from "../slice";


interface Props {
    disabled: boolean
}

export function FeesRegister(props: Props) {
    const { disabled } = props
    const companyState = useAppSelector(state => state.company)
    const dispatch = useAppDispatch()

    const feesRegister = companyState.findCompanyResponse?.feesRegister
    const feesRegisterRequest = companyState.companyRegistryDTO.feesRegister

    useEffect(() => {
        if (companyState.findCompanyStatus === 'successfully' && companyState.findCompanyResponse !== undefined) {
            if (feesRegister !== undefined && feesRegister !== null && feesRegister.length > 0) {
                dispatch(setFeesRegisters(feesRegister))
                dispatch(setFeesRegistersNumber(feesRegister.length))
            } else {
                dispatch(setFeesRegisters(['']))
                dispatch(setFeesRegistersNumber(0))
            }
        }
    }, [feesRegister, companyState.findCompanyStatus, companyState.findCompanyResponse])

    let registers: ReactNode[] = []
    if (feesRegisterRequest !== undefined && feesRegisterRequest !== null && feesRegisterRequest.length > 0) {
        feesRegisterRequest.forEach((fee, index) => {
            registers.push(
                <div
                    key={'fee-register-' + index}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 24
                    }}
                >
                    <TextInput
                        id={"fee-register-input-" + index}
                        type={"text"}
                        disabled={disabled}
                        placeholder={"Inserire nome registro"}
                        value={feesRegisterRequest[index]}
                        onChange={(e) => {
                            const newRegister = [...feesRegisterRequest]
                            newRegister[index] = e.target.value
                            dispatch(setFeesRegisters(newRegister))
                        }}
                    />
                    <ButtonComponent
                        label={""}
                        disabled={disabled}
                        onClick={() => {
                            let newFees = [...feesRegisterRequest]
                            newFees = newFees.slice(0, index).concat(newFees.slice(index + 1));
                            dispatch(setFeesRegisters(newFees.length === 0 ? [""] : newFees))
                            dispatch(setFeesRegistersNumber(newFees.length))
                        }}
                        color={Colors.PRIMARY}
                        variant={Variant.LINK}
                        size={Size.SM}
                        iconStyle={IconStyle.ONLY}
                        icon={<TrashIcon colorBase={colors.primary500} />}
                    />
                </div>
            )
        })
    }

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <HeadingComponent size={HeadingSize.SM} label={"Registro Corrispettivi"} buttons={[
                <ButtonComponent
                    disabled={feesRegisterRequest.length >= 5 || disabled}
                    label={"Aggiungi registro"}
                    icon={<PlusIcon colorBase={""} />}
                    onClick={() => {
                        const newFees = [...feesRegisterRequest]
                        newFees.push('')
                        dispatch(setFeesRegisters(newFees))
                        dispatch(setFeesRegistersNumber(newFees.length))
                    }}
                    color={Colors.PRIMARY}
                    variant={Variant.OUTLINE}
                    size={Size.SM}
                    iconStyle={IconStyle.LEFT}
                />
            ]}
            />
            {registers}
        </div>
    )
}