import { useEffect } from "react";
import { OrdinaryAccountingModal } from "../company/accounting/ordinaryAccountingModal";
import { findAllCompanies, getCompanyUnseenInfoMessages, resetAtecosByIdResponse, resetAtecosByIdStatus, resetCompanyRegistryDTO, resetFindCompanyResponse, setActivityCompleteStatus, setActivityOpenStatus, setCompleteGroupStatus, setFindFundingsResponse, setFindLeasingsResponse, setFindLoansResponse, setFundings, setLeasings, setLoans } from "../company/slice";
import { EmptyList } from "../emptyList/emptyList";
import { ErrorPopup } from "../errorPopup";
import { HeadingSize, HeadingVerticalAlign } from "../heading/dto";
import { keycloak } from "../keycloak";
import { LayoutComponent } from "../layout";
import { PaginationComponent } from "../pagination";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { MenuItems } from "../sidebar/dto";
import { StatusIndicatorComponent } from "../statusIndicator";
import { StatusIndicatorColor, StatusIndicatorIcon, StatusIndicatorSize } from "../statusIndicator/dto";
import { SuccessPopup } from "../successPopup";
import { DashboardCountersComponent } from "./counters";
import { DashboardFiltersComponent } from "./filters";
import { DashboardListComponent } from "./list";
import { setPageFilter } from "./slice";

export function DashboardView() {
    const companyState = useAppSelector(state => state.company)
    const dashboardState = useAppSelector(state => state.dashboard)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(resetCompanyRegistryDTO())
        dispatch(setLoans([]))
        dispatch(setFundings([]))
        dispatch(setLeasings([]))
        dispatch(setFindLoansResponse([]))
        dispatch(setFindFundingsResponse([]))
        dispatch(setFindLeasingsResponse([]))
        dispatch(resetFindCompanyResponse())
        dispatch(resetAtecosByIdResponse())
        dispatch(resetAtecosByIdStatus())
    }, [])

    useEffect(() => {
        dispatch(findAllCompanies(dashboardState.companyFilters))
    }, [dashboardState.companyFilters.page])

    useEffect(() => {
        if (companyState.findAllCompaniesStatus === 'successfully' && companyState.findAllCompaniesResponse !== undefined) {
            if (companyState.findAllCompaniesResponse.page >= companyState.findAllCompaniesResponse.totalPage) {
                dispatch(setPageFilter(0))
                dispatch(findAllCompanies({ ...dashboardState.companyFilters, page: 0 }))
            }
            dispatch(getCompanyUnseenInfoMessages(companyState.findAllCompaniesResponse.data.map(data => data.id.toString())))
        }
    }, [companyState.findAllCompaniesStatus])

    return (
        <LayoutComponent
            menuItem={MenuItems.DASHBOARD}
            headingLabel={"Dashboard"}
            headingVerticalAlign={HeadingVerticalAlign.CENTER}
            headingButtons={[
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', gap: 16 }}>
                    <StatusIndicatorComponent label="Completata" icon={StatusIndicatorIcon.LEFT} color={StatusIndicatorColor.SUCCESS} size={StatusIndicatorSize.MD} />
                    <StatusIndicatorComponent label="In corso" icon={StatusIndicatorIcon.LEFT} color={StatusIndicatorColor.ACTIVE} size={StatusIndicatorSize.MD} />
                    {(keycloak.hasRealmRole('financial-advisor') || keycloak.hasRealmRole('operator')) && <StatusIndicatorComponent pulsing label="Da leggere" icon={StatusIndicatorIcon.LEFT} color={StatusIndicatorColor.ACTIVE} size={StatusIndicatorSize.MD} />}
                    <StatusIndicatorComponent label="Scaduta" icon={StatusIndicatorIcon.LEFT} color={StatusIndicatorColor.ERROR} size={StatusIndicatorSize.MD} />
                    <StatusIndicatorComponent label="Non avviata" icon={StatusIndicatorIcon.LEFT} color={StatusIndicatorColor.INACTIVE} size={StatusIndicatorSize.MD} />
                </div>
            ]}
            breadcrumbItems={['Dashboard']}
            headingSize={HeadingSize.LG}
        >
            <SuccessPopup
                active={companyState.setActivityCompleteStatus === 'successfully'}
                close={() => dispatch(setActivityCompleteStatus('idle'))}
                message="Attività chiusa"
            />
            <ErrorPopup
                active={companyState.setActivityCompleteStatus === 'failed'}
                close={() => dispatch(setActivityCompleteStatus('idle'))}
                message="Errore durante la chiusura dell'attività"
            />
            <SuccessPopup
                active={companyState.setActivityOpenStatus === 'successfully'}
                close={() => dispatch(setActivityOpenStatus('idle'))}
                message="Attività aperta"
            />
            <ErrorPopup
                active={companyState.setActivityOpenStatus === 'failed'}
                close={() => dispatch(setActivityOpenStatus('idle'))}
                message="Errore durante l'apertura dell'attività"
            />
            <SuccessPopup
                active={companyState.completeGroupStatus === 'successfully'}
                close={() => dispatch(setCompleteGroupStatus('idle'))}
                message="Gruppo chiuso"
            />
            <ErrorPopup
                active={companyState.completeGroupStatus === 'failed'}
                close={() => dispatch(setCompleteGroupStatus('idle'))}
                message="Errore durante la chiusura del gruppo"
            />
            <DashboardCountersComponent />
            <DashboardFiltersComponent />
            <DashboardListComponent />
            {
                companyState.findAllCompaniesResponse !== undefined &&
                companyState.findAllCompaniesResponse.total > 0 &&
                <PaginationComponent
                    page={companyState.findAllCompaniesResponse !== undefined ? companyState.findAllCompaniesResponse.page : 0}
                    count={companyState.findAllCompaniesResponse !== undefined ? companyState.findAllCompaniesResponse.totalPage : 0}
                    onChange={(page) => dispatch(setPageFilter(page))}
                />
            }
            {
                companyState.findAllCompaniesResponse !== undefined &&
                companyState.findAllCompaniesResponse.total === 0 &&
                <EmptyList />
            }
            <OrdinaryAccountingModal />
        </LayoutComponent>
    )
}