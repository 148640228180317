import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { FindAllAccountantFiltersDTO, FindAllAccountants } from "../accountant/dto"
import { NewAccountantService } from "../accountant/service"
import { ActivityGroupResponse, ActivityResponse, CompanyStatusEnum, FindAllCompaniesFilters } from "../company/dto"
import { NewCompanyService } from "../company/service"
import { FindAllFinancialAdvisorFiltersDTO, FindAllFinancialAdvisors } from "../financialAdvisor/dto"
import { NewFinancialAdvisorService } from "../financialAdvisor/service"
import { PromiseStatuses } from "../utils"
import { CompanyCountDTO, DashboardActivitiesResponse, FiltersCounterDTO } from "./dto"
import { NewDashboardService } from "./service"

interface DashboardState {
    companyFilters: FindAllCompaniesFilters
    findActivityGroupByCompanyIdsResponse?: ActivityGroupResponse[]
    findActivityGroupByCompanyIdsStatus: PromiseStatuses
    findActivityByCompanyIdsResponse?: ActivityResponse[]
    findActivityByCompanyIdsStatus: PromiseStatuses
    findAllAccountantsFilterResponse?: FindAllAccountants
    findAllAccountantsFilterStatus: PromiseStatuses
    findAllFinancialAdvisorsFilterResponse?: FindAllFinancialAdvisors
    findAllFinancialAdvisorsFilterStatus: PromiseStatuses
    findActivitiesDashboardStatus: PromiseStatuses
    findActivitiesDashboardResponse: DashboardActivitiesResponse[]
    getAllCountResponse?: CompanyCountDTO
    getAllCountStatus: PromiseStatuses
    filtersCounter: FiltersCounterDTO
}

const initialState: DashboardState = {
    companyFilters: {
        accountant: '',
        financialAdvisor: '',
        operators: '',
        businessName: '',
        page: 0,
        itemsPerPage: 25,
        status: [CompanyStatusEnum.Active]
    },
    findActivityGroupByCompanyIdsStatus: 'idle',
    findActivityByCompanyIdsStatus: 'idle',
    findAllAccountantsFilterStatus: 'idle',
    findAllFinancialAdvisorsFilterStatus: 'idle',
    findActivitiesDashboardStatus: 'idle',
    findActivitiesDashboardResponse: [],
    getAllCountStatus: "idle",
    filtersCounter: {
        status: CompanyStatusEnum.Active
    }
}

export const findActivityGroupByCompanyIds = createAsyncThunk(
    'company/findActivityGroupByCompanyIds',
    async (request: { companyId: number, year: number }[]): Promise<ActivityGroupResponse[]> => {
        let promises: Promise<ActivityGroupResponse>[] = []
        promises = request.map(async (company) => {
            const companyService = NewCompanyService()

            return companyService.findActivityGroup(company.year, company.companyId.toString())
        })
        return Promise.all(promises)
    }
)

export const findActivityByCompanyIds = createAsyncThunk(
    'company/findActivityByCompanyIds',
    async (request: { companyId: number, year: number }[]): Promise<ActivityResponse[]> => {
        let promises: Promise<ActivityResponse>[] = []
        promises = request.map(async (company) => {
            const companyService = NewCompanyService()
            return companyService.findActivityByCompanyId(company.companyId, company.year)
        })
        return Promise.all(promises)
    }
)

export const findAllAccountantsFilter = createAsyncThunk(
    'company/findAllAccountantsFilter',
    async (request: FindAllAccountantFiltersDTO): Promise<FindAllAccountants> => {
        const accountantService = NewAccountantService()
        return accountantService.findAllAccountant(request)
    }
)

export const findAllFinancialAdvisorsFilter = createAsyncThunk(
    'company/findAllFinancialAdvisorsFilter',
    async (request: FindAllFinancialAdvisorFiltersDTO): Promise<FindAllFinancialAdvisors> => {
        const financialAdvisorsService = NewFinancialAdvisorService()
        return financialAdvisorsService.findAllFinancialAdvisor(request)
    }
)

export const findActivitiesDashboard = createAsyncThunk(
    'company/findActivitiesDashboard',
    async (request: { companyIds: number[], year: number }): Promise<DashboardActivitiesResponse[]> => {
        const dashboardService = NewDashboardService()
        return dashboardService.activityDashboard(request.companyIds, request.year)
    }
)

export const getAllCount = createAsyncThunk(
    'company/getAllCount',
    async (request: FiltersCounterDTO): Promise<CompanyCountDTO> => {
        const dashboardService = NewDashboardService()
        return dashboardService.getAllCount(request)
    }
)

const dashboardSlice = createSlice({
    name: 'dashboard/slice',
    initialState,
    reducers: {
        setStatusFilter: (state, action) => {
            state.companyFilters.status = action.payload
        },
        setPageFilter: (state, action) => {
            state.companyFilters.page = action.payload
        },
        setAccountantFilter: (state, action) => {
            state.companyFilters.accountant = action.payload
        },
        setFinancialAdvisorFilter: (state, action) => {
            state.companyFilters.financialAdvisor = action.payload
        },
        setBusinessNameFilter: (state, action) => {
            state.companyFilters.businessName = action.payload
        },
        setOperatorsFilter: (state, action) => {
            state.companyFilters.operators = action.payload
        },
        setFindActivitiesDashboardStatus: (state, action) => {
            state.findActivitiesDashboardStatus = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(findActivityGroupByCompanyIds.pending, (state) => {
                state.findActivityGroupByCompanyIdsStatus = 'loading'
            })
            .addCase(findActivityGroupByCompanyIds.fulfilled, (state, action) => {
                state.findActivityGroupByCompanyIdsStatus = 'successfully'
                state.findActivityGroupByCompanyIdsResponse = action.payload
            })
            .addCase(findActivityGroupByCompanyIds.rejected, (state) => {
                state.findActivityGroupByCompanyIdsStatus = 'failed'
            })
            .addCase(findActivityByCompanyIds.pending, (state) => {
                state.findActivityByCompanyIdsStatus = 'loading'
            })
            .addCase(findActivityByCompanyIds.fulfilled, (state, action) => {
                state.findActivityByCompanyIdsStatus = 'successfully'
                state.findActivityByCompanyIdsResponse = action.payload
            })
            .addCase(findActivityByCompanyIds.rejected, (state) => {
                state.findActivityByCompanyIdsStatus = 'failed'
            })
            .addCase(findAllAccountantsFilter.pending, (state) => {
                state.findAllAccountantsFilterStatus = 'loading'
            })
            .addCase(findAllAccountantsFilter.fulfilled, (state, action) => {
                state.findAllAccountantsFilterStatus = 'successfully'
                state.findAllAccountantsFilterResponse = action.payload
            })
            .addCase(findAllAccountantsFilter.rejected, (state) => {
                state.findAllAccountantsFilterStatus = 'failed'
            })
            .addCase(findAllFinancialAdvisorsFilter.pending, (state) => {
                state.findAllFinancialAdvisorsFilterStatus = 'loading'
            })
            .addCase(findAllFinancialAdvisorsFilter.fulfilled, (state, action) => {
                state.findAllFinancialAdvisorsFilterStatus = 'successfully'
                state.findAllFinancialAdvisorsFilterResponse = action.payload
            })
            .addCase(findAllFinancialAdvisorsFilter.rejected, (state) => {
                state.findAllFinancialAdvisorsFilterStatus = 'failed'
            })
            .addCase(findActivitiesDashboard.pending, (state) => {
                state.findActivitiesDashboardStatus = 'loading'
            })
            .addCase(findActivitiesDashboard.fulfilled, (state, action) => {
                state.findActivitiesDashboardResponse = action.payload
                state.findActivitiesDashboardStatus = 'successfully'
            })
            .addCase(findActivitiesDashboard.rejected, (state) => {
                state.findActivitiesDashboardStatus = 'failed'
            })
            .addCase(getAllCount.pending, (state) => {
                state.getAllCountStatus = 'loading'
            })
            .addCase(getAllCount.fulfilled, (state, action) => {
                state.getAllCountResponse = action.payload
                state.getAllCountStatus = 'successfully'
            })
            .addCase(getAllCount.rejected, (state) => {
                state.getAllCountStatus = 'failed'
            })
    },
})

export const {
    setAccountantFilter,
    setFinancialAdvisorFilter,
    setBusinessNameFilter,
    setStatusFilter,
    setOperatorsFilter,
    setPageFilter,
    setFindActivitiesDashboardStatus
} = dashboardSlice.actions

export default dashboardSlice.reducer