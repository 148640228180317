export interface CompanyCreationDTO {
    accountingScheme: AccountingSchemeEnum
    periodicity: PeriodicityEnum
    annualForeignInvoices: number
    atecoCodesNumber: number
    banksNumber: number
    journal: number | null
    specialVatSchemes: boolean
    specialVatScheme: SpecialVatSchemeEnum | null
    financialAdvisorId: number
    professionistWithFund: boolean
    activityCategoryIds: number[],
    activityNotifications: boolean
    operationsNotifications: boolean
}

export enum AccountingSchemeEnum {
    Ordinary = 'Ordinary',
    Simplified = 'Simplified'
}

export enum PeriodicityEnum {
    Monthly = 'Monthly',
    Quarterly = 'Quarterly'
}

export enum JournalEnum {
    JOURNAL_0 = 'JOURNAL_0',
    JOURNAL_1 = 'JOURNAL_1',
    JOURNAL_2 = 'JOURNAL_2',
    JOURNAL_3 = 'JOURNAL_3',
    JOURNAL_4 = 'JOURNAL_4',
    JOURNAL_5 = 'JOURNAL_5',
    JOURNAL_6 = 'JOURNAL_6',
}

export enum SpecialVatSchemeEnum {
    SCHEME_1 = 'SCHEME_1',
    SCHEME_2 = 'SCHEME_2',
    SCHEME_3 = 'SCHEME_3',
    SCHEME_4 = 'SCHEME_4',
    SCHEME_5 = 'SCHEME_5',
    SCHEME_6 = 'SCHEME_6',
    SCHEME_7 = 'SCHEME_7',
    SCHEME_8 = 'SCHEME_8',
    SCHEME_9 = 'SCHEME_9',
    SCHEME_10 = 'SCHEME_10',
    SCHEME_11 = 'SCHEME_11',
    SCHEME_12 = 'SCHEME_12',
}

export enum BankEnum {
    BANK_0 = 'BANK_0',
    BANK_1 = 'BANK_1',
    BANK_2 = 'BANK_2',
}

export enum AtecosEnum {
    ATECOS_0 = 'ATECOS_0',
    ATECOS_1 = 'ATECOS_1',
    ATECOS_2 = 'ATECOS_2',
}

export enum AnnualForeignInvoicesEnum {
    FOREIGN_EMPTY = 'FOREIGN_EMPTY',
    FOREIGN_0 = 'FOREIGN_0',
    FOREIGN_1 = 'FOREIGN_1',
    FOREIGN_2 = 'FOREIGN_2'
}

export const journalToNumberMap = new Map<JournalEnum, number>([
    [JournalEnum.JOURNAL_0, 2500],
    [JournalEnum.JOURNAL_1, 7500],
    [JournalEnum.JOURNAL_2, 12500],
    [JournalEnum.JOURNAL_3, 17500],
    [JournalEnum.JOURNAL_4, 20500],
    [JournalEnum.JOURNAL_5, 30500],
    [JournalEnum.JOURNAL_6, 50500],
])

export const numberToJournalMap = (journal: number | null) => {
    if (journal === null) {
        return JournalEnum.JOURNAL_0
    } else if (journal < 5000) {
        return JournalEnum.JOURNAL_0
    } else if (journal >= 5000 && journal < 10000) {
        return JournalEnum.JOURNAL_1
    } else if (journal >= 10000 && journal < 15000) {
        return JournalEnum.JOURNAL_2
    } else if (journal >= 15000 && journal < 20000) {
        return JournalEnum.JOURNAL_3
    } else if (journal >= 20000 && journal < 30000) {
        return JournalEnum.JOURNAL_4
    } else if (journal >= 30000 && journal < 50000) {
        return JournalEnum.JOURNAL_5
    } else if (journal >= 50000) {
        return JournalEnum.JOURNAL_6
    }
    return JournalEnum.JOURNAL_0
}

export const bankToNumberMap = new Map<BankEnum, number>([
    [BankEnum.BANK_0, 2],
    [BankEnum.BANK_1, 5],
    [BankEnum.BANK_2, 6]
])

export const numberToBankMap = (bank: number | null) => {
    if (bank === null) {
        return BankEnum.BANK_0
    } else if (bank <= 2) {
        return BankEnum.BANK_0
    } else if (bank > 2 && bank <= 5) {
        return BankEnum.BANK_1
    } else if (bank > 5) {
        return BankEnum.BANK_2
    }
    return BankEnum.BANK_0
}

export const atecoToNumberMap = new Map<AtecosEnum, number>([
    [AtecosEnum.ATECOS_0, 2],
    [AtecosEnum.ATECOS_1, 4],
    [AtecosEnum.ATECOS_2, 5]
])

export const numberToAtecoMap = (ateco: number) => {
    if (ateco === 2) {
        return AtecosEnum.ATECOS_0
    } else if (ateco === 4) {
        return AtecosEnum.ATECOS_1
    } else if (ateco >= 5) {
        return AtecosEnum.ATECOS_2
    }
    return AtecosEnum.ATECOS_0
}

export const foreignToNumberMap = new Map<AnnualForeignInvoicesEnum, number>([
    [AnnualForeignInvoicesEnum.FOREIGN_EMPTY, 0],
    [AnnualForeignInvoicesEnum.FOREIGN_0, 5],
    [AnnualForeignInvoicesEnum.FOREIGN_1, 50],
    [AnnualForeignInvoicesEnum.FOREIGN_2, 101]
])

export const numberToForeignMap = (foreign: number) => {
    if (foreign === 0) {
        return AnnualForeignInvoicesEnum.FOREIGN_EMPTY
    } else if (foreign < 10) {
        return AnnualForeignInvoicesEnum.FOREIGN_0
    } else if (foreign >= 10 && foreign <= 100) {
        return AnnualForeignInvoicesEnum.FOREIGN_1
    } else if (foreign > 100) {
        return AnnualForeignInvoicesEnum.FOREIGN_2
    }
    return AnnualForeignInvoicesEnum.FOREIGN_EMPTY
}