import { Tab } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { ActivityKind } from "../activity/dto";
import { CheckboxComponent } from "../checkbox";
import { CheckboxSize, CheckboxStyle } from "../checkbox/dto";
import { TabPanelComponent } from "../horizontalTabPrimary/tabPanel";
import { HorizontalTabSecondaryComponent } from "../horizontalTabSecondary";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SpinnerComponent } from "../spinner";
import colors from '../utils/index.module.scss';
import { activityCanBeDeleted, findCompanyActivityCategories, findCompanyActivityTypes } from "./slice";

interface Props {
    selectedActivities: number[]
    setSelectedActivities: React.Dispatch<React.SetStateAction<number[]>>
}
export function ActivityManagementModal(props: Props) {
    const { selectedActivities } = props
    const { setSelectedActivities } = props

    const [activityKindValue, setActivityKindValue] = useState<number>(0)
    const [activityValue, setActivityValue] = useState<number>(0)


    const companyState = useAppSelector(state => state.company)

    const dispatch = useAppDispatch()

    const handleChangeActivity = (event: React.SyntheticEvent, newValue: number) => {
        setActivityValue(newValue);
    };

    const handleChangeActivityKind = (event: React.SyntheticEvent, newValue: number) => {
        setActivityKindValue(newValue);
    };

    useEffect(() => {
        if (
            companyState.findCompanyStatus === 'successfully' &&
            companyState.findCompanyResponse !== undefined &&
            companyState.findCompanyResponse.activityCategoryIds !== null &&
            companyState.findCompanyResponse.activityCategoryIds.length > 0 &&
            companyState.settingsTabValue === 3
        ) {
            dispatch(findCompanyActivityCategories(companyState.findCompanyResponse.activityCategoryIds))
            dispatch(activityCanBeDeleted(companyState.findCompanyResponse.id.toString()))
            setSelectedActivities([...companyState.findCompanyResponse.activityTypeIds])
        }
    }, [companyState.findCompanyResponse, companyState.findCompanyStatus, companyState.settingsTabValue])

    useEffect(() => {
        if (
            companyState.findCompanyCategoryStatus === 'successfully' &&
            companyState.findCompanyCategoryResponse !== undefined &&
            companyState.findCompanyCategoryResponse[0] !== undefined &&
            companyState.settingsTabValue === 3
        ) {
            setActivityValue(companyState.findCompanyCategoryResponse[0].id)
            dispatch(findCompanyActivityTypes(companyState.findCompanyCategoryResponse.map(category => category.id)))
        }
    }, [companyState.findCompanyCategoryResponse, companyState.findCompanyCategoryStatus, companyState.settingsTabValue])

    if (
        companyState.findCompanyStatus === 'loading' ||
        companyState.findCompanyCategoryStatus === 'loading' ||
        companyState.findCompanyTypesStatus === 'loading' ||
        companyState.activityCanBeDeletedStatus === 'loading' ||
        companyState.editCompanyActivityTypesStatus === 'loading'
    ) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1, height: '50vh' }}>
                <SpinnerComponent size={"small"} />
            </div>
        )
    }

    let newCategoryTab: ReactNode[] = []
    let tabsPanelsControls: ReactNode[] = []

    if (
        companyState.findCompanyResponse !== undefined &&
        companyState.findCompanyCategoryStatus === 'successfully' &&
        companyState.findCompanyCategoryResponse !== undefined &&
        companyState.findCompanyCategoryResponse[0] !== undefined &&
        companyState.settingsTabValue === 3 &&
        companyState.findCompanyTypesStatus === 'successfully' &&
        companyState.findCompanyTypesResponse !== undefined &&
        companyState.activityCanBeDeletedStatus === 'successfully'
    ) {
        let activityRow: { rows: ReactNode[], categoryId: number }[] = []
        if (
            companyState.findCompanyResponse.activityCategoryIds !== null &&
            companyState.findCompanyResponse.activityCategoryIds.length > 0
        ) {
            companyState.findCompanyTypesResponse.forEach((type) => {
                if (
                    type.data !== undefined &&
                    type.data !== null &&
                    type.data.length > 0
                ) {
                    let rows: ReactNode[] = [];
                    type.data.forEach((_type, _index) => {
                        const activityCanBeDeleted = companyState.activityCanBeDeletedResponse.find(activity => activity.activityTypeId === _type.id);
                        if ((activityKindValue === 0 && _type.activityKind === ActivityKind.Records) || (activityKindValue === 1 && _type.activityKind === ActivityKind.Controls)) {
                            if (_type.activityCategoryId === activityValue) {
                                rows.push(
                                    <div
                                        key={'activity-' + _type.id + '-category-' + type.data[0].activityCategoryId}
                                        style={{
                                            display: 'flex',
                                            padding: '8px 16px',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            alignSelf: 'stretch',
                                            borderRadius: '8px',
                                            border: '1px solid ' + colors.neutral80,
                                            background: (activityCanBeDeleted?.canBeDeleted === false ? colors.neutral40 : colors.white),
                                            width: '100%',
                                            gap: '8px'
                                        }}
                                    >
                                        <CheckboxComponent
                                            size={CheckboxSize.SM}
                                            style={CheckboxStyle.SQUARE}
                                            checked={selectedActivities.includes(_type.id)}
                                            disabled={activityCanBeDeleted?.canBeDeleted === false}
                                            onChange={(event, checked) => {
                                                if (event.target.checked) {
                                                    setSelectedActivities([...selectedActivities.concat(_type.id)])
                                                }
                                                else {
                                                    setSelectedActivities([...selectedActivities.filter(activity => activity.toString() !== _type.id.toString())])
                                                }
                                            }}
                                        />
                                        <span className="text text--lg typography--medium typography-neutral--500">
                                            {_type.name}
                                        </span>
                                    </div>
                                );
                            }
                        }
                    });
                    activityRow.push({ categoryId: type.data[0].activityCategoryId, rows: rows });
                }
            });
            companyState.findCompanyCategoryResponse.forEach((category) => {
                newCategoryTab.push(<Tab label={category.name} value={category.id} key={category.id} />);
                tabsPanelsControls.push(
                    <TabPanelComponent value={activityValue} index={category.id} key={category.id}>
                        <div style={{ width: '100%', display: 'flex', gap: 12, flexDirection: 'column' }}>
                            {activityRow.filter(activity => activity.categoryId === activityValue).map(activity => activity.rows)}
                        </div>
                    </TabPanelComponent>
                );
            });
        }
    }
    return (
        <div>
            <div className="d-flex flex-column align-items-start justify-content-between w-100" style={{ gap: '16px', width: '100%' }}>
                <HorizontalTabSecondaryComponent
                    value={activityKindValue}
                    negativeMargin={36}
                    handleChange={handleChangeActivityKind}
                >
                    <Tab label="Registrazioni" value={0} />
                    <Tab label="Controlli" value={1} />
                </HorizontalTabSecondaryComponent>
                <HorizontalTabSecondaryComponent
                    value={activityValue}
                    negativeMargin={36}
                    handleChange={handleChangeActivity}>
                    {newCategoryTab}
                </HorizontalTabSecondaryComponent>
                {tabsPanelsControls}
            </div>
        </div>
    )
}