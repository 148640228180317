import { keycloak } from "../../keycloak";
import { InstrumentalVehicles } from "./instrumentalVehicles";
import { NotInstrumentalVehicles } from "./notInstrumentalVehicles";

export function VehiclesAndCars() {

    return (
        <div className="row m-0 p-0" style={{ gap: 24, width: '100%' }}>
            <div className="col-12 col-xl p-0 m-0">
                <InstrumentalVehicles disabled={!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('admin-accountant') && !keycloak.hasRealmRole('accountant') && !keycloak.hasRealmRole('operator')} />
            </div>
            <div className="col-12 col-xl p-0 m-0">
                <NotInstrumentalVehicles disabled={!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('financial-advisor') && !keycloak.hasRealmRole('admin-accountant') && !keycloak.hasRealmRole('accountant') && !keycloak.hasRealmRole('operator')} />
            </div>
        </div>
    )
}