import { StatusIndicatorColor, StatusIndicatorColorMap, StatusIndicatorIcon, StatusIndicatorSize, StatusIndicatorSizeMap } from "./dto";
import './style.scss';

interface Props {
    icon: StatusIndicatorIcon
    color: StatusIndicatorColor
    size: StatusIndicatorSize
    isButton?: boolean
    pulsing?: boolean
    onClick?: Function
    label?: string
}

export function StatusIndicatorComponent(props: Props) {
    const { icon } = props;
    const { size } = props;
    const { color } = props;
    const { isButton } = props;
    const { pulsing } = props;
    const { onClick } = props;
    const { label } = props;

    const pulsingBadgeColorMap = new Map<StatusIndicatorColor, string>([
        [StatusIndicatorColor.PRIMARY, 'pulsing-badge-primary '],
        [StatusIndicatorColor.ERROR, 'pulsing-badge-destructive '],
        [StatusIndicatorColor.ACTIVE, 'pulsing-badge-active ']
    ])

    const pulsingBadgeSizeMap = new Map<StatusIndicatorSize, string>([
        [StatusIndicatorSize.LG, 'pulsing-badge-lg '],
        [StatusIndicatorSize.MD, 'pulsing-badge-md '],
        [StatusIndicatorSize.SM, 'pulsing-badge-sm ']
    ])

    return (
        <div
            onClick={() => onClick && onClick()}
            style={{ display: 'flex', alignItems: 'center', gap: 4, flexDirection: (icon === StatusIndicatorIcon.LEFT ? 'row' : 'row-reverse'), position: 'relative', justifyContent: 'center', cursor: isButton ? 'pointer' : 'auto' }}>
            {
                icon !== StatusIndicatorIcon.OFF && (
                    <div
                        className={pulsing ? ((pulsingBadgeColorMap.get(color) || '') + pulsingBadgeSizeMap.get(size)) : ''}
                        style={{
                            height: StatusIndicatorSizeMap.get(size),
                            width: StatusIndicatorSizeMap.get(size),
                            borderRadius: 'calc(' + StatusIndicatorSizeMap.get(size) + ' / 2)',
                            backgroundColor: StatusIndicatorColorMap.get(color),
                            marginLeft: icon === StatusIndicatorIcon.RIGHT ? '4px' : '',
                            marginRight: icon === StatusIndicatorIcon.LEFT ? '4px' : '',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    />
                )
            }
            {
                icon !== StatusIndicatorIcon.ONLY && (
                    <span
                        className="button button--sm typography--medium typography-neutral--500">
                        {label}
                    </span>
                )
            }
        </div>
    )
}