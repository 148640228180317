import { CheckboxSize, CheckboxStyle } from "./dto";

export const CheckboxSizeMap = new Map<CheckboxSize, { placeholder: string, supportingText: string }>([
    [CheckboxSize.SM, {
        placeholder: 'text text--md typography--medium typography-neutral--800',
        supportingText: 'text text--md typography--regular typography-neutral--500'
    }],
    [CheckboxSize.MD, {
        placeholder: 'text text--lg typography--medium typography-neutral--800',
        supportingText: 'text text--lg typography--regular typography-neutral--500'
    }],
])

export const CheckboxStyleMap = new Map<string, { dim: string, borderRadius: string, outerDim: string, outerRadius: string }>([
    [
        JSON.stringify({
            size: CheckboxSize.SM,
            style: CheckboxStyle.CIRCLE
        }),
        {
            dim: '16px',
            borderRadius: '8px',
            outerDim: '24px',
            outerRadius: '12px'
        }
    ],
    [
        JSON.stringify({
            size: CheckboxSize.MD,
            style: CheckboxStyle.CIRCLE
        }),
        {
            dim: '20px',
            borderRadius: '12px',
            outerDim: '28px',
            outerRadius: '14px'
        }
    ],
    [
        JSON.stringify({
            size: CheckboxSize.SM,
            style: CheckboxStyle.SQUARE
        }),
        {
            dim: '16px',
            borderRadius: '4px',
            outerDim: '24px',
            outerRadius: '8px'
        }
    ],
    [
        JSON.stringify({
            size: CheckboxSize.MD,
            style: CheckboxStyle.SQUARE
        }),
        {
            dim: '20px',
            borderRadius: '4px',
            outerDim: '28px',
            outerRadius: '8px'
        }
    ],
])