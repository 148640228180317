import { useEffect, useState } from "react";
import { IconsProps } from "./dto";

export function PersonalcardIcon(props: IconsProps) {
    const [color, setColor] = useState<string>(props.colorBase)

    useEffect(() => {
        setColor(props.colorBase)
    }, [props.colorBase])

    return (
        <svg
            onMouseOver={() => setColor(props.colorHover !== undefined ? props.colorHover : props.colorBase)}
            onMouseOut={() => setColor(props.colorBase)}
            width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1666 18.125H5.83329C2.15829 18.125 1.04163 17.0083 1.04163 13.3333V6.66667C1.04163 2.99167 2.15829 1.875 5.83329 1.875H14.1666C17.8416 1.875 18.9583 2.99167 18.9583 6.66667V13.3333C18.9583 17.0083 17.8416 18.125 14.1666 18.125ZM5.83329 3.125C2.84996 3.125 2.29163 3.69167 2.29163 6.66667V13.3333C2.29163 16.3083 2.84996 16.875 5.83329 16.875H14.1666C17.15 16.875 17.7083 16.3083 17.7083 13.3333V6.66667C17.7083 3.69167 17.15 3.125 14.1666 3.125H5.83329Z" fill={color} />
            <path d="M15.8333 7.29169H11.6666C11.325 7.29169 11.0416 7.00835 11.0416 6.66669C11.0416 6.32502 11.325 6.04169 11.6666 6.04169H15.8333C16.175 6.04169 16.4583 6.32502 16.4583 6.66669C16.4583 7.00835 16.175 7.29169 15.8333 7.29169Z" fill={color} />
            <path d="M15.8333 10.625H12.5C12.1583 10.625 11.875 10.3417 11.875 10C11.875 9.65833 12.1583 9.375 12.5 9.375H15.8333C16.175 9.375 16.4583 9.65833 16.4583 10C16.4583 10.3417 16.175 10.625 15.8333 10.625Z" fill={color} />
            <path d="M15.8333 13.9584H14.1666C13.825 13.9584 13.5416 13.675 13.5416 13.3334C13.5416 12.9917 13.825 12.7084 14.1666 12.7084H15.8333C16.175 12.7084 16.4583 12.9917 16.4583 13.3334C16.4583 13.675 16.175 13.9584 15.8333 13.9584Z" fill={color} />
            <path d="M7.08328 10.0333C5.90828 10.0333 4.94995 9.07499 4.94995 7.9C4.94995 6.725 5.90828 5.76666 7.08328 5.76666C8.25828 5.76666 9.21662 6.725 9.21662 7.9C9.21662 9.07499 8.25828 10.0333 7.08328 10.0333ZM7.08328 7.01666C6.59995 7.01666 6.19995 7.41666 6.19995 7.9C6.19995 8.38333 6.59995 8.78333 7.08328 8.78333C7.56662 8.78333 7.96662 8.38333 7.96662 7.9C7.96662 7.41666 7.56662 7.01666 7.08328 7.01666Z" fill={color} />
            <path d="M10 14.2333C9.68338 14.2333 9.40838 13.9917 9.37504 13.6667C9.28338 12.7667 8.55838 12.0417 7.65004 11.9583C7.26671 11.925 6.88338 11.925 6.50004 11.9583C5.59171 12.0417 4.86671 12.7583 4.77504 13.6667C4.74171 14.0083 4.43338 14.2667 4.09171 14.225C3.75004 14.1917 3.50004 13.8833 3.53338 13.5417C3.68338 12.0417 4.87504 10.85 6.38338 10.7167C6.84171 10.675 7.30838 10.675 7.76671 10.7167C9.26671 10.8583 10.4667 12.05 10.6167 13.5417C10.65 13.8833 10.4 14.1917 10.0584 14.225C10.0417 14.2333 10.0167 14.2333 10 14.2333Z" fill={color} />
        </svg>
    )
}