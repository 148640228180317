import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmOrDenyModal } from "../confirmOrDenyModal.tsx";
import { ContextMenuComponent } from "../contextMenu";
import { MenuItemComponent } from "../contextMenu/menuItem";
import { EditIcon } from "../icons/edit";
import { TrashIcon } from "../icons/trash";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import colors from '../utils/index.module.scss';
import { deleteAccountant, setAccountantSettedId, setDeleteAccountantId, setOpenDeleteAccountantModal } from "./slice";

interface Props {
    id: string
}

export function AccountantMenuComponent(props: Props) {
    const { id } = props
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const accountantState = useAppSelector(state => state.accountant)

    return (
        <ContextMenuComponent anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
            <MenuItemComponent
                onClick={() => {
                    dispatch(setAccountantSettedId(id))
                    navigate('/edit-accountant/' + id)
                }}>
                <EditIcon colorBase={colors.neutral600} />
                Modifica
            </MenuItemComponent>
            <MenuItemComponent
                onClick={() => {
                    dispatch(setDeleteAccountantId(id))
                    dispatch(setOpenDeleteAccountantModal(true))
                }}>
                <TrashIcon colorBase={colors.neutral600} />
                Cancella
            </MenuItemComponent>
            <ConfirmOrDenyModal
                open={accountantState.openDeleteAccountantModal}
                handleClose={() => dispatch(setOpenDeleteAccountantModal(false))}
                title={"Elimina account"}
                description={"Sei sicuro di voler eliminare questo account?"}
                labelDeny={"Annulla"}
                labelConfirm={"Elimina"}
                actionConfirm={() => {
                    dispatch(deleteAccountant(id))
                    dispatch(setOpenDeleteAccountantModal(false))
                }}
            />
        </ContextMenuComponent>
    )
}