import { CompanyConfirmDTO } from "./dto";
import { CompanyToConfirmServiceImpl } from "./serviceImpl";

export interface CompanyToConfirmService {
    confirmCompany(data: CompanyConfirmDTO[]): Promise<void>
}

export function NewCompanyToConfirmService(): CompanyToConfirmService {
    return new CompanyToConfirmServiceImpl();
}
