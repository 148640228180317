import axios from "axios";
import { keycloak } from "../../keycloak";
import { EmployeeTypology, F24Typology, FinancialStatementsTypology, FindAllFiscalDocumentDTO, FiscalDocumentFilters, FiscalDocumentResponseDTO } from "./dtoDocument";
import { FiscalDocumentService } from "./serviceDocument";

export class FiscalDocumentServiceImpl implements FiscalDocumentService {

    public findAllFiscalDocuments(filters: FiscalDocumentFilters): Promise<FindAllFiscalDocumentDTO> {
        let url: string =
            "/api/fiscal-documents?companyId=" + filters.companyId +
            "&itemsPerPage=" + filters.itemsPerPage +
            (filters.fileName !== '' ? '&name=' + filters.fileName : '') +
            "&page=" + filters.page +
            (filters.month !== undefined && filters.month !== '' ? '&referenceMonth=' + filters.month : '') +
            (filters.year !== undefined ? '&referenceYear=' + filters.year : '') +
            (filters.status !== '' ? '&status=' + filters.status : '') +
            "&type=" + filters.type +
            (filters.typology !== ('' as FinancialStatementsTypology | '' as F24Typology | '' as EmployeeTypology) ? '&typology=' + filters.typology : ('' as FinancialStatementsTypology | '' as F24Typology | '' as EmployeeTypology))
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public createFiscalDocuments(request: FormData): Promise<void> {
        let url: string = "/api/fiscal-documents"
        return axios({
            url: url,
            method: "POST",
            data: request,
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
    public findByIdFiscalDocument(id: string): Promise<FiscalDocumentResponseDTO> {
        let url: string = "/api/fiscal-documents/" + id
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public deleteFiscalDocuments(id: string): Promise<void> {
        let url: string = "/api/fiscal-documents/" + id
        return axios({
            url: url,
            method: "DELETE",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then(() => undefined)
    }
}