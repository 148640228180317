export interface FindAllActivityCategory {
    total: number,
    page: number,
    perPage: number,
    prev: boolean,
    next: boolean,
    totalPage: number,
    data: ActivityCategory[]
}

export interface FindAllActivityType {
    total: number,
    page: number,
    perPage: number,
    prev: boolean,
    next: boolean,
    totalPage: number,
    data: ActivityType[]
}

export interface ActivityCategory {
    id: number,
    name: string,
    dueDay: number
}

export interface ActivityType {
    id: number,
    name: string,
    activityCategoryId: number
    activityKind: ActivityKind | ""
}

export interface ActivityTypeCreationDTO {
    name: string
    activityCategoryId: number
    activityKind: ActivityKind | ""
}

export interface ActivityTypeFilters {
    name: string
    page: number
    itemsPerPage: number
    activityCategoryId: string
}

export enum ActivityKind{
    Records = "Records",
    Controls = "Controls"
}

export const ActivityKindMap = new Map<ActivityKind | "", string>([
    [ActivityKind.Records, "Registrazioni"],
    [ActivityKind.Controls, "Controlli"]
])