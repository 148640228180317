import { createSlice } from "@reduxjs/toolkit"
import { keycloak } from "."
import { PromiseStatuses } from "../utils"

export const urlMap = new Map<string, string>([
    ['https://iltuocontabile.madfarm.it', 'wss://iltuocontabile.madfarm.it'],
    ['https://app.iltuocontabile.it', 'wss://app.iltuocontabile.it'],
    ['http://localhost:3000', 'ws://localhost:8086'],
])
interface SecuredState {
    userRoles: string[]
    keycloakInitStatus: PromiseStatuses
    socket?: WebSocket
}

const initialState: SecuredState = {
    userRoles: [],
    keycloakInitStatus: 'idle',
}

const securedSlice = createSlice({
    name: 'secured/slice',
    initialState,
    reducers: {
        setRoles: (state, action) => {
            state.userRoles = action.payload
        },
        setKeycloakInitStatus: (state, action) => {
            state.keycloakInitStatus = action.payload
        },
        setSocket: (state, action) => {
            state.socket = action.payload
        }
    },
})

export const {
    setRoles,
    setKeycloakInitStatus,
    setSocket
} = securedSlice.actions

export default securedSlice.reducer