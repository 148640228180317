import axios from "axios";
import { keycloak } from "../keycloak";
import { FindMeResponseDTO } from "./dto";
import { AuthService } from "./service";

export class AuthServiceImpl implements AuthService {

    public findMe(): Promise<FindMeResponseDTO> {
        let url: string = "/api/users/me"
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}
