import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { ActivityCategory } from "../../activity/dto"
import { ActvityGroupDashboard } from "../../dashboard/dto"
import { PromiseStatuses } from "../../utils"
import { ActivityDTO } from "../dto"
import { NewControlsService } from "./service"


export interface ControlsState {
    controlsInitialTab: number
    ordinaryControlsActivityGroups: ActvityGroupDashboard[]
    ordinaryControlsActivityCategories: ActivityCategory[]
    findActivityByIdStatus: PromiseStatuses
    findActivityByIdResponse?: ActivityDTO[]
}

const initialState: ControlsState = {
    controlsInitialTab: 0,
    ordinaryControlsActivityGroups: [],
    ordinaryControlsActivityCategories: [],
    findActivityByIdStatus: "idle"
}

export const findActivityById = createAsyncThunk(
    'controls/findActivityById',
    async (request: number[], thunkApi): Promise<ActivityDTO[]> => {
        let promises: Promise<ActivityDTO>[] = []
        promises = request.map(async (id) => {
            const companyService = NewControlsService()

            return companyService.findActivityById(id)
        })
        return Promise.all(promises)
    }
)

const controlSlice = createSlice({
    name: 'controls/slice',
    initialState,
    reducers: {
        setControlsInitialTab: (state, action) => {
            state.controlsInitialTab = action.payload
        },
        setOrdinaryControlsActivityGroups: (state, action) => {
            state.ordinaryControlsActivityGroups = action.payload
        },
        setOrdinaryControlsActivityCategories: (state, action) => {
            state.ordinaryControlsActivityCategories = action.payload
        },
    },
    extraReducers(builder) {
        builder
            .addCase(findActivityById.pending, (state => {
                state.findActivityByIdStatus = 'loading'
            }))
            .addCase(findActivityById.fulfilled, ((state, action) => {
                state.findActivityByIdStatus = 'successfully'
                state.findActivityByIdResponse = action.payload
            }))
            .addCase(findActivityById.rejected, ((state) => {
                state.findActivityByIdStatus = 'failed'
            }))
    },
})

export const {
    setControlsInitialTab,
    setOrdinaryControlsActivityGroups,
    setOrdinaryControlsActivityCategories
} = controlSlice.actions

export default controlSlice.reducer