import { MenuItem } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { FormGroup } from "../formGroup";
import { ModalComponent } from "../modal";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SelectComponent } from "../select";
import { TextInput } from "../textInput";
import { editTodos, setOpenCriticityModal } from "./slice";

export function CriticityModal() {
    const companyState = useAppSelector(state => state.company)
    const activityState = useAppSelector(state => state.activity)

    const [todoDescription, setTodoDescription] = useState<string>('')
    const [todoCategory, setTodoCategory] = useState<string>('')

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (companyState.criticityOperation === 'edit' && companyState.openCriticityModal) {
            setTodoDescription(companyState.todoToEdit.todo.split('::')[0])
            setTodoCategory(companyState.todoToEdit.todo.split('::')[1])
        }
    }, [companyState.criticityOperation, companyState.openCriticityModal])

    let menuItems: ReactNode[] = []

    if (
        companyState.findCompanyResponse !== undefined &&
        activityState.findAllActivityCategoryResponse !== undefined &&
        activityState.findAllActivityCategoryResponse.data !== undefined &&
        activityState.findAllActivityCategoryResponse.data.length > 0
    ) {
        companyState.findCompanyResponse.activityCategoryIds?.forEach(
            category => {
                activityState.findAllActivityCategoryResponse?.data.forEach(
                    categoryResponse => {
                        if (category.toString() === categoryResponse.id.toString()) {
                            menuItems.push(<MenuItem key={categoryResponse.id} value={categoryResponse.id.toString()}>{categoryResponse.name}</MenuItem>)
                        }
                    }
                )
            }
        )
    }

    return (
        <ModalComponent
            open={companyState.openCriticityModal}
            handleClose={() => dispatch(setOpenCriticityModal(false))}
        >
            <div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    padding: '20px',
                    gap: '16px',
                }}
                >
                    <span className="heading heading--xs typography--semibold typography-neutral--800">
                        {(companyState.criticityOperation === 'save' ? 'Aggiungi ' : 'Modifica ') + 'criticità'}
                    </span>
                    <FormGroup
                        label={"Tipologia"}
                        inputs={[
                            <SelectComponent
                                id={"criticity-list-category"}
                                key={"criticity-list-category"}
                                value={todoCategory}
                                menuItems={[<MenuItem key={'select-category'} value={''}>Seleziona la tipologia</MenuItem>, menuItems]}
                                onChange={e => setTodoCategory(e.target.value)}
                            />]}
                        style={"column"}
                    />
                    <FormGroup
                        label={"Descrizione"}
                        inputs={[
                            <TextInput
                                id={"criticity-list-description"}
                                key={"criticity-list-description"}
                                defaultValue={todoDescription}
                                type={"text"}
                                placeholder={""}
                                multiline
                                onChange={e => setTodoDescription(e.target.value)}
                            />]}
                        style={"column"}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '20px',
                        gap: '12px',
                        width: '100%',
                        height: '80px',
                    }}
                >
                    <div style={{ margin: 0, padding: 0, gap: '12px', width: '100%' }} className="row">
                        <div className="col" style={{ margin: 0, padding: 0 }}><ButtonComponent label={"Annulla"} onClick={() => dispatch(setOpenCriticityModal(false))} color={Colors.NEUTRAL} variant={Variant.OUTLINE} size={Size.FIT} iconStyle={IconStyle.OFF} /></div>
                        <div className="col" style={{ margin: 0, padding: 0 }}>
                            <ButtonComponent
                                label={"Aggiorna"}
                                onClick={() => {
                                    dispatch(setOpenCriticityModal(false))
                                    let todos: string[] = []
                                    if (companyState.findCompanyResponse !== undefined) {
                                        todos = companyState.findCompanyResponse.todos !== null ? [...companyState.findCompanyResponse.todos] : []
                                        if (companyState.criticityOperation === 'save') {
                                            todos.push(
                                                todoDescription + '::' + todoCategory
                                            )
                                        } else {
                                            todos[companyState.todoToEdit.index] = todoDescription + '::' + todoCategory
                                        }
                                    }
                                    dispatch(editTodos(
                                        {
                                            companyId: companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse.id.toString() : '',
                                            data: {
                                                todos: todos
                                            }
                                        }))
                                }}
                                color={Colors.PRIMARY}
                                variant={Variant.SOLID}
                                size={Size.FIT}
                                iconStyle={IconStyle.OFF}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ModalComponent >
    )
}